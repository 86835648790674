import React, { useState } from 'react';

import { Accordion, Card } from 'react-bootstrap';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import InputMask from 'react-input-mask';

import TextareaAutosize from 'react-textarea-autosize';

import Moment from 'moment';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function MemberEdit(props) {
    const [ firstname, setFirstname ] = useState(null);
    const [ lastname, setLastname ] = useState(null);
    const [ nationality, setNationality ] = useState(null);
    const [ personalid, setPersonalid ] = useState(null);
    const [ contactnumber, setContactnumber ] = useState(null);
    const [ birthdate, setBirthdate ] = useState(null);
    // user 1
    const [ firstname1, setFirstname1 ] = useState(null);
    const [ lastname1, setLastname1 ] = useState(null);
    const [ contactnumber1, setContactnumber1 ] = useState(null);
    const [ province1, setProvince1 ] = useState(null);
    const [ district1, setDistrict1 ] = useState(null);
    const [ subdistrict1, setSubdistrict1 ] = useState(null);
    const [ postalcode1, setPostalcode1 ] = useState(null);
    const [ address1, setAddress1 ] = useState(null);
    // user 2
    const [ firstname2, setFirstname2 ] = useState(null);
    const [ lastname2, setLastname2 ] = useState(null);
    const [ contactnumber2, setContactnumber2 ] = useState(null);
    const [ province2, setProvince2 ] = useState(null);
    const [ district2, setDistrict2 ] = useState(null);
    const [ subdistrict2, setSubdistrict2 ] = useState(null);
    const [ postalcode2, setPostalcode2 ] = useState(null);
    const [ address2, setAddress2 ] = useState(null);
    // user 3
    const [ firstname3, setFirstname3 ] = useState(null);
    const [ lastname3, setLastname3 ] = useState(null);
    const [ contactnumber3, setContactnumber3 ] = useState(null);
    const [ province3, setProvince3 ] = useState(null);
    const [ district3, setDistrict3 ] = useState(null);
    const [ subdistrict3, setSubdistrict3 ] = useState(null);
    const [ postalcode3, setPostalcode3 ] = useState(null);
    const [ address3, setAddress3 ] = useState(null);

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    const btnUpdate = () => {
        let param = {
            beneficiary1: {},
            beneficiary2: {},
            beneficiary3: {}
        };
        if (firstname !== null) {
            param['first_name'] = firstname;
        }
        if (lastname !== null) {
            param['last_name'] = lastname;
        }
        if (nationality !== null) {
            param['id_type'] = nationality;
        }
        if (personalid !== null) {
            param['personal_id'] = personalid;
        }
        if (contactnumber !== null) {
            param['contact_number'] = contactnumber;
        }
        if (birthdate !== null) {
            if (birthdate.length !== 10 || birthdate.includes('_')) {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณากรอกวัน/เดือน/ปีเกิดให้ครบถ้วน',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            } else {
                let birthdatelist = birthdate.split('/');
                let newBirthday = birthdatelist[2] + '-' + birthdatelist[1] + '-' + birthdatelist[0];
                if (parseInt(birthdatelist[1]) > 12 || parseInt(birthdatelist[1]) < 0) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: 'กรุณากรอกเดือนเกิดให้ถูกต้อง',
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                let maxdate = new Date(parseInt(birthdatelist[2]), parseInt(birthdatelist[1]), 0).getDate();
                if (parseInt(birthdatelist[0]) > maxdate || parseInt(birthdatelist[0]) < 0) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: 'กรุณากรอกวันเกิดให้ถูกต้อง',
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
                param['birthdate'] = newBirthday;
            }
        }
        if (firstname1 !== null) {
            param['beneficiary1']['contact_firstname'] = firstname1;
        }
        if (lastname1 !== null) {
            param['beneficiary1']['contact_lastname'] = lastname1;
        }
        if (contactnumber1 !== null) {
            param['beneficiary1']['contact_phonenumber'] = contactnumber1;
        }
        if (province1 !== null) {
            param['beneficiary1']['province'] = province1;
        }
        if (district1 !== null) {
            param['beneficiary1']['district'] = district1;
        }
        if (subdistrict1 !== null) {
            param['beneficiary1']['subdistrict'] = subdistrict1;
        }
        if (postalcode1 !== null) {
            param['beneficiary1']['postalcode'] = postalcode1;
        }
        if (address1 !== null) {
            param['beneficiary1']['address1'] = address1;
        }
        if (firstname2 !== null) {
            param['beneficiary2']['contact_firstname'] = firstname2;
        }
        if (lastname2 !== null) {
            param['beneficiary2']['contact_lastname'] = lastname2;
        }
        if (contactnumber2 !== null) {
            param['beneficiary2']['contact_phonenumber'] = contactnumber2;
        }
        if (province2 !== null) {
            param['beneficiary2']['province'] = province2;
        }
        if (district2 !== null) {
            param['beneficiary2']['district'] = district2;
        }
        if (subdistrict2 !== null) {
            param['beneficiary2']['subdistrict'] = subdistrict2;
        }
        if (postalcode2 !== null) {
            param['beneficiary2']['postalcode'] = postalcode2;
        }
        if (address2 !== null) {
            param['beneficiary2']['address1'] = address2;
        }
        if (firstname3 !== null) {
            param['beneficiary3']['contact_firstname'] = firstname3;
        }
        if (lastname3 !== null) {
            param['beneficiary3']['contact_lastname'] = lastname3;
        }
        if (contactnumber3 !== null) {
            param['beneficiary3']['contact_phonenumber'] = contactnumber3;
        }
        if (province3 !== null) {
            param['beneficiary3']['province'] = province3;
        }
        if (district3 !== null) {
            param['beneficiary3']['district'] = district3;
        }
        if (subdistrict3 !== null) {
            param['beneficiary3']['subdistrict'] = subdistrict3;
        }
        if (postalcode3 !== null) {
            param['beneficiary3']['postalcode'] = postalcode3;
        }
        if (address3 !== null) {
            param['beneficiary3']['address1'] = address3;
        }
        if (Object.keys(param['beneficiary1']).length === 0) {
            delete param.beneficiary1;
        }
        if (Object.keys(param['beneficiary2']).length === 0) {
            delete param.beneficiary2;
        }
        if (Object.keys(param['beneficiary3']).length === 0) {
            delete param.beneficiary3;
        }
        if (Object.keys(param).length > 0) {
            props.btnUpdate(props.info.id, param);
        }
    };
    return (
        <div className='row'>
            <div className='col-12 p-0'>
                <Accordion className='px-5' defaultActiveKey='0'>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey='0'>
                            <div className='card-title'>รายละเอียดสมาชิก</div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='0'>
                            <Card.Body>
                                <div className='row m-0'>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>ชื่อ</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='firstname'
                                            defaultValue={props.info.first_name}
                                            value={firstname}
                                            onChange={(e) => setFirstname(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>นามสกุล</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='lastname'
                                            defaultValue={props.info.last_name}
                                            value={lastname}
                                            onChange={(e) => setLastname(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>ประเภทข้อมูลประจำตัว</label>
                                        <div className='radio-inline form-control border-0'>
                                            <label className='radio radio-primary'>
                                                <input
                                                    type='radio'
                                                    name='nationality'
                                                    checked={props.info.id_type === '1' || nationality === '1'}
                                                    onChange={() => setNationality('1')}
                                                />
                                                <span />
                                                ไทย
                                            </label>
                                            <label className='radio radio-primary'>
                                                <input
                                                    type='radio'
                                                    name='nationality'
                                                    checked={props.info.id_type === '2' || nationality === '2'}
                                                    onChange={() => setNationality('2')}
                                                />
                                                <span />
                                                ต่างชาติ
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>หมายเลขประจำตัว</label>
                                        <input
                                            type='text'
                                            inputMode={
                                                props.info.id_type === '1' || nationality === '1' ? 'tel' : 'text'
                                            }
                                            className='form-control rounded-lg'
                                            name='personalid'
                                            defaultValue={props.info.personal_id}
                                            value={personalid}
                                            onChange={(e) => setPersonalid(e.target.value)}
                                            maxLength='13'
                                            onInput={maxLengthCheck}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>เบอร์ติดต่อ</label>
                                        <input
                                            type='text'
                                            inputMode='tel'
                                            className='form-control rounded-lg'
                                            name='contactnumber'
                                            defaultValue={props.info.contact_number}
                                            value={contactnumber}
                                            onChange={(e) => setContactnumber(e.target.value)}
                                            maxLength='10'
                                            onInput={maxLengthCheck}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>วัน/เดือน/ปีเกิด</label>
                                        <InputMask
                                            mask='99/99/9999'
                                            alwaysShowMask={true}
                                            name='birthdate'
                                            inputMode='tel'
                                            className='form-control rounded-lg'
                                            defaultValue={Moment(new Date(props.info.birthdate)).format('DD/MM/YYYY')}
                                            value={birthdate}
                                            onChange={(e) => setBirthdate(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 text-right'>
                                        <button
                                            type='button'
                                            className='btn btn-primary rounded-lg'
                                            onClick={btnUpdate}
                                        >
                                            บันทึกการเปลี่ยนแปลง
                                        </button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey='1'>
                            <div className='card-title'>รายละเอียดผู้รับผลประโยชน์</div>
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey='1'>
                            <Card.Body>
                                <div className='row m-0'>
                                    {/* <div className='col-12 col-md-6 form-group'>
                                        <label>ชื่อ</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='firstname1'
                                            defaultValue={
                                                props.info.beneficiary[0]['contact_firstname'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={firstname1}
                                            onChange={(e) => setFirstname1(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>นามสกุล</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='lastname1'
                                            defaultValue={
                                                props.info.beneficiary[0]['contact_lastname'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={lastname1}
                                            onChange={(e) => setLastname1(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group d-block'>
                                        <label>เบอร์ติดต่อ</label>
                                        <input
                                            type='text'
                                            inputMode='tel'
                                            className='form-control rounded-lg'
                                            name='contactnumber1'
                                            defaultValue={
                                                props.info.beneficiary[0]['contact_phonenumber'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={contactnumber1}
                                            onChange={(e) => setContactnumber1(e.target.value)}
                                            maxLength='10'
                                            onInput={maxLengthCheck}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>จังหวัด</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='province1'
                                            defaultValue={
                                                props.info.beneficiary[0]['province'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={province1}
                                            onChange={(e) => setProvince1(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>อำเภอ / เขต</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='district1'
                                            defaultValue={
                                                props.info.beneficiary[0]['district'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={district1}
                                            onChange={(e) => setDistrict1(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>ตำบล / แขวง</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='subdistrict1'
                                            defaultValue={
                                                props.info.beneficiary[0]['subdistrict'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={subdistrict1}
                                            onChange={(e) => setSubdistrict1(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>รหัสไปรษณีย์</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='postalcode1'
                                            defaultValue={
                                                props.info.beneficiary[0]['postalcode'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={postalcode1}
                                            onChange={(e) => setPostalcode1(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 form-group'>
                                        <label>ที่อยู่</label>
                                        <TextareaAutosize
                                            name='address1'
                                            className='form-control rounded-lg'
                                            maxLength='255'
                                            defaultValue={
                                                props.info.beneficiary[0]['address1'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={address1}
                                            onChange={(e) => setAddress1(e.target.value)}
                                        />
                                    </div>
                                    <br />
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>ชื่อ</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='firstname2'
                                            defaultValue={
                                                props.info.beneficiary[1]['contact_firstname'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={firstname2}
                                            onChange={(e) => setFirstname2(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>นามสกุล</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='lastname2'
                                            defaultValue={
                                                props.info.beneficiary[1]['contact_lastname'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={lastname2}
                                            onChange={(e) => setLastname2(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group d-block'>
                                        <label>เบอร์ติดต่อ</label>
                                        <input
                                            type='text'
                                            inputMode='tel'
                                            className='form-control rounded-lg'
                                            name='contactnumber2'
                                            defaultValue={
                                                props.info.beneficiary[1]['contact_phonenumber'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={contactnumber2}
                                            onChange={(e) => setContactnumber2(e.target.value)}
                                            maxLength='10'
                                            onInput={maxLengthCheck}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>จังหวัด</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='province2'
                                            defaultValue={
                                                props.info.beneficiary[1]['province'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={province2}
                                            onChange={(e) => setProvince2(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>อำเภอ / เขต</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='district2'
                                            defaultValue={
                                                props.info.beneficiary[1]['district'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={district2}
                                            onChange={(e) => setDistrict2(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>ตำบล / แขวง</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='subdistrict2'
                                            defaultValue={
                                                props.info.beneficiary[1]['subdistrict'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={subdistrict2}
                                            onChange={(e) => setSubdistrict2(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>รหัสไปรษณีย์</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='postalcode2'
                                            defaultValue={
                                                props.info.beneficiary[1]['postalcode'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={postalcode2}
                                            onChange={(e) => setPostalcode2(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 form-group'>
                                        <label>ที่อยู่</label>
                                        <TextareaAutosize
                                            name='address2'
                                            className='form-control rounded-lg'
                                            maxLength='255'
                                            defaultValue={
                                                props.info.beneficiary[1]['address1'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={address2}
                                            onChange={(e) => setAddress2(e.target.value)}
                                        />
                                    </div>
                                    <br />
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>ชื่อ</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='firstname3'
                                            defaultValue={
                                                props.info.beneficiary[2]['contact_firstname'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={firstname3}
                                            onChange={(e) => setFirstname3(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>นามสกุล</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='lastname3'
                                            defaultValue={
                                                props.info.beneficiary[2]['contact_lastname'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={lastname3}
                                            onChange={(e) => setLastname3(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group d-block'>
                                        <label>เบอร์ติดต่อ</label>
                                        <input
                                            type='text'
                                            inputMode='tel'
                                            className='form-control rounded-lg'
                                            name='contactnumber3'
                                            defaultValue={
                                                props.info.beneficiary[2]['contact_phonenumber'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={contactnumber3}
                                            onChange={(e) => setContactnumber3(e.target.value)}
                                            maxLength='10'
                                            onInput={maxLengthCheck}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>จังหวัด</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='province3'
                                            defaultValue={
                                                props.info.beneficiary[2]['province'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={province3}
                                            onChange={(e) => setProvince3(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>อำเภอ / เขต</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='district3'
                                            defaultValue={
                                                props.info.beneficiary[2]['district'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={district3}
                                            onChange={(e) => setDistrict3(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>ตำบล / แขวง</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='subdistrict3'
                                            defaultValue={
                                                props.info.beneficiary[2]['subdistrict'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={subdistrict3}
                                            onChange={(e) => setSubdistrict3(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 col-md-6 form-group'>
                                        <label>รหัสไปรษณีย์</label>
                                        <input
                                            type='text'
                                            className='form-control rounded-lg'
                                            name='postalcode3'
                                            defaultValue={
                                                props.info.beneficiary[2]['postalcode'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={postalcode3}
                                            onChange={(e) => setPostalcode3(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-12 form-group'>
                                        <label>ที่อยู่</label>
                                        <TextareaAutosize
                                            name='address3'
                                            className='form-control rounded-lg'
                                            maxLength='255'
                                            defaultValue={
                                                props.info.beneficiary[2]['address1'] !== undefined ? (
                                                    props.info.beneficiary[0]['contact_firstname']
                                                ) : (
                                                    ''
                                                )
                                            }
                                            value={address3}
                                            onChange={(e) => setAddress3(e.target.value)}
                                        />
                                    </div> */}
                                    <div className='col-12 text-right'>
                                        <button
                                            type='button'
                                            className='btn btn-primary rounded-lg'
                                            onClick={btnUpdate}
                                        >
                                            บันทึกการเปลี่ยนแปลง
                                        </button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        </div>
    );
}
