const initaddress = {
    geo: [
        {
            value: '1',
            label: 'ภาคเหนือ'
        },
        {
            value: '2',
            label: 'ภาคกลาง'
        },
        {
            value: '3',
            label: 'ภาคตะวันออกเฉียงเหนือ'
        },
        {
            value: '4',
            label: 'ภาคตะวันตก'
        },
        {
            value: '5',
            label: 'ภาคตะวันออก'
        },
        {
            value: '6',
            label: 'ภาคใต้'
        }
    ],
    province: [
        {
            geoid: '2',
            value: '1',
            label: 'กรุงเทพมหานคร'
        },
        {
            geoid: '2',
            value: '2',
            label: 'สมุทรปราการ'
        },
        {
            geoid: '2',
            value: '3',
            label: 'นนทบุรี'
        },
        {
            geoid: '2',
            value: '4',
            label: 'ปทุมธานี'
        },
        {
            geoid: '2',
            value: '5',
            label: 'พระนครศรีอยุธยา'
        },
        {
            geoid: '2',
            value: '6',
            label: 'อ่างทอง'
        },
        {
            geoid: '2',
            value: '7',
            label: 'ลพบุรี'
        },
        {
            geoid: '2',
            value: '8',
            label: 'สิงห์บุรี'
        },
        {
            geoid: '2',
            value: '9',
            label: 'ชัยนาท'
        },
        {
            geoid: '2',
            value: '10',
            label: 'สระบุรี'
        },
        {
            geoid: '5',
            value: '11',
            label: 'ชลบุรี'
        },
        {
            geoid: '5',
            value: '12',
            label: 'ระยอง'
        },
        {
            geoid: '5',
            value: '13',
            label: 'จันทบุรี'
        },
        {
            geoid: '5',
            value: '14',
            label: 'ตราด'
        },
        {
            geoid: '5',
            value: '15',
            label: 'ฉะเชิงเทรา'
        },
        {
            geoid: '5',
            value: '16',
            label: 'ปราจีนบุรี'
        },
        {
            geoid: '2',
            value: '17',
            label: 'นครนายก'
        },
        {
            geoid: '5',
            value: '18',
            label: 'สระแก้ว'
        },
        {
            geoid: '3',
            value: '19',
            label: 'นครราชสีมา'
        },
        {
            geoid: '3',
            value: '20',
            label: 'บุรีรัมย์'
        },
        {
            geoid: '3',
            value: '21',
            label: 'สุรินทร์'
        },
        {
            geoid: '3',
            value: '22',
            label: 'ศรีสะเกษ'
        },
        {
            geoid: '3',
            value: '23',
            label: 'อุบลราชธานี'
        },
        {
            geoid: '3',
            value: '24',
            label: 'ยโสธร'
        },
        {
            geoid: '3',
            value: '25',
            label: 'ชัยภูมิ'
        },
        {
            geoid: '3',
            value: '26',
            label: 'อำนาจเจริญ'
        },
        {
            geoid: '3',
            value: '27',
            label: 'หนองบัวลำภู'
        },
        {
            geoid: '3',
            value: '28',
            label: 'ขอนแก่น'
        },
        {
            geoid: '3',
            value: '29',
            label: 'อุดรธานี'
        },
        {
            geoid: '3',
            value: '30',
            label: 'เลย'
        },
        {
            geoid: '3',
            value: '31',
            label: 'หนองคาย'
        },
        {
            geoid: '3',
            value: '32',
            label: 'มหาสารคาม'
        },
        {
            geoid: '3',
            value: '33',
            label: 'ร้อยเอ็ด'
        },
        {
            geoid: '3',
            value: '34',
            label: 'กาฬสินธุ์'
        },
        {
            geoid: '3',
            value: '35',
            label: 'สกลนคร'
        },
        {
            geoid: '3',
            value: '36',
            label: 'นครพนม'
        },
        {
            geoid: '3',
            value: '37',
            label: 'มุกดาหาร'
        },
        {
            geoid: '1',
            value: '38',
            label: 'เชียงใหม่'
        },
        {
            geoid: '1',
            value: '39',
            label: 'ลำพูน'
        },
        {
            geoid: '1',
            value: '40',
            label: 'ลำปาง'
        },
        {
            geoid: '1',
            value: '41',
            label: 'อุตรดิตถ์'
        },
        {
            geoid: '1',
            value: '42',
            label: 'แพร่'
        },
        {
            geoid: '1',
            value: '43',
            label: 'น่าน'
        },
        {
            geoid: '1',
            value: '44',
            label: 'พะเยา'
        },
        {
            geoid: '1',
            value: '45',
            label: 'เชียงราย'
        },
        {
            geoid: '1',
            value: '46',
            label: 'แม่ฮ่องสอน'
        },
        {
            geoid: '2',
            value: '47',
            label: 'นครสวรรค์'
        },
        {
            geoid: '2',
            value: '48',
            label: 'อุทัยธานี'
        },
        {
            geoid: '2',
            value: '49',
            label: 'กำแพงเพชร'
        },
        {
            geoid: '4',
            value: '50',
            label: 'ตาก'
        },
        {
            geoid: '2',
            value: '51',
            label: 'สุโขทัย'
        },
        {
            geoid: '2',
            value: '52',
            label: 'พิษณุโลก'
        },
        {
            geoid: '2',
            value: '53',
            label: 'พิจิตร'
        },
        {
            geoid: '2',
            value: '54',
            label: 'เพชรบูรณ์'
        },
        {
            geoid: '4',
            value: '55',
            label: 'ราชบุรี'
        },
        {
            geoid: '4',
            value: '56',
            label: 'กาญจนบุรี'
        },
        {
            geoid: '2',
            value: '57',
            label: 'สุพรรณบุรี'
        },
        {
            geoid: '2',
            value: '58',
            label: 'นครปฐม'
        },
        {
            geoid: '2',
            value: '59',
            label: 'สมุทรสาคร'
        },
        {
            geoid: '2',
            value: '60',
            label: 'สมุทรสงคราม'
        },
        {
            geoid: '4',
            value: '61',
            label: 'เพชรบุรี'
        },
        {
            geoid: '4',
            value: '62',
            label: 'ประจวบคีรีขันธ์'
        },
        {
            geoid: '6',
            value: '63',
            label: 'นครศรีธรรมราช'
        },
        {
            geoid: '6',
            value: '64',
            label: 'กระบี่'
        },
        {
            geoid: '6',
            value: '65',
            label: 'พังงา'
        },
        {
            geoid: '6',
            value: '66',
            label: 'ภูเก็ต'
        },
        {
            geoid: '6',
            value: '67',
            label: 'สุราษฎร์ธานี'
        },
        {
            geoid: '6',
            value: '68',
            label: 'ระนอง'
        },
        {
            geoid: '6',
            value: '69',
            label: 'ชุมพร'
        },
        {
            geoid: '6',
            value: '70',
            label: 'สงขลา'
        },
        {
            geoid: '6',
            value: '71',
            label: 'สตูล'
        },
        {
            geoid: '6',
            value: '72',
            label: 'ตรัง'
        },
        {
            geoid: '6',
            value: '73',
            label: 'พัทลุง'
        },
        {
            geoid: '6',
            value: '74',
            label: 'ปัตตานี'
        },
        {
            geoid: '6',
            value: '75',
            label: 'ยะลา'
        },
        {
            geoid: '6',
            value: '76',
            label: 'นราธิวาส'
        },
        {
            geoid: '3',
            value: '77',
            label: 'บึงกาฬ'
        }
    ],
    district: [
        {
            geoid: '2',
            provinceid: '1',
            value: '1',
            label: 'เขตพระนคร'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '2',
            label: 'เขตดุสิต'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '3',
            label: 'เขตหนองจอก'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '4',
            label: 'เขตบางรัก'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '5',
            label: 'เขตบางเขน'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '6',
            label: 'เขตบางกะปิ'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '7',
            label: 'เขตปทุมวัน'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '8',
            label: 'เขตป้อมปราบศัตรูพ่าย'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '9',
            label: 'เขตพระโขนง'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '10',
            label: 'เขตมีนบุรี'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '11',
            label: 'เขตลาดกระบัง'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '12',
            label: 'เขตยานนาวา'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '13',
            label: 'เขตสัมพันธวงศ์'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '14',
            label: 'เขตพญาไท'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '15',
            label: 'เขตธนบุรี'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '16',
            label: 'เขตบางกอกใหญ่'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '17',
            label: 'เขตห้วยขวาง'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '18',
            label: 'เขตคลองสาน'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '19',
            label: 'เขตตลิ่งชัน'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '20',
            label: 'เขตบางกอกน้อย'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '21',
            label: 'เขตบางขุนเทียน'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '22',
            label: 'เขตภาษีเจริญ'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '23',
            label: 'เขตหนองแขม'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '24',
            label: 'เขตราษฎร์บูรณะ'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '25',
            label: 'เขตบางพลัด'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '26',
            label: 'เขตดินแดง'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '27',
            label: 'เขตบึงกุ่ม'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '28',
            label: 'เขตสาทร'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '29',
            label: 'เขตบางซื่อ'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '30',
            label: 'เขตจตุจักร'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '31',
            label: 'เขตบางคอแหลม'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '32',
            label: 'เขตประเวศ'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '33',
            label: 'เขตคลองเตย'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '34',
            label: 'เขตสวนหลวง'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '35',
            label: 'เขตจอมทอง'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '36',
            label: 'เขตดอนเมือง'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '37',
            label: 'เขตราชเทวี'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '38',
            label: 'เขตลาดพร้าว'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '39',
            label: 'เขตวัฒนา'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '40',
            label: 'เขตบางแค'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '41',
            label: 'เขตหลักสี่'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '42',
            label: 'เขตสายไหม'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '43',
            label: 'เขตคันนายาว'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '44',
            label: 'เขตสะพานสูง'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '45',
            label: 'เขตวังทองหลาง'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '46',
            label: 'เขตคลองสามวา'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '47',
            label: 'เขตบางนา'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '48',
            label: 'เขตทวีวัฒนา'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '49',
            label: 'เขตทุ่งครุ'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '50',
            label: 'เขตบางบอน'
        },
        {
            geoid: '2',
            provinceid: '1',
            value: '51',
            label: 'บ้านทะวาย'
        },
        {
            geoid: '2',
            provinceid: '2',
            value: '52',
            label: 'เมืองสมุทรปราการ'
        },
        {
            geoid: '2',
            provinceid: '2',
            value: '53',
            label: 'บางบ่อ'
        },
        {
            geoid: '2',
            provinceid: '2',
            value: '54',
            label: 'บางพลี'
        },
        {
            geoid: '2',
            provinceid: '2',
            value: '55',
            label: 'พระประแดง'
        },
        {
            geoid: '2',
            provinceid: '2',
            value: '56',
            label: 'พระสมุทรเจดีย์'
        },
        {
            geoid: '2',
            provinceid: '2',
            value: '57',
            label: 'บางเสาธง'
        },
        {
            geoid: '2',
            provinceid: '3',
            value: '58',
            label: 'เมืองนนทบุรี'
        },
        {
            geoid: '2',
            provinceid: '3',
            value: '59',
            label: 'บางกรวย'
        },
        {
            geoid: '2',
            provinceid: '3',
            value: '60',
            label: 'บางใหญ่'
        },
        {
            geoid: '2',
            provinceid: '3',
            value: '61',
            label: 'บางบัวทอง'
        },
        {
            geoid: '2',
            provinceid: '3',
            value: '62',
            label: 'ไทรน้อย'
        },
        {
            geoid: '2',
            provinceid: '3',
            value: '63',
            label: 'ปากเกร็ด'
        },
        {
            geoid: '2',
            provinceid: '3',
            value: '64',
            label: 'เทศบาลนครนนทบุรี (สาขาแขวงท่าทราย)'
        },
        {
            geoid: '2',
            provinceid: '3',
            value: '65',
            label: 'เทศบาลเมืองปากเกร็ด'
        },
        {
            geoid: '2',
            provinceid: '4',
            value: '66',
            label: 'เมืองปทุมธานี'
        },
        {
            geoid: '2',
            provinceid: '4',
            value: '67',
            label: 'คลองหลวง'
        },
        {
            geoid: '2',
            provinceid: '4',
            value: '68',
            label: 'ธัญบุรี'
        },
        {
            geoid: '2',
            provinceid: '4',
            value: '69',
            label: 'หนองเสือ'
        },
        {
            geoid: '2',
            provinceid: '4',
            value: '70',
            label: 'ลาดหลุมแก้ว'
        },
        {
            geoid: '2',
            provinceid: '4',
            value: '71',
            label: 'ลำลูกกา'
        },
        {
            geoid: '2',
            provinceid: '4',
            value: '72',
            label: 'สามโคก'
        },
        {
            geoid: '2',
            provinceid: '4',
            value: '73',
            label: 'ลำลูกกา (สาขาตำบลคูคต)'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '74',
            label: 'พระนครศรีอยุธยา'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '75',
            label: 'ท่าเรือ'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '76',
            label: 'นครหลวง'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '77',
            label: 'บางไทร'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '78',
            label: 'บางบาล'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '79',
            label: 'บางปะอิน'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '80',
            label: 'บางปะหัน'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '81',
            label: 'ผักไห่'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '82',
            label: 'ภาชี'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '83',
            label: 'ลาดบัวหลวง'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '84',
            label: 'วังน้อย'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '85',
            label: 'เสนา'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '86',
            label: 'บางซ้าย'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '87',
            label: 'อุทัย'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '88',
            label: 'มหาราช'
        },
        {
            geoid: '2',
            provinceid: '5',
            value: '89',
            label: 'บ้านแพรก'
        },
        {
            geoid: '2',
            provinceid: '6',
            value: '90',
            label: 'เมืองอ่างทอง'
        },
        {
            geoid: '2',
            provinceid: '6',
            value: '91',
            label: 'ไชโย'
        },
        {
            geoid: '2',
            provinceid: '6',
            value: '92',
            label: 'ป่าโมก'
        },
        {
            geoid: '2',
            provinceid: '6',
            value: '93',
            label: 'โพธิ์ทอง'
        },
        {
            geoid: '2',
            provinceid: '6',
            value: '94',
            label: 'แสวงหา'
        },
        {
            geoid: '2',
            provinceid: '6',
            value: '95',
            label: 'วิเศษชัยชาญ'
        },
        {
            geoid: '2',
            provinceid: '6',
            value: '96',
            label: 'สามโก้'
        },
        {
            geoid: '2',
            provinceid: '7',
            value: '97',
            label: 'เมืองลพบุรี'
        },
        {
            geoid: '2',
            provinceid: '7',
            value: '98',
            label: 'พัฒนานิคม'
        },
        {
            geoid: '2',
            provinceid: '7',
            value: '99',
            label: 'โคกสำโรง'
        },
        {
            geoid: '2',
            provinceid: '7',
            value: '100',
            label: 'ชัยบาดาล'
        },
        {
            geoid: '2',
            provinceid: '7',
            value: '101',
            label: 'ท่าวุ้ง'
        },
        {
            geoid: '2',
            provinceid: '7',
            value: '102',
            label: 'บ้านหมี่'
        },
        {
            geoid: '2',
            provinceid: '7',
            value: '103',
            label: 'ท่าหลวง'
        },
        {
            geoid: '2',
            provinceid: '7',
            value: '104',
            label: 'สระโบสถ์'
        },
        {
            geoid: '2',
            provinceid: '7',
            value: '105',
            label: 'โคกเจริญ'
        },
        {
            geoid: '2',
            provinceid: '7',
            value: '106',
            label: 'ลำสนธิ'
        },
        {
            geoid: '2',
            provinceid: '7',
            value: '107',
            label: 'หนองม่วง'
        },
        {
            geoid: '2',
            provinceid: '7',
            value: '108',
            label: 'อ.บ้านเช่า  จ.ลพบุรี'
        },
        {
            geoid: '2',
            provinceid: '8',
            value: '109',
            label: 'เมืองสิงห์บุรี'
        },
        {
            geoid: '2',
            provinceid: '8',
            value: '110',
            label: 'บางระจัน'
        },
        {
            geoid: '2',
            provinceid: '8',
            value: '111',
            label: 'ค่ายบางระจัน'
        },
        {
            geoid: '2',
            provinceid: '8',
            value: '112',
            label: 'พรหมบุรี'
        },
        {
            geoid: '2',
            provinceid: '8',
            value: '113',
            label: 'ท่าช้าง'
        },
        {
            geoid: '2',
            provinceid: '8',
            value: '114',
            label: 'อินทร์บุรี'
        },
        {
            geoid: '2',
            provinceid: '9',
            value: '115',
            label: 'เมืองชัยนาท'
        },
        {
            geoid: '2',
            provinceid: '9',
            value: '116',
            label: 'มโนรมย์'
        },
        {
            geoid: '2',
            provinceid: '9',
            value: '117',
            label: 'วัดสิงห์'
        },
        {
            geoid: '2',
            provinceid: '9',
            value: '118',
            label: 'สรรพยา'
        },
        {
            geoid: '2',
            provinceid: '9',
            value: '119',
            label: 'สรรคบุรี'
        },
        {
            geoid: '2',
            provinceid: '9',
            value: '120',
            label: 'หันคา'
        },
        {
            geoid: '2',
            provinceid: '9',
            value: '121',
            label: 'หนองมะโมง'
        },
        {
            geoid: '2',
            provinceid: '9',
            value: '122',
            label: 'เนินขาม'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '123',
            label: 'เมืองสระบุรี'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '124',
            label: 'แก่งคอย'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '125',
            label: 'หนองแค'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '126',
            label: 'วิหารแดง'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '127',
            label: 'หนองแซง'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '128',
            label: 'บ้านหมอ'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '129',
            label: 'ดอนพุด'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '130',
            label: 'หนองโดน'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '131',
            label: 'พระพุทธบาท'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '132',
            label: 'เสาไห้'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '133',
            label: 'มวกเหล็ก'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '134',
            label: 'วังม่วง'
        },
        {
            geoid: '2',
            provinceid: '10',
            value: '135',
            label: 'เฉลิมพระเกียรติ'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '136',
            label: 'เมืองชลบุรี'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '137',
            label: 'บ้านบึง'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '138',
            label: 'หนองใหญ่'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '139',
            label: 'บางละมุง'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '140',
            label: 'พานทอง'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '141',
            label: 'พนัสนิคม'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '142',
            label: 'ศรีราชา'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '143',
            label: 'เกาะสีชัง'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '144',
            label: 'สัตหีบ'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '145',
            label: 'บ่อทอง'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '146',
            label: 'เกาะจันทร์'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '147',
            label: 'สัตหีบ (สาขาตำบลบางเสร่)'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '148',
            label: 'ท้องถิ่นเทศบาลเมืองหนองปรือ'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '149',
            label: 'เทศบาลตำบลแหลมฉบัง'
        },
        {
            geoid: '5',
            provinceid: '11',
            value: '150',
            label: 'เทศบาลเมืองชลบุรี'
        },
        {
            geoid: '5',
            provinceid: '12',
            value: '151',
            label: 'เมืองระยอง'
        },
        {
            geoid: '5',
            provinceid: '12',
            value: '152',
            label: 'บ้านฉาง'
        },
        {
            geoid: '5',
            provinceid: '12',
            value: '153',
            label: 'แกลง'
        },
        {
            geoid: '5',
            provinceid: '12',
            value: '154',
            label: 'วังจันทร์'
        },
        {
            geoid: '5',
            provinceid: '12',
            value: '155',
            label: 'บ้านค่าย'
        },
        {
            geoid: '5',
            provinceid: '12',
            value: '156',
            label: 'ปลวกแดง'
        },
        {
            geoid: '5',
            provinceid: '12',
            value: '157',
            label: 'เขาชะเมา'
        },
        {
            geoid: '5',
            provinceid: '12',
            value: '158',
            label: 'นิคมพัฒนา'
        },
        {
            geoid: '5',
            provinceid: '12',
            value: '159',
            label: 'สาขาตำบลมาบข่า'
        },
        {
            geoid: '5',
            provinceid: '13',
            value: '160',
            label: 'เมืองจันทบุรี'
        },
        {
            geoid: '5',
            provinceid: '13',
            value: '161',
            label: 'ขลุง'
        },
        {
            geoid: '5',
            provinceid: '13',
            value: '162',
            label: 'ท่าใหม่'
        },
        {
            geoid: '5',
            provinceid: '13',
            value: '163',
            label: 'โป่งน้ำร้อน'
        },
        {
            geoid: '5',
            provinceid: '13',
            value: '164',
            label: 'มะขาม'
        },
        {
            geoid: '5',
            provinceid: '13',
            value: '165',
            label: 'แหลมสิงห์'
        },
        {
            geoid: '5',
            provinceid: '13',
            value: '166',
            label: 'สอยดาว'
        },
        {
            geoid: '5',
            provinceid: '13',
            value: '167',
            label: 'แก่งหางแมว'
        },
        {
            geoid: '5',
            provinceid: '13',
            value: '168',
            label: 'นายายอาม'
        },
        {
            geoid: '5',
            provinceid: '13',
            value: '169',
            label: 'เขาคิชฌกูฏ'
        },
        {
            geoid: '5',
            provinceid: '13',
            value: '170',
            label: 'กิ่ง อ.กำพุธ จ.จันทบุรี'
        },
        {
            geoid: '5',
            provinceid: '14',
            value: '171',
            label: 'เมืองตราด'
        },
        {
            geoid: '5',
            provinceid: '14',
            value: '172',
            label: 'คลองใหญ่'
        },
        {
            geoid: '5',
            provinceid: '14',
            value: '173',
            label: 'เขาสมิง'
        },
        {
            geoid: '5',
            provinceid: '14',
            value: '174',
            label: 'บ่อไร่'
        },
        {
            geoid: '5',
            provinceid: '14',
            value: '175',
            label: 'แหลมงอบ'
        },
        {
            geoid: '5',
            provinceid: '14',
            value: '176',
            label: 'เกาะกูด'
        },
        {
            geoid: '5',
            provinceid: '14',
            value: '177',
            label: 'เกาะช้าง'
        },
        {
            geoid: '5',
            provinceid: '15',
            value: '178',
            label: 'เมืองฉะเชิงเทรา'
        },
        {
            geoid: '5',
            provinceid: '15',
            value: '179',
            label: 'บางคล้า'
        },
        {
            geoid: '5',
            provinceid: '15',
            value: '180',
            label: 'บางน้ำเปรี้ยว'
        },
        {
            geoid: '5',
            provinceid: '15',
            value: '181',
            label: 'บางปะกง'
        },
        {
            geoid: '5',
            provinceid: '15',
            value: '182',
            label: 'บ้านโพธิ์'
        },
        {
            geoid: '5',
            provinceid: '15',
            value: '183',
            label: 'พนมสารคาม'
        },
        {
            geoid: '5',
            provinceid: '15',
            value: '184',
            label: 'ราชสาส์น'
        },
        {
            geoid: '5',
            provinceid: '15',
            value: '185',
            label: 'สนามชัยเขต'
        },
        {
            geoid: '5',
            provinceid: '15',
            value: '186',
            label: 'แปลงยาว'
        },
        {
            geoid: '5',
            provinceid: '15',
            value: '187',
            label: 'ท่าตะเกียบ'
        },
        {
            geoid: '5',
            provinceid: '15',
            value: '188',
            label: 'คลองเขื่อน'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '189',
            label: 'เมืองปราจีนบุรี'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '190',
            label: 'กบินทร์บุรี'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '191',
            label: 'นาดี'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '192',
            label: 'สระแก้ว'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '193',
            label: 'วังน้ำเย็น'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '194',
            label: 'บ้านสร้าง'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '195',
            label: 'ประจันตคาม'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '196',
            label: 'ศรีมหาโพธิ'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '197',
            label: 'ศรีมโหสถ'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '198',
            label: 'อรัญประเทศ'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '199',
            label: 'ตาพระยา'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '200',
            label: 'วัฒนานคร'
        },
        {
            geoid: '5',
            provinceid: '16',
            value: '201',
            label: 'คลองหาด'
        },
        {
            geoid: '2',
            provinceid: '17',
            value: '202',
            label: 'เมืองนครนายก'
        },
        {
            geoid: '2',
            provinceid: '17',
            value: '203',
            label: 'ปากพลี'
        },
        {
            geoid: '2',
            provinceid: '17',
            value: '204',
            label: 'บ้านนา'
        },
        {
            geoid: '2',
            provinceid: '17',
            value: '205',
            label: 'องครักษ์'
        },
        {
            geoid: '5',
            provinceid: '18',
            value: '206',
            label: 'เมืองสระแก้ว'
        },
        {
            geoid: '5',
            provinceid: '18',
            value: '207',
            label: 'คลองหาด'
        },
        {
            geoid: '5',
            provinceid: '18',
            value: '208',
            label: 'ตาพระยา'
        },
        {
            geoid: '5',
            provinceid: '18',
            value: '209',
            label: 'วังน้ำเย็น'
        },
        {
            geoid: '5',
            provinceid: '18',
            value: '210',
            label: 'วัฒนานคร'
        },
        {
            geoid: '5',
            provinceid: '18',
            value: '211',
            label: 'อรัญประเทศ'
        },
        {
            geoid: '5',
            provinceid: '18',
            value: '212',
            label: 'เขาฉกรรจ์'
        },
        {
            geoid: '5',
            provinceid: '18',
            value: '213',
            label: 'โคกสูง'
        },
        {
            geoid: '5',
            provinceid: '18',
            value: '214',
            label: 'วังสมบูรณ์'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '215',
            label: 'เมืองนครราชสีมา'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '216',
            label: 'ครบุรี'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '217',
            label: 'เสิงสาง'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '218',
            label: 'คง'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '219',
            label: 'บ้านเหลื่อม'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '220',
            label: 'จักราช'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '221',
            label: 'โชคชัย'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '222',
            label: 'ด่านขุนทด'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '223',
            label: 'โนนไทย'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '224',
            label: 'โนนสูง'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '225',
            label: 'ขามสะแกแสง'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '226',
            label: 'บัวใหญ่'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '227',
            label: 'ประทาย'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '228',
            label: 'ปักธงชัย'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '229',
            label: 'พิมาย'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '230',
            label: 'ห้วยแถลง'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '231',
            label: 'ชุมพวง'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '232',
            label: 'สูงเนิน'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '233',
            label: 'ขามทะเลสอ'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '234',
            label: 'สีคิ้ว'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '235',
            label: 'ปากช่อง'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '236',
            label: 'หนองบุญมาก'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '237',
            label: 'แก้งสนามนาง'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '238',
            label: 'โนนแดง'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '239',
            label: 'วังน้ำเขียว'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '240',
            label: 'เทพารักษ์'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '241',
            label: 'เมืองยาง'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '242',
            label: 'พระทองคำ'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '243',
            label: 'ลำทะเมนชัย'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '244',
            label: 'บัวลาย'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '245',
            label: 'สีดา'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '246',
            label: 'เฉลิมพระเกียรติ'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '247',
            label: 'ท้องถิ่นเทศบาลตำบลโพธิ์กลาง'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '248',
            label: 'สาขาตำบลมะค่า-พลสงคราม'
        },
        {
            geoid: '3',
            provinceid: '19',
            value: '249',
            label: 'โนนลาว'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '250',
            label: 'เมืองบุรีรัมย์'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '251',
            label: 'คูเมือง'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '252',
            label: 'กระสัง'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '253',
            label: 'นางรอง'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '254',
            label: 'หนองกี่'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '255',
            label: 'ละหานทราย'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '256',
            label: 'ประโคนชัย'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '257',
            label: 'บ้านกรวด'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '258',
            label: 'พุทไธสง'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '259',
            label: 'ลำปลายมาศ'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '260',
            label: 'สตึก'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '261',
            label: 'ปะคำ'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '262',
            label: 'นาโพธิ์'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '263',
            label: 'หนองหงส์'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '264',
            label: 'พลับพลาชัย'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '265',
            label: 'ห้วยราช'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '266',
            label: 'โนนสุวรรณ'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '267',
            label: 'ชำนิ'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '268',
            label: 'บ้านใหม่ไชยพจน์'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '269',
            label: 'โนนดินแดง'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '270',
            label: 'บ้านด่าน'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '271',
            label: 'แคนดง'
        },
        {
            geoid: '3',
            provinceid: '20',
            value: '272',
            label: 'เฉลิมพระเกียรติ'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '273',
            label: 'เมืองสุรินทร์'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '274',
            label: 'ชุมพลบุรี'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '275',
            label: 'ท่าตูม'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '276',
            label: 'จอมพระ'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '277',
            label: 'ปราสาท'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '278',
            label: 'กาบเชิง'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '279',
            label: 'รัตนบุรี'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '280',
            label: 'สนม'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '281',
            label: 'ศีขรภูมิ'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '282',
            label: 'สังขะ'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '283',
            label: 'ลำดวน'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '284',
            label: 'สำโรงทาบ'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '285',
            label: 'บัวเชด'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '286',
            label: 'พนมดงรัก'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '287',
            label: 'ศรีณรงค์'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '288',
            label: 'เขวาสินรินทร์'
        },
        {
            geoid: '3',
            provinceid: '21',
            value: '289',
            label: 'โนนนารายณ์'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '290',
            label: 'เมืองศรีสะเกษ'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '291',
            label: 'ยางชุมน้อย'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '292',
            label: 'กันทรารมย์'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '293',
            label: 'กันทรลักษ์'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '294',
            label: 'ขุขันธ์'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '295',
            label: 'ไพรบึง'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '296',
            label: 'ปรางค์กู่'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '297',
            label: 'ขุนหาญ'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '298',
            label: 'ราษีไศล'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '299',
            label: 'อุทุมพรพิสัย'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '300',
            label: 'บึงบูรพ์'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '301',
            label: 'ห้วยทับทัน'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '302',
            label: 'โนนคูณ'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '303',
            label: 'ศรีรัตนะ'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '304',
            label: 'น้ำเกลี้ยง'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '305',
            label: 'วังหิน'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '306',
            label: 'ภูสิงห์'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '307',
            label: 'เมืองจันทร์'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '308',
            label: 'เบญจลักษ์'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '309',
            label: 'พยุห์'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '310',
            label: 'โพธิ์ศรีสุวรรณ'
        },
        {
            geoid: '3',
            provinceid: '22',
            value: '311',
            label: 'ศิลาลาด'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '312',
            label: 'เมืองอุบลราชธานี'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '313',
            label: 'ศรีเมืองใหม่'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '314',
            label: 'โขงเจียม'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '315',
            label: 'เขื่องใน'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '316',
            label: 'เขมราฐ'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '317',
            label: 'ชานุมาน'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '318',
            label: 'เดชอุดม'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '319',
            label: 'นาจะหลวย'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '320',
            label: 'น้ำยืน'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '321',
            label: 'บุณฑริก'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '322',
            label: 'ตระการพืชผล'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '323',
            label: 'กุดข้าวปุ้น'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '324',
            label: 'พนา'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '325',
            label: 'ม่วงสามสิบ'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '326',
            label: 'วารินชำราบ'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '327',
            label: 'อำนาจเจริญ'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '328',
            label: 'เสนางคนิคม'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '329',
            label: 'หัวตะพาน'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '330',
            label: 'พิบูลมังสาหาร'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '331',
            label: 'ตาลสุม'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '332',
            label: 'โพธิ์ไทร'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '333',
            label: 'สำโรง'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '334',
            label: 'กิ่งอำเภอลืออำนาจ'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '335',
            label: 'ดอนมดแดง'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '336',
            label: 'สิรินธร'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '337',
            label: 'ทุ่งศรีอุดม'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '338',
            label: 'ปทุมราชวงศา'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '339',
            label: 'กิ่งอำเภอศรีหลักชัย'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '340',
            label: 'นาเยีย'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '341',
            label: 'นาตาล'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '342',
            label: 'เหล่าเสือโก้ก'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '343',
            label: 'สว่างวีระวงศ์'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '344',
            label: 'น้ำขุ่น'
        },
        {
            geoid: '3',
            provinceid: '23',
            value: '345',
            label: 'อ.สุวรรณวารี จ.อุบลราชธานี'
        },
        {
            geoid: '3',
            provinceid: '24',
            value: '346',
            label: 'เมืองยโสธร'
        },
        {
            geoid: '3',
            provinceid: '24',
            value: '347',
            label: 'ทรายมูล'
        },
        {
            geoid: '3',
            provinceid: '24',
            value: '348',
            label: 'กุดชุม'
        },
        {
            geoid: '3',
            provinceid: '24',
            value: '349',
            label: 'คำเขื่อนแก้ว'
        },
        {
            geoid: '3',
            provinceid: '24',
            value: '350',
            label: 'ป่าติ้ว'
        },
        {
            geoid: '3',
            provinceid: '24',
            value: '351',
            label: 'มหาชนะชัย'
        },
        {
            geoid: '3',
            provinceid: '24',
            value: '352',
            label: 'ค้อวัง'
        },
        {
            geoid: '3',
            provinceid: '24',
            value: '353',
            label: 'เลิงนกทา'
        },
        {
            geoid: '3',
            provinceid: '24',
            value: '354',
            label: 'ไทยเจริญ'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '355',
            label: 'เมืองชัยภูมิ'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '356',
            label: 'บ้านเขว้า'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '357',
            label: 'คอนสวรรค์'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '358',
            label: 'เกษตรสมบูรณ์'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '359',
            label: 'หนองบัวแดง'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '360',
            label: 'จัตุรัส'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '361',
            label: 'บำเหน็จณรงค์'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '362',
            label: 'หนองบัวระเหว'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '363',
            label: 'เทพสถิต'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '364',
            label: 'ภูเขียว'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '365',
            label: 'บ้านแท่น'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '366',
            label: 'แก้งคร้อ'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '367',
            label: 'คอนสาร'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '368',
            label: 'ภักดีชุมพล'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '369',
            label: 'เนินสง่า'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '370',
            label: 'ซับใหญ่'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '371',
            label: 'เมืองชัยภูมิ (สาขาตำบลโนนสำราญ)'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '372',
            label: 'สาขาตำบลบ้านหว่าเฒ่า'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '373',
            label: 'หนองบัวแดง (สาขาตำบลวังชมภู)'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '374',
            label: 'กิ่งอำเภอซับใหญ่ (สาขาตำบลซับใหญ่)'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '375',
            label: 'สาขาตำบลโคกเพชร'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '376',
            label: 'เทพสถิต (สาขาตำบลนายางกลัก)'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '377',
            label: 'บ้านแท่น (สาขาตำบลบ้านเต่า)'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '378',
            label: 'แก้งคร้อ (สาขาตำบลท่ามะไฟหวาน)'
        },
        {
            geoid: '3',
            provinceid: '25',
            value: '379',
            label: 'คอนสาร (สาขาตำบลโนนคูณ)'
        },
        {
            geoid: '3',
            provinceid: '26',
            value: '380',
            label: 'เมืองอำนาจเจริญ'
        },
        {
            geoid: '3',
            provinceid: '26',
            value: '381',
            label: 'ชานุมาน'
        },
        {
            geoid: '3',
            provinceid: '26',
            value: '382',
            label: 'ปทุมราชวงศา'
        },
        {
            geoid: '3',
            provinceid: '26',
            value: '383',
            label: 'พนา'
        },
        {
            geoid: '3',
            provinceid: '26',
            value: '384',
            label: 'เสนางคนิคม'
        },
        {
            geoid: '3',
            provinceid: '26',
            value: '385',
            label: 'หัวตะพาน'
        },
        {
            geoid: '3',
            provinceid: '26',
            value: '386',
            label: 'ลืออำนาจ'
        },
        {
            geoid: '3',
            provinceid: '27',
            value: '387',
            label: 'เมืองหนองบัวลำภู'
        },
        {
            geoid: '3',
            provinceid: '27',
            value: '388',
            label: 'นากลาง'
        },
        {
            geoid: '3',
            provinceid: '27',
            value: '389',
            label: 'โนนสัง'
        },
        {
            geoid: '3',
            provinceid: '27',
            value: '390',
            label: 'ศรีบุญเรือง'
        },
        {
            geoid: '3',
            provinceid: '27',
            value: '391',
            label: 'สุวรรณคูหา'
        },
        {
            geoid: '3',
            provinceid: '27',
            value: '392',
            label: 'นาวัง'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '393',
            label: 'เมืองขอนแก่น'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '394',
            label: 'บ้านฝาง'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '395',
            label: 'พระยืน'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '396',
            label: 'หนองเรือ'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '397',
            label: 'ชุมแพ'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '398',
            label: 'สีชมพู'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '399',
            label: 'น้ำพอง'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '400',
            label: 'อุบลรัตน์'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '401',
            label: 'กระนวน'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '402',
            label: 'บ้านไผ่'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '403',
            label: 'เปือยน้อย'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '404',
            label: 'พล'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '405',
            label: 'แวงใหญ่'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '406',
            label: 'แวงน้อย'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '407',
            label: 'หนองสองห้อง'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '408',
            label: 'ภูเวียง'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '409',
            label: 'มัญจาคีรี'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '410',
            label: 'ชนบท'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '411',
            label: 'เขาสวนกวาง'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '412',
            label: 'ภูผาม่าน'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '413',
            label: 'ซำสูง'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '414',
            label: 'โคกโพธิ์ไชย'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '415',
            label: 'หนองนาคำ'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '416',
            label: 'บ้านแฮด'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '417',
            label: 'โนนศิลา'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '418',
            label: 'เวียงเก่า'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '419',
            label: 'ท้องถิ่นเทศบาลตำบลบ้านเป็ด'
        },
        {
            geoid: '3',
            provinceid: '28',
            value: '420',
            label: 'เทศบาลตำบลเมืองพล'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '421',
            label: 'เมืองอุดรธานี'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '422',
            label: 'กุดจับ'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '423',
            label: 'หนองวัวซอ'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '424',
            label: 'กุมภวาปี'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '425',
            label: 'โนนสะอาด'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '426',
            label: 'หนองหาน'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '427',
            label: 'ทุ่งฝน'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '428',
            label: 'ไชยวาน'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '429',
            label: 'ศรีธาตุ'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '430',
            label: 'วังสามหมอ'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '431',
            label: 'บ้านดุง'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '432',
            label: 'หนองบัวลำภู'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '433',
            label: 'ศรีบุญเรือง'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '434',
            label: 'นากลาง'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '435',
            label: 'สุวรรณคูหา'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '436',
            label: 'โนนสัง'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '437',
            label: 'บ้านผือ'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '438',
            label: 'น้ำโสม'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '439',
            label: 'เพ็ญ'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '440',
            label: 'สร้างคอม'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '441',
            label: 'หนองแสง'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '442',
            label: 'นายูง'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '443',
            label: 'พิบูลย์รักษ์'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '444',
            label: 'กู่แก้ว'
        },
        {
            geoid: '3',
            provinceid: '29',
            value: '445',
            label: 'ประจักษ์ศิลปาคม'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '446',
            label: 'เมืองเลย'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '447',
            label: 'นาด้วง'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '448',
            label: 'เชียงคาน'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '449',
            label: 'ปากชม'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '450',
            label: 'ด่านซ้าย'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '451',
            label: 'นาแห้ว'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '452',
            label: 'ภูเรือ'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '453',
            label: 'ท่าลี่'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '454',
            label: 'วังสะพุง'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '455',
            label: 'ภูกระดึง'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '456',
            label: 'ภูหลวง'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '457',
            label: 'ผาขาว'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '458',
            label: 'เอราวัณ'
        },
        {
            geoid: '3',
            provinceid: '30',
            value: '459',
            label: 'หนองหิน'
        },
        {
            geoid: '3',
            provinceid: '31',
            value: '460',
            label: 'เมืองหนองคาย'
        },
        {
            geoid: '3',
            provinceid: '31',
            value: '461',
            label: 'ท่าบ่อ'
        },
        {
            geoid: '3',
            provinceid: '97',
            value: '462',
            label: 'เมืองบึงกาฬ'
        },
        {
            geoid: '3',
            provinceid: '97',
            value: '463',
            label: 'พรเจริญ'
        },
        {
            geoid: '3',
            provinceid: '31',
            value: '464',
            label: 'โพนพิสัย'
        },
        {
            geoid: '3',
            provinceid: '97',
            value: '465',
            label: 'โซ่พิสัย'
        },
        {
            geoid: '3',
            provinceid: '31',
            value: '466',
            label: 'ศรีเชียงใหม่'
        },
        {
            geoid: '3',
            provinceid: '31',
            value: '467',
            label: 'สังคม'
        },
        {
            geoid: '3',
            provinceid: '97',
            value: '468',
            label: 'เซกา'
        },
        {
            geoid: '3',
            provinceid: '97',
            value: '469',
            label: 'ปากคาด'
        },
        {
            geoid: '3',
            provinceid: '97',
            value: '470',
            label: 'บึงโขงหลง'
        },
        {
            geoid: '3',
            provinceid: '97',
            value: '471',
            label: 'ศรีวิไล'
        },
        {
            geoid: '3',
            provinceid: '97',
            value: '472',
            label: 'บุ่งคล้า'
        },
        {
            geoid: '3',
            provinceid: '31',
            value: '473',
            label: 'สระใคร'
        },
        {
            geoid: '3',
            provinceid: '31',
            value: '474',
            label: 'เฝ้าไร่'
        },
        {
            geoid: '3',
            provinceid: '31',
            value: '475',
            label: 'รัตนวาปี'
        },
        {
            geoid: '3',
            provinceid: '31',
            value: '476',
            label: 'โพธิ์ตาก'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '477',
            label: 'เมืองมหาสารคาม'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '478',
            label: 'แกดำ'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '479',
            label: 'โกสุมพิสัย'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '480',
            label: 'กันทรวิชัย'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '481',
            label: 'เชียงยืน'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '482',
            label: 'บรบือ'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '483',
            label: 'นาเชือก'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '484',
            label: 'พยัคฆภูมิพิสัย'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '485',
            label: 'วาปีปทุม'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '486',
            label: 'นาดูน'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '487',
            label: 'ยางสีสุราช'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '488',
            label: 'กุดรัง'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '489',
            label: 'ชื่นชม'
        },
        {
            geoid: '3',
            provinceid: '32',
            value: '490',
            label: 'หลุบ'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '491',
            label: 'เมืองร้อยเอ็ด'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '492',
            label: 'เกษตรวิสัย'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '493',
            label: 'ปทุมรัตต์'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '494',
            label: 'จตุรพักตรพิมาน'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '495',
            label: 'ธวัชบุรี'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '496',
            label: 'พนมไพร'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '497',
            label: 'โพนทอง'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '498',
            label: 'โพธิ์ชัย'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '499',
            label: 'หนองพอก'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '500',
            label: 'เสลภูมิ'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '501',
            label: 'สุวรรณภูมิ'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '502',
            label: 'เมืองสรวง'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '503',
            label: 'โพนทราย'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '504',
            label: 'อาจสามารถ'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '505',
            label: 'เมยวดี'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '506',
            label: 'ศรีสมเด็จ'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '507',
            label: 'จังหาร'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '508',
            label: 'เชียงขวัญ'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '509',
            label: 'หนองฮี'
        },
        {
            geoid: '3',
            provinceid: '33',
            value: '510',
            label: 'ทุ่งเขาหลวง'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '511',
            label: 'เมืองกาฬสินธุ์'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '512',
            label: 'นามน'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '513',
            label: 'กมลาไสย'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '514',
            label: 'ร่องคำ'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '515',
            label: 'กุฉินารายณ์'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '516',
            label: 'เขาวง'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '517',
            label: 'ยางตลาด'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '518',
            label: 'ห้วยเม็ก'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '519',
            label: 'สหัสขันธ์'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '520',
            label: 'คำม่วง'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '521',
            label: 'ท่าคันโท'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '522',
            label: 'หนองกุงศรี'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '523',
            label: 'สมเด็จ'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '524',
            label: 'ห้วยผึ้ง'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '525',
            label: 'สามชัย'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '526',
            label: 'นาคู'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '527',
            label: 'ดอนจาน'
        },
        {
            geoid: '3',
            provinceid: '34',
            value: '528',
            label: 'ฆ้องชัย'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '529',
            label: 'เมืองสกลนคร'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '530',
            label: 'กุสุมาลย์'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '531',
            label: 'กุดบาก'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '532',
            label: 'พรรณานิคม'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '533',
            label: 'พังโคน'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '534',
            label: 'วาริชภูมิ'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '535',
            label: 'นิคมน้ำอูน'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '536',
            label: 'วานรนิวาส'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '537',
            label: 'คำตากล้า'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '538',
            label: 'บ้านม่วง'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '539',
            label: 'อากาศอำนวย'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '540',
            label: 'สว่างแดนดิน'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '541',
            label: 'ส่องดาว'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '542',
            label: 'เต่างอย'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '543',
            label: 'โคกศรีสุพรรณ'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '544',
            label: 'เจริญศิลป์'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '545',
            label: 'โพนนาแก้ว'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '546',
            label: 'ภูพาน'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '547',
            label: 'วานรนิวาส (สาขาตำบลกุดเรือคำ)'
        },
        {
            geoid: '3',
            provinceid: '35',
            value: '548',
            label: 'อ.บ้านหัน จ.สกลนคร'
        },
        {
            geoid: '3',
            provinceid: '36',
            value: '549',
            label: 'เมืองนครพนม'
        },
        {
            geoid: '3',
            provinceid: '36',
            value: '550',
            label: 'ปลาปาก'
        },
        {
            geoid: '3',
            provinceid: '36',
            value: '551',
            label: 'ท่าอุเทน'
        },
        {
            geoid: '3',
            provinceid: '36',
            value: '552',
            label: 'บ้านแพง'
        },
        {
            geoid: '3',
            provinceid: '36',
            value: '553',
            label: 'ธาตุพนม'
        },
        {
            geoid: '3',
            provinceid: '36',
            value: '554',
            label: 'เรณูนคร'
        },
        {
            geoid: '3',
            provinceid: '36',
            value: '555',
            label: 'นาแก'
        },
        {
            geoid: '3',
            provinceid: '36',
            value: '556',
            label: 'ศรีสงคราม'
        },
        {
            geoid: '3',
            provinceid: '36',
            value: '557',
            label: 'นาหว้า'
        },
        {
            geoid: '3',
            provinceid: '36',
            value: '558',
            label: 'โพนสวรรค์'
        },
        {
            geoid: '3',
            provinceid: '36',
            value: '559',
            label: 'นาทม'
        },
        {
            geoid: '3',
            provinceid: '36',
            value: '560',
            label: 'วังยาง'
        },
        {
            geoid: '3',
            provinceid: '37',
            value: '561',
            label: 'เมืองมุกดาหาร'
        },
        {
            geoid: '3',
            provinceid: '37',
            value: '562',
            label: 'นิคมคำสร้อย'
        },
        {
            geoid: '3',
            provinceid: '37',
            value: '563',
            label: 'ดอนตาล'
        },
        {
            geoid: '3',
            provinceid: '37',
            value: '564',
            label: 'ดงหลวง'
        },
        {
            geoid: '3',
            provinceid: '37',
            value: '565',
            label: 'คำชะอี'
        },
        {
            geoid: '3',
            provinceid: '37',
            value: '566',
            label: 'หว้านใหญ่'
        },
        {
            geoid: '3',
            provinceid: '37',
            value: '567',
            label: 'หนองสูง'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '568',
            label: 'เมืองเชียงใหม่'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '569',
            label: 'จอมทอง'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '570',
            label: 'แม่แจ่ม'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '571',
            label: 'เชียงดาว'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '572',
            label: 'ดอยสะเก็ด'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '573',
            label: 'แม่แตง'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '574',
            label: 'แม่ริม'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '575',
            label: 'สะเมิง'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '576',
            label: 'ฝาง'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '577',
            label: 'แม่อาย'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '578',
            label: 'พร้าว'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '579',
            label: 'สันป่าตอง'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '580',
            label: 'สันกำแพง'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '581',
            label: 'สันทราย'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '582',
            label: 'หางดง'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '583',
            label: 'ฮอด'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '584',
            label: 'ดอยเต่า'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '585',
            label: 'อมก๋อย'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '586',
            label: 'สารภี'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '587',
            label: 'เวียงแหง'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '588',
            label: 'ไชยปราการ'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '589',
            label: 'แม่วาง'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '590',
            label: 'แม่ออน'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '591',
            label: 'ดอยหล่อ'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '592',
            label: 'เทศบาลนครเชียงใหม่ (สาขาแขวงกาลวิละ)'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '593',
            label: 'เทศบาลนครเชียงใหม่ (สาขาแขวงศรีวิชั)'
        },
        {
            geoid: '1',
            provinceid: '38',
            value: '594',
            label: 'เทศบาลนครเชียงใหม่ (สาขาเม็งราย)'
        },
        {
            geoid: '1',
            provinceid: '39',
            value: '595',
            label: 'เมืองลำพูน'
        },
        {
            geoid: '1',
            provinceid: '39',
            value: '596',
            label: 'แม่ทา'
        },
        {
            geoid: '1',
            provinceid: '39',
            value: '597',
            label: 'บ้านโฮ่ง'
        },
        {
            geoid: '1',
            provinceid: '39',
            value: '598',
            label: 'ลี้'
        },
        {
            geoid: '1',
            provinceid: '39',
            value: '599',
            label: 'ทุ่งหัวช้าง'
        },
        {
            geoid: '1',
            provinceid: '39',
            value: '600',
            label: 'ป่าซาง'
        },
        {
            geoid: '1',
            provinceid: '39',
            value: '601',
            label: 'บ้านธิ'
        },
        {
            geoid: '1',
            provinceid: '39',
            value: '602',
            label: 'เวียงหนองล่อง'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '603',
            label: 'เมืองลำปาง'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '604',
            label: 'แม่เมาะ'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '605',
            label: 'เกาะคา'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '606',
            label: 'เสริมงาม'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '607',
            label: 'งาว'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '608',
            label: 'แจ้ห่ม'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '609',
            label: 'วังเหนือ'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '610',
            label: 'เถิน'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '611',
            label: 'แม่พริก'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '612',
            label: 'แม่ทะ'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '613',
            label: 'สบปราบ'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '614',
            label: 'ห้างฉัตร'
        },
        {
            geoid: '1',
            provinceid: '40',
            value: '615',
            label: 'เมืองปาน'
        },
        {
            geoid: '1',
            provinceid: '41',
            value: '616',
            label: 'เมืองอุตรดิตถ์'
        },
        {
            geoid: '1',
            provinceid: '41',
            value: '617',
            label: 'ตรอน'
        },
        {
            geoid: '1',
            provinceid: '41',
            value: '618',
            label: 'ท่าปลา'
        },
        {
            geoid: '1',
            provinceid: '41',
            value: '619',
            label: 'น้ำปาด'
        },
        {
            geoid: '1',
            provinceid: '41',
            value: '620',
            label: 'ฟากท่า'
        },
        {
            geoid: '1',
            provinceid: '41',
            value: '621',
            label: 'บ้านโคก'
        },
        {
            geoid: '1',
            provinceid: '41',
            value: '622',
            label: 'พิชัย'
        },
        {
            geoid: '1',
            provinceid: '41',
            value: '623',
            label: 'ลับแล'
        },
        {
            geoid: '1',
            provinceid: '41',
            value: '624',
            label: 'ทองแสนขัน'
        },
        {
            geoid: '1',
            provinceid: '42',
            value: '625',
            label: 'เมืองแพร่'
        },
        {
            geoid: '1',
            provinceid: '42',
            value: '626',
            label: 'ร้องกวาง'
        },
        {
            geoid: '1',
            provinceid: '42',
            value: '627',
            label: 'ลอง'
        },
        {
            geoid: '1',
            provinceid: '42',
            value: '628',
            label: 'สูงเม่น'
        },
        {
            geoid: '1',
            provinceid: '42',
            value: '629',
            label: 'เด่นชัย'
        },
        {
            geoid: '1',
            provinceid: '42',
            value: '630',
            label: 'สอง'
        },
        {
            geoid: '1',
            provinceid: '42',
            value: '631',
            label: 'วังชิ้น'
        },
        {
            geoid: '1',
            provinceid: '42',
            value: '632',
            label: 'หนองม่วงไข่'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '633',
            label: 'เมืองน่าน'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '634',
            label: 'แม่จริม'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '635',
            label: 'บ้านหลวง'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '636',
            label: 'นาน้อย'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '637',
            label: 'ปัว'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '638',
            label: 'ท่าวังผา'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '639',
            label: 'เวียงสา'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '640',
            label: 'ทุ่งช้าง'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '641',
            label: 'เชียงกลาง'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '642',
            label: 'นาหมื่น'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '643',
            label: 'สันติสุข'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '644',
            label: 'บ่อเกลือ'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '645',
            label: 'สองแคว'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '646',
            label: 'ภูเพียง'
        },
        {
            geoid: '1',
            provinceid: '43',
            value: '647',
            label: 'เฉลิมพระเกียรติ'
        },
        {
            geoid: '1',
            provinceid: '44',
            value: '648',
            label: 'เมืองพะเยา'
        },
        {
            geoid: '1',
            provinceid: '44',
            value: '649',
            label: 'จุน'
        },
        {
            geoid: '1',
            provinceid: '44',
            value: '650',
            label: 'เชียงคำ'
        },
        {
            geoid: '1',
            provinceid: '44',
            value: '651',
            label: 'เชียงม่วน'
        },
        {
            geoid: '1',
            provinceid: '44',
            value: '652',
            label: 'ดอกคำใต้'
        },
        {
            geoid: '1',
            provinceid: '44',
            value: '653',
            label: 'ปง'
        },
        {
            geoid: '1',
            provinceid: '44',
            value: '654',
            label: 'แม่ใจ'
        },
        {
            geoid: '1',
            provinceid: '44',
            value: '655',
            label: 'ภูซาง'
        },
        {
            geoid: '1',
            provinceid: '44',
            value: '656',
            label: 'ภูกามยาว'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '657',
            label: 'เมืองเชียงราย'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '658',
            label: 'เวียงชัย'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '659',
            label: 'เชียงของ'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '660',
            label: 'เทิง'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '661',
            label: 'พาน'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '662',
            label: 'ป่าแดด'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '663',
            label: 'แม่จัน'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '664',
            label: 'เชียงแสน'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '665',
            label: 'แม่สาย'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '666',
            label: 'แม่สรวย'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '667',
            label: 'เวียงป่าเป้า'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '668',
            label: 'พญาเม็งราย'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '669',
            label: 'เวียงแก่น'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '670',
            label: 'ขุนตาล'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '671',
            label: 'แม่ฟ้าหลวง'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '672',
            label: 'แม่ลาว'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '673',
            label: 'เวียงเชียงรุ้ง'
        },
        {
            geoid: '1',
            provinceid: '45',
            value: '674',
            label: 'ดอยหลวง'
        },
        {
            geoid: '1',
            provinceid: '46',
            value: '675',
            label: 'เมืองแม่ฮ่องสอน'
        },
        {
            geoid: '1',
            provinceid: '46',
            value: '676',
            label: 'ขุนยวม'
        },
        {
            geoid: '1',
            provinceid: '46',
            value: '677',
            label: 'ปาย'
        },
        {
            geoid: '1',
            provinceid: '46',
            value: '678',
            label: 'แม่สะเรียง'
        },
        {
            geoid: '1',
            provinceid: '46',
            value: '679',
            label: 'แม่ลาน้อย'
        },
        {
            geoid: '1',
            provinceid: '46',
            value: '680',
            label: 'สบเมย'
        },
        {
            geoid: '1',
            provinceid: '46',
            value: '681',
            label: 'ปางมะผ้า'
        },
        {
            geoid: '1',
            provinceid: '46',
            value: '682',
            label: 'อ.ม่วยต่อ จ.แม่ฮ่องสอน'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '683',
            label: 'เมืองนครสวรรค์'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '684',
            label: 'โกรกพระ'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '685',
            label: 'ชุมแสง'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '686',
            label: 'หนองบัว'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '687',
            label: 'บรรพตพิสัย'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '688',
            label: 'เก้าเลี้ยว'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '689',
            label: 'ตาคลี'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '690',
            label: 'ท่าตะโก'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '691',
            label: 'ไพศาลี'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '692',
            label: 'พยุหะคีรี'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '693',
            label: 'ลาดยาว'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '694',
            label: 'ตากฟ้า'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '695',
            label: 'แม่วงก์'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '696',
            label: 'แม่เปิน'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '697',
            label: 'ชุมตาบง'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '698',
            label: 'สาขาตำบลห้วยน้ำหอม'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '699',
            label: 'กิ่งอำเภอชุมตาบง (สาขาตำบลชุมตาบง)'
        },
        {
            geoid: '2',
            provinceid: '47',
            value: '700',
            label: 'แม่วงก์ (สาขาตำบลแม่เล่ย์)'
        },
        {
            geoid: '2',
            provinceid: '48',
            value: '701',
            label: 'เมืองอุทัยธานี'
        },
        {
            geoid: '2',
            provinceid: '48',
            value: '702',
            label: 'ทัพทัน'
        },
        {
            geoid: '2',
            provinceid: '48',
            value: '703',
            label: 'สว่างอารมณ์'
        },
        {
            geoid: '2',
            provinceid: '48',
            value: '704',
            label: 'หนองฉาง'
        },
        {
            geoid: '2',
            provinceid: '48',
            value: '705',
            label: 'หนองขาหย่าง'
        },
        {
            geoid: '2',
            provinceid: '48',
            value: '706',
            label: 'บ้านไร่'
        },
        {
            geoid: '2',
            provinceid: '48',
            value: '707',
            label: 'ลานสัก'
        },
        {
            geoid: '2',
            provinceid: '48',
            value: '708',
            label: 'ห้วยคต'
        },
        {
            geoid: '2',
            provinceid: '49',
            value: '709',
            label: 'เมืองกำแพงเพชร'
        },
        {
            geoid: '2',
            provinceid: '49',
            value: '710',
            label: 'ไทรงาม'
        },
        {
            geoid: '2',
            provinceid: '49',
            value: '711',
            label: 'คลองลาน'
        },
        {
            geoid: '2',
            provinceid: '49',
            value: '712',
            label: 'ขาณุวรลักษบุรี'
        },
        {
            geoid: '2',
            provinceid: '49',
            value: '713',
            label: 'คลองขลุง'
        },
        {
            geoid: '2',
            provinceid: '49',
            value: '714',
            label: 'พรานกระต่าย'
        },
        {
            geoid: '2',
            provinceid: '49',
            value: '715',
            label: 'ลานกระบือ'
        },
        {
            geoid: '2',
            provinceid: '49',
            value: '716',
            label: 'ทรายทองวัฒนา'
        },
        {
            geoid: '2',
            provinceid: '49',
            value: '717',
            label: 'ปางศิลาทอง'
        },
        {
            geoid: '2',
            provinceid: '49',
            value: '718',
            label: 'บึงสามัคคี'
        },
        {
            geoid: '2',
            provinceid: '49',
            value: '719',
            label: 'โกสัมพีนคร'
        },
        {
            geoid: '4',
            provinceid: '50',
            value: '720',
            label: 'เมืองตาก'
        },
        {
            geoid: '4',
            provinceid: '50',
            value: '721',
            label: 'บ้านตาก'
        },
        {
            geoid: '4',
            provinceid: '50',
            value: '722',
            label: 'สามเงา'
        },
        {
            geoid: '4',
            provinceid: '50',
            value: '723',
            label: 'แม่ระมาด'
        },
        {
            geoid: '4',
            provinceid: '50',
            value: '724',
            label: 'ท่าสองยาง'
        },
        {
            geoid: '4',
            provinceid: '50',
            value: '725',
            label: 'แม่สอด'
        },
        {
            geoid: '4',
            provinceid: '50',
            value: '726',
            label: 'พบพระ'
        },
        {
            geoid: '4',
            provinceid: '50',
            value: '727',
            label: 'อุ้มผาง'
        },
        {
            geoid: '4',
            provinceid: '50',
            value: '728',
            label: 'วังเจ้า'
        },
        {
            geoid: '4',
            provinceid: '50',
            value: '729',
            label: 'กิ่ง อ.ท่าปุย จ.ตาก'
        },
        {
            geoid: '2',
            provinceid: '51',
            value: '730',
            label: 'เมืองสุโขทัย'
        },
        {
            geoid: '2',
            provinceid: '51',
            value: '731',
            label: 'บ้านด่านลานหอย'
        },
        {
            geoid: '2',
            provinceid: '51',
            value: '732',
            label: 'คีรีมาศ'
        },
        {
            geoid: '2',
            provinceid: '51',
            value: '733',
            label: 'กงไกรลาศ'
        },
        {
            geoid: '2',
            provinceid: '51',
            value: '734',
            label: 'ศรีสัชนาลัย'
        },
        {
            geoid: '2',
            provinceid: '51',
            value: '735',
            label: 'ศรีสำโรง'
        },
        {
            geoid: '2',
            provinceid: '51',
            value: '736',
            label: 'สวรรคโลก'
        },
        {
            geoid: '2',
            provinceid: '51',
            value: '737',
            label: 'ศรีนคร'
        },
        {
            geoid: '2',
            provinceid: '51',
            value: '738',
            label: 'ทุ่งเสลี่ยม'
        },
        {
            geoid: '2',
            provinceid: '52',
            value: '739',
            label: 'เมืองพิษณุโลก'
        },
        {
            geoid: '2',
            provinceid: '52',
            value: '740',
            label: 'นครไทย'
        },
        {
            geoid: '2',
            provinceid: '52',
            value: '741',
            label: 'ชาติตระการ'
        },
        {
            geoid: '2',
            provinceid: '52',
            value: '742',
            label: 'บางระกำ'
        },
        {
            geoid: '2',
            provinceid: '52',
            value: '743',
            label: 'บางกระทุ่ม'
        },
        {
            geoid: '2',
            provinceid: '52',
            value: '744',
            label: 'พรหมพิราม'
        },
        {
            geoid: '2',
            provinceid: '52',
            value: '745',
            label: 'วัดโบสถ์'
        },
        {
            geoid: '2',
            provinceid: '52',
            value: '746',
            label: 'วังทอง'
        },
        {
            geoid: '2',
            provinceid: '52',
            value: '747',
            label: 'เนินมะปราง'
        },
        {
            geoid: '2',
            provinceid: '53',
            value: '748',
            label: 'เมืองพิจิตร'
        },
        {
            geoid: '2',
            provinceid: '53',
            value: '749',
            label: 'วังทรายพูน'
        },
        {
            geoid: '2',
            provinceid: '53',
            value: '750',
            label: 'โพธิ์ประทับช้าง'
        },
        {
            geoid: '2',
            provinceid: '53',
            value: '751',
            label: 'ตะพานหิน'
        },
        {
            geoid: '2',
            provinceid: '53',
            value: '752',
            label: 'บางมูลนาก'
        },
        {
            geoid: '2',
            provinceid: '53',
            value: '753',
            label: 'โพทะเล'
        },
        {
            geoid: '2',
            provinceid: '53',
            value: '754',
            label: 'สามง่าม'
        },
        {
            geoid: '2',
            provinceid: '53',
            value: '755',
            label: 'ทับคล้อ'
        },
        {
            geoid: '2',
            provinceid: '53',
            value: '756',
            label: 'สากเหล็ก'
        },
        {
            geoid: '2',
            provinceid: '53',
            value: '757',
            label: 'บึงนาราง'
        },
        {
            geoid: '2',
            provinceid: '53',
            value: '758',
            label: 'ดงเจริญ'
        },
        {
            geoid: '2',
            provinceid: '53',
            value: '759',
            label: 'วชิรบารมี'
        },
        {
            geoid: '2',
            provinceid: '54',
            value: '760',
            label: 'เมืองเพชรบูรณ์'
        },
        {
            geoid: '2',
            provinceid: '54',
            value: '761',
            label: 'ชนแดน'
        },
        {
            geoid: '2',
            provinceid: '54',
            value: '762',
            label: 'หล่มสัก'
        },
        {
            geoid: '2',
            provinceid: '54',
            value: '763',
            label: 'หล่มเก่า'
        },
        {
            geoid: '2',
            provinceid: '54',
            value: '764',
            label: 'วิเชียรบุรี'
        },
        {
            geoid: '2',
            provinceid: '54',
            value: '765',
            label: 'ศรีเทพ'
        },
        {
            geoid: '2',
            provinceid: '54',
            value: '766',
            label: 'หนองไผ่'
        },
        {
            geoid: '2',
            provinceid: '54',
            value: '767',
            label: 'บึงสามพัน'
        },
        {
            geoid: '2',
            provinceid: '54',
            value: '768',
            label: 'น้ำหนาว'
        },
        {
            geoid: '2',
            provinceid: '54',
            value: '769',
            label: 'วังโป่ง'
        },
        {
            geoid: '2',
            provinceid: '54',
            value: '770',
            label: 'เขาค้อ'
        },
        {
            geoid: '4',
            provinceid: '55',
            value: '771',
            label: 'เมืองราชบุรี'
        },
        {
            geoid: '4',
            provinceid: '55',
            value: '772',
            label: 'จอมบึง'
        },
        {
            geoid: '4',
            provinceid: '55',
            value: '773',
            label: 'สวนผึ้ง'
        },
        {
            geoid: '4',
            provinceid: '55',
            value: '774',
            label: 'ดำเนินสะดวก'
        },
        {
            geoid: '4',
            provinceid: '55',
            value: '775',
            label: 'บ้านโป่ง'
        },
        {
            geoid: '4',
            provinceid: '55',
            value: '776',
            label: 'บางแพ'
        },
        {
            geoid: '4',
            provinceid: '55',
            value: '777',
            label: 'โพธาราม'
        },
        {
            geoid: '4',
            provinceid: '55',
            value: '778',
            label: 'ปากท่อ'
        },
        {
            geoid: '4',
            provinceid: '55',
            value: '779',
            label: 'วัดเพลง'
        },
        {
            geoid: '4',
            provinceid: '55',
            value: '780',
            label: 'บ้านคา'
        },
        {
            geoid: '4',
            provinceid: '55',
            value: '781',
            label: 'ท้องถิ่นเทศบาลตำบลบ้านฆ้อง'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '782',
            label: 'เมืองกาญจนบุรี'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '783',
            label: 'ไทรโยค'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '784',
            label: 'บ่อพลอย'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '785',
            label: 'ศรีสวัสดิ์'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '786',
            label: 'ท่ามะกา'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '787',
            label: 'ท่าม่วง'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '788',
            label: 'ทองผาภูมิ'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '789',
            label: 'สังขละบุรี'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '790',
            label: 'พนมทวน'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '791',
            label: 'เลาขวัญ'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '792',
            label: 'ด่านมะขามเตี้ย'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '793',
            label: 'หนองปรือ'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '794',
            label: 'ห้วยกระเจา'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '795',
            label: 'สาขาตำบลท่ากระดาน'
        },
        {
            geoid: '4',
            provinceid: '56',
            value: '796',
            label: 'บ้านทวน จ.กาญจนบุรี'
        },
        {
            geoid: '2',
            provinceid: '57',
            value: '797',
            label: 'เมืองสุพรรณบุรี'
        },
        {
            geoid: '2',
            provinceid: '57',
            value: '798',
            label: 'เดิมบางนางบวช'
        },
        {
            geoid: '2',
            provinceid: '57',
            value: '799',
            label: 'ด่านช้าง'
        },
        {
            geoid: '2',
            provinceid: '57',
            value: '800',
            label: 'บางปลาม้า'
        },
        {
            geoid: '2',
            provinceid: '57',
            value: '801',
            label: 'ศรีประจันต์'
        },
        {
            geoid: '2',
            provinceid: '57',
            value: '802',
            label: 'ดอนเจดีย์'
        },
        {
            geoid: '2',
            provinceid: '57',
            value: '803',
            label: 'สองพี่น้อง'
        },
        {
            geoid: '2',
            provinceid: '57',
            value: '804',
            label: 'สามชุก'
        },
        {
            geoid: '2',
            provinceid: '57',
            value: '805',
            label: 'อู่ทอง'
        },
        {
            geoid: '2',
            provinceid: '57',
            value: '806',
            label: 'หนองหญ้าไซ'
        },
        {
            geoid: '2',
            provinceid: '58',
            value: '807',
            label: 'เมืองนครปฐม'
        },
        {
            geoid: '2',
            provinceid: '58',
            value: '808',
            label: 'กำแพงแสน'
        },
        {
            geoid: '2',
            provinceid: '58',
            value: '809',
            label: 'นครชัยศรี'
        },
        {
            geoid: '2',
            provinceid: '58',
            value: '810',
            label: 'ดอนตูม'
        },
        {
            geoid: '2',
            provinceid: '58',
            value: '811',
            label: 'บางเลน'
        },
        {
            geoid: '2',
            provinceid: '58',
            value: '812',
            label: 'สามพราน'
        },
        {
            geoid: '2',
            provinceid: '58',
            value: '813',
            label: 'พุทธมณฑล'
        },
        {
            geoid: '2',
            provinceid: '59',
            value: '814',
            label: 'เมืองสมุทรสาคร'
        },
        {
            geoid: '2',
            provinceid: '59',
            value: '815',
            label: 'กระทุ่มแบน'
        },
        {
            geoid: '2',
            provinceid: '59',
            value: '816',
            label: 'บ้านแพ้ว'
        },
        {
            geoid: '2',
            provinceid: '60',
            value: '817',
            label: 'เมืองสมุทรสงคราม'
        },
        {
            geoid: '2',
            provinceid: '60',
            value: '818',
            label: 'บางคนที'
        },
        {
            geoid: '2',
            provinceid: '60',
            value: '819',
            label: 'อัมพวา'
        },
        {
            geoid: '4',
            provinceid: '61',
            value: '820',
            label: 'เมืองเพชรบุรี'
        },
        {
            geoid: '4',
            provinceid: '61',
            value: '821',
            label: 'เขาย้อย'
        },
        {
            geoid: '4',
            provinceid: '61',
            value: '822',
            label: 'หนองหญ้าปล้อง'
        },
        {
            geoid: '4',
            provinceid: '61',
            value: '823',
            label: 'ชะอำ'
        },
        {
            geoid: '4',
            provinceid: '61',
            value: '824',
            label: 'ท่ายาง'
        },
        {
            geoid: '4',
            provinceid: '61',
            value: '825',
            label: 'บ้านลาด'
        },
        {
            geoid: '4',
            provinceid: '61',
            value: '826',
            label: 'บ้านแหลม'
        },
        {
            geoid: '4',
            provinceid: '61',
            value: '827',
            label: 'แก่งกระจาน'
        },
        {
            geoid: '4',
            provinceid: '62',
            value: '828',
            label: 'เมืองประจวบคีรีขันธ์'
        },
        {
            geoid: '4',
            provinceid: '62',
            value: '829',
            label: 'กุยบุรี'
        },
        {
            geoid: '4',
            provinceid: '62',
            value: '830',
            label: 'ทับสะแก'
        },
        {
            geoid: '4',
            provinceid: '62',
            value: '831',
            label: 'บางสะพาน'
        },
        {
            geoid: '4',
            provinceid: '62',
            value: '832',
            label: 'บางสะพานน้อย'
        },
        {
            geoid: '4',
            provinceid: '62',
            value: '833',
            label: 'ปราณบุรี'
        },
        {
            geoid: '4',
            provinceid: '62',
            value: '834',
            label: 'หัวหิน'
        },
        {
            geoid: '4',
            provinceid: '62',
            value: '835',
            label: 'สามร้อยยอด'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '836',
            label: 'เมืองนครศรีธรรมราช'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '837',
            label: 'พรหมคีรี'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '838',
            label: 'ลานสกา'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '839',
            label: 'ฉวาง'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '840',
            label: 'พิปูน'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '841',
            label: 'เชียรใหญ่'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '842',
            label: 'ชะอวด'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '843',
            label: 'ท่าศาลา'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '844',
            label: 'ทุ่งสง'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '845',
            label: 'นาบอน'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '846',
            label: 'ทุ่งใหญ่'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '847',
            label: 'ปากพนัง'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '848',
            label: 'ร่อนพิบูลย์'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '849',
            label: 'สิชล'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '850',
            label: 'ขนอม'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '851',
            label: 'หัวไทร'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '852',
            label: 'บางขัน'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '853',
            label: 'ถ้ำพรรณรา'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '854',
            label: 'จุฬาภรณ์'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '855',
            label: 'พระพรหม'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '856',
            label: 'นบพิตำ'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '857',
            label: 'ช้างกลาง'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '858',
            label: 'เฉลิมพระเกียรติ'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '859',
            label: 'เชียรใหญ่ (สาขาตำบลเสือหึง)'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '860',
            label: 'สาขาตำบลสวนหลวง'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '861',
            label: 'ร่อนพิบูลย์ (สาขาตำบลหินตก)'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '862',
            label: 'หัวไทร (สาขาตำบลควนชะลิก)'
        },
        {
            geoid: '6',
            provinceid: '63',
            value: '863',
            label: 'ทุ่งสง (สาขาตำบลกะปาง)'
        },
        {
            geoid: '6',
            provinceid: '64',
            value: '864',
            label: 'เมืองกระบี่'
        },
        {
            geoid: '6',
            provinceid: '64',
            value: '865',
            label: 'เขาพนม'
        },
        {
            geoid: '6',
            provinceid: '64',
            value: '866',
            label: 'เกาะลันตา'
        },
        {
            geoid: '6',
            provinceid: '64',
            value: '867',
            label: 'คลองท่อม'
        },
        {
            geoid: '6',
            provinceid: '64',
            value: '868',
            label: 'อ่าวลึก'
        },
        {
            geoid: '6',
            provinceid: '64',
            value: '869',
            label: 'ปลายพระยา'
        },
        {
            geoid: '6',
            provinceid: '64',
            value: '870',
            label: 'ลำทับ'
        },
        {
            geoid: '6',
            provinceid: '64',
            value: '871',
            label: 'เหนือคลอง'
        },
        {
            geoid: '6',
            provinceid: '65',
            value: '872',
            label: 'เมืองพังงา'
        },
        {
            geoid: '6',
            provinceid: '65',
            value: '873',
            label: 'เกาะยาว'
        },
        {
            geoid: '6',
            provinceid: '65',
            value: '874',
            label: 'กะปง'
        },
        {
            geoid: '6',
            provinceid: '65',
            value: '875',
            label: 'ตะกั่วทุ่ง'
        },
        {
            geoid: '6',
            provinceid: '65',
            value: '876',
            label: 'ตะกั่วป่า'
        },
        {
            geoid: '6',
            provinceid: '65',
            value: '877',
            label: 'คุระบุรี'
        },
        {
            geoid: '6',
            provinceid: '65',
            value: '878',
            label: 'ทับปุด'
        },
        {
            geoid: '6',
            provinceid: '65',
            value: '879',
            label: 'ท้ายเหมือง'
        },
        {
            geoid: '6',
            provinceid: '66',
            value: '880',
            label: 'เมืองภูเก็ต'
        },
        {
            geoid: '6',
            provinceid: '66',
            value: '881',
            label: 'กะทู้'
        },
        {
            geoid: '6',
            provinceid: '66',
            value: '882',
            label: 'ถลาง'
        },
        {
            geoid: '6',
            provinceid: '66',
            value: '883',
            label: 'ทุ่งคา'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '884',
            label: 'เมืองสุราษฎร์ธานี'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '885',
            label: 'กาญจนดิษฐ์'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '886',
            label: 'ดอนสัก'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '887',
            label: 'เกาะสมุย'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '888',
            label: 'เกาะพะงัน'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '889',
            label: 'ไชยา'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '890',
            label: 'ท่าชนะ'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '891',
            label: 'คีรีรัฐนิคม'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '892',
            label: 'บ้านตาขุน'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '893',
            label: 'พนม'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '894',
            label: 'ท่าฉาง'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '895',
            label: 'บ้านนาสาร'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '896',
            label: 'บ้านนาเดิม'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '897',
            label: 'เคียนซา'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '898',
            label: 'เวียงสระ'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '899',
            label: 'พระแสง'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '900',
            label: 'พุนพิน'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '901',
            label: 'ชัยบุรี'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '902',
            label: 'วิภาวดี'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '903',
            label: 'เกาะพงัน (สาขาตำบลเกาะเต่า)'
        },
        {
            geoid: '6',
            provinceid: '67',
            value: '904',
            label: 'อ.บ้านดอน จ.สุราษฎร์ธานี'
        },
        {
            geoid: '6',
            provinceid: '68',
            value: '905',
            label: 'เมืองระนอง'
        },
        {
            geoid: '6',
            provinceid: '68',
            value: '906',
            label: 'ละอุ่น'
        },
        {
            geoid: '6',
            provinceid: '68',
            value: '907',
            label: 'กะเปอร์'
        },
        {
            geoid: '6',
            provinceid: '68',
            value: '908',
            label: 'กระบุรี'
        },
        {
            geoid: '6',
            provinceid: '68',
            value: '909',
            label: 'สุขสำราญ'
        },
        {
            geoid: '6',
            provinceid: '69',
            value: '910',
            label: 'เมืองชุมพร'
        },
        {
            geoid: '6',
            provinceid: '69',
            value: '911',
            label: 'ท่าแซะ'
        },
        {
            geoid: '6',
            provinceid: '69',
            value: '912',
            label: 'ปะทิว'
        },
        {
            geoid: '6',
            provinceid: '69',
            value: '913',
            label: 'หลังสวน'
        },
        {
            geoid: '6',
            provinceid: '69',
            value: '914',
            label: 'ละแม'
        },
        {
            geoid: '6',
            provinceid: '69',
            value: '915',
            label: 'พะโต๊ะ'
        },
        {
            geoid: '6',
            provinceid: '69',
            value: '916',
            label: 'สวี'
        },
        {
            geoid: '6',
            provinceid: '69',
            value: '917',
            label: 'ทุ่งตะโก'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '918',
            label: 'เมืองสงขลา'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '919',
            label: 'สทิงพระ'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '920',
            label: 'จะนะ'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '921',
            label: 'นาทวี'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '922',
            label: 'เทพา'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '923',
            label: 'สะบ้าย้อย'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '924',
            label: 'ระโนด'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '925',
            label: 'กระแสสินธุ์'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '926',
            label: 'รัตภูมิ'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '927',
            label: 'สะเดา'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '928',
            label: 'หาดใหญ่'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '929',
            label: 'นาหม่อม'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '930',
            label: 'ควนเนียง'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '931',
            label: 'บางกล่ำ'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '932',
            label: 'สิงหนคร'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '933',
            label: 'คลองหอยโข่ง'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '934',
            label: 'ท้องถิ่นเทศบาลตำบลสำนักขาม'
        },
        {
            geoid: '6',
            provinceid: '70',
            value: '935',
            label: 'เทศบาลตำบลบ้านพรุ'
        },
        {
            geoid: '6',
            provinceid: '71',
            value: '936',
            label: 'เมืองสตูล'
        },
        {
            geoid: '6',
            provinceid: '71',
            value: '937',
            label: 'ควนโดน'
        },
        {
            geoid: '6',
            provinceid: '71',
            value: '938',
            label: 'ควนกาหลง'
        },
        {
            geoid: '6',
            provinceid: '71',
            value: '939',
            label: 'ท่าแพ'
        },
        {
            geoid: '6',
            provinceid: '71',
            value: '940',
            label: 'ละงู'
        },
        {
            geoid: '6',
            provinceid: '71',
            value: '941',
            label: 'ทุ่งหว้า'
        },
        {
            geoid: '6',
            provinceid: '71',
            value: '942',
            label: 'มะนัง'
        },
        {
            geoid: '6',
            provinceid: '72',
            value: '943',
            label: 'เมืองตรัง'
        },
        {
            geoid: '6',
            provinceid: '72',
            value: '944',
            label: 'กันตัง'
        },
        {
            geoid: '6',
            provinceid: '72',
            value: '945',
            label: 'ย่านตาขาว'
        },
        {
            geoid: '6',
            provinceid: '72',
            value: '946',
            label: 'ปะเหลียน'
        },
        {
            geoid: '6',
            provinceid: '72',
            value: '947',
            label: 'สิเกา'
        },
        {
            geoid: '6',
            provinceid: '72',
            value: '948',
            label: 'ห้วยยอด'
        },
        {
            geoid: '6',
            provinceid: '72',
            value: '949',
            label: 'วังวิเศษ'
        },
        {
            geoid: '6',
            provinceid: '72',
            value: '950',
            label: 'นาโยง'
        },
        {
            geoid: '6',
            provinceid: '72',
            value: '951',
            label: 'รัษฎา'
        },
        {
            geoid: '6',
            provinceid: '72',
            value: '952',
            label: 'หาดสำราญ'
        },
        {
            geoid: '6',
            provinceid: '72',
            value: '953',
            label: 'อำเภอเมืองตรัง(สาขาคลองเต็ง)'
        },
        {
            geoid: '6',
            provinceid: '73',
            value: '954',
            label: 'เมืองพัทลุง'
        },
        {
            geoid: '6',
            provinceid: '73',
            value: '955',
            label: 'กงหรา'
        },
        {
            geoid: '6',
            provinceid: '73',
            value: '956',
            label: 'เขาชัยสน'
        },
        {
            geoid: '6',
            provinceid: '73',
            value: '957',
            label: 'ตะโหมด'
        },
        {
            geoid: '6',
            provinceid: '73',
            value: '958',
            label: 'ควนขนุน'
        },
        {
            geoid: '6',
            provinceid: '73',
            value: '959',
            label: 'ปากพะยูน'
        },
        {
            geoid: '6',
            provinceid: '73',
            value: '960',
            label: 'ศรีบรรพต'
        },
        {
            geoid: '6',
            provinceid: '73',
            value: '961',
            label: 'ป่าบอน'
        },
        {
            geoid: '6',
            provinceid: '73',
            value: '962',
            label: 'บางแก้ว'
        },
        {
            geoid: '6',
            provinceid: '73',
            value: '963',
            label: 'ป่าพะยอม'
        },
        {
            geoid: '6',
            provinceid: '73',
            value: '964',
            label: 'ศรีนครินทร์'
        },
        {
            geoid: '6',
            provinceid: '74',
            value: '965',
            label: 'เมืองปัตตานี'
        },
        {
            geoid: '6',
            provinceid: '74',
            value: '966',
            label: 'โคกโพธิ์'
        },
        {
            geoid: '6',
            provinceid: '74',
            value: '967',
            label: 'หนองจิก'
        },
        {
            geoid: '6',
            provinceid: '74',
            value: '968',
            label: 'ปะนาเระ'
        },
        {
            geoid: '6',
            provinceid: '74',
            value: '969',
            label: 'มายอ'
        },
        {
            geoid: '6',
            provinceid: '74',
            value: '970',
            label: 'ทุ่งยางแดง'
        },
        {
            geoid: '6',
            provinceid: '74',
            value: '971',
            label: 'สายบุรี'
        },
        {
            geoid: '6',
            provinceid: '74',
            value: '972',
            label: 'ไม้แก่น'
        },
        {
            geoid: '6',
            provinceid: '74',
            value: '973',
            label: 'ยะหริ่ง'
        },
        {
            geoid: '6',
            provinceid: '74',
            value: '974',
            label: 'ยะรัง'
        },
        {
            geoid: '6',
            provinceid: '74',
            value: '975',
            label: 'กะพ้อ'
        },
        {
            geoid: '6',
            provinceid: '74',
            value: '976',
            label: 'แม่ลาน'
        },
        {
            geoid: '6',
            provinceid: '75',
            value: '977',
            label: 'เมืองยะลา'
        },
        {
            geoid: '6',
            provinceid: '75',
            value: '978',
            label: 'เบตง'
        },
        {
            geoid: '6',
            provinceid: '75',
            value: '979',
            label: 'บันนังสตา'
        },
        {
            geoid: '6',
            provinceid: '75',
            value: '980',
            label: 'ธารโต'
        },
        {
            geoid: '6',
            provinceid: '75',
            value: '981',
            label: 'ยะหา'
        },
        {
            geoid: '6',
            provinceid: '75',
            value: '982',
            label: 'รามัน'
        },
        {
            geoid: '6',
            provinceid: '75',
            value: '983',
            label: 'กาบัง'
        },
        {
            geoid: '6',
            provinceid: '75',
            value: '984',
            label: 'กรงปินัง'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '985',
            label: 'เมืองนราธิวาส'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '986',
            label: 'ตากใบ'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '987',
            label: 'บาเจาะ'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '988',
            label: 'ยี่งอ'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '989',
            label: 'ระแงะ'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '990',
            label: 'รือเสาะ'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '991',
            label: 'ศรีสาคร'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '992',
            label: 'แว้ง'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '993',
            label: 'สุคิริน'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '994',
            label: 'สุไหงโก-ลก'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '995',
            label: 'สุไหงปาดี'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '996',
            label: 'จะแนะ'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '997',
            label: 'เจาะไอร้อง'
        },
        {
            geoid: '6',
            provinceid: '76',
            value: '998',
            label: 'อ.บางนรา จ.นราธิวาส'
        },
        {
            geoid: '3',
            provinceid: '77',
            value: '1005',
            label: 'ปากคาด'
        },
        {
            geoid: '3',
            provinceid: '77',
            value: '1004',
            label: 'บึงโขงหลง'
        },
        {
            geoid: '3',
            provinceid: '77',
            value: '1003',
            label: 'ศรีวิไล'
        },
        {
            geoid: '3',
            provinceid: '77',
            value: '1002',
            label: 'พรเจริญ'
        },
        {
            geoid: '3',
            provinceid: '77',
            value: '1001',
            label: 'โซ่พิสัย'
        },
        {
            geoid: '3',
            provinceid: '77',
            value: '1000',
            label: 'เซกา'
        },
        {
            geoid: '3',
            provinceid: '77',
            value: '999',
            label: 'เมืองบึงกาฬ'
        },
        {
            geoid: '3',
            provinceid: '77',
            value: '1006',
            label: 'บุ่งคล้า'
        }
    ],
    subdistrict: [
        {
            geoid: '2',
            provinceid: '1',
            districtid: '1',
            value: '1',
            label: 'พระบรมมหาราชวัง',
            zipcode: '10200'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '1',
            value: '2',
            label: 'วังบูรพาภิรมย์',
            zipcode: '10200'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '1',
            value: '3',
            label: 'วัดราชบพิธ',
            zipcode: '10200'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '1',
            value: '4',
            label: 'สำราญราษฎร์',
            zipcode: '10200'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '1',
            value: '5',
            label: 'ศาลเจ้าพ่อเสือ',
            zipcode: '10200'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '1',
            value: '6',
            label: 'เสาชิงช้า',
            zipcode: '10200'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '1',
            value: '7',
            label: 'บวรนิเวศ',
            zipcode: '10200'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '1',
            value: '8',
            label: 'ตลาดยอด',
            zipcode: '10200'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '1',
            value: '9',
            label: 'ชนะสงคราม',
            zipcode: '10200'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '1',
            value: '10',
            label: 'บ้านพานถม',
            zipcode: '10200'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '1',
            value: '11',
            label: 'บางขุนพรหม',
            zipcode: '10200'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '1',
            value: '12',
            label: 'วัดสามพระยา',
            zipcode: '10200'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '2',
            value: '13',
            label: 'ดุสิต',
            zipcode: '10300'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '2',
            value: '14',
            label: 'วชิรพยาบาล',
            zipcode: '10300'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '2',
            value: '15',
            label: 'สวนจิตรลดา',
            zipcode: '10300'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '2',
            value: '16',
            label: 'สี่แยกมหานาค',
            zipcode: '10300'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '2',
            value: '18',
            label: 'ถนนนครไชยศรี',
            zipcode: '10300'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '3',
            value: '20',
            label: 'กระทุ่มราย',
            zipcode: '10530'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '3',
            value: '21',
            label: 'หนองจอก',
            zipcode: '10530'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '3',
            value: '22',
            label: 'คลองสิบ',
            zipcode: '10530'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '3',
            value: '23',
            label: 'คลองสิบสอง',
            zipcode: '10530'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '3',
            value: '24',
            label: 'โคกแฝด',
            zipcode: '10530'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '3',
            value: '25',
            label: 'คู้ฝั่งเหนือ',
            zipcode: '10530'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '3',
            value: '26',
            label: 'ลำผักชี',
            zipcode: '10530'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '3',
            value: '27',
            label: 'ลำต้อยติ่ง',
            zipcode: '10530'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '4',
            value: '28',
            label: 'มหาพฤฒาราม',
            zipcode: '10500'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '4',
            value: '29',
            label: 'สีลม',
            zipcode: '10500'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '4',
            value: '30',
            label: 'สุริยวงศ์',
            zipcode: '10500'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '4',
            value: '31',
            label: 'บางรัก',
            zipcode: '10500'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '4',
            value: '32',
            label: 'สี่พระยา',
            zipcode: '10500'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '5',
            value: '34',
            label: 'อนุสาวรีย์',
            zipcode: '10220'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '5',
            value: '40',
            label: 'ท่าแร้ง',
            zipcode: '10220'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '6',
            value: '43',
            label: 'คลองจั่น',
            zipcode: '10240'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '6',
            value: '50',
            label: 'หัวหมาก',
            zipcode: '10240'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '7',
            value: '51',
            label: 'รองเมือง',
            zipcode: '10330'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '7',
            value: '52',
            label: 'วังใหม่',
            zipcode: '10330'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '7',
            value: '53',
            label: 'ปทุมวัน',
            zipcode: '10330'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '7',
            value: '54',
            label: 'ลุมพินี',
            zipcode: '10330'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '8',
            value: '55',
            label: 'ป้อมปราบ',
            zipcode: '10100'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '8',
            value: '56',
            label: 'วัดเทพศิรินทร์',
            zipcode: '10100'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '8',
            value: '57',
            label: 'คลองมหานาค',
            zipcode: '10100'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '8',
            value: '58',
            label: 'บ้านบาตร',
            zipcode: '10100'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '8',
            value: '59',
            label: 'วัดโสมนัส',
            zipcode: '10100'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '9',
            value: '65',
            label: 'บางจาก',
            zipcode: '10260'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '10',
            value: '73',
            label: 'มีนบุรี',
            zipcode: '10510'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '10',
            value: '74',
            label: 'แสนแสบ',
            zipcode: '10510'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '11',
            value: '80',
            label: 'ลาดกระบัง',
            zipcode: '10520'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '11',
            value: '81',
            label: 'คลองสองต้นนุ่น',
            zipcode: '10520'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '11',
            value: '82',
            label: 'คลองสามประเวศ',
            zipcode: '10520'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '11',
            value: '83',
            label: 'ลำปลาทิว',
            zipcode: '10520'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '11',
            value: '84',
            label: 'ทับยาว',
            zipcode: '10520'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '11',
            value: '85',
            label: 'ขุมทอง',
            zipcode: '10520'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '12',
            value: '88',
            label: 'ช่องนนทรี',
            zipcode: '10120'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '12',
            value: '89',
            label: 'บางโพงพาง',
            zipcode: '10120'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '13',
            value: '96',
            label: 'จักรวรรดิ',
            zipcode: '10100'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '13',
            value: '97',
            label: 'สัมพันธวงศ์',
            zipcode: '10100'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '13',
            value: '98',
            label: 'ตลาดน้อย',
            zipcode: '10100'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '14',
            value: '99',
            label: 'สามเสนใน',
            zipcode: '10400'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '15',
            value: '105',
            label: 'วัดกัลยาณ์',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '15',
            value: '106',
            label: 'หิรัญรูจี',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '15',
            value: '107',
            label: 'บางยี่เรือ',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '15',
            value: '108',
            label: 'บุคคโล',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '15',
            value: '109',
            label: 'ตลาดพลู',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '15',
            value: '110',
            label: 'ดาวคะนอง',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '15',
            value: '111',
            label: 'สำเหร่',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '16',
            value: '113',
            label: 'วัดอรุณ',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '16',
            value: '114',
            label: 'วัดท่าพระ',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '17',
            value: '115',
            label: 'ห้วยขวาง',
            zipcode: '10310'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '17',
            value: '116',
            label: 'บางกะปิ',
            zipcode: '10310'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '17',
            value: '118',
            label: 'สามเสนนอก',
            zipcode: '10310'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '18',
            value: '119',
            label: 'สมเด็จเจ้าพระยา',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '18',
            value: '120',
            label: 'คลองสาน',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '18',
            value: '121',
            label: 'บางลำภูล่าง',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '18',
            value: '122',
            label: 'คลองต้นไทร',
            zipcode: '10600'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '19',
            value: '123',
            label: 'คลองชักพระ',
            zipcode: '10170'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '19',
            value: '124',
            label: 'ตลิ่งชัน',
            zipcode: '10170'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '19',
            value: '125',
            label: 'ฉิมพลี',
            zipcode: '10170'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '19',
            value: '126',
            label: 'บางพรม',
            zipcode: '10170'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '19',
            value: '127',
            label: 'บางระมาด',
            zipcode: '10170'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '19',
            value: '129',
            label: 'บางเชือกหนัง',
            zipcode: '10170'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '20',
            value: '134',
            label: 'ศิริราช',
            zipcode: '10700'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '20',
            value: '135',
            label: 'บ้านช่างหล่อ',
            zipcode: '10700'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '20',
            value: '136',
            label: 'บางขุนนนท์',
            zipcode: '10700'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '20',
            value: '137',
            label: 'บางขุนศรี',
            zipcode: '10700'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '20',
            value: '139',
            label: 'อรุณอมรินทร์',
            zipcode: '10700'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '21',
            value: '147',
            label: 'ท่าข้าม',
            zipcode: '10150'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '21',
            value: '149',
            label: 'แสมดำ',
            zipcode: '10150'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '22',
            value: '150',
            label: 'บางหว้า',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '22',
            value: '151',
            label: 'บางด้วน',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '22',
            value: '152',
            label: 'บางแค',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '22',
            value: '153',
            label: 'บางแคเหนือ',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '22',
            value: '154',
            label: 'บางไผ่',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '22',
            value: '155',
            label: 'บางจาก',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '22',
            value: '156',
            label: 'บางแวก',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '22',
            value: '157',
            label: 'คลองขวาง',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '22',
            value: '158',
            label: 'ปากคลองภาษีเจริญ',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '22',
            value: '159',
            label: 'คูหาสวรรค์',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '23',
            value: '161',
            label: 'หนองแขม',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '23',
            value: '162',
            label: 'หนองค้างพลู',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '24',
            value: '163',
            label: 'ราษฎร์บูรณะ',
            zipcode: '10140'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '24',
            value: '164',
            label: 'บางปะกอก',
            zipcode: '10140'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '24',
            value: '165',
            label: 'บางมด',
            zipcode: '10140'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '24',
            value: '166',
            label: 'ทุ่งครุ',
            zipcode: '10140'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '25',
            value: '167',
            label: 'บางพลัด',
            zipcode: '10700'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '25',
            value: '168',
            label: 'บางอ้อ',
            zipcode: '10700'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '25',
            value: '169',
            label: 'บางบำหรุ',
            zipcode: '10700'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '25',
            value: '170',
            label: 'บางยี่ขัน',
            zipcode: '10700'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '26',
            value: '171',
            label: 'ดินแดง',
            zipcode: '10400'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '27',
            value: '172',
            label: 'คลองกุ่ม',
            zipcode: '10240'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '27',
            value: '173',
            label: 'สะพานสูง',
            zipcode: '10240'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '27',
            value: '174',
            label: 'คันนายาว',
            zipcode: '10240'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '28',
            value: '175',
            label: 'ทุ่งวัดดอน',
            zipcode: '10120'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '28',
            value: '176',
            label: 'ยานนาวา',
            zipcode: '10120'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '28',
            value: '177',
            label: 'ทุ่งมหาเมฆ',
            zipcode: '10120'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '29',
            value: '178',
            label: 'บางซื่อ',
            zipcode: '10800'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '30',
            value: '179',
            label: 'ลาดยาว',
            zipcode: '10900'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '30',
            value: '180',
            label: 'เสนานิคม',
            zipcode: '10900'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '30',
            value: '181',
            label: 'จันทรเกษม',
            zipcode: '10900'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '30',
            value: '182',
            label: 'จอมพล',
            zipcode: '10900'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '30',
            value: '183',
            label: 'จตุจักร',
            zipcode: '10900'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '31',
            value: '184',
            label: 'บางคอแหลม',
            zipcode: '10120'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '31',
            value: '185',
            label: 'วัดพระยาไกร',
            zipcode: '10120'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '31',
            value: '186',
            label: 'บางโคล่',
            zipcode: '10120'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '32',
            value: '187',
            label: 'ประเวศ',
            zipcode: '10250'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '32',
            value: '188',
            label: 'หนองบอน',
            zipcode: '10250'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '32',
            value: '189',
            label: 'ดอกไม้',
            zipcode: '10250'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '32',
            value: '190',
            label: 'สวนหลวง',
            zipcode: '10250'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '33',
            value: '191',
            label: 'คลองเตย',
            zipcode: '10110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '33',
            value: '192',
            label: 'คลองตัน',
            zipcode: '10110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '33',
            value: '193',
            label: 'พระโขนง',
            zipcode: '10110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '33',
            value: '194',
            label: 'คลองเตยเหนือ',
            zipcode: '10110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '33',
            value: '195',
            label: 'คลองตันเหนือ',
            zipcode: '10110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '33',
            value: '196',
            label: 'พระโขนงเหนือ',
            zipcode: '10110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '34',
            value: '197',
            label: 'สวนหลวง',
            zipcode: '10250'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '35',
            value: '198',
            label: 'บางขุนเทียน',
            zipcode: '10150'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '35',
            value: '199',
            label: 'บางค้อ',
            zipcode: '10150'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '35',
            value: '200',
            label: 'บางมด',
            zipcode: '10150'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '35',
            value: '201',
            label: 'จอมทอง',
            zipcode: '10150'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '36',
            value: '203',
            label: 'สีกัน',
            zipcode: '10210'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '37',
            value: '205',
            label: 'ทุ่งพญาไท',
            zipcode: '10400'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '37',
            value: '206',
            label: 'ถนนพญาไท',
            zipcode: '10400'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '37',
            value: '207',
            label: 'ถนนเพชรบุรี',
            zipcode: '10400'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '37',
            value: '208',
            label: 'มักกะสัน',
            zipcode: '10400'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '38',
            value: '209',
            label: 'ลาดพร้าว',
            zipcode: '10230'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '38',
            value: '210',
            label: 'จรเข้บัว',
            zipcode: '10230'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '39',
            value: '211',
            label: 'คลองเตยเหนือ',
            zipcode: '10110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '39',
            value: '212',
            label: 'คลองตันเหนือ',
            zipcode: '10110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '39',
            value: '213',
            label: 'พระโขนงเหนือ',
            zipcode: '10110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '40',
            value: '214',
            label: 'บางแค',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '40',
            value: '215',
            label: 'บางแคเหนือ',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '40',
            value: '216',
            label: 'บางไผ่',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '40',
            value: '217',
            label: 'หลักสอง',
            zipcode: '10160'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '41',
            value: '218',
            label: 'ทุ่งสองห้อง',
            zipcode: '10210'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '41',
            value: '219',
            label: 'ตลาดบางเขน',
            zipcode: '10210'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '42',
            value: '220',
            label: 'สายไหม',
            zipcode: '10220'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '42',
            value: '221',
            label: 'ออเงิน',
            zipcode: '10220'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '42',
            value: '222',
            label: 'คลองถนน',
            zipcode: '10220'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '43',
            value: '223',
            label: 'คันนายาว',
            zipcode: '10230'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '44',
            value: '224',
            label: 'สะพานสูง',
            zipcode: '10240'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '45',
            value: '225',
            label: 'วังทองหลาง',
            zipcode: '10310'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '46',
            value: '226',
            label: 'สามวาตะวันตก',
            zipcode: '10510'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '46',
            value: '227',
            label: 'สามวาตะวันออก',
            zipcode: '10510'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '46',
            value: '228',
            label: 'บางชัน',
            zipcode: '10510'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '46',
            value: '229',
            label: 'ทรายกองดิน',
            zipcode: '10510'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '46',
            value: '230',
            label: 'ทรายกองดินใต้',
            zipcode: '10510'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '47',
            value: '231',
            label: 'บางนา',
            zipcode: '10260'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '48',
            value: '232',
            label: 'ทวีวัฒนา',
            zipcode: '10170'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '48',
            value: '233',
            label: 'ศาลาธรรมสพน์',
            zipcode: '10170'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '49',
            value: '234',
            label: 'บางมด',
            zipcode: '10140'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '49',
            value: '235',
            label: 'ทุ่งครุ',
            zipcode: '10140'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '50',
            value: '236',
            label: 'บางบอน',
            zipcode: '10150'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '237',
            label: 'ปากน้ำ',
            zipcode: '10270'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '238',
            label: 'สำโรงเหนือ',
            zipcode: '10270'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '239',
            label: 'บางเมือง',
            zipcode: '10270'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '240',
            label: 'ท้ายบ้าน',
            zipcode: '10280'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '244',
            label: 'บางปูใหม่',
            zipcode: '10280'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '246',
            label: 'แพรกษา',
            zipcode: '10280'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '247',
            label: 'บางโปรง',
            zipcode: '10270'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '248',
            label: 'บางปู',
            zipcode: '10270'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '249',
            label: 'บางด้วน',
            zipcode: '10270'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '250',
            label: 'บางเมืองใหม่',
            zipcode: '10270'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '251',
            label: 'เทพารักษ์',
            zipcode: '10270'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '252',
            label: 'ท้ายบ้านใหม่',
            zipcode: '10280'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '253',
            label: 'แพรกษาใหม่',
            zipcode: '10280'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '53',
            value: '260',
            label: 'บางบ่อ',
            zipcode: '10560'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '53',
            value: '261',
            label: 'บ้านระกาศ',
            zipcode: '10560'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '53',
            value: '262',
            label: 'บางพลีน้อย',
            zipcode: '10560'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '53',
            value: '263',
            label: 'บางเพรียง',
            zipcode: '10560'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '53',
            value: '264',
            label: 'คลองด่าน',
            zipcode: '10550'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '53',
            value: '265',
            label: 'คลองสวน',
            zipcode: '10560'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '53',
            value: '266',
            label: 'เปร็ง',
            zipcode: '10560'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '53',
            value: '267',
            label: 'คลองนิยมยาตรา',
            zipcode: '10560'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '54',
            value: '269',
            label: 'บางพลีใหญ่',
            zipcode: '10540'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '54',
            value: '270',
            label: 'บางแก้ว',
            zipcode: '10540'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '54',
            value: '271',
            label: 'บางปลา',
            zipcode: '10540'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '54',
            value: '272',
            label: 'บางโฉลง',
            zipcode: '10540'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '54',
            value: '276',
            label: 'ราชาเทวะ',
            zipcode: '10540'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '54',
            value: '277',
            label: 'หนองปรือ',
            zipcode: '10540'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '278',
            label: 'ตลาด',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '279',
            label: 'บางพึ่ง',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '280',
            label: 'บางจาก',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '281',
            label: 'บางครุ',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '282',
            label: 'บางหญ้าแพรก',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '283',
            label: 'บางหัวเสือ',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '284',
            label: 'สำโรงใต้',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '285',
            label: 'บางยอ',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '286',
            label: 'บางกะเจ้า',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '287',
            label: 'บางน้ำผึ้ง',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '288',
            label: 'บางกระสอบ',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '289',
            label: 'บางกอบัว',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '290',
            label: 'ทรงคนอง',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '291',
            label: 'สำโรง',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '55',
            value: '292',
            label: 'สำโรงกลาง',
            zipcode: '10130'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '56',
            value: '293',
            label: 'นาเกลือ',
            zipcode: '10290'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '56',
            value: '294',
            label: 'บ้านคลองสวน',
            zipcode: '10290'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '56',
            value: '295',
            label: 'แหลมฟ้าผ่า',
            zipcode: '10290'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '56',
            value: '296',
            label: 'ปากคลองบางปลากด',
            zipcode: '10290'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '56',
            value: '297',
            label: 'ในคลองบางปลากด',
            zipcode: '10290'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '57',
            value: '298',
            label: 'บางเสาธง',
            zipcode: '10540'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '57',
            value: '299',
            label: 'ศีรษะจรเข้น้อย',
            zipcode: '10540'
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '57',
            value: '300',
            label: 'ศีรษะจรเข้ใหญ่',
            zipcode: '10540'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '58',
            value: '301',
            label: 'สวนใหญ่',
            zipcode: '11000'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '58',
            value: '302',
            label: 'ตลาดขวัญ',
            zipcode: '11000'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '58',
            value: '303',
            label: 'บางเขน',
            zipcode: '11000'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '58',
            value: '304',
            label: 'บางกระสอ',
            zipcode: '11000'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '58',
            value: '305',
            label: 'ท่าทราย',
            zipcode: '11000'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '58',
            value: '306',
            label: 'บางไผ่',
            zipcode: '11000'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '58',
            value: '307',
            label: 'บางศรีเมือง',
            zipcode: '11000'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '58',
            value: '308',
            label: 'บางกร่าง',
            zipcode: '11000'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '58',
            value: '309',
            label: 'ไทรม้า',
            zipcode: '11000'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '58',
            value: '310',
            label: 'บางรักน้อย',
            zipcode: '11000'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '59',
            value: '311',
            label: 'วัดชลอ',
            zipcode: '11130'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '59',
            value: '312',
            label: 'บางกรวย',
            zipcode: '11130'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '59',
            value: '313',
            label: 'บางสีทอง',
            zipcode: '11130'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '59',
            value: '314',
            label: 'บางขนุน',
            zipcode: '11130'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '59',
            value: '315',
            label: 'บางขุนกอง',
            zipcode: '11130'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '59',
            value: '316',
            label: 'บางคูเวียง',
            zipcode: '11130'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '59',
            value: '317',
            label: 'มหาสวัสดิ์',
            zipcode: '11130'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '59',
            value: '318',
            label: 'ปลายบาง',
            zipcode: '11130'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '59',
            value: '319',
            label: 'ศาลากลาง',
            zipcode: '11130'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '60',
            value: '320',
            label: 'บางม่วง',
            zipcode: '11140'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '60',
            value: '321',
            label: 'บางแม่นาง',
            zipcode: '11140'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '60',
            value: '322',
            label: 'บางเลน',
            zipcode: '11140'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '60',
            value: '323',
            label: 'เสาธงหิน',
            zipcode: '11140'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '60',
            value: '324',
            label: 'บางใหญ่',
            zipcode: '11140'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '60',
            value: '325',
            label: 'บ้านใหม่',
            zipcode: '11140'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '61',
            value: '326',
            label: 'โสนลอย',
            zipcode: '11110'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '61',
            value: '327',
            label: 'บางบัวทอง',
            zipcode: '11110'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '61',
            value: '328',
            label: 'บางรักใหญ่',
            zipcode: '11110'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '61',
            value: '329',
            label: 'บางคูรัด',
            zipcode: '11110'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '61',
            value: '330',
            label: 'ละหาร',
            zipcode: '11110'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '61',
            value: '331',
            label: 'ลำโพ',
            zipcode: '11110'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '61',
            value: '332',
            label: 'พิมลราช',
            zipcode: '11110'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '61',
            value: '333',
            label: 'บางรักพัฒนา',
            zipcode: '11110'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '62',
            value: '334',
            label: 'ไทรน้อย',
            zipcode: '11150'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '62',
            value: '335',
            label: 'ราษฎร์นิยม',
            zipcode: '11150'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '62',
            value: '336',
            label: 'หนองเพรางาย',
            zipcode: '11150'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '62',
            value: '337',
            label: 'ไทรใหญ่',
            zipcode: '11150'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '62',
            value: '338',
            label: 'ขุนศรี',
            zipcode: '11150'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '62',
            value: '339',
            label: 'คลองขวาง',
            zipcode: '11150'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '62',
            value: '340',
            label: 'ทวีวัฒนา',
            zipcode: '11150'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '63',
            value: '341',
            label: 'ปากเกร็ด',
            zipcode: '11120'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '63',
            value: '342',
            label: 'บางตลาด',
            zipcode: '11120'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '63',
            value: '343',
            label: 'บ้านใหม่',
            zipcode: '11120'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '63',
            value: '344',
            label: 'บางพูด',
            zipcode: '11120'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '63',
            value: '345',
            label: 'บางตะไนย์',
            zipcode: '11120'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '63',
            value: '346',
            label: 'คลองพระอุดม',
            zipcode: '11120'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '63',
            value: '347',
            label: 'ท่าอิฐ',
            zipcode: '11120'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '63',
            value: '348',
            label: 'เกาะเกร็ด',
            zipcode: '11120'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '63',
            value: '349',
            label: 'อ้อมเกร็ด',
            zipcode: '11120'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '63',
            value: '350',
            label: 'คลองข่อย',
            zipcode: '11120'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '63',
            value: '351',
            label: 'บางพลับ',
            zipcode: '11120'
        },
        {
            geoid: '2',
            provinceid: '3',
            districtid: '63',
            value: '352',
            label: 'คลองเกลือ',
            zipcode: '11120'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '353',
            label: 'บางปรอก',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '354',
            label: 'บ้านใหม่',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '355',
            label: 'บ้านกลาง',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '356',
            label: 'บ้านฉาง',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '357',
            label: 'บ้านกระแชง',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '358',
            label: 'บางขะแยง',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '359',
            label: 'บางคูวัด',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '360',
            label: 'บางหลวง',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '361',
            label: 'บางเดื่อ',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '362',
            label: 'บางพูด',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '363',
            label: 'บางพูน',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '364',
            label: 'บางกะดี',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '365',
            label: 'สวนพริกไทย',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '66',
            value: '366',
            label: 'หลักหก',
            zipcode: '12000'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '67',
            value: '367',
            label: 'คลองหนึ่ง',
            zipcode: '12120'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '67',
            value: '368',
            label: 'คลองสอง',
            zipcode: '12120'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '67',
            value: '369',
            label: 'คลองสาม',
            zipcode: '12120'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '67',
            value: '370',
            label: 'คลองสี่',
            zipcode: '12120'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '67',
            value: '371',
            label: 'คลองห้า',
            zipcode: '12120'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '67',
            value: '372',
            label: 'คลองหก',
            zipcode: '12120'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '67',
            value: '373',
            label: 'คลองเจ็ด',
            zipcode: '12120'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '68',
            value: '374',
            label: 'ประชาธิปัตย์',
            zipcode: '12130'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '68',
            value: '375',
            label: 'บึงยี่โถ',
            zipcode: '12130'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '68',
            value: '376',
            label: 'รังสิต',
            zipcode: '12110'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '68',
            value: '377',
            label: 'ลำผักกูด',
            zipcode: '12110'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '68',
            value: '378',
            label: 'บึงสนั่น',
            zipcode: '12110'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '68',
            value: '379',
            label: 'บึงน้ำรักษ์',
            zipcode: '12110'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '69',
            value: '380',
            label: 'บึงบา',
            zipcode: '12170'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '69',
            value: '381',
            label: 'บึงบอน',
            zipcode: '12170'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '69',
            value: '382',
            label: 'บึงกาสาม',
            zipcode: '12170'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '69',
            value: '383',
            label: 'บึงชำอ้อ',
            zipcode: '12170'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '69',
            value: '384',
            label: 'หนองสามวัง',
            zipcode: '12170'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '69',
            value: '385',
            label: 'ศาลาครุ',
            zipcode: '12170'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '69',
            value: '386',
            label: 'นพรัตน์',
            zipcode: '12170'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '70',
            value: '387',
            label: 'ระแหง',
            zipcode: '12140'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '70',
            value: '388',
            label: 'ลาดหลุมแก้ว',
            zipcode: '12140'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '70',
            value: '389',
            label: 'คูบางหลวง',
            zipcode: '12140'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '70',
            value: '390',
            label: 'คูขวาง',
            zipcode: '12140'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '70',
            value: '391',
            label: 'คลองพระอุดม',
            zipcode: '12140'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '70',
            value: '392',
            label: 'บ่อเงิน',
            zipcode: '12140'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '70',
            value: '393',
            label: 'หน้าไม้',
            zipcode: '12140'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '71',
            value: '394',
            label: 'คูคต',
            zipcode: '12130'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '71',
            value: '395',
            label: 'ลาดสวาย',
            zipcode: '12150'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '71',
            value: '396',
            label: 'บึงคำพร้อย',
            zipcode: '12150'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '71',
            value: '397',
            label: 'ลำลูกกา',
            zipcode: '12150'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '71',
            value: '398',
            label: 'บึงทองหลาง',
            zipcode: '12150'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '71',
            value: '399',
            label: 'ลำไทร',
            zipcode: '12150'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '71',
            value: '400',
            label: 'บึงคอไห',
            zipcode: '12150'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '71',
            value: '401',
            label: 'พืชอุดม',
            zipcode: '12150'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '72',
            value: '402',
            label: 'บางเตย',
            zipcode: '12160'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '72',
            value: '403',
            label: 'คลองควาย',
            zipcode: '12160'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '72',
            value: '404',
            label: 'สามโคก',
            zipcode: '12160'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '72',
            value: '405',
            label: 'กระแชง',
            zipcode: '12160'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '72',
            value: '406',
            label: 'บางโพธิ์เหนือ',
            zipcode: '12160'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '72',
            value: '407',
            label: 'เชียงรากใหญ่',
            zipcode: '12160'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '72',
            value: '408',
            label: 'บ้านปทุม',
            zipcode: '12160'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '72',
            value: '409',
            label: 'บ้านงิ้ว',
            zipcode: '12160'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '72',
            value: '410',
            label: 'เชียงรากน้อย',
            zipcode: '12160'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '72',
            value: '411',
            label: 'บางกระบือ',
            zipcode: '12160'
        },
        {
            geoid: '2',
            provinceid: '4',
            districtid: '72',
            value: '412',
            label: 'ท้ายเกาะ',
            zipcode: '12160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '413',
            label: 'ประตูชัย',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '414',
            label: 'กะมัง',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '415',
            label: 'หอรัตนไชย',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '416',
            label: 'หัวรอ',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '417',
            label: 'ท่าวาสุกรี',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '418',
            label: 'ไผ่ลิง',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '419',
            label: 'ปากกราน',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '420',
            label: 'ภูเขาทอง',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '421',
            label: 'สำเภาล่ม',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '422',
            label: 'สวนพริก',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '423',
            label: 'คลองตะเคียน',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '424',
            label: 'วัดตูม',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '425',
            label: 'หันตรา',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '426',
            label: 'ลุมพลี',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '427',
            label: 'บ้านใหม่',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '428',
            label: 'บ้านเกาะ',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '429',
            label: 'คลองสวนพลู',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '430',
            label: 'คลองสระบัว',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '431',
            label: 'เกาะเรียน',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '432',
            label: 'บ้านป้อม',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '433',
            label: 'บ้านรุน',
            zipcode: '13000'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '75',
            value: '435',
            label: 'ท่าเรือ',
            zipcode: '13130'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '75',
            value: '436',
            label: 'จำปา',
            zipcode: '13130'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '75',
            value: '437',
            label: 'ท่าหลวง',
            zipcode: '13130'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '75',
            value: '438',
            label: 'บ้านร่อม',
            zipcode: '13130'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '75',
            value: '439',
            label: 'ศาลาลอย',
            zipcode: '13130'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '75',
            value: '440',
            label: 'วังแดง',
            zipcode: '13130'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '75',
            value: '441',
            label: 'โพธิ์เอน',
            zipcode: '13130'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '75',
            value: '442',
            label: 'ปากท่า',
            zipcode: '13130'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '75',
            value: '443',
            label: 'หนองขนาก',
            zipcode: '13130'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '75',
            value: '444',
            label: 'ท่าเจ้าสนุก',
            zipcode: '13130'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '76',
            value: '445',
            label: 'นครหลวง',
            zipcode: '13260'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '76',
            value: '446',
            label: 'ท่าช้าง',
            zipcode: '13260'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '76',
            value: '447',
            label: 'บ่อโพง',
            zipcode: '13260'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '76',
            value: '448',
            label: 'บ้านชุ้ง',
            zipcode: '13260'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '76',
            value: '449',
            label: 'ปากจั่น',
            zipcode: '13260'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '76',
            value: '450',
            label: 'บางระกำ',
            zipcode: '13260'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '76',
            value: '451',
            label: 'บางพระครู',
            zipcode: '13260'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '76',
            value: '452',
            label: 'แม่ลา',
            zipcode: '13260'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '76',
            value: '453',
            label: 'หนองปลิง',
            zipcode: '13260'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '76',
            value: '454',
            label: 'คลองสะแก',
            zipcode: '13260'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '76',
            value: '455',
            label: 'สามไถ',
            zipcode: '13260'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '76',
            value: '456',
            label: 'พระนอน',
            zipcode: '13260'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '457',
            label: 'บางไทร',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '458',
            label: 'บางพลี',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '459',
            label: 'สนามชัย',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '460',
            label: 'บ้านแป้ง',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '461',
            label: 'หน้าไม้',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '462',
            label: 'บางยี่โท',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '463',
            label: 'แคออก',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '464',
            label: 'แคตก',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '465',
            label: 'ช่างเหล็ก',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '466',
            label: 'กระแชง',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '467',
            label: 'บ้านกลึง',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '468',
            label: 'ช้างน้อย',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '469',
            label: 'ห่อหมก',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '470',
            label: 'ไผ่พระ',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '471',
            label: 'กกแก้วบูรพา',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '472',
            label: 'ไม้ตรา',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '473',
            label: 'บ้านม้า',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '474',
            label: 'บ้านเกาะ',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '475',
            label: 'ราชคราม',
            zipcode: '13290'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '476',
            label: 'ช้างใหญ่',
            zipcode: '13290'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '477',
            label: 'โพแตง',
            zipcode: '13290'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '478',
            label: 'เชียงรากน้อย',
            zipcode: '13290'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '77',
            value: '479',
            label: 'โคกช้าง',
            zipcode: '13190'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '480',
            label: 'บางบาล',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '481',
            label: 'วัดยม',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '482',
            label: 'ไทรน้อย',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '483',
            label: 'สะพานไทย',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '484',
            label: 'มหาพราหมณ์',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '485',
            label: 'กบเจา',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '486',
            label: 'บ้านคลัง',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '487',
            label: 'พระขาว',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '488',
            label: 'น้ำเต้า',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '489',
            label: 'ทางช้าง',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '490',
            label: 'วัดตะกู',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '491',
            label: 'บางหลวง',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '492',
            label: 'บางหลวงโดด',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '493',
            label: 'บางหัก',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '494',
            label: 'บางชะนี',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '78',
            value: '495',
            label: 'บ้านกุ่ม',
            zipcode: '13250'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '496',
            label: 'บ้านเลน',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '497',
            label: 'เชียงรากน้อย',
            zipcode: '13180'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '498',
            label: 'บ้านโพ',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '499',
            label: 'บ้านกรด',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '500',
            label: 'บางกระสั้น',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '501',
            label: 'คลองจิก',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '502',
            label: 'บ้านหว้า',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '503',
            label: 'วัดยม',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '504',
            label: 'บางประแดง',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '505',
            label: 'สามเรือน',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '506',
            label: 'เกาะเกิด',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '507',
            label: 'บ้านพลับ',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '508',
            label: 'บ้านแป้ง',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '509',
            label: 'คุ้งลาน',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '510',
            label: 'ตลิ่งชัน',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '511',
            label: 'บ้านสร้าง',
            zipcode: '13170'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '512',
            label: 'ตลาดเกรียบ',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '79',
            value: '513',
            label: 'ขนอนหลวง',
            zipcode: '13160'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '514',
            label: 'บางปะหัน',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '515',
            label: 'ขยาย',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '516',
            label: 'บางเดื่อ',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '517',
            label: 'เสาธง',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '518',
            label: 'ทางกลาง',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '519',
            label: 'บางเพลิง',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '520',
            label: 'หันสัง',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '521',
            label: 'บางนางร้า',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '522',
            label: 'ตานิม',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '523',
            label: 'ทับน้ำ',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '524',
            label: 'บ้านม้า',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '525',
            label: 'ขวัญเมือง',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '526',
            label: 'บ้านลี่',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '527',
            label: 'โพธิ์สามต้น',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '528',
            label: 'พุทเลา',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '529',
            label: 'ตาลเอน',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '80',
            value: '530',
            label: 'บ้านขล้อ',
            zipcode: '13220'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '531',
            label: 'ผักไห่',
            zipcode: '13120'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '532',
            label: 'อมฤต',
            zipcode: '13120'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '533',
            label: 'บ้านแค',
            zipcode: '13120'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '534',
            label: 'ลาดน้ำเค็ม',
            zipcode: '13120'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '535',
            label: 'ตาลาน',
            zipcode: '13120'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '536',
            label: 'ท่าดินแดง',
            zipcode: '13120'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '537',
            label: 'ดอนลาน',
            zipcode: '13280'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '538',
            label: 'นาคู',
            zipcode: '13280'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '539',
            label: 'กุฎี',
            zipcode: '13120'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '540',
            label: 'ลำตะเคียน',
            zipcode: '13280'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '541',
            label: 'โคกช้าง',
            zipcode: '13120'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '542',
            label: 'จักราช',
            zipcode: '13280'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '543',
            label: 'หนองน้ำใหญ่',
            zipcode: '13280'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '544',
            label: 'ลาดชิด',
            zipcode: '13120'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '545',
            label: 'หน้าโคก',
            zipcode: '13120'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '81',
            value: '546',
            label: 'บ้านใหญ่',
            zipcode: '13120'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '82',
            value: '547',
            label: 'ภาชี',
            zipcode: '13140'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '82',
            value: '548',
            label: 'โคกม่วง',
            zipcode: '13140'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '82',
            value: '549',
            label: 'ระโสม',
            zipcode: '13140'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '82',
            value: '550',
            label: 'หนองน้ำใส',
            zipcode: '13140'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '82',
            value: '551',
            label: 'ดอนหญ้านาง',
            zipcode: '13140'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '82',
            value: '552',
            label: 'ไผ่ล้อม',
            zipcode: '13140'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '82',
            value: '553',
            label: 'กระจิว',
            zipcode: '13140'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '82',
            value: '554',
            label: 'พระแก้ว',
            zipcode: '13140'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '83',
            value: '555',
            label: 'ลาดบัวหลวง',
            zipcode: '13230'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '83',
            value: '556',
            label: 'หลักชัย',
            zipcode: '13230'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '83',
            value: '557',
            label: 'สามเมือง',
            zipcode: '13230'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '83',
            value: '558',
            label: 'พระยาบันลือ',
            zipcode: '13230'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '83',
            value: '559',
            label: 'สิงหนาท',
            zipcode: '13230'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '83',
            value: '560',
            label: 'คู้สลอด',
            zipcode: '13230'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '83',
            value: '561',
            label: 'คลองพระยาบันลือ',
            zipcode: '13230'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '84',
            value: '562',
            label: 'ลำตาเสา',
            zipcode: '13170'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '84',
            value: '563',
            label: 'บ่อตาโล่',
            zipcode: '13170'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '84',
            value: '564',
            label: 'วังน้อย',
            zipcode: '13170'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '84',
            value: '565',
            label: 'ลำไทร',
            zipcode: '13170'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '84',
            value: '566',
            label: 'สนับทึบ',
            zipcode: '13170'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '84',
            value: '567',
            label: 'พยอม',
            zipcode: '13170'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '84',
            value: '568',
            label: 'หันตะเภา',
            zipcode: '13170'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '84',
            value: '569',
            label: 'วังจุฬา',
            zipcode: '13170'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '84',
            value: '570',
            label: 'ข้าวงาม',
            zipcode: '13170'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '84',
            value: '571',
            label: 'ชะแมบ',
            zipcode: '13170'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '572',
            label: 'เสนา',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '573',
            label: 'บ้านแพน',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '574',
            label: 'เจ้าเจ็ด',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '575',
            label: 'สามกอ',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '576',
            label: 'บางนมโค',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '577',
            label: 'หัวเวียง',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '578',
            label: 'มารวิชัย',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '579',
            label: 'บ้านโพธิ์',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '580',
            label: 'รางจรเข้',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '581',
            label: 'บ้านกระทุ่ม',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '582',
            label: 'บ้านแถว',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '583',
            label: 'ชายนา',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '584',
            label: 'สามตุ่ม',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '585',
            label: 'ลาดงา',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '586',
            label: 'ดอนทอง',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '587',
            label: 'บ้านหลวง',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '85',
            value: '588',
            label: 'เจ้าเสด็จ',
            zipcode: '13110'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '86',
            value: '589',
            label: 'บางซ้าย',
            zipcode: '13270'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '86',
            value: '590',
            label: 'แก้วฟ้า',
            zipcode: '13270'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '86',
            value: '591',
            label: 'เต่าเล่า',
            zipcode: '13270'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '86',
            value: '592',
            label: 'ปลายกลัด',
            zipcode: '13270'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '86',
            value: '593',
            label: 'เทพมงคล',
            zipcode: '13270'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '86',
            value: '594',
            label: 'วังพัฒนา',
            zipcode: '13270'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '87',
            value: '595',
            label: 'คานหาม',
            zipcode: '13210'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '87',
            value: '596',
            label: 'บ้านช้าง',
            zipcode: '13210'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '87',
            value: '597',
            label: 'สามบัณฑิต',
            zipcode: '13210'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '87',
            value: '598',
            label: 'บ้านหีบ',
            zipcode: '13210'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '87',
            value: '599',
            label: 'หนองไม้ซุง',
            zipcode: '13210'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '87',
            value: '600',
            label: 'อุทัย',
            zipcode: '13210'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '87',
            value: '601',
            label: 'เสนา',
            zipcode: '13210'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '87',
            value: '602',
            label: 'หนองน้ำส้ม',
            zipcode: '13210'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '87',
            value: '603',
            label: 'โพสาวหาญ',
            zipcode: '13210'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '87',
            value: '604',
            label: 'ธนู',
            zipcode: '13210'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '87',
            value: '605',
            label: 'ข้าวเม่า',
            zipcode: '13210'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '88',
            value: '606',
            label: 'หัวไผ่',
            zipcode: '13150'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '88',
            value: '607',
            label: 'กะทุ่ม',
            zipcode: '13150'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '88',
            value: '608',
            label: 'มหาราช',
            zipcode: '13150'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '88',
            value: '609',
            label: 'น้ำเต้า',
            zipcode: '13150'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '88',
            value: '610',
            label: 'บางนา',
            zipcode: '13150'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '88',
            value: '611',
            label: 'โรงช้าง',
            zipcode: '13150'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '88',
            value: '612',
            label: 'เจ้าปลุก',
            zipcode: '13150'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '88',
            value: '613',
            label: 'พิตเพียน',
            zipcode: '13150'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '88',
            value: '614',
            label: 'บ้านนา',
            zipcode: '13150'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '88',
            value: '615',
            label: 'บ้านขวาง',
            zipcode: '13150'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '88',
            value: '616',
            label: 'ท่าตอ',
            zipcode: '13150'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '88',
            value: '617',
            label: 'บ้านใหม่',
            zipcode: '13150'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '89',
            value: '618',
            label: 'บ้านแพรก',
            zipcode: '13240'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '89',
            value: '619',
            label: 'บ้านใหม่',
            zipcode: '13240'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '89',
            value: '620',
            label: 'สำพะเนียง',
            zipcode: '13240'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '89',
            value: '621',
            label: 'คลองน้อย',
            zipcode: '13240'
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '89',
            value: '622',
            label: 'สองห้อง',
            zipcode: '13240'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '623',
            label: 'ตลาดหลวง',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '624',
            label: 'บางแก้ว',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '625',
            label: 'ศาลาแดง',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '626',
            label: 'ป่างิ้ว',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '627',
            label: 'บ้านแห',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '628',
            label: 'ตลาดกรวด',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '629',
            label: 'มหาดไทย',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '630',
            label: 'บ้านอิฐ',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '631',
            label: 'หัวไผ่',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '632',
            label: 'จำปาหล่อ',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '633',
            label: 'โพสะ',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '634',
            label: 'บ้านรี',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '635',
            label: 'คลองวัว',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '90',
            value: '636',
            label: 'ย่านซื่อ',
            zipcode: '14000'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '91',
            value: '637',
            label: 'จรเข้ร้อง',
            zipcode: '14140'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '91',
            value: '638',
            label: 'ไชยภูมิ',
            zipcode: '14140'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '91',
            value: '639',
            label: 'ชัยฤทธิ์',
            zipcode: '14140'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '91',
            value: '640',
            label: 'เทวราช',
            zipcode: '14140'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '91',
            value: '641',
            label: 'ราชสถิตย์',
            zipcode: '14140'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '91',
            value: '642',
            label: 'ไชโย',
            zipcode: '14140'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '91',
            value: '643',
            label: 'หลักฟ้า',
            zipcode: '14140'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '91',
            value: '644',
            label: 'ชะไว',
            zipcode: '14140'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '91',
            value: '645',
            label: 'ตรีณรงค์',
            zipcode: '14140'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '92',
            value: '646',
            label: 'บางปลากด',
            zipcode: '14130'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '92',
            value: '647',
            label: 'ป่าโมก',
            zipcode: '14130'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '92',
            value: '648',
            label: 'สายทอง',
            zipcode: '14130'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '92',
            value: '649',
            label: 'โรงช้าง',
            zipcode: '14130'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '92',
            value: '650',
            label: 'บางเสด็จ',
            zipcode: '14130'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '92',
            value: '651',
            label: 'นรสิงห์',
            zipcode: '14130'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '92',
            value: '652',
            label: 'เอกราช',
            zipcode: '14130'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '92',
            value: '653',
            label: 'โผงเผง',
            zipcode: '14130'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '654',
            label: 'อ่างแก้ว',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '655',
            label: 'อินทประมูล',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '656',
            label: 'บางพลับ',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '657',
            label: 'หนองแม่ไก่',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '658',
            label: 'รำมะสัก',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '659',
            label: 'บางระกำ',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '660',
            label: 'โพธิ์รังนก',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '661',
            label: 'องครักษ์',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '662',
            label: 'โคกพุทรา',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '663',
            label: 'ยางช้าย',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '664',
            label: 'บ่อแร่',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '665',
            label: 'ทางพระ',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '666',
            label: 'สามง่าม',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '667',
            label: 'บางเจ้าฉ่า',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '93',
            value: '668',
            label: 'คำหยาด',
            zipcode: '14120'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '94',
            value: '669',
            label: 'แสวงหา',
            zipcode: '14150'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '94',
            value: '670',
            label: 'ศรีพราน',
            zipcode: '14150'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '94',
            value: '671',
            label: 'บ้านพราน',
            zipcode: '14150'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '94',
            value: '672',
            label: 'วังน้ำเย็น',
            zipcode: '14150'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '94',
            value: '673',
            label: 'สีบัวทอง',
            zipcode: '14150'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '94',
            value: '674',
            label: 'ห้วยไผ่',
            zipcode: '14150'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '94',
            value: '675',
            label: 'จำลอง',
            zipcode: '14150'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '676',
            label: 'ไผ่จำศิล',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '677',
            label: 'ศาลเจ้าโรงทอง',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '678',
            label: 'ไผ่ดำพัฒนา',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '679',
            label: 'สาวร้องไห้',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '680',
            label: 'ท่าช้าง',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '681',
            label: 'ยี่ล้น',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '682',
            label: 'บางจัก',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '683',
            label: 'ห้วยคันแหลน',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '684',
            label: 'คลองขนาก',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '685',
            label: 'ไผ่วง',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '686',
            label: 'สี่ร้อย',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '687',
            label: 'ม่วงเตี้ย',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '688',
            label: 'หัวตะพาน',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '689',
            label: 'หลักแก้ว',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '95',
            value: '690',
            label: 'ตลาดใหม่',
            zipcode: '14110'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '96',
            value: '691',
            label: 'สามโก้',
            zipcode: '14160'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '96',
            value: '692',
            label: 'ราษฎรพัฒนา',
            zipcode: '14160'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '96',
            value: '693',
            label: 'อบทม',
            zipcode: '14160'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '96',
            value: '694',
            label: 'โพธิ์ม่วงพันธ์',
            zipcode: '14160'
        },
        {
            geoid: '2',
            provinceid: '6',
            districtid: '96',
            value: '695',
            label: 'มงคลธรรมนิมิต',
            zipcode: '14160'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '696',
            label: 'ทะเลชุบศร',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '697',
            label: 'ท่าหิน',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '698',
            label: 'กกโก',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '699',
            label: 'โก่งธนู',
            zipcode: '13240'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '700',
            label: 'เขาพระงาม',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '701',
            label: 'เขาสามยอด',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '702',
            label: 'โคกกะเทียม',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '703',
            label: 'โคกลำพาน',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '704',
            label: 'โคกตูม',
            zipcode: '15210'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '705',
            label: 'งิ้วราย',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '706',
            label: 'ดอนโพธิ์',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '707',
            label: 'ตะลุง',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '709',
            label: 'ท่าแค',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '710',
            label: 'ท่าศาลา',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '711',
            label: 'นิคมสร้างตนเอง',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '712',
            label: 'บางขันหมาก',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '713',
            label: 'บ้านข่อย',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '714',
            label: 'ท้ายตลาด',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '715',
            label: 'ป่าตาล',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '716',
            label: 'พรหมมาสตร์',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '717',
            label: 'โพธิ์เก้าต้น',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '718',
            label: 'โพธิ์ตรุ',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '719',
            label: 'สี่คลอง',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '720',
            label: 'ถนนใหญ่',
            zipcode: '15000'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '98',
            value: '721',
            label: 'พัฒนานิคม',
            zipcode: '15140'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '98',
            value: '722',
            label: 'ช่องสาริกา',
            zipcode: '15220'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '98',
            value: '723',
            label: 'มะนาวหวาน',
            zipcode: '15140'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '98',
            value: '724',
            label: 'ดีลัง',
            zipcode: '15220'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '98',
            value: '725',
            label: 'โคกสลุง',
            zipcode: '15140'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '98',
            value: '726',
            label: 'ชอนน้อย',
            zipcode: '15140'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '98',
            value: '727',
            label: 'หนองบัว',
            zipcode: '15140'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '98',
            value: '728',
            label: 'ห้วยขุนราม',
            zipcode: '18220'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '98',
            value: '729',
            label: 'น้ำสุด',
            zipcode: '15140'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '730',
            label: 'โคกสำโรง',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '731',
            label: 'เกาะแก้ว',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '732',
            label: 'ถลุงเหล็ก',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '733',
            label: 'หลุมข้าว',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '734',
            label: 'ห้วยโป่ง',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '735',
            label: 'คลองเกตุ',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '736',
            label: 'สะแกราบ',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '737',
            label: 'เพนียด',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '738',
            label: 'วังเพลิง',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '739',
            label: 'ดงมะรุม',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '747',
            label: 'วังขอนขว้าง',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '749',
            label: 'วังจั่น',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '751',
            label: 'หนองแขม',
            zipcode: '15120'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '764',
            label: 'ลำนารายณ์',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '765',
            label: 'ชัยนารายณ์',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '766',
            label: 'ศิลาทิพย์',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '767',
            label: 'ห้วยหิน',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '768',
            label: 'ม่วงค่อม',
            zipcode: '15230'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '769',
            label: 'บัวชุม',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '770',
            label: 'ท่าดินดำ',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '771',
            label: 'มะกอกหวาน',
            zipcode: '15230'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '772',
            label: 'ซับตะเคียน',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '773',
            label: 'นาโสม',
            zipcode: '15190'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '774',
            label: 'หนองยายโต๊ะ',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '775',
            label: 'เกาะรัง',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '777',
            label: 'ท่ามะนาว',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '780',
            label: 'นิคมลำนารายณ์',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '781',
            label: 'ชัยบาดาล',
            zipcode: '15230'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '782',
            label: 'บ้านใหม่สามัคคี',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '785',
            label: 'เขาแหลม',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '101',
            value: '794',
            label: 'ท่าวุ้ง',
            zipcode: '15150'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '101',
            value: '795',
            label: 'บางคู้',
            zipcode: '15150'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '101',
            value: '796',
            label: 'โพตลาดแก้ว',
            zipcode: '15150'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '101',
            value: '797',
            label: 'บางลี่',
            zipcode: '15150'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '101',
            value: '798',
            label: 'บางงา',
            zipcode: '15150'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '101',
            value: '799',
            label: 'โคกสลุด',
            zipcode: '15150'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '101',
            value: '800',
            label: 'เขาสมอคอน',
            zipcode: '15180'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '101',
            value: '801',
            label: 'หัวสำโรง',
            zipcode: '15150'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '101',
            value: '802',
            label: 'ลาดสาลี่',
            zipcode: '15150'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '101',
            value: '803',
            label: 'บ้านเบิก',
            zipcode: '15150'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '101',
            value: '804',
            label: 'มุจลินท์',
            zipcode: '15150'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '805',
            label: 'ไผ่ใหญ่',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '806',
            label: 'บ้านทราย',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '807',
            label: 'บ้านกล้วย',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '808',
            label: 'ดงพลับ',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '809',
            label: 'บ้านชี',
            zipcode: '15180'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '810',
            label: 'พุคา',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '811',
            label: 'หินปัก',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '812',
            label: 'บางพึ่ง',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '813',
            label: 'หนองทรายขาว',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '814',
            label: 'บางกะพี้',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '815',
            label: 'หนองเต่า',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '816',
            label: 'โพนทอง',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '817',
            label: 'บางขาม',
            zipcode: '15180'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '818',
            label: 'ดอนดึง',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '819',
            label: 'ชอนม่วง',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '820',
            label: 'หนองกระเบียน',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '821',
            label: 'สายห้วยแก้ว',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '822',
            label: 'มหาสอน',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '823',
            label: 'บ้านหมี่',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '824',
            label: 'เชียงงา',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '825',
            label: 'หนองเมือง',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '102',
            value: '826',
            label: 'สนามแจง',
            zipcode: '15110'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '103',
            value: '827',
            label: 'ท่าหลวง',
            zipcode: '15230'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '103',
            value: '828',
            label: 'แก่งผักกูด',
            zipcode: '15230'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '103',
            value: '829',
            label: 'ซับจำปา',
            zipcode: '15230'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '103',
            value: '830',
            label: 'หนองผักแว่น',
            zipcode: '15230'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '103',
            value: '831',
            label: 'ทะเลวังวัด',
            zipcode: '15230'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '103',
            value: '832',
            label: 'หัวลำ',
            zipcode: '15230'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '104',
            value: '833',
            label: 'สระโบสถ์',
            zipcode: '15240'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '104',
            value: '834',
            label: 'มหาโพธิ',
            zipcode: '15240'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '104',
            value: '835',
            label: 'ทุ่งท่าช้าง',
            zipcode: '15240'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '104',
            value: '836',
            label: 'ห้วยใหญ่',
            zipcode: '15240'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '104',
            value: '837',
            label: 'นิยมชัย',
            zipcode: '15240'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '105',
            value: '838',
            label: 'โคกเจริญ',
            zipcode: '15250'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '105',
            value: '839',
            label: 'ยางราก',
            zipcode: '15250'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '105',
            value: '840',
            label: 'หนองมะค่า',
            zipcode: '15250'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '105',
            value: '841',
            label: 'วังทอง',
            zipcode: '15250'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '105',
            value: '842',
            label: 'โคกแสมสาร',
            zipcode: '15250'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '106',
            value: '843',
            label: 'ลำสนธิ',
            zipcode: '15190'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '106',
            value: '844',
            label: 'ซับสมบูรณ์',
            zipcode: '15190'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '106',
            value: '845',
            label: 'หนองรี',
            zipcode: '15190'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '106',
            value: '846',
            label: 'กุดตาเพชร',
            zipcode: '15190'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '106',
            value: '847',
            label: 'เขารวก',
            zipcode: '15190'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '106',
            value: '848',
            label: 'เขาน้อย',
            zipcode: '15130'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '107',
            value: '849',
            label: 'หนองม่วง',
            zipcode: '15170'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '107',
            value: '850',
            label: 'บ่อทอง',
            zipcode: '15170'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '107',
            value: '851',
            label: 'ดงดินแดง',
            zipcode: '15170'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '107',
            value: '852',
            label: 'ชอนสมบูรณ์',
            zipcode: '15170'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '107',
            value: '853',
            label: 'ยางโทน',
            zipcode: '15170'
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '107',
            value: '854',
            label: 'ชอนสารเดช',
            zipcode: '15170'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '109',
            value: '855',
            label: 'บางพุทรา',
            zipcode: '16000'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '109',
            value: '856',
            label: 'บางมัญ',
            zipcode: '16000'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '109',
            value: '857',
            label: 'โพกรวม',
            zipcode: '16000'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '109',
            value: '858',
            label: 'ม่วงหมู่',
            zipcode: '16000'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '109',
            value: '859',
            label: 'หัวไผ่',
            zipcode: '16000'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '109',
            value: '860',
            label: 'ต้นโพธิ์',
            zipcode: '16000'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '109',
            value: '861',
            label: 'จักรสีห์',
            zipcode: '16000'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '109',
            value: '862',
            label: 'บางกระบือ',
            zipcode: '16000'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '110',
            value: '863',
            label: 'สิงห์',
            zipcode: '16130'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '110',
            value: '864',
            label: 'ไม้ดัด',
            zipcode: '16130'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '110',
            value: '865',
            label: 'เชิงกลัด',
            zipcode: '16130'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '110',
            value: '866',
            label: 'โพชนไก่',
            zipcode: '16130'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '110',
            value: '867',
            label: 'แม่ลา',
            zipcode: '16130'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '110',
            value: '868',
            label: 'บ้านจ่า',
            zipcode: '16130'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '110',
            value: '869',
            label: 'พักทัน',
            zipcode: '16130'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '110',
            value: '870',
            label: 'สระแจง',
            zipcode: '16130'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '111',
            value: '871',
            label: 'โพทะเล',
            zipcode: '16150'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '111',
            value: '872',
            label: 'บางระจัน',
            zipcode: '16150'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '111',
            value: '873',
            label: 'โพสังโฆ',
            zipcode: '16150'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '111',
            value: '874',
            label: 'ท่าข้าม',
            zipcode: '16150'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '111',
            value: '875',
            label: 'คอทราย',
            zipcode: '16150'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '111',
            value: '876',
            label: 'หนองกระทุ่ม',
            zipcode: '16150'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '112',
            value: '877',
            label: 'พระงาม',
            zipcode: '16120'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '112',
            value: '878',
            label: 'พรหมบุรี',
            zipcode: '16160'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '112',
            value: '879',
            label: 'บางน้ำเชี่ยว',
            zipcode: '16120'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '112',
            value: '880',
            label: 'บ้านหม้อ',
            zipcode: '16120'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '112',
            value: '881',
            label: 'บ้านแป้ง',
            zipcode: '16120'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '112',
            value: '882',
            label: 'หัวป่า',
            zipcode: '16120'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '112',
            value: '883',
            label: 'โรงช้าง',
            zipcode: '16120'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '113',
            value: '884',
            label: 'ถอนสมอ',
            zipcode: '16140'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '113',
            value: '885',
            label: 'โพประจักษ์',
            zipcode: '16140'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '113',
            value: '886',
            label: 'วิหารขาว',
            zipcode: '16140'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '113',
            value: '887',
            label: 'พิกุลทอง',
            zipcode: '16140'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '114',
            value: '888',
            label: 'อินทร์บุรี',
            zipcode: '16110'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '114',
            value: '889',
            label: 'ประศุก',
            zipcode: '16110'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '114',
            value: '890',
            label: 'ทับยา',
            zipcode: '16110'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '114',
            value: '891',
            label: 'งิ้วราย',
            zipcode: '16110'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '114',
            value: '892',
            label: 'ชีน้ำร้าย',
            zipcode: '16110'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '114',
            value: '893',
            label: 'ท่างาม',
            zipcode: '16110'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '114',
            value: '894',
            label: 'น้ำตาล',
            zipcode: '16110'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '114',
            value: '895',
            label: 'ทองเอน',
            zipcode: '16110'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '114',
            value: '896',
            label: 'ห้วยชัน',
            zipcode: '16110'
        },
        {
            geoid: '2',
            provinceid: '8',
            districtid: '114',
            value: '897',
            label: 'โพธิ์ชัย',
            zipcode: '16110'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '115',
            value: '898',
            label: 'ในเมือง',
            zipcode: '17000'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '115',
            value: '899',
            label: 'บ้านกล้วย',
            zipcode: '17000'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '115',
            value: '900',
            label: 'ท่าชัย',
            zipcode: '17000'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '115',
            value: '901',
            label: 'ชัยนาท',
            zipcode: '17000'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '115',
            value: '902',
            label: 'เขาท่าพระ',
            zipcode: '17000'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '115',
            value: '903',
            label: 'หาดท่าเสา',
            zipcode: '17000'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '115',
            value: '904',
            label: 'ธรรมามูล',
            zipcode: '17000'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '115',
            value: '905',
            label: 'เสือโฮก',
            zipcode: '17000'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '115',
            value: '906',
            label: 'นางลือ',
            zipcode: '17000'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '116',
            value: '907',
            label: 'คุ้งสำเภา',
            zipcode: '17110'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '116',
            value: '908',
            label: 'วัดโคก',
            zipcode: '17110'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '116',
            value: '909',
            label: 'ศิลาดาน',
            zipcode: '17110'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '116',
            value: '910',
            label: 'ท่าฉนวน',
            zipcode: '17110'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '116',
            value: '911',
            label: 'หางน้ำสาคร',
            zipcode: '17170'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '116',
            value: '912',
            label: 'ไร่พัฒนา',
            zipcode: '17170'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '116',
            value: '913',
            label: 'อู่ตะเภา',
            zipcode: '17170'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '117',
            value: '914',
            label: 'วัดสิงห์',
            zipcode: '17120'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '117',
            value: '915',
            label: 'มะขามเฒ่า',
            zipcode: '17120'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '117',
            value: '916',
            label: 'หนองน้อย',
            zipcode: '17120'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '117',
            value: '917',
            label: 'หนองบัว',
            zipcode: '17120'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '117',
            value: '919',
            label: 'หนองขุ่น',
            zipcode: '17120'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '117',
            value: '920',
            label: 'บ่อแร่',
            zipcode: '17120'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '117',
            value: '924',
            label: 'วังหมัน',
            zipcode: '17120'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '118',
            value: '925',
            label: 'สรรพยา',
            zipcode: '17150'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '118',
            value: '926',
            label: 'ตลุก',
            zipcode: '17150'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '118',
            value: '927',
            label: 'เขาแก้ว',
            zipcode: '17150'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '118',
            value: '928',
            label: 'โพนางดำตก',
            zipcode: '17150'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '118',
            value: '929',
            label: 'โพนางดำออก',
            zipcode: '17150'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '118',
            value: '930',
            label: 'บางหลวง',
            zipcode: '17150'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '118',
            value: '931',
            label: 'หาดอาษา',
            zipcode: '17150'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '119',
            value: '932',
            label: 'แพรกศรีราชา',
            zipcode: '17140'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '119',
            value: '933',
            label: 'เที่ยงแท้',
            zipcode: '17140'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '119',
            value: '934',
            label: 'ห้วยกรด',
            zipcode: '17140'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '119',
            value: '935',
            label: 'โพงาม',
            zipcode: '17140'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '119',
            value: '936',
            label: 'บางขุด',
            zipcode: '17140'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '119',
            value: '937',
            label: 'ดงคอน',
            zipcode: '17140'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '119',
            value: '938',
            label: 'ดอนกำ',
            zipcode: '17140'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '119',
            value: '939',
            label: 'ห้วยกรดพัฒนา',
            zipcode: '17140'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '120',
            value: '940',
            label: 'หันคา',
            zipcode: '17130'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '120',
            value: '941',
            label: 'บ้านเชี่ยน',
            zipcode: '17130'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '120',
            value: '944',
            label: 'ไพรนกยูง',
            zipcode: '17130'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '120',
            value: '945',
            label: 'หนองแซง',
            zipcode: '17160'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '120',
            value: '946',
            label: 'ห้วยงู',
            zipcode: '17160'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '120',
            value: '947',
            label: 'วังไก่เถื่อน',
            zipcode: '17130'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '120',
            value: '948',
            label: 'เด่นใหญ่',
            zipcode: '17130'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '120',
            value: '950',
            label: 'สามง่ามท่าโบสถ์',
            zipcode: '17160'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '121',
            value: '951',
            label: 'หนองมะโมง',
            zipcode: '17120'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '121',
            value: '952',
            label: 'วังตะเคียน',
            zipcode: '17120'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '121',
            value: '953',
            label: 'สะพานหิน',
            zipcode: '17120'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '121',
            value: '954',
            label: 'กุดจอก',
            zipcode: '17120'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '122',
            value: '955',
            label: 'เนินขาม',
            zipcode: '17130'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '122',
            value: '956',
            label: 'กะบกเตี้ย',
            zipcode: '17130'
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '122',
            value: '957',
            label: 'สุขเดือนห้า',
            zipcode: '17130'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '958',
            label: 'ปากเพรียว',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '962',
            label: 'ดาวเรือง',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '963',
            label: 'นาโฉง',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '964',
            label: 'โคกสว่าง',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '965',
            label: 'หนองโน',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '966',
            label: 'หนองยาว',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '967',
            label: 'ปากข้าวสาร',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '968',
            label: 'หนองปลาไหล',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '969',
            label: 'กุดนกเปล้า',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '970',
            label: 'ตลิ่งชัน',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '971',
            label: 'ตะกุด',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '975',
            label: 'แก่งคอย',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '976',
            label: 'ทับกวาง',
            zipcode: '18260'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '977',
            label: 'ตาลเดี่ยว',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '978',
            label: 'ห้วยแห้ง',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '979',
            label: 'ท่าคล้อ',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '980',
            label: 'หินซ้อน',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '981',
            label: 'บ้านธาตุ',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '982',
            label: 'บ้านป่า',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '983',
            label: 'ท่าตูม',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '984',
            label: 'ชะอม',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '985',
            label: 'สองคอน',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '986',
            label: 'เตาปูน',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '987',
            label: 'ชำผักแพว',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '124',
            value: '988',
            label: 'ท่ามะปราง',
            zipcode: '18110'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '989',
            label: 'หนองแค',
            zipcode: '18140'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '990',
            label: 'กุ่มหัก',
            zipcode: '18140'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '991',
            label: 'คชสิทธิ์',
            zipcode: '18250'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '992',
            label: 'โคกตูม',
            zipcode: '18250'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '993',
            label: 'โคกแย้',
            zipcode: '18230'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '994',
            label: 'บัวลอย',
            zipcode: '18230'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '995',
            label: 'ไผ่ต่ำ',
            zipcode: '18140'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '996',
            label: 'โพนทอง',
            zipcode: '18250'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '997',
            label: 'ห้วยขมิ้น',
            zipcode: '18230'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '998',
            label: 'ห้วยทราย',
            zipcode: '18230'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '999',
            label: 'หนองไข่น้ำ',
            zipcode: '18140'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '1000',
            label: 'หนองแขม',
            zipcode: '18140'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '1001',
            label: 'หนองจิก',
            zipcode: '18230'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '1002',
            label: 'หนองจรเข้',
            zipcode: '18140'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '1003',
            label: 'หนองนาก',
            zipcode: '18230'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '1004',
            label: 'หนองปลาหมอ',
            zipcode: '18140'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '1005',
            label: 'หนองปลิง',
            zipcode: '18140'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '125',
            value: '1006',
            label: 'หนองโรง',
            zipcode: '18140'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '126',
            value: '1007',
            label: 'หนองหมู',
            zipcode: '18150'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '126',
            value: '1008',
            label: 'บ้านลำ',
            zipcode: '18150'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '126',
            value: '1009',
            label: 'คลองเรือ',
            zipcode: '18150'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '126',
            value: '1010',
            label: 'วิหารแดง',
            zipcode: '18150'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '126',
            value: '1011',
            label: 'หนองสรวง',
            zipcode: '18150'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '126',
            value: '1012',
            label: 'เจริญธรรม',
            zipcode: '18150'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '127',
            value: '1013',
            label: 'หนองแซง',
            zipcode: '18170'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '127',
            value: '1014',
            label: 'หนองควายโซ',
            zipcode: '18170'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '127',
            value: '1015',
            label: 'หนองหัวโพ',
            zipcode: '18170'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '127',
            value: '1016',
            label: 'หนองสีดา',
            zipcode: '18170'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '127',
            value: '1017',
            label: 'หนองกบ',
            zipcode: '18170'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '127',
            value: '1018',
            label: 'ไก่เส่า',
            zipcode: '18170'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '127',
            value: '1019',
            label: 'โคกสะอาด',
            zipcode: '18170'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '127',
            value: '1020',
            label: 'ม่วงหวาน',
            zipcode: '18170'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '127',
            value: '1021',
            label: 'เขาดิน',
            zipcode: '18170'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1022',
            label: 'บ้านหมอ',
            zipcode: '18130'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1023',
            label: 'บางโขมด',
            zipcode: '18130'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1024',
            label: 'สร่างโศก',
            zipcode: '18130'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1025',
            label: 'ตลาดน้อย',
            zipcode: '18130'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1026',
            label: 'หรเทพ',
            zipcode: '18130'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1027',
            label: 'โคกใหญ่',
            zipcode: '18130'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1028',
            label: 'ไผ่ขวาง',
            zipcode: '18130'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1029',
            label: 'บ้านครัว',
            zipcode: '18270'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1030',
            label: 'หนองบัว',
            zipcode: '18130'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '129',
            value: '1035',
            label: 'ดอนพุด',
            zipcode: '18210'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '129',
            value: '1036',
            label: 'ไผ่หลิ่ว',
            zipcode: '18210'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '129',
            value: '1037',
            label: 'บ้านหลวง',
            zipcode: '18210'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '129',
            value: '1038',
            label: 'ดงตะงาว',
            zipcode: '18210'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '130',
            value: '1039',
            label: 'หนองโดน',
            zipcode: '18190'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '130',
            value: '1040',
            label: 'บ้านกลับ',
            zipcode: '18190'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '130',
            value: '1041',
            label: 'ดอนทอง',
            zipcode: '18190'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '130',
            value: '1042',
            label: 'บ้านโปร่ง',
            zipcode: '18190'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '131',
            value: '1043',
            label: 'พระพุทธบาท',
            zipcode: '18120'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '131',
            value: '1044',
            label: 'ขุนโขลน',
            zipcode: '18120'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '131',
            value: '1045',
            label: 'ธารเกษม',
            zipcode: '18120'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '131',
            value: '1046',
            label: 'นายาว',
            zipcode: '18120'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '131',
            value: '1047',
            label: 'พุคำจาน',
            zipcode: '18120'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '131',
            value: '1048',
            label: 'เขาวง',
            zipcode: '18120'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '131',
            value: '1049',
            label: 'ห้วยป่าหวาย',
            zipcode: '18120'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '131',
            value: '1050',
            label: 'พุกร่าง',
            zipcode: '18120'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '131',
            value: '1051',
            label: 'หนองแก',
            zipcode: '18120'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '132',
            value: '1052',
            label: 'เสาไห้',
            zipcode: '18160'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '132',
            value: '1053',
            label: 'บ้านยาง',
            zipcode: '18160'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '132',
            value: '1054',
            label: 'หัวปลวก',
            zipcode: '18160'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '132',
            value: '1055',
            label: 'งิ้วงาม',
            zipcode: '18160'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '132',
            value: '1056',
            label: 'ศาลารีไทย',
            zipcode: '18160'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '132',
            value: '1057',
            label: 'ต้นตาล',
            zipcode: '18160'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '132',
            value: '1058',
            label: 'ท่าช้าง',
            zipcode: '18160'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '132',
            value: '1059',
            label: 'พระยาทด',
            zipcode: '18160'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '132',
            value: '1060',
            label: 'ม่วงงาม',
            zipcode: '18160'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '132',
            value: '1061',
            label: 'เริงราง',
            zipcode: '18160'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '132',
            value: '1062',
            label: 'เมืองเก่า',
            zipcode: '18160'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '132',
            value: '1063',
            label: 'สวนดอกไม้',
            zipcode: '18160'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '133',
            value: '1064',
            label: 'มวกเหล็ก',
            zipcode: '18180'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '133',
            value: '1065',
            label: 'มิตรภาพ',
            zipcode: '18180'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '133',
            value: '1067',
            label: 'หนองย่างเสือ',
            zipcode: '18180'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '133',
            value: '1068',
            label: 'ลำสมพุง',
            zipcode: '18180'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '133',
            value: '1070',
            label: 'ลำพญากลาง',
            zipcode: '18180'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '133',
            value: '1072',
            label: 'ซับสนุ่น',
            zipcode: '18220'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '134',
            value: '1073',
            label: 'แสลงพัน',
            zipcode: '18220'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '134',
            value: '1074',
            label: 'คำพราน',
            zipcode: '18220'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '134',
            value: '1075',
            label: 'วังม่วง',
            zipcode: '18220'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '135',
            value: '1076',
            label: 'เขาดินพัฒนา',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '135',
            value: '1077',
            label: 'บ้านแก้ง',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '135',
            value: '1078',
            label: 'ผึ้งรวง',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '135',
            value: '1079',
            label: 'พุแค',
            zipcode: '18240'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '135',
            value: '1080',
            label: 'ห้วยบง',
            zipcode: '18000'
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '135',
            value: '1081',
            label: 'หน้าพระลาน',
            zipcode: '18240'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1082',
            label: 'บางปลาสร้อย',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1083',
            label: 'มะขามหย่ง',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1084',
            label: 'บ้านโขด',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1085',
            label: 'แสนสุข',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1086',
            label: 'บ้านสวน',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1087',
            label: 'หนองรี',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1088',
            label: 'นาป่า',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1089',
            label: 'หนองข้างคอก',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1090',
            label: 'ดอนหัวฬ่อ',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1091',
            label: 'หนองไม้แดง',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1092',
            label: 'บางทราย',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1093',
            label: 'คลองตำหรุ',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1094',
            label: 'เหมือง',
            zipcode: '20130'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1095',
            label: 'บ้านปึก',
            zipcode: '20130'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1096',
            label: 'ห้วยกะปิ',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1097',
            label: 'เสม็ด',
            zipcode: '20130'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1098',
            label: 'อ่างศิลา',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1099',
            label: 'สำนักบก',
            zipcode: '20000'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '137',
            value: '1101',
            label: 'บ้านบึง',
            zipcode: '20170'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '137',
            value: '1102',
            label: 'คลองกิ่ว',
            zipcode: '20220'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '137',
            value: '1103',
            label: 'มาบไผ่',
            zipcode: '20170'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '137',
            value: '1104',
            label: 'หนองซ้ำซาก',
            zipcode: '20170'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '137',
            value: '1105',
            label: 'หนองบอนแดง',
            zipcode: '20170'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '137',
            value: '1106',
            label: 'หนองชาก',
            zipcode: '20170'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '137',
            value: '1107',
            label: 'หนองอิรุณ',
            zipcode: '20220'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '137',
            value: '1108',
            label: 'หนองไผ่แก้ว',
            zipcode: '20220'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '138',
            value: '1112',
            label: 'หนองใหญ่',
            zipcode: '20190'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '138',
            value: '1113',
            label: 'คลองพลู',
            zipcode: '20190'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '138',
            value: '1114',
            label: 'หนองเสือช้าง',
            zipcode: '20190'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '138',
            value: '1115',
            label: 'ห้างสูง',
            zipcode: '20190'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '138',
            value: '1116',
            label: 'เขาซก',
            zipcode: '20190'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '139',
            value: '1117',
            label: 'บางละมุง',
            zipcode: '20150'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '139',
            value: '1118',
            label: 'หนองปรือ',
            zipcode: '20150'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '139',
            value: '1119',
            label: 'หนองปลาไหล',
            zipcode: '20150'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '139',
            value: '1120',
            label: 'โป่ง',
            zipcode: '20150'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '139',
            value: '1121',
            label: 'เขาไม้แก้ว',
            zipcode: '20150'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '139',
            value: '1122',
            label: 'ห้วยใหญ่',
            zipcode: '20150'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '139',
            value: '1123',
            label: 'ตะเคียนเตี้ย',
            zipcode: '20150'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '139',
            value: '1124',
            label: 'นาเกลือ',
            zipcode: '20150'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '140',
            value: '1125',
            label: 'พานทอง',
            zipcode: '20160'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '140',
            value: '1126',
            label: 'หนองตำลึง',
            zipcode: '20160'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '140',
            value: '1127',
            label: 'มาบโป่ง',
            zipcode: '20160'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '140',
            value: '1128',
            label: 'หนองกะขะ',
            zipcode: '20160'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '140',
            value: '1129',
            label: 'หนองหงษ์',
            zipcode: '20160'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '140',
            value: '1130',
            label: 'โคกขี้หนอน',
            zipcode: '20160'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '140',
            value: '1131',
            label: 'บ้านเก่า',
            zipcode: '20160'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '140',
            value: '1132',
            label: 'หน้าประดู่',
            zipcode: '20160'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '140',
            value: '1133',
            label: 'บางนาง',
            zipcode: '20160'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '140',
            value: '1134',
            label: 'เกาะลอย',
            zipcode: '20160'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '140',
            value: '1135',
            label: 'บางหัก',
            zipcode: '20160'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1136',
            label: 'พนัสนิคม',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1137',
            label: 'หน้าพระธาตุ',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1138',
            label: 'วัดหลวง',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1139',
            label: 'บ้านเซิด',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1140',
            label: 'นาเริก',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1141',
            label: 'หมอนนาง',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1142',
            label: 'สระสี่เหลี่ยม',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1143',
            label: 'วัดโบสถ์',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1144',
            label: 'กุฎโง้ง',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1145',
            label: 'หัวถนน',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1146',
            label: 'ท่าข้าม',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1148',
            label: 'หนองปรือ',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1149',
            label: 'หนองขยาด',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1150',
            label: 'ทุ่งขวาง',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1151',
            label: 'หนองเหียง',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1152',
            label: 'นาวังหิน',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1153',
            label: 'บ้านช้าง',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1155',
            label: 'โคกเพลาะ',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1156',
            label: 'ไร่หลักทอง',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1157',
            label: 'นามะตูม',
            zipcode: '20140'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '142',
            value: '1163',
            label: 'ศรีราชา',
            zipcode: '20110'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '142',
            value: '1164',
            label: 'สุรศักดิ์',
            zipcode: '20110'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '142',
            value: '1165',
            label: 'ทุ่งสุขลา',
            zipcode: '20230'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '142',
            value: '1166',
            label: 'บึง',
            zipcode: '20230'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '142',
            value: '1167',
            label: 'หนองขาม',
            zipcode: '20110'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '142',
            value: '1168',
            label: 'เขาคันทรง',
            zipcode: '20110'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '142',
            value: '1169',
            label: 'บางพระ',
            zipcode: '20110'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '142',
            value: '1170',
            label: 'บ่อวิน',
            zipcode: '20230'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '143',
            value: '1172',
            label: 'ท่าเทววงษ์',
            zipcode: '20120'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '144',
            value: '1173',
            label: 'สัตหีบ',
            zipcode: '20180'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '144',
            value: '1174',
            label: 'นาจอมเทียน',
            zipcode: '20250'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '144',
            value: '1175',
            label: 'พลูตาหลวง',
            zipcode: '20180'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '144',
            value: '1176',
            label: 'บางเสร่',
            zipcode: '20250'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '144',
            value: '1177',
            label: 'แสมสาร',
            zipcode: '20180'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '145',
            value: '1178',
            label: 'บ่อทอง',
            zipcode: '20270'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '145',
            value: '1179',
            label: 'วัดสุวรรณ',
            zipcode: '20270'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '145',
            value: '1180',
            label: 'บ่อกวางทอง',
            zipcode: '20270'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '145',
            value: '1181',
            label: 'ธาตุทอง',
            zipcode: '20270'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '145',
            value: '1182',
            label: 'เกษตรสุวรรณ',
            zipcode: '20270'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '145',
            value: '1183',
            label: 'พลวงทอง',
            zipcode: '20270'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '146',
            value: '1184',
            label: 'เกาะจันทร์',
            zipcode: '20240'
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '146',
            value: '1185',
            label: 'ท่าบุญมี',
            zipcode: '20240'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1187',
            label: 'ท่าประดู่',
            zipcode: '21000'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1188',
            label: 'เชิงเนิน',
            zipcode: '21000'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1189',
            label: 'ตะพง',
            zipcode: '21000'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1190',
            label: 'ปากน้ำ',
            zipcode: '21000'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1191',
            label: 'เพ',
            zipcode: '21160'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1192',
            label: 'แกลง',
            zipcode: '21160'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1193',
            label: 'บ้านแลง',
            zipcode: '21000'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1194',
            label: 'นาตาขวัญ',
            zipcode: '21000'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1195',
            label: 'เนินพระ',
            zipcode: '21000'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1196',
            label: 'กะเฉด',
            zipcode: '21100'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1197',
            label: 'ทับมา',
            zipcode: '21000'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1198',
            label: 'น้ำคอก',
            zipcode: '21000'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1199',
            label: 'ห้วยโป่ง',
            zipcode: '21150'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1200',
            label: 'มาบตาพุด',
            zipcode: '21150'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1201',
            label: 'สำนักทอง',
            zipcode: '21100'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '152',
            value: '1204',
            label: 'สำนักท้อน',
            zipcode: '21130'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '152',
            value: '1205',
            label: 'พลา',
            zipcode: '21130'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '152',
            value: '1206',
            label: 'บ้านฉาง',
            zipcode: '21130'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1207',
            label: 'ทางเกวียน',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1208',
            label: 'วังหว้า',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1209',
            label: 'ชากโดน',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1210',
            label: 'เนินฆ้อ',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1211',
            label: 'กร่ำ',
            zipcode: '21190'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1212',
            label: 'ชากพง',
            zipcode: '21190'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1213',
            label: 'กระแสบน',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1214',
            label: 'บ้านนา',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1215',
            label: 'ทุ่งควายกิน',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1216',
            label: 'กองดิน',
            zipcode: '22160'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1217',
            label: 'คลองปูน',
            zipcode: '21170'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1218',
            label: 'พังราด',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1219',
            label: 'ปากน้ำกระแส',
            zipcode: '21170'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1223',
            label: 'ห้วยยาง',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1224',
            label: 'สองสลึง',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '154',
            value: '1228',
            label: 'วังจันทร์',
            zipcode: '21210'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '154',
            value: '1229',
            label: 'ชุมแสง',
            zipcode: '21210'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '154',
            value: '1230',
            label: 'ป่ายุบใน',
            zipcode: '21210'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '154',
            value: '1231',
            label: 'พลงตาเอี่ยม',
            zipcode: '21210'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1232',
            label: 'บ้านค่าย',
            zipcode: '21120'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1233',
            label: 'หนองละลอก',
            zipcode: '21120'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1234',
            label: 'หนองตะพาน',
            zipcode: '21120'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1235',
            label: 'ตาขัน',
            zipcode: '21120'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1236',
            label: 'บางบุตร',
            zipcode: '21120'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1237',
            label: 'หนองบัว',
            zipcode: '21120'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1238',
            label: 'ชากบก',
            zipcode: '21120'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '156',
            value: '1249',
            label: 'ปลวกแดง',
            zipcode: '21140'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '156',
            value: '1250',
            label: 'ตาสิทธิ์',
            zipcode: '21140'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '156',
            value: '1251',
            label: 'ละหาร',
            zipcode: '21140'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '156',
            value: '1252',
            label: 'แม่น้ำคู้',
            zipcode: '21140'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '156',
            value: '1253',
            label: 'มาบยางพร',
            zipcode: '21140'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '156',
            value: '1254',
            label: 'หนองไร่',
            zipcode: '21140'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '157',
            value: '1255',
            label: 'น้ำเป็น',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '157',
            value: '1256',
            label: 'ห้วยทับมอญ',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '157',
            value: '1257',
            label: 'ชำฆ้อ',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '157',
            value: '1258',
            label: 'เขาน้อย',
            zipcode: '21110'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '158',
            value: '1259',
            label: 'นิคมพัฒนา',
            zipcode: '21180'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '158',
            value: '1260',
            label: 'มาบข่า',
            zipcode: '21180'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '158',
            value: '1261',
            label: 'พนานิคม',
            zipcode: '21180'
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '158',
            value: '1262',
            label: 'มะขามคู่',
            zipcode: '21180'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '160',
            value: '1263',
            label: 'ตลาด',
            zipcode: '22000'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '160',
            value: '1264',
            label: 'วัดใหม่',
            zipcode: '22000'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '160',
            value: '1265',
            label: 'คลองนารายณ์',
            zipcode: '22000'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '160',
            value: '1266',
            label: 'เกาะขวาง',
            zipcode: '22000'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '160',
            value: '1267',
            label: 'คมบาง',
            zipcode: '22000'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '160',
            value: '1268',
            label: 'ท่าช้าง',
            zipcode: '22000'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '160',
            value: '1269',
            label: 'จันทนิมิต',
            zipcode: '22000'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '160',
            value: '1270',
            label: 'บางกะจะ',
            zipcode: '22000'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '160',
            value: '1271',
            label: 'แสลง',
            zipcode: '22000'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '160',
            value: '1272',
            label: 'หนองบัว',
            zipcode: '22000'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '160',
            value: '1273',
            label: 'พลับพลา',
            zipcode: '22000'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '161',
            value: '1274',
            label: 'ขลุง',
            zipcode: '22110'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '161',
            value: '1275',
            label: 'บ่อ',
            zipcode: '22110'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '161',
            value: '1276',
            label: 'เกวียนหัก',
            zipcode: '22110'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '161',
            value: '1277',
            label: 'ตะปอน',
            zipcode: '22110'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '161',
            value: '1278',
            label: 'บางชัน',
            zipcode: '22110'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '161',
            value: '1279',
            label: 'วันยาว',
            zipcode: '22110'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '161',
            value: '1280',
            label: 'ซึ้ง',
            zipcode: '22110'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '161',
            value: '1281',
            label: 'มาบไพ',
            zipcode: '22110'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '161',
            value: '1282',
            label: 'วังสรรพรส',
            zipcode: '22110'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '161',
            value: '1283',
            label: 'ตรอกนอง',
            zipcode: '22110'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '161',
            value: '1284',
            label: 'ตกพรม',
            zipcode: '22110'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '161',
            value: '1285',
            label: 'บ่อเวฬุ',
            zipcode: '22150'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1286',
            label: 'ท่าใหม่',
            zipcode: '22120'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1287',
            label: 'ยายร้า',
            zipcode: '22120'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1288',
            label: 'สีพยา',
            zipcode: '22120'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1289',
            label: 'บ่อพุ',
            zipcode: '22120'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1290',
            label: 'พลอยแหวน',
            zipcode: '22120'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1291',
            label: 'เขาวัว',
            zipcode: '22120'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1292',
            label: 'เขาบายศรี',
            zipcode: '22120'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1293',
            label: 'สองพี่น้อง',
            zipcode: '22120'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1294',
            label: 'ทุ่งเบญจา',
            zipcode: '22170'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1296',
            label: 'รำพัน',
            zipcode: '22170'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1297',
            label: 'โขมง',
            zipcode: '22170'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1298',
            label: 'ตะกาดเง้า',
            zipcode: '22120'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1299',
            label: 'คลองขุด',
            zipcode: '22120'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1309',
            label: 'เขาแก้ว',
            zipcode: '22170'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '163',
            value: '1316',
            label: 'ทับไทร',
            zipcode: '22140'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '163',
            value: '1317',
            label: 'โป่งน้ำร้อน',
            zipcode: '22140'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '163',
            value: '1319',
            label: 'หนองตาคง',
            zipcode: '22140'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '163',
            value: '1324',
            label: 'เทพนิมิต',
            zipcode: '22140'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '163',
            value: '1325',
            label: 'คลองใหญ่',
            zipcode: '22140'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '164',
            value: '1326',
            label: 'มะขาม',
            zipcode: '22150'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '164',
            value: '1327',
            label: 'ท่าหลวง',
            zipcode: '22150'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '164',
            value: '1328',
            label: 'ปัถวี',
            zipcode: '22150'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '164',
            value: '1329',
            label: 'วังแซ้ม',
            zipcode: '22150'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '164',
            value: '1331',
            label: 'ฉมัน',
            zipcode: '22150'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '164',
            value: '1333',
            label: 'อ่างคีรี',
            zipcode: '22150'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '165',
            value: '1336',
            label: 'ปากน้ำแหลมสิงห์',
            zipcode: '22130'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '165',
            value: '1337',
            label: 'เกาะเปริด',
            zipcode: '22130'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '165',
            value: '1338',
            label: 'หนองชิ่ม',
            zipcode: '22130'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '165',
            value: '1339',
            label: 'พลิ้ว',
            zipcode: '22190'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '165',
            value: '1340',
            label: 'คลองน้ำเค็ม',
            zipcode: '22190'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '165',
            value: '1341',
            label: 'บางสระเก้า',
            zipcode: '22190'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '165',
            value: '1342',
            label: 'บางกะไชย',
            zipcode: '22120'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '166',
            value: '1343',
            label: 'ปะตง',
            zipcode: '22180'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '166',
            value: '1344',
            label: 'ทุ่งขนาน',
            zipcode: '22180'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '166',
            value: '1345',
            label: 'ทับช้าง',
            zipcode: '22180'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '166',
            value: '1346',
            label: 'ทรายขาว',
            zipcode: '22180'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '166',
            value: '1347',
            label: 'สะตอน',
            zipcode: '22180'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '167',
            value: '1348',
            label: 'แก่งหางแมว',
            zipcode: '22160'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '167',
            value: '1349',
            label: 'ขุนซ่อง',
            zipcode: '22160'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '167',
            value: '1350',
            label: 'สามพี่น้อง',
            zipcode: '22160'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '167',
            value: '1351',
            label: 'พวา',
            zipcode: '22160'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '167',
            value: '1352',
            label: 'เขาวงกต',
            zipcode: '22160'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '168',
            value: '1353',
            label: 'นายายอาม',
            zipcode: '22160'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '168',
            value: '1354',
            label: 'วังโตนด',
            zipcode: '22170'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '168',
            value: '1355',
            label: 'กระแจะ',
            zipcode: '22170'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '168',
            value: '1356',
            label: 'สนามไชย',
            zipcode: '22170'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '168',
            value: '1357',
            label: 'ช้างข้าม',
            zipcode: '22160'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '168',
            value: '1358',
            label: 'วังใหม่',
            zipcode: '22170'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '169',
            value: '1359',
            label: 'ชากไทย',
            zipcode: '22210'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '169',
            value: '1360',
            label: 'พลวง',
            zipcode: '22210'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '169',
            value: '1361',
            label: 'ตะเคียนทอง',
            zipcode: '22210'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '169',
            value: '1362',
            label: 'คลองพลู',
            zipcode: '22210'
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '169',
            value: '1363',
            label: 'จันทเขลม',
            zipcode: '22210'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1364',
            label: 'บางพระ',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1365',
            label: 'หนองเสม็ด',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1366',
            label: 'หนองโสน',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1367',
            label: 'หนองคันทรง',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1368',
            label: 'ห้วงน้ำขาว',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1369',
            label: 'อ่าวใหญ่',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1370',
            label: 'วังกระแจะ',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1371',
            label: 'ห้วยแร้ง',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1372',
            label: 'เนินทราย',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1373',
            label: 'ท่าพริก',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1374',
            label: 'ท่ากุ่ม',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1375',
            label: 'ตะกาง',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1376',
            label: 'ชำราก',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '171',
            value: '1377',
            label: 'แหลมกลัด',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '172',
            value: '1378',
            label: 'คลองใหญ่',
            zipcode: '23110'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '172',
            value: '1379',
            label: 'ไม้รูด',
            zipcode: '23110'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '172',
            value: '1380',
            label: 'หาดเล็ก',
            zipcode: '23110'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1381',
            label: 'เขาสมิง',
            zipcode: '23130'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1382',
            label: 'แสนตุ้ง',
            zipcode: '23150'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1383',
            label: 'วังตะเคียน',
            zipcode: '23130'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1384',
            label: 'ท่าโสม',
            zipcode: '23150'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1385',
            label: 'สะตอ',
            zipcode: '23150'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1386',
            label: 'ประณีต',
            zipcode: '23150'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1387',
            label: 'เทพนิมิต',
            zipcode: '23150'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1388',
            label: 'ทุ่งนนทรี',
            zipcode: '23130'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '174',
            value: '1394',
            label: 'บ่อพลอย',
            zipcode: '23140'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '174',
            value: '1395',
            label: 'ช้างทูน',
            zipcode: '23140'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '174',
            value: '1396',
            label: 'ด่านชุมพล',
            zipcode: '23140'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '174',
            value: '1397',
            label: 'หนองบอน',
            zipcode: '23140'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '174',
            value: '1398',
            label: 'นนทรีย์',
            zipcode: '23140'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '175',
            value: '1399',
            label: 'แหลมงอบ',
            zipcode: '23120'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '175',
            value: '1400',
            label: 'น้ำเชี่ยว',
            zipcode: '23120'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '175',
            value: '1401',
            label: 'บางปิด',
            zipcode: '23120'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '175',
            value: '1405',
            label: 'คลองใหญ่',
            zipcode: '23120'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '176',
            value: '1407',
            label: 'เกาะหมาก',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '176',
            value: '1408',
            label: 'เกาะกูด',
            zipcode: '23000'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '177',
            value: '1409',
            label: 'เกาะช้าง',
            zipcode: '23170'
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '177',
            value: '1410',
            label: 'เกาะช้างใต้',
            zipcode: '23170'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1411',
            label: 'หน้าเมือง',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1412',
            label: 'ท่าไข่',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1413',
            label: 'บ้านใหม่',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1414',
            label: 'คลองนา',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1415',
            label: 'บางตีนเป็ด',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1416',
            label: 'บางไผ่',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1417',
            label: 'คลองจุกกระเฌอ',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1418',
            label: 'บางแก้ว',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1419',
            label: 'บางขวัญ',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1420',
            label: 'คลองนครเนื่องเขต',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1421',
            label: 'วังตะเคียน',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1422',
            label: 'โสธร',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1423',
            label: 'บางพระ',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1424',
            label: 'บางกะไห',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1425',
            label: 'หนามแดง',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1426',
            label: 'คลองเปรง',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1427',
            label: 'คลองอุดมชลจร',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1428',
            label: 'คลองหลวงแพ่ง',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '178',
            value: '1429',
            label: 'บางเตย',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1430',
            label: 'บางคล้า',
            zipcode: '24110'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1433',
            label: 'บางสวน',
            zipcode: '24110'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1437',
            label: 'บางกระเจ็ด',
            zipcode: '24110'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1438',
            label: 'ปากน้ำ',
            zipcode: '24110'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1439',
            label: 'ท่าทองหลาง',
            zipcode: '24110'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1440',
            label: 'สาวชะโงก',
            zipcode: '24110'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1441',
            label: 'เสม็ดเหนือ',
            zipcode: '24110'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1442',
            label: 'เสม็ดใต้',
            zipcode: '24110'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1443',
            label: 'หัวไทร',
            zipcode: '24110'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '180',
            value: '1444',
            label: 'บางน้ำเปรี้ยว',
            zipcode: '24150'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '180',
            value: '1445',
            label: 'บางขนาก',
            zipcode: '24150'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '180',
            value: '1446',
            label: 'สิงโตทอง',
            zipcode: '24150'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '180',
            value: '1447',
            label: 'หมอนทอง',
            zipcode: '24150'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '180',
            value: '1448',
            label: 'บึงน้ำรักษ์',
            zipcode: '24170'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '180',
            value: '1449',
            label: 'ดอนเกาะกา',
            zipcode: '24170'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '180',
            value: '1450',
            label: 'โยธะกา',
            zipcode: '24150'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '180',
            value: '1451',
            label: 'ดอนฉิมพลี',
            zipcode: '24170'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '180',
            value: '1452',
            label: 'ศาลาแดง',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '180',
            value: '1453',
            label: 'โพรงอากาศ',
            zipcode: '24150'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '181',
            value: '1454',
            label: 'บางปะกง',
            zipcode: '24130'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '181',
            value: '1455',
            label: 'ท่าสะอ้าน',
            zipcode: '24130'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '181',
            value: '1456',
            label: 'บางวัว',
            zipcode: '24180'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '181',
            value: '1457',
            label: 'บางสมัคร',
            zipcode: '24180'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '181',
            value: '1458',
            label: 'บางผึ้ง',
            zipcode: '24130'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '181',
            value: '1459',
            label: 'บางเกลือ',
            zipcode: '24180'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '181',
            value: '1460',
            label: 'สองคลอง',
            zipcode: '24130'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '181',
            value: '1461',
            label: 'หนองจอก',
            zipcode: '24130'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '181',
            value: '1462',
            label: 'พิมพา',
            zipcode: '24130'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '181',
            value: '1463',
            label: 'ท่าข้าม',
            zipcode: '24130'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '181',
            value: '1464',
            label: 'หอมศีล',
            zipcode: '24180'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '181',
            value: '1465',
            label: 'เขาดิน',
            zipcode: '24130'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1466',
            label: 'บ้านโพธิ์',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1467',
            label: 'เกาะไร่',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1468',
            label: 'คลองขุด',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1469',
            label: 'คลองบ้านโพธิ์',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1470',
            label: 'คลองประเวศ',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1471',
            label: 'ดอนทราย',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1472',
            label: 'เทพราช',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1473',
            label: 'ท่าพลับ',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1474',
            label: 'หนองตีนนก',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1475',
            label: 'หนองบัว',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1476',
            label: 'บางซ่อน',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1477',
            label: 'บางกรูด',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1478',
            label: 'แหลมประดู่',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1479',
            label: 'ลาดขวาง',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1480',
            label: 'สนามจันทร์',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1481',
            label: 'แสนภูดาษ',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '182',
            value: '1482',
            label: 'สิบเอ็ดศอก',
            zipcode: '24140'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '183',
            value: '1483',
            label: 'เกาะขนุน',
            zipcode: '24120'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '183',
            value: '1484',
            label: 'บ้านซ่อง',
            zipcode: '24120'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '183',
            value: '1485',
            label: 'พนมสารคาม',
            zipcode: '24120'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '183',
            value: '1486',
            label: 'เมืองเก่า',
            zipcode: '24120'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '183',
            value: '1487',
            label: 'หนองยาว',
            zipcode: '24120'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '183',
            value: '1488',
            label: 'ท่าถ่าน',
            zipcode: '24120'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '183',
            value: '1489',
            label: 'หนองแหน',
            zipcode: '24120'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '183',
            value: '1490',
            label: 'เขาหินซ้อน',
            zipcode: '24120'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '184',
            value: '1491',
            label: 'บางคา',
            zipcode: '24120'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '184',
            value: '1492',
            label: 'เมืองใหม่',
            zipcode: '24120'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '184',
            value: '1493',
            label: 'ดงน้อย',
            zipcode: '24120'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '185',
            value: '1494',
            label: 'คู้ยายหมี',
            zipcode: '24160'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '185',
            value: '1495',
            label: 'ท่ากระดาน',
            zipcode: '24160'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '185',
            value: '1496',
            label: 'ทุ่งพระยา',
            zipcode: '24160'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '185',
            value: '1498',
            label: 'ลาดกระทิง',
            zipcode: '24160'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '186',
            value: '1500',
            label: 'แปลงยาว',
            zipcode: '24190'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '186',
            value: '1501',
            label: 'วังเย็น',
            zipcode: '24190'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '186',
            value: '1502',
            label: 'หัวสำโรง',
            zipcode: '24190'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '186',
            value: '1503',
            label: 'หนองไม้แก่น',
            zipcode: '24190'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '187',
            value: '1504',
            label: 'ท่าตะเกียบ',
            zipcode: '24160'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '187',
            value: '1505',
            label: 'คลองตะเกรา',
            zipcode: '24160'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '188',
            value: '1506',
            label: 'ก้อนแก้ว',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '188',
            value: '1507',
            label: 'คลองเขื่อน',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '188',
            value: '1508',
            label: 'บางเล่า',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '188',
            value: '1509',
            label: 'บางโรง',
            zipcode: '24000'
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '188',
            value: '1510',
            label: 'บางตลาด',
            zipcode: '24110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1511',
            label: 'หน้าเมือง',
            zipcode: '25000'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1512',
            label: 'รอบเมือง',
            zipcode: '25000'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1513',
            label: 'วัดโบสถ์',
            zipcode: '25000'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1514',
            label: 'บางเดชะ',
            zipcode: '25000'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1515',
            label: 'ท่างาม',
            zipcode: '25000'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1516',
            label: 'บางบริบูรณ์',
            zipcode: '25000'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1517',
            label: 'ดงพระราม',
            zipcode: '25000'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1518',
            label: 'บ้านพระ',
            zipcode: '25230'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1519',
            label: 'โคกไม้ลาย',
            zipcode: '25230'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1520',
            label: 'ไม้เค็ด',
            zipcode: '25230'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1521',
            label: 'ดงขี้เหล็ก',
            zipcode: '25000'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1522',
            label: 'เนินหอม',
            zipcode: '25230'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '189',
            value: '1523',
            label: 'โนนห้อม',
            zipcode: '25000'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1524',
            label: 'กบินทร์',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1525',
            label: 'เมืองเก่า',
            zipcode: '25240'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1526',
            label: 'วังดาล',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1527',
            label: 'นนทรี',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1528',
            label: 'ย่านรี',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1529',
            label: 'วังตะเคียน',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1530',
            label: 'หาดนางแก้ว',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1531',
            label: 'ลาดตะเคียน',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1532',
            label: 'บ้านนา',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1533',
            label: 'บ่อทอง',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1534',
            label: 'หนองกี่',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1535',
            label: 'นาแขม',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1536',
            label: 'เขาไม้แก้ว',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1537',
            label: 'วังท่าช้าง',
            zipcode: '25110'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '191',
            value: '1542',
            label: 'นาดี',
            zipcode: '25220'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '191',
            value: '1543',
            label: 'สำพันตา',
            zipcode: '25220'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '191',
            value: '1544',
            label: 'สะพานหิน',
            zipcode: '25220'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '191',
            value: '1545',
            label: 'ทุ่งโพธิ์',
            zipcode: '25220'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '191',
            value: '1546',
            label: 'แก่งดินสอ',
            zipcode: '25220'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '191',
            value: '1547',
            label: 'บุพราหมณ์',
            zipcode: '25220'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '194',
            value: '1569',
            label: 'บ้านสร้าง',
            zipcode: '25150'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '194',
            value: '1570',
            label: 'บางกระเบา',
            zipcode: '25150'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '194',
            value: '1571',
            label: 'บางเตย',
            zipcode: '25150'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '194',
            value: '1572',
            label: 'บางยาง',
            zipcode: '25150'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '194',
            value: '1573',
            label: 'บางแตน',
            zipcode: '25150'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '194',
            value: '1574',
            label: 'บางพลวง',
            zipcode: '25150'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '194',
            value: '1575',
            label: 'บางปลาร้า',
            zipcode: '25150'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '194',
            value: '1576',
            label: 'บางขาม',
            zipcode: '25150'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '194',
            value: '1577',
            label: 'กระทุ่มแพ้ว',
            zipcode: '25150'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '195',
            value: '1578',
            label: 'ประจันตคาม',
            zipcode: '25130'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '195',
            value: '1579',
            label: 'เกาะลอย',
            zipcode: '25130'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '195',
            value: '1580',
            label: 'บ้านหอย',
            zipcode: '25130'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '195',
            value: '1581',
            label: 'หนองแสง',
            zipcode: '25130'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '195',
            value: '1582',
            label: 'ดงบัง',
            zipcode: '25130'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '195',
            value: '1583',
            label: 'คำโตนด',
            zipcode: '25130'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '195',
            value: '1584',
            label: 'บุฝ้าย',
            zipcode: '25130'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '195',
            value: '1585',
            label: 'หนองแก้ว',
            zipcode: '25130'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '195',
            value: '1586',
            label: 'โพธิ์งาม',
            zipcode: '25130'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1587',
            label: 'ศรีมหาโพธิ',
            zipcode: '25140'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1588',
            label: 'สัมพันธ์',
            zipcode: '25140'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1589',
            label: 'บ้านทาม',
            zipcode: '25140'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1590',
            label: 'ท่าตูม',
            zipcode: '25140'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1591',
            label: 'บางกุ้ง',
            zipcode: '25140'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1592',
            label: 'ดงกระทงยาม',
            zipcode: '25140'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1593',
            label: 'หนองโพรง',
            zipcode: '25140'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1594',
            label: 'หัวหว้า',
            zipcode: '25140'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1595',
            label: 'หาดยาง',
            zipcode: '25140'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1596',
            label: 'กรอกสมบูรณ์',
            zipcode: '25140'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '197',
            value: '1601',
            label: 'โคกปีบ',
            zipcode: '25190'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '197',
            value: '1602',
            label: 'โคกไทย',
            zipcode: '25190'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '197',
            value: '1603',
            label: 'คู้ลำพัน',
            zipcode: '25190'
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '197',
            value: '1604',
            label: 'ไผ่ชะเลือด',
            zipcode: '25190'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1649',
            label: 'นครนายก',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1650',
            label: 'ท่าช้าง',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1651',
            label: 'บ้านใหญ่',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1652',
            label: 'วังกระโจม',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1653',
            label: 'ท่าทราย',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1654',
            label: 'ดอนยอ',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1655',
            label: 'ศรีจุฬา',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1656',
            label: 'ดงละคร',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1657',
            label: 'ศรีนาวา',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1658',
            label: 'สาริกา',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1659',
            label: 'หินตั้ง',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1660',
            label: 'เขาพระ',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '202',
            value: '1661',
            label: 'พรหมณี',
            zipcode: '26000'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '203',
            value: '1662',
            label: 'เกาะหวาย',
            zipcode: '26130'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '203',
            value: '1663',
            label: 'เกาะโพธิ์',
            zipcode: '26130'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '203',
            value: '1664',
            label: 'ปากพลี',
            zipcode: '26130'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '203',
            value: '1665',
            label: 'โคกกรวด',
            zipcode: '26130'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '203',
            value: '1666',
            label: 'ท่าเรือ',
            zipcode: '26130'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '203',
            value: '1667',
            label: 'หนองแสง',
            zipcode: '26130'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '203',
            value: '1668',
            label: 'นาหินลาด',
            zipcode: '26130'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '204',
            value: '1669',
            label: 'บ้านนา',
            zipcode: '26110'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '204',
            value: '1670',
            label: 'บ้านพร้าว',
            zipcode: '26110'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '204',
            value: '1671',
            label: 'บ้านพริก',
            zipcode: '26110'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '204',
            value: '1672',
            label: 'อาษา',
            zipcode: '26110'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '204',
            value: '1673',
            label: 'ทองหลาง',
            zipcode: '26110'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '204',
            value: '1674',
            label: 'บางอ้อ',
            zipcode: '26110'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '204',
            value: '1675',
            label: 'พิกุลออก',
            zipcode: '26110'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '204',
            value: '1676',
            label: 'ป่าขะ',
            zipcode: '26110'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '204',
            value: '1677',
            label: 'เขาเพิ่ม',
            zipcode: '26110'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '204',
            value: '1678',
            label: 'ศรีกะอาง',
            zipcode: '26110'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '205',
            value: '1679',
            label: 'พระอาจารย์',
            zipcode: '26120'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '205',
            value: '1680',
            label: 'บึงศาล',
            zipcode: '26120'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '205',
            value: '1681',
            label: 'ศีรษะกระบือ',
            zipcode: '26120'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '205',
            value: '1682',
            label: 'โพธิ์แทน',
            zipcode: '26120'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '205',
            value: '1683',
            label: 'บางสมบูรณ์',
            zipcode: '26120'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '205',
            value: '1684',
            label: 'ทรายมูล',
            zipcode: '26120'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '205',
            value: '1685',
            label: 'บางปลากด',
            zipcode: '26120'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '205',
            value: '1686',
            label: 'บางลูกเสือ',
            zipcode: '26120'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '205',
            value: '1687',
            label: 'องครักษ์',
            zipcode: '26120'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '205',
            value: '1688',
            label: 'ชุมพล',
            zipcode: '26120'
        },
        {
            geoid: '2',
            provinceid: '17',
            districtid: '205',
            value: '1689',
            label: 'คลองใหญ่',
            zipcode: '26120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '206',
            value: '1690',
            label: 'สระแก้ว',
            zipcode: '27000'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '206',
            value: '1691',
            label: 'บ้านแก้ง',
            zipcode: '27000'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '206',
            value: '1692',
            label: 'ศาลาลำดวน',
            zipcode: '27000'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '206',
            value: '1693',
            label: 'โคกปี่ฆ้อง',
            zipcode: '27000'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '206',
            value: '1694',
            label: 'ท่าแยก',
            zipcode: '27000'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '206',
            value: '1695',
            label: 'ท่าเกษม',
            zipcode: '27000'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '206',
            value: '1697',
            label: 'สระขวัญ',
            zipcode: '27000'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '206',
            value: '1700',
            label: 'หนองบอน',
            zipcode: '27000'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '207',
            value: '1702',
            label: 'คลองหาด',
            zipcode: '27260'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '207',
            value: '1703',
            label: 'ไทยอุดม',
            zipcode: '27260'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '207',
            value: '1704',
            label: 'ซับมะกรูด',
            zipcode: '27260'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '207',
            value: '1705',
            label: 'ไทรเดี่ยว',
            zipcode: '27260'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '207',
            value: '1706',
            label: 'คลองไก่เถื่อน',
            zipcode: '27260'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '207',
            value: '1707',
            label: 'เบญจขร',
            zipcode: '27260'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '207',
            value: '1708',
            label: 'ไทรทอง',
            zipcode: '27260'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '208',
            value: '1709',
            label: 'ตาพระยา',
            zipcode: '27180'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '208',
            value: '1710',
            label: 'ทัพเสด็จ',
            zipcode: '27180'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '208',
            value: '1714',
            label: 'ทัพราช',
            zipcode: '27180'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '208',
            value: '1715',
            label: 'ทัพไทย',
            zipcode: '27180'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '208',
            value: '1717',
            label: 'โคคลาน',
            zipcode: '27180'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '209',
            value: '1718',
            label: 'วังน้ำเย็น',
            zipcode: '27210'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '209',
            value: '1720',
            label: 'ตาหลังใน',
            zipcode: '27210'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '209',
            value: '1722',
            label: 'คลองหินปูน',
            zipcode: '27210'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '209',
            value: '1723',
            label: 'ทุ่งมหาเจริญ',
            zipcode: '27210'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '210',
            value: '1725',
            label: 'วัฒนานคร',
            zipcode: '27160'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '210',
            value: '1726',
            label: 'ท่าเกวียน',
            zipcode: '27160'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '210',
            value: '1727',
            label: 'ผักขะ',
            zipcode: '27160'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '210',
            value: '1728',
            label: 'โนนหมากเค็ง',
            zipcode: '27160'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '210',
            value: '1729',
            label: 'หนองน้ำใส',
            zipcode: '27160'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '210',
            value: '1730',
            label: 'ช่องกุ่ม',
            zipcode: '27160'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '210',
            value: '1731',
            label: 'หนองแวง',
            zipcode: '27160'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '210',
            value: '1732',
            label: 'แซร์ออ',
            zipcode: '27160'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '210',
            value: '1733',
            label: 'หนองหมากฝ้าย',
            zipcode: '27160'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '210',
            value: '1734',
            label: 'หนองตะเคียนบอน',
            zipcode: '27160'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '210',
            value: '1735',
            label: 'ห้วยโจด',
            zipcode: '27160'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1736',
            label: 'อรัญประเทศ',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1737',
            label: 'เมืองไผ่',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1738',
            label: 'หันทราย',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1739',
            label: 'คลองน้ำใส',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1740',
            label: 'ท่าข้าม',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1741',
            label: 'ป่าไร่',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1742',
            label: 'ทับพริก',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1743',
            label: 'บ้านใหม่หนองไทร',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1744',
            label: 'ผ่านศึก',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1745',
            label: 'หนองสังข์',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1746',
            label: 'คลองทับจันทร์',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1747',
            label: 'ฟากห้วย',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '211',
            value: '1748',
            label: 'บ้านด่าน',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '212',
            value: '1749',
            label: 'เขาฉกรรจ์',
            zipcode: '27000'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '212',
            value: '1750',
            label: 'หนองหว้า',
            zipcode: '27000'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '212',
            value: '1751',
            label: 'พระเพลิง',
            zipcode: '27000'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '212',
            value: '1752',
            label: 'เขาสามสิบ',
            zipcode: '27000'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '213',
            value: '1753',
            label: 'โคกสูง',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '213',
            value: '1754',
            label: 'หนองม่วง',
            zipcode: '27180'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '213',
            value: '1755',
            label: 'หนองแวง',
            zipcode: '27180'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '213',
            value: '1756',
            label: 'โนนหมากมุ่น',
            zipcode: '27120'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '214',
            value: '1757',
            label: 'วังสมบูรณ์',
            zipcode: '27250'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '214',
            value: '1758',
            label: 'วังใหม่',
            zipcode: '27250'
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '214',
            value: '1759',
            label: 'วังทอง',
            zipcode: '27250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1760',
            label: 'ในเมือง',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1761',
            label: 'โพธิ์กลาง',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1762',
            label: 'หนองจะบก',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1763',
            label: 'โคกสูง',
            zipcode: '30310'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1764',
            label: 'มะเริง',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1765',
            label: 'หนองระเวียง',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1766',
            label: 'ปรุใหญ่',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1767',
            label: 'หมื่นไวย',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1768',
            label: 'พลกรัง',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1769',
            label: 'หนองไผ่ล้อม',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1770',
            label: 'หัวทะเล',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1771',
            label: 'บ้านเกาะ',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1772',
            label: 'บ้านใหม่',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1773',
            label: 'พุดซา',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1774',
            label: 'บ้านโพธิ์',
            zipcode: '30310'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1775',
            label: 'จอหอ',
            zipcode: '30310'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1776',
            label: 'โคกกรวด',
            zipcode: '30280'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1777',
            label: 'ไชยมงคล',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1778',
            label: 'หนองบัวศาลา',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1779',
            label: 'สุรนารี',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1780',
            label: 'สีมุม',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1781',
            label: 'ตลาด',
            zipcode: '30310'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1782',
            label: 'พะเนา',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1783',
            label: 'หนองกระทุ่ม',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '215',
            value: '1784',
            label: 'หนองไข่น้ำ',
            zipcode: '30310'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '216',
            value: '1785',
            label: 'แชะ',
            zipcode: '30250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '216',
            value: '1786',
            label: 'เฉลียง',
            zipcode: '30250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '216',
            value: '1787',
            label: 'ครบุรี',
            zipcode: '30250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '216',
            value: '1788',
            label: 'โคกกระชาย',
            zipcode: '30250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '216',
            value: '1789',
            label: 'จระเข้หิน',
            zipcode: '30250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '216',
            value: '1790',
            label: 'มาบตะโกเอน',
            zipcode: '30250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '216',
            value: '1791',
            label: 'อรพิมพ์',
            zipcode: '30250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '216',
            value: '1792',
            label: 'บ้านใหม่',
            zipcode: '30250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '216',
            value: '1793',
            label: 'ลำเพียก',
            zipcode: '30250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '216',
            value: '1794',
            label: 'ครบุรีใต้',
            zipcode: '30250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '216',
            value: '1795',
            label: 'ตะแบกบาน',
            zipcode: '30250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '216',
            value: '1796',
            label: 'สระว่านพระยา',
            zipcode: '30250'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '217',
            value: '1797',
            label: 'เสิงสาง',
            zipcode: '30330'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '217',
            value: '1798',
            label: 'สระตะเคียน',
            zipcode: '30330'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '217',
            value: '1799',
            label: 'โนนสมบูรณ์',
            zipcode: '30330'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '217',
            value: '1800',
            label: 'กุดโบสถ์',
            zipcode: '30330'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '217',
            value: '1801',
            label: 'สุขไพบูลย์',
            zipcode: '30330'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '217',
            value: '1802',
            label: 'บ้านราษฎร์',
            zipcode: '30330'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '218',
            value: '1803',
            label: 'เมืองคง',
            zipcode: '30260'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '218',
            value: '1804',
            label: 'คูขาด',
            zipcode: '30260'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '218',
            value: '1805',
            label: 'เทพาลัย',
            zipcode: '30260'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '218',
            value: '1806',
            label: 'ตาจั่น',
            zipcode: '30260'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '218',
            value: '1807',
            label: 'บ้านปรางค์',
            zipcode: '30260'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '218',
            value: '1808',
            label: 'หนองมะนาว',
            zipcode: '30260'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '218',
            value: '1809',
            label: 'หนองบัว',
            zipcode: '30260'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '218',
            value: '1810',
            label: 'โนนเต็ง',
            zipcode: '30260'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '218',
            value: '1811',
            label: 'ดอนใหญ่',
            zipcode: '30260'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '218',
            value: '1812',
            label: 'ขามสมบูรณ์',
            zipcode: '30260'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '219',
            value: '1813',
            label: 'บ้านเหลื่อม',
            zipcode: '30350'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '219',
            value: '1814',
            label: 'วังโพธิ์',
            zipcode: '30350'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '219',
            value: '1815',
            label: 'โคกกระเบื้อง',
            zipcode: '30350'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '219',
            value: '1816',
            label: 'ช่อระกา',
            zipcode: '30350'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1817',
            label: 'จักราช',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1818',
            label: 'ท่าช้าง',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1819',
            label: 'ทองหลาง',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1820',
            label: 'สีสุก',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1821',
            label: 'หนองขาม',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1822',
            label: 'หนองงูเหลือม',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1823',
            label: 'หนองพลวง',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1824',
            label: 'หนองยาง',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1825',
            label: 'พระพุทธ',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1826',
            label: 'ศรีละกอ',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1827',
            label: 'คลองเมือง',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1828',
            label: 'ช้างทอง',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '220',
            value: '1829',
            label: 'หินโคน',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '221',
            value: '1830',
            label: 'กระโทก',
            zipcode: '30190'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '221',
            value: '1831',
            label: 'พลับพลา',
            zipcode: '30190'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '221',
            value: '1832',
            label: 'ท่าอ่าง',
            zipcode: '30190'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '221',
            value: '1833',
            label: 'ทุ่งอรุณ',
            zipcode: '30190'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '221',
            value: '1834',
            label: 'ท่าลาดขาว',
            zipcode: '30190'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '221',
            value: '1835',
            label: 'ท่าจะหลุง',
            zipcode: '30190'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '221',
            value: '1836',
            label: 'ท่าเยี่ยม',
            zipcode: '30190'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '221',
            value: '1837',
            label: 'โชคชัย',
            zipcode: '30190'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '221',
            value: '1838',
            label: 'ละลมใหม่พัฒนา',
            zipcode: '30190'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '221',
            value: '1839',
            label: 'ด่านเกวียน',
            zipcode: '30190'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1840',
            label: 'กุดพิมาน',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1841',
            label: 'ด่านขุนทด',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1842',
            label: 'ด่านนอก',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1843',
            label: 'ด่านใน',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1844',
            label: 'ตะเคียน',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1845',
            label: 'บ้านเก่า',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1846',
            label: 'บ้านแปรง',
            zipcode: '36220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1847',
            label: 'พันชนะ',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1848',
            label: 'สระจรเข้',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1849',
            label: 'หนองกราด',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1850',
            label: 'หนองบัวตะเกียด',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1851',
            label: 'หนองบัวละคร',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1852',
            label: 'หินดาด',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1854',
            label: 'ห้วยบง',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1856',
            label: 'โนนเมืองพัฒนา',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1857',
            label: 'หนองไทร',
            zipcode: '36220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1859',
            label: 'โนนไทย',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1860',
            label: 'ด่านจาก',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1861',
            label: 'กำปัง',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1862',
            label: 'สำโรง',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1863',
            label: 'ค้างพลู',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1864',
            label: 'บ้านวัง',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1865',
            label: 'บัลลังก์',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1866',
            label: 'สายออ',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1867',
            label: 'ถนนโพธิ์',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1868',
            label: 'พังเทียม',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1869',
            label: 'สระพระ',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1870',
            label: 'ทัพรั้ง',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1871',
            label: 'หนองหอย',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1872',
            label: 'มะค่า',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '223',
            value: '1873',
            label: 'มาบกราด',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1874',
            label: 'โนนสูง',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1875',
            label: 'ใหม่',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1876',
            label: 'โตนด',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1877',
            label: 'บิง',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1878',
            label: 'ดอนชมพู',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1879',
            label: 'ธารปราสาท',
            zipcode: '30240'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1880',
            label: 'หลุมข้าว',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1881',
            label: 'มะค่า',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1882',
            label: 'พลสงคราม',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1883',
            label: 'จันอัด',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1884',
            label: 'ขามเฒ่า',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1885',
            label: 'ด่านคล้า',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1886',
            label: 'ลำคอหงษ์',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1887',
            label: 'เมืองปราสาท',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1888',
            label: 'ดอนหวาย',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '224',
            value: '1889',
            label: 'ลำมูล',
            zipcode: '30160'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '225',
            value: '1890',
            label: 'ขามสะแกแสง',
            zipcode: '30290'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '225',
            value: '1891',
            label: 'โนนเมือง',
            zipcode: '30290'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '225',
            value: '1892',
            label: 'เมืองนาท',
            zipcode: '30290'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '225',
            value: '1893',
            label: 'ชีวึก',
            zipcode: '30290'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '225',
            value: '1894',
            label: 'พะงาด',
            zipcode: '30290'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '225',
            value: '1895',
            label: 'หนองหัวฟาน',
            zipcode: '30290'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '225',
            value: '1896',
            label: 'เมืองเกษตร',
            zipcode: '30290'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1897',
            label: 'บัวใหญ่',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1898',
            label: 'ห้วยยาง',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1899',
            label: 'เสมาใหญ่',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1901',
            label: 'ดอนตะหนิน',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1902',
            label: 'หนองบัวสะอาด',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1903',
            label: 'โนนทองหลาง',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1904',
            label: 'หนองหว้า',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1905',
            label: 'บัวลาย',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1906',
            label: 'สีดา',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1907',
            label: 'โพนทอง',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1909',
            label: 'กุดจอก',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1910',
            label: 'ด่านช้าง',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1911',
            label: 'โนนจาน',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1913',
            label: 'สามเมือง',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1915',
            label: 'ขุนทอง',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1916',
            label: 'หนองตาดใหญ่',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1917',
            label: 'เมืองพะไล',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1918',
            label: 'โนนประดู่',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1919',
            label: 'หนองแจ้งใหญ่',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1920',
            label: 'ประทาย',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1922',
            label: 'กระทุ่มราย',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1923',
            label: 'วังไม้แดง',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1925',
            label: 'ตลาดไทร',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1926',
            label: 'หนองพลวง',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1927',
            label: 'หนองค่าย',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1928',
            label: 'หันห้วยทราย',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1929',
            label: 'ดอนมัน',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1932',
            label: 'นางรำ',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1933',
            label: 'โนนเพ็ด',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1934',
            label: 'ทุ่งสว่าง',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1936',
            label: 'โคกกลาง',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1937',
            label: 'เมืองโดน',
            zipcode: '30180'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1938',
            label: 'เมืองปัก',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1939',
            label: 'ตะคุ',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1940',
            label: 'โคกไทย',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1941',
            label: 'สำโรง',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1942',
            label: 'ตะขบ',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1943',
            label: 'นกออก',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1944',
            label: 'ดอน',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1946',
            label: 'ตูม',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1947',
            label: 'งิ้ว',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1948',
            label: 'สะแกราช',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1949',
            label: 'ลำนางแก้ว',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1953',
            label: 'ภูหลวง',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1954',
            label: 'ธงชัยเหนือ',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1955',
            label: 'สุขเกษม',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1956',
            label: 'เกษมทรัพย์',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1957',
            label: 'บ่อปลาทอง',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '229',
            value: '1958',
            label: 'ในเมือง',
            zipcode: '30110'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '229',
            value: '1959',
            label: 'สัมฤทธิ์',
            zipcode: '30110'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '229',
            value: '1960',
            label: 'โบสถ์',
            zipcode: '30110'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '229',
            value: '1961',
            label: 'กระเบื้องใหญ่',
            zipcode: '30110'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '229',
            value: '1962',
            label: 'ท่าหลวง',
            zipcode: '30110'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '229',
            value: '1963',
            label: 'รังกาใหญ่',
            zipcode: '30110'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '229',
            value: '1964',
            label: 'ชีวาน',
            zipcode: '30110'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '229',
            value: '1965',
            label: 'นิคมสร้างตนเอง',
            zipcode: '30110'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '229',
            value: '1966',
            label: 'กระชอน',
            zipcode: '30110'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '229',
            value: '1967',
            label: 'ดงใหญ่',
            zipcode: '30110'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '229',
            value: '1968',
            label: 'ธารละหลอด',
            zipcode: '30110'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '229',
            value: '1969',
            label: 'หนองระเวียง',
            zipcode: '30110'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '230',
            value: '1970',
            label: 'ห้วยแถลง',
            zipcode: '30240'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '230',
            value: '1971',
            label: 'ทับสวาย',
            zipcode: '30240'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '230',
            value: '1972',
            label: 'เมืองพลับพลา',
            zipcode: '30240'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '230',
            value: '1973',
            label: 'หลุ่งตะเคียน',
            zipcode: '30240'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '230',
            value: '1974',
            label: 'หินดาด',
            zipcode: '30240'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '230',
            value: '1975',
            label: 'งิ้ว',
            zipcode: '30240'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '230',
            value: '1976',
            label: 'กงรถ',
            zipcode: '30240'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '230',
            value: '1977',
            label: 'หลุ่งประดู่',
            zipcode: '30240'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '230',
            value: '1978',
            label: 'ตะโก',
            zipcode: '30240'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '230',
            value: '1979',
            label: 'ห้วยแคน',
            zipcode: '30240'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1980',
            label: 'ชุมพวง',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1981',
            label: 'ประสุข',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1982',
            label: 'ท่าลาด',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1983',
            label: 'สาหร่าย',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1984',
            label: 'ตลาดไทร',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1985',
            label: 'ช่องแมว',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1986',
            label: 'ขุย',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1989',
            label: 'โนนรัง',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1990',
            label: 'บ้านยาง',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1993',
            label: 'หนองหลัก',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1994',
            label: 'ไพล',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1995',
            label: 'โนนตูม',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1996',
            label: 'โนนยอ',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '232',
            value: '1997',
            label: 'สูงเนิน',
            zipcode: '30170'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '232',
            value: '1998',
            label: 'เสมา',
            zipcode: '30170'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '232',
            value: '1999',
            label: 'โคราช',
            zipcode: '30170'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '232',
            value: '2000',
            label: 'บุ่งขี้เหล็ก',
            zipcode: '30170'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '232',
            value: '2001',
            label: 'โนนค่า',
            zipcode: '30170'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '232',
            value: '2002',
            label: 'โค้งยาง',
            zipcode: '30170'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '232',
            value: '2003',
            label: 'มะเกลือเก่า',
            zipcode: '30170'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '232',
            value: '2004',
            label: 'มะเกลือใหม่',
            zipcode: '30170'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '232',
            value: '2005',
            label: 'นากลาง',
            zipcode: '30380'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '232',
            value: '2006',
            label: 'หนองตะไก้',
            zipcode: '30380'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '232',
            value: '2007',
            label: 'กุดจิก',
            zipcode: '30380'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '233',
            value: '2008',
            label: 'ขามทะเลสอ',
            zipcode: '30280'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '233',
            value: '2009',
            label: 'โป่งแดง',
            zipcode: '30280'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '233',
            value: '2010',
            label: 'พันดุง',
            zipcode: '30280'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '233',
            value: '2011',
            label: 'หนองสรวง',
            zipcode: '30280'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '233',
            value: '2012',
            label: 'บึงอ้อ',
            zipcode: '30280'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '234',
            value: '2013',
            label: 'สีคิ้ว',
            zipcode: '30140'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '234',
            value: '2014',
            label: 'บ้านหัน',
            zipcode: '30140'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '234',
            value: '2015',
            label: 'กฤษณา',
            zipcode: '30140'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '234',
            value: '2016',
            label: 'ลาดบัวขาว',
            zipcode: '30340'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '234',
            value: '2017',
            label: 'หนองหญ้าขาว',
            zipcode: '30140'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '234',
            value: '2018',
            label: 'กุดน้อย',
            zipcode: '30140'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '234',
            value: '2019',
            label: 'หนองน้ำใส',
            zipcode: '30140'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '234',
            value: '2020',
            label: 'วังโรงใหญ่',
            zipcode: '30140'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '234',
            value: '2021',
            label: 'มิตรภาพ',
            zipcode: '30140'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '234',
            value: '2022',
            label: 'คลองไผ่',
            zipcode: '30340'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '234',
            value: '2023',
            label: 'ดอนเมือง',
            zipcode: '30140'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '234',
            value: '2024',
            label: 'หนองบัวน้อย',
            zipcode: '30140'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '235',
            value: '2025',
            label: 'ปากช่อง',
            zipcode: '30130'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '235',
            value: '2026',
            label: 'กลางดง',
            zipcode: '30320'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '235',
            value: '2027',
            label: 'จันทึก',
            zipcode: '30130'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '235',
            value: '2028',
            label: 'วังกะทะ',
            zipcode: '30130'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '235',
            value: '2029',
            label: 'หมูสี',
            zipcode: '30130'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '235',
            value: '2030',
            label: 'หนองสาหร่าย',
            zipcode: '30130'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '235',
            value: '2031',
            label: 'ขนงพระ',
            zipcode: '30130'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '235',
            value: '2032',
            label: 'โป่งตาลอง',
            zipcode: '30130'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '235',
            value: '2033',
            label: 'คลองม่วง',
            zipcode: '30130'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '235',
            value: '2034',
            label: 'หนองน้ำแดง',
            zipcode: '30130'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '235',
            value: '2035',
            label: 'วังไทร',
            zipcode: '30130'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '235',
            value: '2036',
            label: 'พญาเย็น',
            zipcode: '30320'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '236',
            value: '2037',
            label: 'หนองบุนนาก',
            zipcode: '30410'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '236',
            value: '2038',
            label: 'สารภี',
            zipcode: '30410'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '236',
            value: '2039',
            label: 'ไทยเจริญ',
            zipcode: '30410'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '236',
            value: '2040',
            label: 'หนองหัวแรต',
            zipcode: '30410'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '236',
            value: '2041',
            label: 'แหลมทอง',
            zipcode: '30410'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '236',
            value: '2042',
            label: 'หนองตะไก้',
            zipcode: '30410'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '236',
            value: '2043',
            label: 'ลุงเขว้า',
            zipcode: '30410'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '236',
            value: '2044',
            label: 'หนองไม้ไผ่',
            zipcode: '30410'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '236',
            value: '2045',
            label: 'บ้านใหม่',
            zipcode: '30410'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '237',
            value: '2046',
            label: 'แก้งสนามนาง',
            zipcode: '30440'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '237',
            value: '2047',
            label: 'โนนสำราญ',
            zipcode: '30440'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '237',
            value: '2048',
            label: 'บึงพะไล',
            zipcode: '30440'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '237',
            value: '2049',
            label: 'สีสุก',
            zipcode: '30440'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '237',
            value: '2050',
            label: 'บึงสำโรง',
            zipcode: '30440'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '238',
            value: '2051',
            label: 'โนนแดง',
            zipcode: '30360'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '238',
            value: '2052',
            label: 'โนนตาเถร',
            zipcode: '30360'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '238',
            value: '2053',
            label: 'สำพะเนียง',
            zipcode: '30360'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '238',
            value: '2054',
            label: 'วังหิน',
            zipcode: '30360'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '238',
            value: '2055',
            label: 'ดอนยาวใหญ่',
            zipcode: '30360'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '239',
            value: '2056',
            label: 'วังน้ำเขียว',
            zipcode: '30370'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '239',
            value: '2057',
            label: 'วังหมี',
            zipcode: '30370'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '239',
            value: '2058',
            label: 'ระเริง',
            zipcode: '30150'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '239',
            value: '2059',
            label: 'อุดมทรัพย์',
            zipcode: '30370'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '239',
            value: '2060',
            label: 'ไทยสามัคคี',
            zipcode: '30370'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '240',
            value: '2061',
            label: 'สำนักตะคร้อ',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '240',
            value: '2062',
            label: 'หนองแวง',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '240',
            value: '2063',
            label: 'บึงปรือ',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '240',
            value: '2064',
            label: 'วังยายทอง',
            zipcode: '30210'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '241',
            value: '2065',
            label: 'เมืองยาง',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '241',
            value: '2066',
            label: 'กระเบื้องนอก',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '241',
            value: '2067',
            label: 'ละหานปลาค้าว',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '241',
            value: '2068',
            label: 'โนนอุดม',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '242',
            value: '2069',
            label: 'สระพระ',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '242',
            value: '2070',
            label: 'มาบกราด',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '242',
            value: '2071',
            label: 'พังเทียม',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '242',
            value: '2072',
            label: 'ทัพรั้ง',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '242',
            value: '2073',
            label: 'หนองหอย',
            zipcode: '30220'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '243',
            value: '2074',
            label: 'ขุย',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '243',
            value: '2075',
            label: 'บ้านยาง',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '243',
            value: '2076',
            label: 'ช่องแมว',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '243',
            value: '2077',
            label: 'ไพล',
            zipcode: '30270'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '244',
            value: '2078',
            label: 'เมืองพะไล',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '244',
            value: '2079',
            label: 'โนนจาน',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '244',
            value: '2080',
            label: 'บัวลาย',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '244',
            value: '2081',
            label: 'หนองหว้า',
            zipcode: '30120'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '245',
            value: '2082',
            label: 'สีดา',
            zipcode: '30430'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '245',
            value: '2083',
            label: 'โพนทอง',
            zipcode: '30430'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '245',
            value: '2084',
            label: 'โนนประดู่',
            zipcode: '30430'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '245',
            value: '2085',
            label: 'สามเมือง',
            zipcode: '30430'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '245',
            value: '2086',
            label: 'หนองตาดใหญ่',
            zipcode: '30430'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '246',
            value: '2087',
            label: 'ช้างทอง',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '246',
            value: '2088',
            label: 'ท่าช้าง',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '246',
            value: '2089',
            label: 'พระพุทธ',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '246',
            value: '2090',
            label: 'หนองงูเหลือม',
            zipcode: '30000'
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '246',
            value: '2091',
            label: 'หนองยาง',
            zipcode: '30230'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2092',
            label: 'ในเมือง',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2093',
            label: 'อิสาณ',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2094',
            label: 'เสม็ด',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2095',
            label: 'บ้านบัว',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2096',
            label: 'สะแกโพรง',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2097',
            label: 'สวายจีก',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2099',
            label: 'บ้านยาง',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2103',
            label: 'พระครู',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2104',
            label: 'ถลุงเหล็ก',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2105',
            label: 'หนองตาด',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2108',
            label: 'ลุมปุ๊ก',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2109',
            label: 'สองห้อง',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2110',
            label: 'บัวทอง',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2111',
            label: 'ชุมเห็ด',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2113',
            label: 'หลักเขต',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2116',
            label: 'สะแกซำ',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2117',
            label: 'กลันทา',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2118',
            label: 'กระสัง',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2119',
            label: 'เมืองฝาง',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '251',
            value: '2122',
            label: 'คูเมือง',
            zipcode: '31190'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '251',
            value: '2123',
            label: 'ปะเคียบ',
            zipcode: '31190'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '251',
            value: '2124',
            label: 'บ้านแพ',
            zipcode: '31190'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '251',
            value: '2125',
            label: 'พรสำราญ',
            zipcode: '31190'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '251',
            value: '2126',
            label: 'หินเหล็กไฟ',
            zipcode: '31190'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '251',
            value: '2127',
            label: 'ตูมใหญ่',
            zipcode: '31190'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '251',
            value: '2128',
            label: 'หนองขมาร',
            zipcode: '31190'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '252',
            value: '2129',
            label: 'กระสัง',
            zipcode: '31160'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '252',
            value: '2130',
            label: 'ลำดวน',
            zipcode: '31160'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '252',
            value: '2131',
            label: 'สองชั้น',
            zipcode: '31160'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '252',
            value: '2132',
            label: 'สูงเนิน',
            zipcode: '31160'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '252',
            value: '2133',
            label: 'หนองเต็ง',
            zipcode: '31160'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '252',
            value: '2134',
            label: 'เมืองไผ่',
            zipcode: '31160'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '252',
            value: '2135',
            label: 'ชุมแสง',
            zipcode: '31160'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '252',
            value: '2136',
            label: 'บ้านปรือ',
            zipcode: '31160'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '252',
            value: '2137',
            label: 'ห้วยสำราญ',
            zipcode: '31160'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '252',
            value: '2138',
            label: 'กันทรารมย์',
            zipcode: '31160'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '252',
            value: '2139',
            label: 'ศรีภูมิ',
            zipcode: '31160'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2140',
            label: 'นางรอง',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2142',
            label: 'สะเดา',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2144',
            label: 'ชุมแสง',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2145',
            label: 'หนองโบสถ์',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2147',
            label: 'หนองกง',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2152',
            label: 'ถนนหัก',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2153',
            label: 'หนองไทร',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2154',
            label: 'ก้านเหลือง',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2155',
            label: 'บ้านสิงห์',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2156',
            label: 'ลำไทรโยง',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2157',
            label: 'ทรัพย์พระยา',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2163',
            label: 'หนองยายพิมพ์',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2164',
            label: 'หัวถนน',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2165',
            label: 'ทุ่งแสงทอง',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2166',
            label: 'หนองโสน',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '254',
            value: '2173',
            label: 'หนองกี่',
            zipcode: '31210'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '254',
            value: '2174',
            label: 'เย้ยปราสาท',
            zipcode: '31210'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '254',
            value: '2175',
            label: 'เมืองไผ่',
            zipcode: '31210'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '254',
            value: '2176',
            label: 'ดอนอะราง',
            zipcode: '31210'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '254',
            value: '2177',
            label: 'โคกสว่าง',
            zipcode: '31210'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '254',
            value: '2178',
            label: 'ทุ่งกระตาดพัฒนา',
            zipcode: '31210'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '254',
            value: '2179',
            label: 'ทุ่งกระเต็น',
            zipcode: '31210'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '254',
            value: '2180',
            label: 'ท่าโพธิ์ชัย',
            zipcode: '31210'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '254',
            value: '2181',
            label: 'โคกสูง',
            zipcode: '31210'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '254',
            value: '2182',
            label: 'บุกระสัง',
            zipcode: '31210'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '255',
            value: '2183',
            label: 'ละหานทราย',
            zipcode: '31170'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '255',
            value: '2185',
            label: 'ตาจง',
            zipcode: '31170'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '255',
            value: '2186',
            label: 'สำโรงใหม่',
            zipcode: '31170'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '255',
            value: '2189',
            label: 'หนองแวง',
            zipcode: '31170'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '255',
            value: '2192',
            label: 'หนองตะครอง',
            zipcode: '31170'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '255',
            value: '2193',
            label: 'โคกว่าน',
            zipcode: '31170'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2195',
            label: 'ประโคนชัย',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2196',
            label: 'แสลงโทน',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2197',
            label: 'บ้านไทร',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2199',
            label: 'ละเวี้ย',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2200',
            label: 'จรเข้มาก',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2201',
            label: 'ปังกู',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2202',
            label: 'โคกย่าง',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2204',
            label: 'โคกม้า',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2207',
            label: 'ไพศาล',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2208',
            label: 'ตะโกตาพิ',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2209',
            label: 'เขาคอก',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2210',
            label: 'หนองบอน',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2212',
            label: 'โคกมะขาม',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2213',
            label: 'โคกตูม',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2214',
            label: 'ประทัดบุ',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2215',
            label: 'สี่เหลี่ยม',
            zipcode: '31140'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '257',
            value: '2219',
            label: 'บ้านกรวด',
            zipcode: '31180'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '257',
            value: '2220',
            label: 'โนนเจริญ',
            zipcode: '31180'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '257',
            value: '2221',
            label: 'หนองไม้งาม',
            zipcode: '31180'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '257',
            value: '2222',
            label: 'ปราสาท',
            zipcode: '31180'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '257',
            value: '2223',
            label: 'สายตะกู',
            zipcode: '31180'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '257',
            value: '2224',
            label: 'หินลาด',
            zipcode: '31180'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '257',
            value: '2225',
            label: 'บึงเจริญ',
            zipcode: '31180'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '257',
            value: '2226',
            label: 'จันทบเพชร',
            zipcode: '31180'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '257',
            value: '2227',
            label: 'เขาดินเหนือ',
            zipcode: '31180'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '258',
            value: '2228',
            label: 'พุทไธสง',
            zipcode: '31120'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '258',
            value: '2229',
            label: 'มะเฟือง',
            zipcode: '31120'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '258',
            value: '2230',
            label: 'บ้านจาน',
            zipcode: '31120'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '258',
            value: '2233',
            label: 'บ้านเป้า',
            zipcode: '31120'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '258',
            value: '2234',
            label: 'บ้านแวง',
            zipcode: '31120'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '258',
            value: '2236',
            label: 'บ้านยาง',
            zipcode: '31120'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '258',
            value: '2237',
            label: 'หายโศก',
            zipcode: '31120'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2240',
            label: 'ลำปลายมาศ',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2241',
            label: 'หนองคู',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2242',
            label: 'แสลงพัน',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2243',
            label: 'ทะเมนชัย',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2244',
            label: 'ตลาดโพธิ์',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2245',
            label: 'หนองกะทิง',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2246',
            label: 'โคกกลาง',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2247',
            label: 'โคกสะอาด',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2248',
            label: 'เมืองแฝก',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2249',
            label: 'บ้านยาง',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2250',
            label: 'ผไทรินทร์',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2251',
            label: 'โคกล่าม',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2252',
            label: 'หินโคน',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2253',
            label: 'หนองบัวโคก',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2254',
            label: 'บุโพธิ์',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2255',
            label: 'หนองโดน',
            zipcode: '31130'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2259',
            label: 'สตึก',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2260',
            label: 'นิคม',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2261',
            label: 'ทุ่งวัง',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2262',
            label: 'เมืองแก',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2263',
            label: 'หนองใหญ่',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2264',
            label: 'ร่อนทอง',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2267',
            label: 'ดอนมนต์',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2268',
            label: 'ชุมแสง',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2269',
            label: 'ท่าม่วง',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2270',
            label: 'สะแก',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2272',
            label: 'สนามชัย',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2273',
            label: 'กระสัง',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '261',
            value: '2275',
            label: 'ปะคำ',
            zipcode: '31220'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '261',
            value: '2276',
            label: 'ไทยเจริญ',
            zipcode: '31220'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '261',
            value: '2277',
            label: 'หนองบัว',
            zipcode: '31220'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '261',
            value: '2278',
            label: 'โคกมะม่วง',
            zipcode: '31220'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '261',
            value: '2279',
            label: 'หูทำนบ',
            zipcode: '31220'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '262',
            value: '2280',
            label: 'นาโพธิ์',
            zipcode: '31230'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '262',
            value: '2281',
            label: 'บ้านคู',
            zipcode: '31230'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '262',
            value: '2282',
            label: 'บ้านดู่',
            zipcode: '31230'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '262',
            value: '2283',
            label: 'ดอนกอก',
            zipcode: '31230'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '262',
            value: '2284',
            label: 'ศรีสว่าง',
            zipcode: '31230'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '263',
            value: '2285',
            label: 'สระแก้ว',
            zipcode: '31240'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '263',
            value: '2286',
            label: 'ห้วยหิน',
            zipcode: '31240'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '263',
            value: '2287',
            label: 'ไทยสามัคคี',
            zipcode: '31240'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '263',
            value: '2288',
            label: 'หนองชัยศรี',
            zipcode: '31240'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '263',
            value: '2289',
            label: 'เสาเดียว',
            zipcode: '31240'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '263',
            value: '2290',
            label: 'เมืองฝ้าย',
            zipcode: '31240'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '263',
            value: '2291',
            label: 'สระทอง',
            zipcode: '31240'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '264',
            value: '2292',
            label: 'จันดุม',
            zipcode: '31250'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '264',
            value: '2293',
            label: 'โคกขมิ้น',
            zipcode: '31250'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '264',
            value: '2294',
            label: 'ป่าชัน',
            zipcode: '31250'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '264',
            value: '2295',
            label: 'สะเดา',
            zipcode: '31250'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '264',
            value: '2296',
            label: 'สำโรง',
            zipcode: '31250'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '265',
            value: '2297',
            label: 'ห้วยราช',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '265',
            value: '2298',
            label: 'สามแวง',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '265',
            value: '2299',
            label: 'ตาเสา',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '265',
            value: '2300',
            label: 'บ้านตะโก',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '265',
            value: '2301',
            label: 'สนวน',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '265',
            value: '2302',
            label: 'โคกเหล็ก',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '265',
            value: '2303',
            label: 'เมืองโพธิ์',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '265',
            value: '2304',
            label: 'ห้วยราชา',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '266',
            value: '2305',
            label: 'โนนสุวรรณ',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '266',
            value: '2306',
            label: 'ทุ่งจังหัน',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '266',
            value: '2307',
            label: 'โกรกแก้ว',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '266',
            value: '2308',
            label: 'ดงอีจาน',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '267',
            value: '2309',
            label: 'ชำนิ',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '267',
            value: '2310',
            label: 'หนองปล่อง',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '267',
            value: '2311',
            label: 'เมืองยาง',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '267',
            value: '2312',
            label: 'ช่อผกา',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '267',
            value: '2313',
            label: 'ละลวด',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '267',
            value: '2314',
            label: 'โคกสนวน',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '268',
            value: '2315',
            label: 'หนองแวง',
            zipcode: '31120'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '268',
            value: '2316',
            label: 'ทองหลาง',
            zipcode: '31120'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '268',
            value: '2317',
            label: 'แดงใหญ่',
            zipcode: '31120'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '268',
            value: '2318',
            label: 'กู่สวนแตง',
            zipcode: '31120'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '268',
            value: '2319',
            label: 'หนองเยือง',
            zipcode: '31120'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '269',
            value: '2320',
            label: 'โนนดินแดง',
            zipcode: '31260'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '269',
            value: '2321',
            label: 'ส้มป่อย',
            zipcode: '31260'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '269',
            value: '2322',
            label: 'ลำนางรอง',
            zipcode: '31260'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '270',
            value: '2323',
            label: 'บ้านด่าน',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '270',
            value: '2324',
            label: 'ปราสาท',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '270',
            value: '2325',
            label: 'วังเหนือ',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '270',
            value: '2326',
            label: 'โนนขวาง',
            zipcode: '31000'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '271',
            value: '2327',
            label: 'แคนดง',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '271',
            value: '2328',
            label: 'ดงพลอง',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '271',
            value: '2329',
            label: 'สระบัว',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '271',
            value: '2330',
            label: 'หัวฝาย',
            zipcode: '31150'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '272',
            value: '2331',
            label: 'เจริญสุข',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '272',
            value: '2332',
            label: 'ตาเป๊ก',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '272',
            value: '2333',
            label: 'อีสานเขต',
            zipcode: '31110'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '272',
            value: '2334',
            label: 'ถาวร',
            zipcode: '31170'
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '272',
            value: '2335',
            label: 'ยายแย้มวัฒนา',
            zipcode: '31170'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2336',
            label: 'ในเมือง',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2337',
            label: 'ตั้งใจ',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2338',
            label: 'เพี้ยราม',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2339',
            label: 'นาดี',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2340',
            label: 'ท่าสว่าง',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2341',
            label: 'สลักได',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2342',
            label: 'ตาอ็อง',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2344',
            label: 'สำโรง',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2345',
            label: 'แกใหญ่',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2346',
            label: 'นอกเมือง',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2347',
            label: 'คอโค',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2348',
            label: 'สวาย',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2349',
            label: 'เฉนียง',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2351',
            label: 'เทนมีย์',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2353',
            label: 'นาบัว',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2354',
            label: 'เมืองที',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2355',
            label: 'ราม',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2356',
            label: 'บุฤาษี',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2357',
            label: 'ตระแสง',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2360',
            label: 'แสลงพันธ์',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2361',
            label: 'กาเกาะ',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '274',
            value: '2362',
            label: 'ชุมพลบุรี',
            zipcode: '32190'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '274',
            value: '2363',
            label: 'นาหนองไผ่',
            zipcode: '32190'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '274',
            value: '2364',
            label: 'ไพรขลา',
            zipcode: '32190'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '274',
            value: '2365',
            label: 'ศรีณรงค์',
            zipcode: '32190'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '274',
            value: '2366',
            label: 'ยะวึก',
            zipcode: '32190'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '274',
            value: '2367',
            label: 'เมืองบัว',
            zipcode: '32190'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '274',
            value: '2368',
            label: 'สระขุด',
            zipcode: '32190'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '274',
            value: '2369',
            label: 'กระเบื้อง',
            zipcode: '32190'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '274',
            value: '2370',
            label: 'หนองเรือ',
            zipcode: '32190'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '275',
            value: '2371',
            label: 'ท่าตูม',
            zipcode: '32120'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '275',
            value: '2372',
            label: 'กระโพ',
            zipcode: '32120'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '275',
            value: '2373',
            label: 'พรมเทพ',
            zipcode: '32120'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '275',
            value: '2374',
            label: 'โพนครก',
            zipcode: '32120'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '275',
            value: '2375',
            label: 'เมืองแก',
            zipcode: '32120'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '275',
            value: '2376',
            label: 'บะ',
            zipcode: '32120'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '275',
            value: '2377',
            label: 'หนองบัว',
            zipcode: '32120'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '275',
            value: '2378',
            label: 'บัวโคก',
            zipcode: '32120'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '275',
            value: '2379',
            label: 'หนองเมธี',
            zipcode: '32120'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '275',
            value: '2380',
            label: 'ทุ่งกุลา',
            zipcode: '32120'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '276',
            value: '2381',
            label: 'จอมพระ',
            zipcode: '32180'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '276',
            value: '2382',
            label: 'เมืองลีง',
            zipcode: '32180'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '276',
            value: '2383',
            label: 'กระหาด',
            zipcode: '32180'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '276',
            value: '2384',
            label: 'บุแกรง',
            zipcode: '32180'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '276',
            value: '2385',
            label: 'หนองสนิท',
            zipcode: '32180'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '276',
            value: '2386',
            label: 'บ้านผือ',
            zipcode: '32180'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '276',
            value: '2387',
            label: 'ลุ่มระวี',
            zipcode: '32180'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '276',
            value: '2388',
            label: 'ชุมแสง',
            zipcode: '32180'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '276',
            value: '2389',
            label: 'เป็นสุข',
            zipcode: '32180'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2390',
            label: 'กังแอน',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2391',
            label: 'ทมอ',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2392',
            label: 'ไพล',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2393',
            label: 'ปรือ',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2394',
            label: 'ทุ่งมน',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2395',
            label: 'ตาเบา',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2396',
            label: 'หนองใหญ่',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2397',
            label: 'โคกยาง',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2398',
            label: 'โคกสะอาด',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2399',
            label: 'บ้านไทร',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2400',
            label: 'โชคนาสาม',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2401',
            label: 'เชื้อเพลิง',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2402',
            label: 'ปราสาททนง',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2403',
            label: 'ตานี',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2404',
            label: 'บ้านพลวง',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2405',
            label: 'กันตวจระมวล',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2406',
            label: 'สมุด',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2407',
            label: 'ประทัดบุ',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '278',
            value: '2413',
            label: 'กาบเชิง',
            zipcode: '32210'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '278',
            value: '2416',
            label: 'คูตัน',
            zipcode: '32210'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '278',
            value: '2417',
            label: 'ด่าน',
            zipcode: '32210'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '278',
            value: '2418',
            label: 'แนงมุด',
            zipcode: '32210'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '278',
            value: '2419',
            label: 'โคกตะเคียน',
            zipcode: '32210'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '278',
            value: '2422',
            label: 'ตะเคียน',
            zipcode: '32210'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2423',
            label: 'รัตนบุรี',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2424',
            label: 'ธาตุ',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2425',
            label: 'แก',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2426',
            label: 'ดอนแรด',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2427',
            label: 'หนองบัวทอง',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2428',
            label: 'หนองบัวบาน',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2431',
            label: 'ไผ่',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2433',
            label: 'เบิด',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2435',
            label: 'น้ำเขียว',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2436',
            label: 'กุดขาคีม',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2437',
            label: 'ยางสว่าง',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2438',
            label: 'ทับใหญ่',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '280',
            value: '2445',
            label: 'สนม',
            zipcode: '32160'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '280',
            value: '2446',
            label: 'โพนโก',
            zipcode: '32160'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '280',
            value: '2447',
            label: 'หนองระฆัง',
            zipcode: '32160'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '280',
            value: '2448',
            label: 'นานวน',
            zipcode: '32160'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '280',
            value: '2449',
            label: 'แคน',
            zipcode: '32160'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '280',
            value: '2450',
            label: 'หัวงัว',
            zipcode: '32160'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '280',
            value: '2451',
            label: 'หนองอียอ',
            zipcode: '32160'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2452',
            label: 'ระแงง',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2453',
            label: 'ตรึม',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2454',
            label: 'จารพัต',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2455',
            label: 'ยาง',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2456',
            label: 'แตล',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2457',
            label: 'หนองบัว',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2458',
            label: 'คาละแมะ',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2459',
            label: 'หนองเหล็ก',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2460',
            label: 'หนองขวาว',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2461',
            label: 'ช่างปี่',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2462',
            label: 'กุดหวาย',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2463',
            label: 'ขวาวใหญ่',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2464',
            label: 'นารุ่ง',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2465',
            label: 'ตรมไพร',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '281',
            value: '2466',
            label: 'ผักไหม',
            zipcode: '32110'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2467',
            label: 'สังขะ',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2468',
            label: 'ขอนแตก',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2472',
            label: 'ดม',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2473',
            label: 'พระแก้ว',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2474',
            label: 'บ้านจารย์',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2475',
            label: 'กระเทียม',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2476',
            label: 'สะกาด',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2477',
            label: 'ตาตุม',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2478',
            label: 'ทับทัน',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2479',
            label: 'ตาคง',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2481',
            label: 'บ้านชบ',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2483',
            label: 'เทพรักษา',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '283',
            value: '2486',
            label: 'ลำดวน',
            zipcode: '32220'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '283',
            value: '2487',
            label: 'โชคเหนือ',
            zipcode: '32220'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '283',
            value: '2488',
            label: 'อู่โลก',
            zipcode: '32220'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '283',
            value: '2489',
            label: 'ตรำดม',
            zipcode: '32220'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '283',
            value: '2490',
            label: 'ตระเปียงเตีย',
            zipcode: '32220'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '284',
            value: '2491',
            label: 'สำโรงทาบ',
            zipcode: '32170'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '284',
            value: '2492',
            label: 'หนองไผ่ล้อม',
            zipcode: '32170'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '284',
            value: '2493',
            label: 'กระออม',
            zipcode: '32170'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '284',
            value: '2494',
            label: 'หนองฮะ',
            zipcode: '32170'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '284',
            value: '2495',
            label: 'ศรีสุข',
            zipcode: '32170'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '284',
            value: '2496',
            label: 'เกาะแก้ว',
            zipcode: '32170'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '284',
            value: '2497',
            label: 'หมื่นศรี',
            zipcode: '32170'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '284',
            value: '2498',
            label: 'เสม็จ',
            zipcode: '32170'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '284',
            value: '2499',
            label: 'สะโน',
            zipcode: '32170'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '284',
            value: '2500',
            label: 'ประดู่',
            zipcode: '32170'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '285',
            value: '2501',
            label: 'บัวเชด',
            zipcode: '32230'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '285',
            value: '2502',
            label: 'สะเดา',
            zipcode: '32230'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '285',
            value: '2503',
            label: 'จรัส',
            zipcode: '32230'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '285',
            value: '2504',
            label: 'ตาวัง',
            zipcode: '32230'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '285',
            value: '2505',
            label: 'อาโพน',
            zipcode: '32230'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '285',
            value: '2506',
            label: 'สำเภาลูน',
            zipcode: '32230'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '286',
            value: '2507',
            label: 'บักได',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '286',
            value: '2508',
            label: 'โคกกลาง',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '286',
            value: '2509',
            label: 'จีกแดก',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '286',
            value: '2510',
            label: 'ตาเมียง',
            zipcode: '32140'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '287',
            value: '2511',
            label: 'ณรงค์',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '287',
            value: '2512',
            label: 'แจนแวน',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '287',
            value: '2513',
            label: 'ตรวจ',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '287',
            value: '2514',
            label: 'หนองแวง',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '287',
            value: '2515',
            label: 'ศรีสุข',
            zipcode: '32150'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '288',
            value: '2516',
            label: 'เขวาสินรินทร์',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '288',
            value: '2517',
            label: 'บึง',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '288',
            value: '2518',
            label: 'ตากูก',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '288',
            value: '2519',
            label: 'ปราสาททอง',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '288',
            value: '2520',
            label: 'บ้านแร่',
            zipcode: '32000'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '289',
            value: '2521',
            label: 'หนองหลวง',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '289',
            value: '2522',
            label: 'คำผง',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '289',
            value: '2523',
            label: 'โนน',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '289',
            value: '2524',
            label: 'ระเวียง',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '289',
            value: '2525',
            label: 'หนองเทพ',
            zipcode: '32130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2526',
            label: 'เมืองเหนือ',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2527',
            label: 'เมืองใต้',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2528',
            label: 'คูซอด',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2529',
            label: 'ซำ',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2530',
            label: 'จาน',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2531',
            label: 'ตะดอบ',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2532',
            label: 'หนองครก',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2536',
            label: 'โพนข่า',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2537',
            label: 'โพนค้อ',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2540',
            label: 'โพนเขวา',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2541',
            label: 'หญ้าปล้อง',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2543',
            label: 'ทุ่ม',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2544',
            label: 'หนองไฮ',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2546',
            label: 'หนองแก้ว',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2547',
            label: 'น้ำคำ',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2548',
            label: 'โพธิ์',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2549',
            label: 'หมากเขียบ',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2552',
            label: 'หนองไผ่',
            zipcode: '33000'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '291',
            value: '2558',
            label: 'ยางชุมน้อย',
            zipcode: '33190'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '291',
            value: '2559',
            label: 'ลิ้นฟ้า',
            zipcode: '33190'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '291',
            value: '2560',
            label: 'คอนกาม',
            zipcode: '33190'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '291',
            value: '2561',
            label: 'โนนคูณ',
            zipcode: '33190'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '291',
            value: '2562',
            label: 'กุดเมืองฮาม',
            zipcode: '33190'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '291',
            value: '2563',
            label: 'บึงบอน',
            zipcode: '33190'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '291',
            value: '2564',
            label: 'ยางชุมใหญ่',
            zipcode: '33190'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2565',
            label: 'ดูน',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2566',
            label: 'โนนสัง',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2567',
            label: 'หนองหัวช้าง',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2568',
            label: 'ยาง',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2569',
            label: 'หนองแวง',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2570',
            label: 'หนองแก้ว',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2571',
            label: 'ทาม',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2572',
            label: 'ละทาย',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2573',
            label: 'เมืองน้อย',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2574',
            label: 'อีปาด',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2575',
            label: 'บัวน้อย',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2576',
            label: 'หนองบัว',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2577',
            label: 'ดู่',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2578',
            label: 'ผักแพว',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2579',
            label: 'จาน',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2584',
            label: 'คำเนียม',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2591',
            label: 'บึงมะลู',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2592',
            label: 'กุดเสลา',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2593',
            label: 'เมือง',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2595',
            label: 'สังเม็ก',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2596',
            label: 'น้ำอ้อม',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2597',
            label: 'ละลาย',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2598',
            label: 'รุง',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2599',
            label: 'ตระกาจ',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2601',
            label: 'จานใหญ่',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2602',
            label: 'ภูเงิน',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2603',
            label: 'ชำ',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2604',
            label: 'กระแชง',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2605',
            label: 'โนนสำราญ',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2606',
            label: 'หนองหญ้าลาด',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2609',
            label: 'เสาธงชัย',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2610',
            label: 'ขนุน',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2611',
            label: 'สวนกล้วย',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2613',
            label: 'เวียงเหนือ',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2614',
            label: 'ทุ่งใหญ่',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2615',
            label: 'ภูผาหมอก',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2620',
            label: 'กันทรารมย์',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2621',
            label: 'จะกง',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2622',
            label: 'ใจดี',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2623',
            label: 'ดองกำเม็ด',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2624',
            label: 'โสน',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2625',
            label: 'ปรือใหญ่',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2626',
            label: 'สะเดาใหญ่',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2627',
            label: 'ตาอุด',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2628',
            label: 'ห้วยเหนือ',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2629',
            label: 'ห้วยใต้',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2630',
            label: 'หัวเสือ',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2632',
            label: 'ตะเคียน',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2634',
            label: 'นิคมพัฒนา',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2636',
            label: 'โคกเพชร',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2637',
            label: 'ปราสาท',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2640',
            label: 'สำโรงตาเจ็น',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2641',
            label: 'ห้วยสำราญ',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2643',
            label: 'กฤษณา',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2644',
            label: 'ลมศักดิ์',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2645',
            label: 'หนองฉลอง',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2646',
            label: 'ศรีตระกูล',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2647',
            label: 'ศรีสะอาด',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '295',
            value: '2649',
            label: 'ไพรบึง',
            zipcode: '33180'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '295',
            value: '2650',
            label: 'ดินแดง',
            zipcode: '33180'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '295',
            value: '2651',
            label: 'ปราสาทเยอ',
            zipcode: '33180'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '295',
            value: '2652',
            label: 'สำโรงพลัน',
            zipcode: '33180'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '295',
            value: '2653',
            label: 'สุขสวัสดิ์',
            zipcode: '33180'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '295',
            value: '2654',
            label: 'โนนปูน',
            zipcode: '33180'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '296',
            value: '2655',
            label: 'พิมาย',
            zipcode: '33170'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '296',
            value: '2656',
            label: 'กู่',
            zipcode: '33170'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '296',
            value: '2657',
            label: 'หนองเชียงทูน',
            zipcode: '33170'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '296',
            value: '2658',
            label: 'ตูม',
            zipcode: '33170'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '296',
            value: '2659',
            label: 'สมอ',
            zipcode: '33170'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '296',
            value: '2660',
            label: 'โพธิ์ศรี',
            zipcode: '33170'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '296',
            value: '2661',
            label: 'สำโรงปราสาท',
            zipcode: '33170'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '296',
            value: '2662',
            label: 'ดู่',
            zipcode: '33170'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '296',
            value: '2663',
            label: 'สวาย',
            zipcode: '33170'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '296',
            value: '2664',
            label: 'พิมายเหนือ',
            zipcode: '33170'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '297',
            value: '2665',
            label: 'สิ',
            zipcode: '33150'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '297',
            value: '2666',
            label: 'บักดอง',
            zipcode: '33150'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '297',
            value: '2667',
            label: 'พราน',
            zipcode: '33150'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '297',
            value: '2668',
            label: 'โพธิ์วงศ์',
            zipcode: '33150'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '297',
            value: '2669',
            label: 'ไพร',
            zipcode: '33150'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '297',
            value: '2670',
            label: 'กระหวัน',
            zipcode: '33150'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '297',
            value: '2671',
            label: 'ขุนหาญ',
            zipcode: '33150'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '297',
            value: '2672',
            label: 'โนนสูง',
            zipcode: '33150'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '297',
            value: '2673',
            label: 'กันทรอม',
            zipcode: '33150'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '297',
            value: '2674',
            label: 'ภูฝ้าย',
            zipcode: '33150'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '297',
            value: '2675',
            label: 'โพธิ์กระสังข์',
            zipcode: '33150'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '297',
            value: '2676',
            label: 'ห้วยจันทร์',
            zipcode: '33150'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2677',
            label: 'เมืองคง',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2678',
            label: 'เมืองแคน',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2679',
            label: 'หนองแค',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2682',
            label: 'จิกสังข์ทอง',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2683',
            label: 'ด่าน',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2684',
            label: 'ดู่',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2685',
            label: 'หนองอึ่ง',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2686',
            label: 'บัวหุ่ง',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2687',
            label: 'ไผ่',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2688',
            label: 'ส้มป่อย',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2689',
            label: 'หนองหมี',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2690',
            label: 'หว้านคำ',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2691',
            label: 'สร้างปี่',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2694',
            label: 'กำแพง',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2695',
            label: 'อี่หล่ำ',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2696',
            label: 'ก้านเหลือง',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2697',
            label: 'ทุ่งไชย',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2698',
            label: 'สำโรง',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2699',
            label: 'แขม',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2700',
            label: 'หนองไฮ',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2701',
            label: 'ขะยูง',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2703',
            label: 'ตาเกษ',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2704',
            label: 'หัวช้าง',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2705',
            label: 'รังแร้ง',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2707',
            label: 'แต้',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2708',
            label: 'แข้',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2709',
            label: 'โพธิ์ชัย',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2710',
            label: 'ปะอาว',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2711',
            label: 'หนองห้าง',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2715',
            label: 'สระกำแพงใหญ่',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2717',
            label: 'โคกหล่าม',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2718',
            label: 'โคกจาน',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '300',
            value: '2725',
            label: 'เป๊าะ',
            zipcode: '33220'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '300',
            value: '2726',
            label: 'บึงบูรพ์',
            zipcode: '33220'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '301',
            value: '2727',
            label: 'ห้วยทับทัน',
            zipcode: '33210'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '301',
            value: '2728',
            label: 'เมืองหลวง',
            zipcode: '33210'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '301',
            value: '2729',
            label: 'กล้วยกว้าง',
            zipcode: '33210'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '301',
            value: '2730',
            label: 'ผักไหม',
            zipcode: '33210'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '301',
            value: '2731',
            label: 'จานแสนไชย',
            zipcode: '33210'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '301',
            value: '2732',
            label: 'ปราสาท',
            zipcode: '33210'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '302',
            value: '2733',
            label: 'โนนค้อ',
            zipcode: '33250'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '302',
            value: '2734',
            label: 'บก',
            zipcode: '33250'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '302',
            value: '2735',
            label: 'โพธิ์',
            zipcode: '33250'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '302',
            value: '2736',
            label: 'หนองกุง',
            zipcode: '33250'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '302',
            value: '2737',
            label: 'เหล่ากวาง',
            zipcode: '33250'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '303',
            value: '2738',
            label: 'ศรีแก้ว',
            zipcode: '33240'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '303',
            value: '2739',
            label: 'พิงพวย',
            zipcode: '33240'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '303',
            value: '2740',
            label: 'สระเยาว์',
            zipcode: '33240'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '303',
            value: '2741',
            label: 'ตูม',
            zipcode: '33240'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '303',
            value: '2742',
            label: 'เสื่องข้าว',
            zipcode: '33240'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '303',
            value: '2743',
            label: 'ศรีโนนงาม',
            zipcode: '33240'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '303',
            value: '2744',
            label: 'สะพุง',
            zipcode: '33240'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '304',
            value: '2745',
            label: 'น้ำเกลี้ยง',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '304',
            value: '2746',
            label: 'ละเอาะ',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '304',
            value: '2747',
            label: 'ตองปิด',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '304',
            value: '2748',
            label: 'เขิน',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '304',
            value: '2749',
            label: 'รุ่งระวี',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '304',
            value: '2750',
            label: 'คูบ',
            zipcode: '33130'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '305',
            value: '2751',
            label: 'บุสูง',
            zipcode: '33270'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '305',
            value: '2752',
            label: 'ธาตุ',
            zipcode: '33270'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '305',
            value: '2753',
            label: 'ดวนใหญ่',
            zipcode: '33270'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '305',
            value: '2754',
            label: 'บ่อแก้ว',
            zipcode: '33270'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '305',
            value: '2755',
            label: 'ศรีสำราญ',
            zipcode: '33270'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '305',
            value: '2756',
            label: 'ทุ่งสว่าง',
            zipcode: '33270'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '305',
            value: '2757',
            label: 'วังหิน',
            zipcode: '33270'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '305',
            value: '2758',
            label: 'โพนยาง',
            zipcode: '33270'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '306',
            value: '2759',
            label: 'โคกตาล',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '306',
            value: '2760',
            label: 'ห้วยตามอญ',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '306',
            value: '2761',
            label: 'ห้วยตึ๊กชู',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '306',
            value: '2762',
            label: 'ละลม',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '306',
            value: '2763',
            label: 'ตะเคียนราม',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '306',
            value: '2764',
            label: 'ดงรัก',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '306',
            value: '2765',
            label: 'ไพรพัฒนา',
            zipcode: '33140'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '307',
            value: '2766',
            label: 'เมืองจันทร์',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '307',
            value: '2767',
            label: 'ตาโกน',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '307',
            value: '2768',
            label: 'หนองใหญ่',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '308',
            value: '2769',
            label: 'เสียว',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '308',
            value: '2770',
            label: 'หนองหว้า',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '308',
            value: '2771',
            label: 'หนองงูเหลือม',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '308',
            value: '2772',
            label: 'หนองฮาง',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '308',
            value: '2773',
            label: 'ท่าคล้อ',
            zipcode: '33110'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '309',
            value: '2774',
            label: 'พยุห์',
            zipcode: '33230'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '309',
            value: '2775',
            label: 'พรหมสวัสดิ์',
            zipcode: '33230'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '309',
            value: '2776',
            label: 'ตำแย',
            zipcode: '33230'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '309',
            value: '2777',
            label: 'โนนเพ็ก',
            zipcode: '33230'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '309',
            value: '2778',
            label: 'หนองค้า',
            zipcode: '33230'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '310',
            value: '2779',
            label: 'โดด',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '310',
            value: '2780',
            label: 'เสียว',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '310',
            value: '2781',
            label: 'หนองม้า',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '310',
            value: '2782',
            label: 'ผือใหญ่',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '310',
            value: '2783',
            label: 'อีเซ',
            zipcode: '33120'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '311',
            value: '2784',
            label: 'กุง',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '311',
            value: '2785',
            label: 'คลีกลิ้ง',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '311',
            value: '2786',
            label: 'หนองบัวดง',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '311',
            value: '2787',
            label: 'โจดม่วง',
            zipcode: '33160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2788',
            label: 'ในเมือง',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2791',
            label: 'หัวเรือ',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2792',
            label: 'หนองขอน',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2794',
            label: 'ปทุม',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2795',
            label: 'ขามใหญ่',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2796',
            label: 'แจระแม',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2798',
            label: 'หนองบ่อ',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2799',
            label: 'ไร่น้อย',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2800',
            label: 'กระโสบ',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2803',
            label: 'กุดลาด',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2806',
            label: 'ขี้เหล็ก',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2807',
            label: 'ปะอาว',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '313',
            value: '2808',
            label: 'นาคำ',
            zipcode: '34250'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '313',
            value: '2809',
            label: 'แก้งกอก',
            zipcode: '34250'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '313',
            value: '2810',
            label: 'เอือดใหญ่',
            zipcode: '34250'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '313',
            value: '2811',
            label: 'วาริน',
            zipcode: '34250'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '313',
            value: '2812',
            label: 'ลาดควาย',
            zipcode: '34250'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '313',
            value: '2813',
            label: 'สงยาง',
            zipcode: '34250'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '313',
            value: '2814',
            label: 'ตะบ่าย',
            zipcode: '34250'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '313',
            value: '2815',
            label: 'คำไหล',
            zipcode: '34250'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '313',
            value: '2816',
            label: 'หนามแท่ง',
            zipcode: '34250'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '313',
            value: '2817',
            label: 'นาเลิน',
            zipcode: '34250'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '313',
            value: '2818',
            label: 'ดอนใหญ่',
            zipcode: '34250'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '314',
            value: '2819',
            label: 'โขงเจียม',
            zipcode: '34220'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '314',
            value: '2820',
            label: 'ห้วยยาง',
            zipcode: '34220'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '314',
            value: '2821',
            label: 'นาโพธิ์กลาง',
            zipcode: '34220'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '314',
            value: '2822',
            label: 'หนองแสงใหญ่',
            zipcode: '34220'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '314',
            value: '2823',
            label: 'ห้วยไผ่',
            zipcode: '34220'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '314',
            value: '2824',
            label: 'คำเขื่อนแก้ว',
            zipcode: '34220'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2825',
            label: 'เขื่องใน',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2826',
            label: 'สร้างถ่อ',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2827',
            label: 'ค้อทอง',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2828',
            label: 'ก่อเอ้',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2829',
            label: 'หัวดอน',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2830',
            label: 'ชีทวน',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2831',
            label: 'ท่าไห',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2832',
            label: 'นาคำใหญ่',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2833',
            label: 'แดงหม้อ',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2834',
            label: 'ธาตุน้อย',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2835',
            label: 'บ้านไทย',
            zipcode: '34320'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2836',
            label: 'บ้านกอก',
            zipcode: '34320'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2837',
            label: 'กลางใหญ่',
            zipcode: '34320'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2838',
            label: 'โนนรัง',
            zipcode: '34320'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2839',
            label: 'ยางขี้นก',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2840',
            label: 'ศรีสุข',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2841',
            label: 'สหธาตุ',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '315',
            value: '2842',
            label: 'หนองเหล่า',
            zipcode: '34150'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2843',
            label: 'เขมราฐ',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2845',
            label: 'ขามป้อม',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2846',
            label: 'เจียด',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2849',
            label: 'หนองผือ',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2850',
            label: 'นาแวง',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2852',
            label: 'แก้งเหนือ',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2853',
            label: 'หนองนกทา',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2854',
            label: 'หนองสิม',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2855',
            label: 'หัวนา',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2863',
            label: 'เมืองเดช',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2864',
            label: 'นาส่วง',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2866',
            label: 'นาเจริญ',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2868',
            label: 'ทุ่งเทิง',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2870',
            label: 'สมสะอาด',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2871',
            label: 'กุดประทาย',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2872',
            label: 'ตบหู',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2873',
            label: 'กลาง',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2874',
            label: 'แก้ง',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2875',
            label: 'ท่าโพธิ์ศรี',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2877',
            label: 'บัวงาม',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2878',
            label: 'คำครั่ง',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2879',
            label: 'นากระแซง',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2882',
            label: 'โพนงาม',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2883',
            label: 'ป่าโมง',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2885',
            label: 'โนนสมบูรณ์',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '319',
            value: '2886',
            label: 'นาจะหลวย',
            zipcode: '34280'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '319',
            value: '2887',
            label: 'โนนสมบูรณ์',
            zipcode: '34280'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '319',
            value: '2888',
            label: 'พรสวรรค์',
            zipcode: '34280'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '319',
            value: '2889',
            label: 'บ้านตูม',
            zipcode: '34280'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '319',
            value: '2890',
            label: 'โสกแสง',
            zipcode: '34280'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '319',
            value: '2891',
            label: 'โนนสวรรค์',
            zipcode: '34280'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '320',
            value: '2892',
            label: 'โซง',
            zipcode: '34260'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '320',
            value: '2894',
            label: 'ยาง',
            zipcode: '34260'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '320',
            value: '2895',
            label: 'โดมประดิษฐ์',
            zipcode: '34260'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '320',
            value: '2897',
            label: 'บุเปือย',
            zipcode: '34260'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '320',
            value: '2898',
            label: 'สีวิเชียร',
            zipcode: '34260'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '320',
            value: '2900',
            label: 'ยางใหญ่',
            zipcode: '34260'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '320',
            value: '2902',
            label: 'เก่าขาม',
            zipcode: '34260'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '321',
            value: '2903',
            label: 'โพนงาม',
            zipcode: '34230'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '321',
            value: '2904',
            label: 'ห้วยข่า',
            zipcode: '34230'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '321',
            value: '2905',
            label: 'คอแลน',
            zipcode: '34230'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '321',
            value: '2906',
            label: 'นาโพธิ์',
            zipcode: '34230'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '321',
            value: '2907',
            label: 'หนองสะโน',
            zipcode: '34230'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '321',
            value: '2908',
            label: 'โนนค้อ',
            zipcode: '34230'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '321',
            value: '2909',
            label: 'บัวงาม',
            zipcode: '34230'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '321',
            value: '2910',
            label: 'บ้านแมด',
            zipcode: '34230'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2911',
            label: 'ขุหลุ',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2912',
            label: 'กระเดียน',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2913',
            label: 'เกษม',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2914',
            label: 'กุศกร',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2915',
            label: 'ขามเปี้ย',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2916',
            label: 'คอนสาย',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2917',
            label: 'โคกจาน',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2918',
            label: 'นาพิน',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2919',
            label: 'นาสะไม',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2920',
            label: 'โนนกุง',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2921',
            label: 'ตระการ',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2922',
            label: 'ตากแดด',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2923',
            label: 'ไหล่ทุ่ง',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2924',
            label: 'เป้า',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2925',
            label: 'เซเป็ด',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2926',
            label: 'สะพือ',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2927',
            label: 'หนองเต่า',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2928',
            label: 'ถ้ำแข้',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2929',
            label: 'ท่าหลวง',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2930',
            label: 'ห้วยฝ้ายพัฒนา',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2931',
            label: 'กุดยาลวน',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2932',
            label: 'บ้านแดง',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '322',
            value: '2933',
            label: 'คำเจริญ',
            zipcode: '34130'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '323',
            value: '2934',
            label: 'ข้าวปุ้น',
            zipcode: '34270'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '323',
            value: '2935',
            label: 'โนนสวาง',
            zipcode: '34270'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '323',
            value: '2936',
            label: 'แก่งเค็ง',
            zipcode: '34270'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '323',
            value: '2937',
            label: 'กาบิน',
            zipcode: '34270'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '323',
            value: '2938',
            label: 'หนองทันน้ำ',
            zipcode: '34270'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2947',
            label: 'ม่วงสามสิบ',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2948',
            label: 'เหล่าบก',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2949',
            label: 'ดุมใหญ่',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2950',
            label: 'หนองช้างใหญ่',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2951',
            label: 'หนองเมือง',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2952',
            label: 'เตย',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2953',
            label: 'ยางสักกระโพหลุ่ม',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2954',
            label: 'หนองไข่นก',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2955',
            label: 'หนองเหล่า',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2956',
            label: 'หนองฮาง',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2957',
            label: 'ยางโยภาพ',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2958',
            label: 'ไผ่ใหญ่',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2959',
            label: 'นาเลิง',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '325',
            value: '2960',
            label: 'โพนแพง',
            zipcode: '34140'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2961',
            label: 'วารินชำราบ',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2962',
            label: 'ธาตุ',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2964',
            label: 'ท่าลาด',
            zipcode: '34310'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2965',
            label: 'โนนโหนน',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2967',
            label: 'คูเมือง',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2968',
            label: 'สระสมิง',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2970',
            label: 'คำน้ำแซบ',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2971',
            label: 'บุ่งหวาย',
            zipcode: '34310'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2975',
            label: 'คำขวาง',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2976',
            label: 'โพธิ์ใหญ่',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2978',
            label: 'แสนสุข',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2980',
            label: 'หนองกินเพล',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2981',
            label: 'โนนผึ้ง',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2982',
            label: 'เมืองศรีไค',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2984',
            label: 'ห้วยขะยูง',
            zipcode: '34310'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2986',
            label: 'บุ่งไหม',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3024',
            label: 'พิบูล',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3025',
            label: 'กุดชมภู',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3027',
            label: 'ดอนจิก',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3028',
            label: 'ทรายมูล',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3029',
            label: 'นาโพธิ์',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3030',
            label: 'โนนกลาง',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3032',
            label: 'โพธิ์ไทร',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3033',
            label: 'โพธิ์ศรี',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3034',
            label: 'ระเว',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3035',
            label: 'ไร่ใต้',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3036',
            label: 'หนองบัวฮี',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3037',
            label: 'อ่างศิลา',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3041',
            label: 'โนนกาหลง',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3042',
            label: 'บ้านแขม',
            zipcode: '34110'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '331',
            value: '3043',
            label: 'ตาลสุม',
            zipcode: '34330'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '331',
            value: '3044',
            label: 'สำโรง',
            zipcode: '34330'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '331',
            value: '3045',
            label: 'จิกเทิง',
            zipcode: '34330'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '331',
            value: '3046',
            label: 'หนองกุง',
            zipcode: '34330'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '331',
            value: '3047',
            label: 'นาคาย',
            zipcode: '34330'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '331',
            value: '3048',
            label: 'คำหว้า',
            zipcode: '34330'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '332',
            value: '3049',
            label: 'โพธิ์ไทร',
            zipcode: '34340'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '332',
            value: '3050',
            label: 'ม่วงใหญ่',
            zipcode: '34340'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '332',
            value: '3051',
            label: 'สำโรง',
            zipcode: '34340'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '332',
            value: '3052',
            label: 'สองคอน',
            zipcode: '34340'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '332',
            value: '3053',
            label: 'สารภี',
            zipcode: '34340'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '332',
            value: '3054',
            label: 'เหล่างาม',
            zipcode: '34340'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '333',
            value: '3055',
            label: 'สำโรง',
            zipcode: '34360'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '333',
            value: '3056',
            label: 'โคกก่อง',
            zipcode: '34360'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '333',
            value: '3057',
            label: 'หนองไฮ',
            zipcode: '34360'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '333',
            value: '3058',
            label: 'ค้อน้อย',
            zipcode: '34360'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '333',
            value: '3059',
            label: 'โนนกาเล็น',
            zipcode: '34360'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '333',
            value: '3060',
            label: 'โคกสว่าง',
            zipcode: '34360'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '333',
            value: '3061',
            label: 'โนนกลาง',
            zipcode: '34360'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '333',
            value: '3062',
            label: 'บอน',
            zipcode: '34360'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '333',
            value: '3063',
            label: 'ขามป้อม',
            zipcode: '34360'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '335',
            value: '3070',
            label: 'ดอนมดแดง',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '335',
            value: '3071',
            label: 'เหล่าแดง',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '335',
            value: '3072',
            label: 'ท่าเมือง',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '335',
            value: '3073',
            label: 'คำไฮใหญ่',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '336',
            value: '3074',
            label: 'คันไร่',
            zipcode: '34350'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '336',
            value: '3075',
            label: 'ช่องเม็ก',
            zipcode: '34350'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '336',
            value: '3076',
            label: 'โนนก่อ',
            zipcode: '34350'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '336',
            value: '3077',
            label: 'นิคมสร้างตนเองลำโดมน้อย',
            zipcode: '34350'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '336',
            value: '3078',
            label: 'ฝางคำ',
            zipcode: '34350'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '336',
            value: '3079',
            label: 'คำเขื่อนแก้ว',
            zipcode: '34350'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '337',
            value: '3081',
            label: 'หนองอ้ม',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '337',
            value: '3082',
            label: 'นาเกษม',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '337',
            value: '3083',
            label: 'กุดเรือ',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '337',
            value: '3084',
            label: 'โคกชำแระ',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '337',
            value: '3085',
            label: 'นาห่อม',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '340',
            value: '3093',
            label: 'นาเยีย',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '340',
            value: '3094',
            label: 'นาดี',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '340',
            value: '3095',
            label: 'นาเรือง',
            zipcode: '34160'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '341',
            value: '3096',
            label: 'นาตาล',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '341',
            value: '3097',
            label: 'พะลาน',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '341',
            value: '3098',
            label: 'กองโพน',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '341',
            value: '3099',
            label: 'พังเคน',
            zipcode: '34170'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '342',
            value: '3100',
            label: 'เหล่าเสือโก้ก',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '342',
            value: '3101',
            label: 'โพนเมือง',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '342',
            value: '3102',
            label: 'แพงใหญ่',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '342',
            value: '3103',
            label: 'หนองบก',
            zipcode: '34000'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '343',
            value: '3104',
            label: 'แก่งโดม',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '343',
            value: '3105',
            label: 'ท่าช้าง',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '343',
            value: '3106',
            label: 'บุ่งมะแลง',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '343',
            value: '3107',
            label: 'สว่าง',
            zipcode: '34190'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '344',
            value: '3108',
            label: 'ตาเกา',
            zipcode: '34260'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '344',
            value: '3109',
            label: 'ไพบูลย์',
            zipcode: '34260'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '344',
            value: '3110',
            label: 'ขี้เหล็ก',
            zipcode: '34260'
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '344',
            value: '3111',
            label: 'โคกสะอาด',
            zipcode: '34260'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3112',
            label: 'ในเมือง',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3113',
            label: 'น้ำคำใหญ่',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3114',
            label: 'ตาดทอง',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3115',
            label: 'สำราญ',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3116',
            label: 'ค้อเหนือ',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3117',
            label: 'ดู่ทุ่ง',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3118',
            label: 'เดิด',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3119',
            label: 'ขั้นไดใหญ่',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3120',
            label: 'ทุ่งแต้',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3121',
            label: 'สิงห์',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3122',
            label: 'นาสะไมย์',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3123',
            label: 'เขื่องคำ',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3124',
            label: 'หนองหิน',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3125',
            label: 'หนองคู',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3126',
            label: 'ขุมเงิน',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3127',
            label: 'ทุ่งนางโอก',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3128',
            label: 'หนองเรือ',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3129',
            label: 'หนองเป็ด',
            zipcode: '35000'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '347',
            value: '3134',
            label: 'ทรายมูล',
            zipcode: '35170'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '347',
            value: '3135',
            label: 'ดู่ลาด',
            zipcode: '35170'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '347',
            value: '3136',
            label: 'ดงมะไฟ',
            zipcode: '35170'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '347',
            value: '3137',
            label: 'นาเวียง',
            zipcode: '35170'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '347',
            value: '3138',
            label: 'ไผ่',
            zipcode: '35170'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '348',
            value: '3139',
            label: 'กุดชุม',
            zipcode: '35140'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '348',
            value: '3140',
            label: 'โนนเปือย',
            zipcode: '35140'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '348',
            value: '3141',
            label: 'กำแมด',
            zipcode: '35140'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '348',
            value: '3142',
            label: 'นาโส่',
            zipcode: '35140'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '348',
            value: '3143',
            label: 'ห้วยแก้ง',
            zipcode: '35140'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '348',
            value: '3144',
            label: 'หนองหมี',
            zipcode: '35140'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '348',
            value: '3145',
            label: 'โพนงาม',
            zipcode: '35140'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '348',
            value: '3146',
            label: 'คำน้ำสร้าง',
            zipcode: '35140'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '348',
            value: '3147',
            label: 'หนองแหน',
            zipcode: '35140'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3148',
            label: 'ลุมพุก',
            zipcode: '35110'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3149',
            label: 'ย่อ',
            zipcode: '35110'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3150',
            label: 'สงเปือย',
            zipcode: '35110'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3151',
            label: 'โพนทัน',
            zipcode: '35110'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3152',
            label: 'ทุ่งมน',
            zipcode: '35110'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3153',
            label: 'นาคำ',
            zipcode: '35180'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3154',
            label: 'ดงแคนใหญ่',
            zipcode: '35180'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3155',
            label: 'กู่จาน',
            zipcode: '35110'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3156',
            label: 'นาแก',
            zipcode: '35180'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3157',
            label: 'กุดกุง',
            zipcode: '35110'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3158',
            label: 'เหล่าไฮ',
            zipcode: '35110'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3159',
            label: 'แคนน้อย',
            zipcode: '35180'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '349',
            value: '3160',
            label: 'ดงเจริญ',
            zipcode: '35110'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '350',
            value: '3161',
            label: 'โพธิ์ไทร',
            zipcode: '35150'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '350',
            value: '3162',
            label: 'กระจาย',
            zipcode: '35150'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '350',
            value: '3163',
            label: 'โคกนาโก',
            zipcode: '35150'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '350',
            value: '3164',
            label: 'เชียงเพ็ง',
            zipcode: '35150'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '350',
            value: '3165',
            label: 'ศรีฐาน',
            zipcode: '35150'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3166',
            label: 'ฟ้าหยาด',
            zipcode: '35130'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3167',
            label: 'หัวเมือง',
            zipcode: '35130'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3168',
            label: 'คูเมือง',
            zipcode: '35130'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3169',
            label: 'ผือฮี',
            zipcode: '35130'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3170',
            label: 'บากเรือ',
            zipcode: '35130'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3171',
            label: 'ม่วง',
            zipcode: '35130'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3172',
            label: 'โนนทราย',
            zipcode: '35130'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3173',
            label: 'บึงแก',
            zipcode: '35130'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3174',
            label: 'พระเสาร์',
            zipcode: '35130'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3175',
            label: 'สงยาง',
            zipcode: '35130'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '352',
            value: '3180',
            label: 'ฟ้าห่วน',
            zipcode: '35160'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '352',
            value: '3181',
            label: 'กุดน้ำใส',
            zipcode: '35160'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '352',
            value: '3182',
            label: 'น้ำอ้อม',
            zipcode: '35160'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '352',
            value: '3183',
            label: 'ค้อวัง',
            zipcode: '35160'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3185',
            label: 'บุ่งค้า',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3186',
            label: 'สวาท',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3188',
            label: 'ห้องแซง',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3189',
            label: 'สามัคคี',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3190',
            label: 'กุดเชียงหมี',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3193',
            label: 'สามแยก',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3194',
            label: 'กุดแห่',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3195',
            label: 'โคกสำราญ',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3196',
            label: 'สร้างมิ่ง',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3197',
            label: 'ศรีแก้ว',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '354',
            value: '3204',
            label: 'ไทยเจริญ',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '354',
            value: '3205',
            label: 'น้ำคำ',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '354',
            value: '3206',
            label: 'ส้มผ่อ',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '354',
            value: '3207',
            label: 'คำเตย',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '354',
            value: '3208',
            label: 'คำไผ่',
            zipcode: '35120'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3209',
            label: 'ในเมือง',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3210',
            label: 'รอบเมือง',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3211',
            label: 'โพนทอง',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3212',
            label: 'นาฝาย',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3213',
            label: 'บ้านค่าย',
            zipcode: '36240'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3214',
            label: 'กุดตุ้ม',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3215',
            label: 'ชีลอง',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3216',
            label: 'บ้านเล่า',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3217',
            label: 'นาเสียว',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3218',
            label: 'หนองนาแซง',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3219',
            label: 'ลาดใหญ่',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3220',
            label: 'หนองไผ่',
            zipcode: '36240'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3221',
            label: 'ท่าหินโงม',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3222',
            label: 'ห้วยต้อน',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3223',
            label: 'ห้วยบง',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3224',
            label: 'โนนสำราญ',
            zipcode: '36240'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3225',
            label: 'โคกสูง',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3226',
            label: 'บุ่งคล้า',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3227',
            label: 'ซับสีทอง',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '356',
            value: '3230',
            label: 'บ้านเขว้า',
            zipcode: '36170'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '356',
            value: '3231',
            label: 'ตลาดแร้ง',
            zipcode: '36170'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '356',
            value: '3232',
            label: 'ลุ่มลำชี',
            zipcode: '36170'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '356',
            value: '3233',
            label: 'ชีบน',
            zipcode: '36170'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '356',
            value: '3234',
            label: 'ภูแลนคา',
            zipcode: '36170'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '356',
            value: '3235',
            label: 'โนนแดง',
            zipcode: '36170'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '357',
            value: '3236',
            label: 'คอนสวรรค์',
            zipcode: '36140'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '357',
            value: '3237',
            label: 'ยางหวาย',
            zipcode: '36140'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '357',
            value: '3238',
            label: 'ช่องสามหมอ',
            zipcode: '36140'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '357',
            value: '3239',
            label: 'โนนสะอาด',
            zipcode: '36140'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '357',
            value: '3240',
            label: 'ห้วยไร่',
            zipcode: '36140'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '357',
            value: '3241',
            label: 'บ้านโสก',
            zipcode: '36140'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '357',
            value: '3242',
            label: 'โคกมั่งงอย',
            zipcode: '36140'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '357',
            value: '3243',
            label: 'หนองขาม',
            zipcode: '36140'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '357',
            value: '3244',
            label: 'ศรีสำราญ',
            zipcode: '36140'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '358',
            value: '3245',
            label: 'บ้านยาง',
            zipcode: '36120'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '358',
            value: '3246',
            label: 'บ้านหัน',
            zipcode: '36120'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '358',
            value: '3247',
            label: 'บ้านเดื่อ',
            zipcode: '36120'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '358',
            value: '3248',
            label: 'บ้านเป้า',
            zipcode: '36120'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '358',
            value: '3249',
            label: 'กุดเลาะ',
            zipcode: '36120'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '358',
            value: '3250',
            label: 'โนนกอก',
            zipcode: '36120'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '358',
            value: '3251',
            label: 'สระโพนทอง',
            zipcode: '36120'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '358',
            value: '3252',
            label: 'หนองข่า',
            zipcode: '36120'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '358',
            value: '3253',
            label: 'หนองโพนงาม',
            zipcode: '36120'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '358',
            value: '3254',
            label: 'บ้านบัว',
            zipcode: '36120'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '358',
            value: '3255',
            label: 'ซับสีทอง',
            zipcode: '36000'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '358',
            value: '3256',
            label: 'โนนทอง',
            zipcode: '36120'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3257',
            label: 'หนองบัวแดง',
            zipcode: '36210'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3258',
            label: 'กุดชุมแสง',
            zipcode: '36210'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3259',
            label: 'ถ้ำวัวแดง',
            zipcode: '36210'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3260',
            label: 'นางแดด',
            zipcode: '36210'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3263',
            label: 'หนองแวง',
            zipcode: '36210'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3264',
            label: 'คูเมือง',
            zipcode: '36210'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3265',
            label: 'ท่าใหญ่',
            zipcode: '36210'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3267',
            label: 'วังชมภู',
            zipcode: '36210'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3270',
            label: 'บ้านกอก',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3271',
            label: 'หนองบัวบาน',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3272',
            label: 'บ้านขาม',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3274',
            label: 'กุดน้ำใส',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3275',
            label: 'หนองโดน',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3276',
            label: 'ละหาน',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3279',
            label: 'หนองบัวใหญ่',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3280',
            label: 'หนองบัวโคก',
            zipcode: '36220'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3282',
            label: 'ส้มป่อย',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '361',
            value: '3286',
            label: 'บ้านชวน',
            zipcode: '36160'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '361',
            value: '3287',
            label: 'บ้านเพชร',
            zipcode: '36160'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '361',
            value: '3288',
            label: 'บ้านตาล',
            zipcode: '36220'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '361',
            value: '3289',
            label: 'หัวทะเล',
            zipcode: '36220'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '361',
            value: '3290',
            label: 'โคกเริงรมย์',
            zipcode: '36160'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '361',
            value: '3291',
            label: 'เกาะมะนาว',
            zipcode: '36160'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '361',
            value: '3292',
            label: 'โคกเพชรพัฒนา',
            zipcode: '36160'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '362',
            value: '3297',
            label: 'หนองบัวระเหว',
            zipcode: '36250'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '362',
            value: '3298',
            label: 'วังตะเฆ่',
            zipcode: '36250'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '362',
            value: '3299',
            label: 'ห้วยแย้',
            zipcode: '36250'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '362',
            value: '3300',
            label: 'โคกสะอาด',
            zipcode: '36250'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '362',
            value: '3301',
            label: 'โสกปลาดุก',
            zipcode: '36250'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '363',
            value: '3302',
            label: 'วะตะแบก',
            zipcode: '36230'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '363',
            value: '3303',
            label: 'ห้วยยายจิ๋ว',
            zipcode: '36230'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '363',
            value: '3304',
            label: 'นายางกลัก',
            zipcode: '36230'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '363',
            value: '3305',
            label: 'บ้านไร่',
            zipcode: '36230'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '363',
            value: '3306',
            label: 'โป่งนก',
            zipcode: '36230'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '364',
            value: '3307',
            label: 'ผักปัง',
            zipcode: '36110'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '364',
            value: '3308',
            label: 'กวางโจน',
            zipcode: '36110'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '364',
            value: '3309',
            label: 'หนองคอนไทย',
            zipcode: '36110'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '364',
            value: '3310',
            label: 'บ้านแก้ง',
            zipcode: '36110'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '364',
            value: '3311',
            label: 'กุดยม',
            zipcode: '36110'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '364',
            value: '3312',
            label: 'บ้านเพชร',
            zipcode: '36110'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '364',
            value: '3313',
            label: 'โคกสะอาด',
            zipcode: '36110'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '364',
            value: '3314',
            label: 'หนองตูม',
            zipcode: '36110'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '364',
            value: '3315',
            label: 'โอโล',
            zipcode: '36110'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '364',
            value: '3316',
            label: 'ธาตุทอง',
            zipcode: '36110'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '364',
            value: '3317',
            label: 'บ้านดอน',
            zipcode: '36110'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '365',
            value: '3318',
            label: 'บ้านแท่น',
            zipcode: '36190'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '365',
            value: '3319',
            label: 'สามสวน',
            zipcode: '36190'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '365',
            value: '3320',
            label: 'สระพัง',
            zipcode: '36190'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '365',
            value: '3321',
            label: 'บ้านเต่า',
            zipcode: '36190'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '365',
            value: '3322',
            label: 'หนองคู',
            zipcode: '36190'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '366',
            value: '3323',
            label: 'ช่องสามหมอ',
            zipcode: '36150'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '366',
            value: '3324',
            label: 'หนองขาม',
            zipcode: '36150'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '366',
            value: '3325',
            label: 'นาหนองทุ่ม',
            zipcode: '36150'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '366',
            value: '3326',
            label: 'บ้านแก้ง',
            zipcode: '36150'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '366',
            value: '3327',
            label: 'หนองสังข์',
            zipcode: '36150'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '366',
            value: '3328',
            label: 'หลุบคา',
            zipcode: '36150'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '366',
            value: '3329',
            label: 'โคกกุง',
            zipcode: '36150'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '366',
            value: '3330',
            label: 'เก่าย่าดี',
            zipcode: '36150'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '366',
            value: '3331',
            label: 'ท่ามะไฟหวาน',
            zipcode: '36150'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '366',
            value: '3332',
            label: 'หนองไผ่',
            zipcode: '36150'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '367',
            value: '3333',
            label: 'คอนสาร',
            zipcode: '36180'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '367',
            value: '3334',
            label: 'ทุ่งพระ',
            zipcode: '36180'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '367',
            value: '3335',
            label: 'โนนคูณ',
            zipcode: '36180'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '367',
            value: '3336',
            label: 'ห้วยยาง',
            zipcode: '36180'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '367',
            value: '3337',
            label: 'ทุ่งลุยลาย',
            zipcode: '36180'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '367',
            value: '3338',
            label: 'ดงบัง',
            zipcode: '36180'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '367',
            value: '3339',
            label: 'ทุ่งนาเลา',
            zipcode: '36180'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '367',
            value: '3340',
            label: 'ดงกลาง',
            zipcode: '36180'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '368',
            value: '3341',
            label: 'บ้านเจียง',
            zipcode: '36260'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '368',
            value: '3342',
            label: 'เจาทอง',
            zipcode: '36260'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '368',
            value: '3343',
            label: 'วังทอง',
            zipcode: '36260'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '368',
            value: '3344',
            label: 'แหลมทอง',
            zipcode: '36260'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '369',
            value: '3345',
            label: 'หนองฉิม',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '369',
            value: '3346',
            label: 'ตาเนิน',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '369',
            value: '3347',
            label: 'กะฮาด',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '369',
            value: '3348',
            label: 'รังงาม',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '370',
            value: '3349',
            label: 'ซับใหญ่',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '370',
            value: '3350',
            label: 'ท่ากูบ',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '370',
            value: '3351',
            label: 'ตะโกทอง',
            zipcode: '36130'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3352',
            label: 'บุ่ง',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3353',
            label: 'ไก่คำ',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3354',
            label: 'นาจิก',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3355',
            label: 'ปลาค้าว',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3356',
            label: 'เหล่าพรวน',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3357',
            label: 'สร้างนกทา',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3358',
            label: 'คึมใหญ่',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3359',
            label: 'นาผือ',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3360',
            label: 'น้ำปลีก',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3361',
            label: 'นาวัง',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3362',
            label: 'นาหมอม้า',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3363',
            label: 'โนนโพธิ์',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3364',
            label: 'โนนหนามแท่ง',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3365',
            label: 'ห้วยไร่',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3366',
            label: 'หนองมะแซว',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3367',
            label: 'กุดปลาดุก',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3368',
            label: 'ดอนเมย',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3369',
            label: 'นายม',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3370',
            label: 'นาแต้',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '381',
            value: '3381',
            label: 'ชานุมาน',
            zipcode: '37210'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '381',
            value: '3382',
            label: 'โคกสาร',
            zipcode: '37210'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '381',
            value: '3383',
            label: 'คำเขื่อนแก้ว',
            zipcode: '37210'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '381',
            value: '3384',
            label: 'โคกก่ง',
            zipcode: '37210'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '381',
            value: '3385',
            label: 'ป่าก่อ',
            zipcode: '37210'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '382',
            value: '3387',
            label: 'หนองข่า',
            zipcode: '37110'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '382',
            value: '3388',
            label: 'คำโพน',
            zipcode: '37110'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '382',
            value: '3389',
            label: 'นาหว้า',
            zipcode: '37110'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '382',
            value: '3390',
            label: 'ลือ',
            zipcode: '37110'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '382',
            value: '3391',
            label: 'ห้วย',
            zipcode: '37110'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '382',
            value: '3392',
            label: 'โนนงาม',
            zipcode: '37110'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '382',
            value: '3393',
            label: 'นาป่าแซง',
            zipcode: '37110'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '383',
            value: '3394',
            label: 'พนา',
            zipcode: '37180'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '383',
            value: '3395',
            label: 'จานลาน',
            zipcode: '37180'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '383',
            value: '3396',
            label: 'ไม้กลอน',
            zipcode: '37180'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '383',
            value: '3397',
            label: 'พระเหลา',
            zipcode: '37180'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '384',
            value: '3401',
            label: 'เสนางคนิคม',
            zipcode: '37290'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '384',
            value: '3402',
            label: 'โพนทอง',
            zipcode: '37290'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '384',
            value: '3403',
            label: 'ไร่สีสุก',
            zipcode: '37290'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '384',
            value: '3404',
            label: 'นาเวียง',
            zipcode: '37290'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '384',
            value: '3405',
            label: 'หนองไฮ',
            zipcode: '37290'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '384',
            value: '3406',
            label: 'หนองสามสี',
            zipcode: '37290'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '385',
            value: '3407',
            label: 'หัวตะพาน',
            zipcode: '37240'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '385',
            value: '3408',
            label: 'คำพระ',
            zipcode: '37240'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '385',
            value: '3409',
            label: 'เค็งใหญ่',
            zipcode: '37240'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '385',
            value: '3410',
            label: 'หนองแก้ว',
            zipcode: '37240'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '385',
            value: '3411',
            label: 'โพนเมืองน้อย',
            zipcode: '37240'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '385',
            value: '3412',
            label: 'สร้างถ่อน้อย',
            zipcode: '37240'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '385',
            value: '3413',
            label: 'จิกดู่',
            zipcode: '37240'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '385',
            value: '3414',
            label: 'รัตนวารี',
            zipcode: '37240'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '386',
            value: '3415',
            label: 'อำนาจ',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '386',
            value: '3416',
            label: 'ดงมะยาง',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '386',
            value: '3417',
            label: 'เปือย',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '386',
            value: '3418',
            label: 'ดงบัง',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '386',
            value: '3419',
            label: 'ไร่ขี',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '386',
            value: '3420',
            label: 'แมด',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '386',
            value: '3421',
            label: 'โคกกลาง',
            zipcode: '37000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3422',
            label: 'หนองบัว',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3423',
            label: 'หนองภัยศูนย์',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3424',
            label: 'โพธิ์ชัย',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3425',
            label: 'หนองสวรรค์',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3426',
            label: 'หัวนา',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3427',
            label: 'บ้านขาม',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3428',
            label: 'นามะเฟือง',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3429',
            label: 'บ้านพร้าว',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3430',
            label: 'โนนขมิ้น',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3431',
            label: 'ลำภู',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3432',
            label: 'กุดจิก',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3433',
            label: 'โนนทัน',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3434',
            label: 'นาคำไฮ',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3435',
            label: 'ป่าไม้งาม',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '387',
            value: '3436',
            label: 'หนองหว้า',
            zipcode: '39000'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3437',
            label: 'นากลาง',
            zipcode: '39170'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3438',
            label: 'ด่านช้าง',
            zipcode: '39170'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3441',
            label: 'กุดดินจี่',
            zipcode: '39350'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3442',
            label: 'ฝั่งแดง',
            zipcode: '39170'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3443',
            label: 'เก่ากลอย',
            zipcode: '39350'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3445',
            label: 'โนนเมือง',
            zipcode: '39170'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3446',
            label: 'อุทัยสวรรค์',
            zipcode: '39170'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3447',
            label: 'ดงสวรรค์',
            zipcode: '39350'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3449',
            label: 'กุดแห่',
            zipcode: '39170'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '389',
            value: '3456',
            label: 'โนนสัง',
            zipcode: '39140'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '389',
            value: '3457',
            label: 'บ้านถิ่น',
            zipcode: '39140'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '389',
            value: '3458',
            label: 'หนองเรือ',
            zipcode: '39140'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '389',
            value: '3459',
            label: 'กุดดู่',
            zipcode: '39140'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '389',
            value: '3460',
            label: 'บ้านค้อ',
            zipcode: '39140'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '389',
            value: '3461',
            label: 'โนนเมือง',
            zipcode: '39140'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '389',
            value: '3462',
            label: 'โคกใหญ่',
            zipcode: '39140'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '389',
            value: '3463',
            label: 'โคกม่วง',
            zipcode: '39140'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '389',
            value: '3464',
            label: 'นิคมพัฒนา',
            zipcode: '39140'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '389',
            value: '3465',
            label: 'ปางกู่',
            zipcode: '39140'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '390',
            value: '3466',
            label: 'เมืองใหม่',
            zipcode: '39180'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '390',
            value: '3467',
            label: 'ศรีบุญเรือง',
            zipcode: '39180'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '390',
            value: '3468',
            label: 'หนองบัวใต้',
            zipcode: '39180'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '390',
            value: '3469',
            label: 'กุดสะเทียน',
            zipcode: '39180'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '390',
            value: '3470',
            label: 'นากอก',
            zipcode: '39180'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '390',
            value: '3471',
            label: 'โนนสะอาด',
            zipcode: '39180'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '390',
            value: '3472',
            label: 'ยางหล่อ',
            zipcode: '39180'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '390',
            value: '3473',
            label: 'โนนม่วง',
            zipcode: '39180'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '390',
            value: '3474',
            label: 'หนองกุงแก้ว',
            zipcode: '39180'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '390',
            value: '3475',
            label: 'หนองแก',
            zipcode: '39180'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '390',
            value: '3476',
            label: 'ทรายทอง',
            zipcode: '39180'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '390',
            value: '3477',
            label: 'หันนางาม',
            zipcode: '39180'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '391',
            value: '3478',
            label: 'นาสี',
            zipcode: '39270'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '391',
            value: '3479',
            label: 'บ้านโคก',
            zipcode: '39270'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '391',
            value: '3480',
            label: 'นาดี',
            zipcode: '39270'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '391',
            value: '3481',
            label: 'นาด่าน',
            zipcode: '39270'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '391',
            value: '3482',
            label: 'ดงมะไฟ',
            zipcode: '39270'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '391',
            value: '3483',
            label: 'สุวรรณคูหา',
            zipcode: '39270'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '391',
            value: '3484',
            label: 'บุญทัน',
            zipcode: '39270'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '391',
            value: '3485',
            label: 'กุดผึ้ง',
            zipcode: '39270'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '392',
            value: '3486',
            label: 'นาเหล่า',
            zipcode: '39170'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '392',
            value: '3487',
            label: 'นาแก',
            zipcode: '39170'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '392',
            value: '3488',
            label: 'วังทอง',
            zipcode: '39170'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '392',
            value: '3489',
            label: 'วังปลาป้อม',
            zipcode: '39170'
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '392',
            value: '3490',
            label: 'เทพคีรี',
            zipcode: '39170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3491',
            label: 'ในเมือง',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3492',
            label: 'สำราญ',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3493',
            label: 'โคกสี',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3494',
            label: 'ท่าพระ',
            zipcode: '40260'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3495',
            label: 'บ้านทุ่ม',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3496',
            label: 'เมืองเก่า',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3497',
            label: 'พระลับ',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3498',
            label: 'สาวะถี',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3499',
            label: 'บ้านหว้า',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3500',
            label: 'บ้านค้อ',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3501',
            label: 'แดงใหญ่',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3502',
            label: 'ดอนช้าง',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3503',
            label: 'ดอนหัน',
            zipcode: '40260'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3504',
            label: 'ศิลา',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3505',
            label: 'บ้านเป็ด',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3506',
            label: 'หนองตูม',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3507',
            label: 'บึงเนียม',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3508',
            label: 'โนนท่อน',
            zipcode: '40000'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '394',
            value: '3511',
            label: 'หนองบัว',
            zipcode: '40270'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '394',
            value: '3512',
            label: 'ป่าหวายนั่ง',
            zipcode: '40270'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '394',
            value: '3513',
            label: 'โนนฆ้อง',
            zipcode: '40270'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '394',
            value: '3514',
            label: 'บ้านเหล่า',
            zipcode: '40270'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '394',
            value: '3515',
            label: 'ป่ามะนาว',
            zipcode: '40270'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '394',
            value: '3516',
            label: 'บ้านฝาง',
            zipcode: '40270'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '394',
            value: '3517',
            label: 'โคกงาม',
            zipcode: '40270'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '395',
            value: '3518',
            label: 'พระยืน',
            zipcode: '40320'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '395',
            value: '3519',
            label: 'พระบุ',
            zipcode: '40320'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '395',
            value: '3520',
            label: 'บ้านโต้น',
            zipcode: '40320'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '395',
            value: '3521',
            label: 'หนองแวง',
            zipcode: '40320'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '395',
            value: '3522',
            label: 'ขามป้อม',
            zipcode: '40320'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '396',
            value: '3523',
            label: 'หนองเรือ',
            zipcode: '40210'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '396',
            value: '3524',
            label: 'บ้านเม็ง',
            zipcode: '40210'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '396',
            value: '3525',
            label: 'บ้านกง',
            zipcode: '40240'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '396',
            value: '3526',
            label: 'ยางคำ',
            zipcode: '40240'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '396',
            value: '3527',
            label: 'จระเข้',
            zipcode: '40240'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '396',
            value: '3528',
            label: 'โนนทอง',
            zipcode: '40210'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '396',
            value: '3529',
            label: 'กุดกว้าง',
            zipcode: '40210'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '396',
            value: '3530',
            label: 'โนนทัน',
            zipcode: '40210'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '396',
            value: '3531',
            label: 'โนนสะอาด',
            zipcode: '40210'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '396',
            value: '3532',
            label: 'บ้านผือ',
            zipcode: '40240'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '397',
            value: '3533',
            label: 'ชุมแพ',
            zipcode: '40130'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '397',
            value: '3534',
            label: 'โนนหัน',
            zipcode: '40290'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '397',
            value: '3535',
            label: 'นาหนองทุ่ม',
            zipcode: '40290'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '397',
            value: '3536',
            label: 'โนนอุดม',
            zipcode: '40130'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '397',
            value: '3537',
            label: 'ขัวเรียง',
            zipcode: '40130'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '397',
            value: '3538',
            label: 'หนองไผ่',
            zipcode: '40130'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '397',
            value: '3539',
            label: 'ไชยสอ',
            zipcode: '40130'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '397',
            value: '3540',
            label: 'วังหินลาด',
            zipcode: '40130'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '397',
            value: '3541',
            label: 'นาเพียง',
            zipcode: '40130'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '397',
            value: '3542',
            label: 'หนองเขียด',
            zipcode: '40290'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '397',
            value: '3543',
            label: 'หนองเสาเล้า',
            zipcode: '40130'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '397',
            value: '3544',
            label: 'โนนสะอาด',
            zipcode: '40290'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '398',
            value: '3545',
            label: 'สีชมพู',
            zipcode: '40220'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '398',
            value: '3546',
            label: 'ศรีสุข',
            zipcode: '40220'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '398',
            value: '3547',
            label: 'นาจาน',
            zipcode: '40220'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '398',
            value: '3548',
            label: 'วังเพิ่ม',
            zipcode: '40220'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '398',
            value: '3549',
            label: 'ซำยาง',
            zipcode: '40220'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '398',
            value: '3550',
            label: 'หนองแดง',
            zipcode: '40220'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '398',
            value: '3551',
            label: 'ดงลาน',
            zipcode: '40220'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '398',
            value: '3552',
            label: 'บริบูรณ์',
            zipcode: '40220'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '398',
            value: '3553',
            label: 'บ้านใหม่',
            zipcode: '40220'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '398',
            value: '3554',
            label: 'ภูห่าน',
            zipcode: '40220'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '399',
            value: '3555',
            label: 'น้ำพอง',
            zipcode: '40140'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '399',
            value: '3556',
            label: 'วังชัย',
            zipcode: '40140'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '399',
            value: '3557',
            label: 'หนองกุง',
            zipcode: '40140'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '399',
            value: '3558',
            label: 'บัวใหญ่',
            zipcode: '40140'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '399',
            value: '3559',
            label: 'สะอาด',
            zipcode: '40310'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '399',
            value: '3560',
            label: 'ม่วงหวาน',
            zipcode: '40310'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '399',
            value: '3561',
            label: 'บ้านขาม',
            zipcode: '40140'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '399',
            value: '3562',
            label: 'บัวเงิน',
            zipcode: '40140'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '399',
            value: '3563',
            label: 'ทรายมูล',
            zipcode: '40140'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '399',
            value: '3564',
            label: 'ท่ากระเสริม',
            zipcode: '40140'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '399',
            value: '3565',
            label: 'พังทุย',
            zipcode: '40140'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '399',
            value: '3566',
            label: 'กุดน้ำใส',
            zipcode: '40140'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '400',
            value: '3567',
            label: 'โคกสูง',
            zipcode: '40250'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '400',
            value: '3568',
            label: 'บ้านดง',
            zipcode: '40250'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '400',
            value: '3569',
            label: 'เขื่อนอุบลรัตน์',
            zipcode: '40250'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '400',
            value: '3570',
            label: 'นาคำ',
            zipcode: '40250'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '400',
            value: '3571',
            label: 'ศรีสุขสำราญ',
            zipcode: '40250'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '400',
            value: '3572',
            label: 'ทุ่งโป่ง',
            zipcode: '40250'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3573',
            label: 'หนองโก',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3574',
            label: 'หนองกุงใหญ่',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3577',
            label: 'ห้วยโจด',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3578',
            label: 'ห้วยยาง',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3579',
            label: 'บ้านฝาง',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3581',
            label: 'ดูนสาด',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3582',
            label: 'หนองโน',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3583',
            label: 'น้ำอ้อม',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3584',
            label: 'หัวนาคำ',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3587',
            label: 'บ้านไผ่',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3588',
            label: 'ในเมือง',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3591',
            label: 'เมืองเพีย',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3595',
            label: 'บ้านลาน',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3596',
            label: 'แคนเหนือ',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3597',
            label: 'ภูเหล็ก',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3599',
            label: 'ป่าปอ',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3600',
            label: 'หินตั้ง',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3602',
            label: 'หนองน้ำใส',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3603',
            label: 'หัวหนอง',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '403',
            value: '3611',
            label: 'เปือยน้อย',
            zipcode: '40340'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '403',
            value: '3612',
            label: 'วังม่วง',
            zipcode: '40340'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '403',
            value: '3613',
            label: 'ขามป้อม',
            zipcode: '40340'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '403',
            value: '3614',
            label: 'สระแก้ว',
            zipcode: '40340'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3615',
            label: 'เมืองพล',
            zipcode: '40120'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3616',
            label: 'โจดหนองแก',
            zipcode: '40120'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3617',
            label: 'เก่างิ้ว',
            zipcode: '40120'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3618',
            label: 'หนองมะเขือ',
            zipcode: '40120'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3619',
            label: 'หนองแวงโสกพระ',
            zipcode: '40120'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3620',
            label: 'เพ็กใหญ่',
            zipcode: '40120'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3621',
            label: 'โคกสง่า',
            zipcode: '40120'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3622',
            label: 'หนองแวงนางเบ้า',
            zipcode: '40120'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3623',
            label: 'ลอมคอม',
            zipcode: '40120'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3624',
            label: 'โนนข่า',
            zipcode: '40120'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3625',
            label: 'โสกนกเต็น',
            zipcode: '40120'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3626',
            label: 'หัวทุ่ง',
            zipcode: '40120'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '405',
            value: '3637',
            label: 'คอนฉิม',
            zipcode: '40330'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '405',
            value: '3638',
            label: 'ใหม่นาเพียง',
            zipcode: '40330'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '405',
            value: '3639',
            label: 'โนนทอง',
            zipcode: '40330'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '405',
            value: '3640',
            label: 'แวงใหญ่',
            zipcode: '40330'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '405',
            value: '3641',
            label: 'โนนสะอาด',
            zipcode: '40330'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '406',
            value: '3642',
            label: 'แวงน้อย',
            zipcode: '40230'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '406',
            value: '3643',
            label: 'ก้านเหลือง',
            zipcode: '40230'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '406',
            value: '3644',
            label: 'ท่านางแนว',
            zipcode: '40230'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '406',
            value: '3645',
            label: 'ละหานนา',
            zipcode: '40230'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '406',
            value: '3646',
            label: 'ท่าวัด',
            zipcode: '40230'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '406',
            value: '3647',
            label: 'ทางขวาง',
            zipcode: '40230'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '407',
            value: '3648',
            label: 'หนองสองห้อง',
            zipcode: '40190'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '407',
            value: '3649',
            label: 'คึมชาด',
            zipcode: '40190'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '407',
            value: '3650',
            label: 'โนนธาตุ',
            zipcode: '40190'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '407',
            value: '3651',
            label: 'ตะกั่วป่า',
            zipcode: '40190'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '407',
            value: '3652',
            label: 'สำโรง',
            zipcode: '40190'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '407',
            value: '3653',
            label: 'หนองเม็ก',
            zipcode: '40190'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '407',
            value: '3654',
            label: 'ดอนดู่',
            zipcode: '40190'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '407',
            value: '3655',
            label: 'ดงเค็ง',
            zipcode: '40190'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '407',
            value: '3656',
            label: 'หันโจด',
            zipcode: '40190'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '407',
            value: '3657',
            label: 'ดอนดั่ง',
            zipcode: '40190'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '407',
            value: '3658',
            label: 'วังหิน',
            zipcode: '40190'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '407',
            value: '3659',
            label: 'หนองไผ่ล้อม',
            zipcode: '40190'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3660',
            label: 'บ้านเรือ',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3661',
            label: 'ในเมือง',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3663',
            label: 'หว้าทอง',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3664',
            label: 'กุดขอนแก่น',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3665',
            label: 'นาชุมแสง',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3666',
            label: 'นาหว้า',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3667',
            label: 'เขาน้อย',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3669',
            label: 'หนองกุงธนสาร',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3671',
            label: 'หนองกุงเซิน',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3672',
            label: 'สงเปือย',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3673',
            label: 'ทุ่งชมพู',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3674',
            label: 'เมืองเก่าพัฒนา',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3675',
            label: 'ดินดำ',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3676',
            label: 'ภูเวียง',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '409',
            value: '3677',
            label: 'กุดเค้า',
            zipcode: '40160'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '409',
            value: '3678',
            label: 'สวนหม่อน',
            zipcode: '40160'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '409',
            value: '3679',
            label: 'หนองแปน',
            zipcode: '40160'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '409',
            value: '3680',
            label: 'โพนเพ็ก',
            zipcode: '40160'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '409',
            value: '3681',
            label: 'คำแคน',
            zipcode: '40160'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '409',
            value: '3682',
            label: 'นาข่า',
            zipcode: '40160'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '409',
            value: '3683',
            label: 'นางาม',
            zipcode: '40160'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '409',
            value: '3686',
            label: 'ท่าศาลา',
            zipcode: '40160'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '410',
            value: '3689',
            label: 'ชนบท',
            zipcode: '40180'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '410',
            value: '3690',
            label: 'กุดเพียขอม',
            zipcode: '40180'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '410',
            value: '3691',
            label: 'วังแสง',
            zipcode: '40180'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '410',
            value: '3692',
            label: 'ห้วยแก',
            zipcode: '40180'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '410',
            value: '3693',
            label: 'บ้านแท่น',
            zipcode: '40180'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '410',
            value: '3694',
            label: 'ศรีบุญเรือง',
            zipcode: '40180'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '410',
            value: '3695',
            label: 'โนนพะยอม',
            zipcode: '40180'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '410',
            value: '3696',
            label: 'ปอแดง',
            zipcode: '40180'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '411',
            value: '3697',
            label: 'เขาสวนกวาง',
            zipcode: '40280'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '411',
            value: '3698',
            label: 'ดงเมืองแอม',
            zipcode: '40280'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '411',
            value: '3699',
            label: 'นางิ้ว',
            zipcode: '40280'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '411',
            value: '3700',
            label: 'โนนสมบูรณ์',
            zipcode: '40280'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '411',
            value: '3701',
            label: 'คำม่วง',
            zipcode: '40280'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '412',
            value: '3702',
            label: 'โนนคอม',
            zipcode: '40350'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '412',
            value: '3703',
            label: 'นาฝาย',
            zipcode: '40350'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '412',
            value: '3704',
            label: 'ภูผาม่าน',
            zipcode: '40350'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '412',
            value: '3705',
            label: 'วังสวาบ',
            zipcode: '40350'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '412',
            value: '3706',
            label: 'ห้วยม่วง',
            zipcode: '40350'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '413',
            value: '3707',
            label: 'กระนวน',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '413',
            value: '3708',
            label: 'คำแมด',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '413',
            value: '3709',
            label: 'บ้านโนน',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '413',
            value: '3710',
            label: 'คูคำ',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '413',
            value: '3711',
            label: 'ห้วยเตย',
            zipcode: '40170'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '414',
            value: '3712',
            label: 'บ้านโคก',
            zipcode: '40160'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '414',
            value: '3713',
            label: 'โพธิ์ไชย',
            zipcode: '40160'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '414',
            value: '3714',
            label: 'ซับสมบูรณ์',
            zipcode: '40160'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '414',
            value: '3715',
            label: 'นาแพง',
            zipcode: '40160'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '415',
            value: '3716',
            label: 'กุดธาตุ',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '415',
            value: '3717',
            label: 'บ้านโคก',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '415',
            value: '3718',
            label: 'ขนวน',
            zipcode: '40150'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '416',
            value: '3719',
            label: 'บ้านแฮด',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '416',
            value: '3720',
            label: 'โคกสำราญ',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '416',
            value: '3721',
            label: 'โนนสมบูรณ์',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '416',
            value: '3722',
            label: 'หนองแซง',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '417',
            value: '3723',
            label: 'โนนศิลา',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '417',
            value: '3724',
            label: 'หนองปลาหมอ',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '417',
            value: '3725',
            label: 'บ้านหัน',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '417',
            value: '3726',
            label: 'เปือยใหญ่',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '417',
            value: '3727',
            label: 'โนนแดง',
            zipcode: '40110'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3732',
            label: 'หมากแข้ง',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3733',
            label: 'นิคมสงเคราะห์',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3734',
            label: 'บ้านขาว',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3735',
            label: 'หนองบัว',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3736',
            label: 'บ้านตาด',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3737',
            label: 'โนนสูง',
            zipcode: '41330'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3738',
            label: 'หมูม่น',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3739',
            label: 'เชียงยืน',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3740',
            label: 'หนองนาคำ',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3741',
            label: 'กุดสระ',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3742',
            label: 'นาดี',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3743',
            label: 'บ้านเลื่อม',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3744',
            label: 'เชียงพิณ',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3745',
            label: 'สามพร้าว',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3746',
            label: 'หนองไฮ',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3747',
            label: 'นาข่า',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3748',
            label: 'บ้านจั่น',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3749',
            label: 'หนองขอนกว้าง',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3750',
            label: 'โคกสะอาด',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3751',
            label: 'นากว้าง',
            zipcode: '41000'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3752',
            label: 'หนองไผ่',
            zipcode: '41330'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '422',
            value: '3761',
            label: 'กุดจับ',
            zipcode: '41250'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '422',
            value: '3762',
            label: 'ปะโค',
            zipcode: '41250'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '422',
            value: '3763',
            label: 'ขอนยูง',
            zipcode: '41250'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '422',
            value: '3764',
            label: 'เชียงเพ็ง',
            zipcode: '41250'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '422',
            value: '3765',
            label: 'สร้างก่อ',
            zipcode: '41250'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '422',
            value: '3766',
            label: 'เมืองเพีย',
            zipcode: '41250'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '422',
            value: '3767',
            label: 'ตาลเลียน',
            zipcode: '41250'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '423',
            value: '3768',
            label: 'หมากหญ้า',
            zipcode: '41360'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '423',
            value: '3769',
            label: 'หนองอ้อ',
            zipcode: '41220'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '423',
            value: '3770',
            label: 'อูบมุง',
            zipcode: '41220'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '423',
            value: '3771',
            label: 'กุดหมากไฟ',
            zipcode: '41220'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '423',
            value: '3772',
            label: 'น้ำพ่น',
            zipcode: '41360'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '423',
            value: '3773',
            label: 'หนองบัวบาน',
            zipcode: '41360'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '423',
            value: '3774',
            label: 'โนนหวาย',
            zipcode: '41220'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '423',
            value: '3775',
            label: 'หนองวัวซอ',
            zipcode: '41360'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3776',
            label: 'ตูมใต้',
            zipcode: '41110'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3777',
            label: 'พันดอน',
            zipcode: '41370'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3778',
            label: 'เวียงคำ',
            zipcode: '41110'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3779',
            label: 'แชแล',
            zipcode: '41110'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3781',
            label: 'เชียงแหว',
            zipcode: '41110'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3782',
            label: 'ห้วยเกิ้ง',
            zipcode: '41110'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3784',
            label: 'เสอเพลอ',
            zipcode: '41370'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3785',
            label: 'สีออ',
            zipcode: '41110'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3786',
            label: 'ปะโค',
            zipcode: '41370'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3788',
            label: 'ผาสุก',
            zipcode: '41370'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3789',
            label: 'ท่าลี่',
            zipcode: '41110'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3790',
            label: 'กุมภวาปี',
            zipcode: '41110'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3791',
            label: 'หนองหว้า',
            zipcode: '41110'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '425',
            value: '3797',
            label: 'โนนสะอาด',
            zipcode: '41240'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '425',
            value: '3798',
            label: 'บุ่งแก้ว',
            zipcode: '41240'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '425',
            value: '3799',
            label: 'โพธิ์ศรีสำราญ',
            zipcode: '41240'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '425',
            value: '3800',
            label: 'ทมนางาม',
            zipcode: '41240'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '425',
            value: '3801',
            label: 'หนองกุงศรี',
            zipcode: '41240'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '425',
            value: '3802',
            label: 'โคกกลาง',
            zipcode: '41240'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3803',
            label: 'หนองหาน',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3804',
            label: 'หนองเม็ก',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3807',
            label: 'พังงู',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3808',
            label: 'สะแบง',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3809',
            label: 'สร้อยพร้าว',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3811',
            label: 'บ้านเชียง',
            zipcode: '41320'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3812',
            label: 'บ้านยา',
            zipcode: '41320'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3813',
            label: 'โพนงาม',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3814',
            label: 'ผักตบ',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3816',
            label: 'หนองไผ่',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3819',
            label: 'ดอนหายโศก',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3820',
            label: 'หนองสระปลา',
            zipcode: '41320'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '427',
            value: '3828',
            label: 'ทุ่งฝน',
            zipcode: '41310'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '427',
            value: '3829',
            label: 'ทุ่งใหญ่',
            zipcode: '41310'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '427',
            value: '3830',
            label: 'นาชุมแสง',
            zipcode: '41310'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '427',
            value: '3831',
            label: 'นาทม',
            zipcode: '41310'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '428',
            value: '3832',
            label: 'ไชยวาน',
            zipcode: '41290'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '428',
            value: '3833',
            label: 'หนองหลัก',
            zipcode: '41290'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '428',
            value: '3834',
            label: 'คำเลาะ',
            zipcode: '41290'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '428',
            value: '3835',
            label: 'โพนสูง',
            zipcode: '41290'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '429',
            value: '3836',
            label: 'ศรีธาตุ',
            zipcode: '41230'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '429',
            value: '3837',
            label: 'จำปี',
            zipcode: '41230'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '429',
            value: '3838',
            label: 'บ้านโปร่ง',
            zipcode: '41230'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '429',
            value: '3839',
            label: 'หัวนาคำ',
            zipcode: '41230'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '429',
            value: '3840',
            label: 'หนองนกเขียน',
            zipcode: '41230'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '429',
            value: '3841',
            label: 'นายูง',
            zipcode: '41230'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '429',
            value: '3842',
            label: 'ตาดทอง',
            zipcode: '41230'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '430',
            value: '3843',
            label: 'หนองกุงทับม้า',
            zipcode: '41280'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '430',
            value: '3844',
            label: 'หนองหญ้าไซ',
            zipcode: '41280'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '430',
            value: '3845',
            label: 'บะยาว',
            zipcode: '41280'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '430',
            value: '3846',
            label: 'ผาสุก',
            zipcode: '41280'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '430',
            value: '3847',
            label: 'คำโคกสูง',
            zipcode: '41280'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '430',
            value: '3848',
            label: 'วังสามหมอ',
            zipcode: '41280'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3849',
            label: 'ศรีสุทโธ',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3850',
            label: 'บ้านดุง',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3851',
            label: 'ดงเย็น',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3852',
            label: 'โพนสูง',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3853',
            label: 'อ้อมกอ',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3854',
            label: 'บ้านจันทน์',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3855',
            label: 'บ้านชัย',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3856',
            label: 'นาไหม',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3857',
            label: 'ถ่อนนาลับ',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3858',
            label: 'วังทอง',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3859',
            label: 'บ้านม่วง',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3860',
            label: 'บ้านตาด',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '431',
            value: '3861',
            label: 'นาคำ',
            zipcode: '41190'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3928',
            label: 'บ้านผือ',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3929',
            label: 'หายโศก',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3930',
            label: 'เขือน้ำ',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3931',
            label: 'คำบง',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3932',
            label: 'โนนทอง',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3933',
            label: 'ข้าวสาร',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3934',
            label: 'จำปาโมง',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3935',
            label: 'กลางใหญ่',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3936',
            label: 'เมืองพาน',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3937',
            label: 'คำด้วง',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3938',
            label: 'หนองหัวคู',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3939',
            label: 'บ้านค้อ',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3940',
            label: 'หนองแวง',
            zipcode: '41160'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3942',
            label: 'นางัว',
            zipcode: '41210'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3943',
            label: 'น้ำโสม',
            zipcode: '41210'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3946',
            label: 'หนองแวง',
            zipcode: '41210'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3947',
            label: 'บ้านหยวก',
            zipcode: '41210'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3948',
            label: 'โสมเยี่ยม',
            zipcode: '41210'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3951',
            label: 'ศรีสำราญ',
            zipcode: '41210'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3953',
            label: 'สามัคคี',
            zipcode: '41210'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3956',
            label: 'เพ็ญ',
            zipcode: '41150'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3957',
            label: 'บ้านธาตุ',
            zipcode: '41150'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3958',
            label: 'นาพู่',
            zipcode: '41150'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3959',
            label: 'เชียงหวาง',
            zipcode: '41150'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3960',
            label: 'สุมเส้า',
            zipcode: '41150'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3961',
            label: 'นาบัว',
            zipcode: '41150'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3962',
            label: 'บ้านเหล่า',
            zipcode: '41150'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3963',
            label: 'จอมศรี',
            zipcode: '41150'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3964',
            label: 'เตาไห',
            zipcode: '41150'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3965',
            label: 'โคกกลาง',
            zipcode: '41150'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3966',
            label: 'สร้างแป้น',
            zipcode: '41150'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '440',
            value: '3970',
            label: 'สร้างคอม',
            zipcode: '41260'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '440',
            value: '3971',
            label: 'เชียงดา',
            zipcode: '41260'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '440',
            value: '3972',
            label: 'บ้านยวด',
            zipcode: '41260'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '440',
            value: '3973',
            label: 'บ้านโคก',
            zipcode: '41260'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '440',
            value: '3974',
            label: 'นาสะอาด',
            zipcode: '41260'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '440',
            value: '3975',
            label: 'บ้านหินโงม',
            zipcode: '41260'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '441',
            value: '3976',
            label: 'หนองแสง',
            zipcode: '41340'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '441',
            value: '3977',
            label: 'แสงสว่าง',
            zipcode: '41340'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '441',
            value: '3978',
            label: 'นาดี',
            zipcode: '41340'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '441',
            value: '3979',
            label: 'ทับกุง',
            zipcode: '41340'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '442',
            value: '3980',
            label: 'นายูง',
            zipcode: '41380'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '442',
            value: '3981',
            label: 'บ้านก้อง',
            zipcode: '41380'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '442',
            value: '3982',
            label: 'นาแค',
            zipcode: '41380'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '442',
            value: '3983',
            label: 'โนนทอง',
            zipcode: '41380'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '443',
            value: '3984',
            label: 'บ้านแดง',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '443',
            value: '3985',
            label: 'นาทราย',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '443',
            value: '3986',
            label: 'ดอนกลอย',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '444',
            value: '3987',
            label: 'บ้านจีต',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '444',
            value: '3988',
            label: 'โนนทองอินทร์',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '444',
            value: '3989',
            label: 'ค้อใหญ่',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '444',
            value: '3990',
            label: 'คอนสาย',
            zipcode: '41130'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '445',
            value: '3991',
            label: 'นาม่วง',
            zipcode: '41110'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '445',
            value: '3992',
            label: 'ห้วยสามพาด',
            zipcode: '41110'
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '445',
            value: '3993',
            label: 'อุ่มจาน',
            zipcode: '41110'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '3994',
            label: 'กุดป่อง',
            zipcode: '42000'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '3995',
            label: 'เมือง',
            zipcode: '42000'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '3996',
            label: 'นาอ้อ',
            zipcode: '42100'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '3997',
            label: 'กกดู่',
            zipcode: '42000'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '3998',
            label: 'น้ำหมาน',
            zipcode: '42000'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '3999',
            label: 'เสี้ยว',
            zipcode: '42000'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '4000',
            label: 'นาอาน',
            zipcode: '42000'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '4001',
            label: 'นาโป่ง',
            zipcode: '42000'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '4002',
            label: 'นาดินดำ',
            zipcode: '42000'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '4003',
            label: 'น้ำสวย',
            zipcode: '42000'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '4004',
            label: 'ชัยพฤกษ์',
            zipcode: '42000'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '4005',
            label: 'นาแขม',
            zipcode: '42000'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '4006',
            label: 'ศรีสองรัก',
            zipcode: '42100'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '446',
            value: '4007',
            label: 'กกทอง',
            zipcode: '42000'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '447',
            value: '4008',
            label: 'นาด้วง',
            zipcode: '42210'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '447',
            value: '4009',
            label: 'นาดอกคำ',
            zipcode: '42210'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '447',
            value: '4010',
            label: 'ท่าสะอาด',
            zipcode: '42210'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '447',
            value: '4011',
            label: 'ท่าสวรรค์',
            zipcode: '42210'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '448',
            value: '4012',
            label: 'เชียงคาน',
            zipcode: '42110'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '448',
            value: '4013',
            label: 'ธาตุ',
            zipcode: '42110'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '448',
            value: '4014',
            label: 'นาซ่าว',
            zipcode: '42110'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '448',
            value: '4015',
            label: 'เขาแก้ว',
            zipcode: '42110'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '448',
            value: '4016',
            label: 'ปากตม',
            zipcode: '42110'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '448',
            value: '4017',
            label: 'บุฮม',
            zipcode: '42110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '2',
            value: '17',
            label: 'บางซื่อ',
            zipcode: '10800'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '2',
            value: '19',
            label: 'สามเสนใน',
            zipcode: '10400'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '5',
            value: '33',
            label: 'ลาดยาว',
            zipcode: '10900'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '5',
            value: '35',
            label: 'คลองถนน',
            zipcode: '10220'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '5',
            value: '36',
            label: 'ตลาดบางเขน',
            zipcode: '10210'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '5',
            value: '37',
            label: 'สีกัน',
            zipcode: '10210'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '5',
            value: '38',
            label: 'สายไหม',
            zipcode: '10220'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '5',
            value: '39',
            label: 'ทุ่งสองห้อง',
            zipcode: '10210'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '5',
            value: '41',
            label: 'ออเงิน',
            zipcode: '10220'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '5',
            value: '42',
            label: 'บางเขน',
            zipcode: '11000'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '6',
            value: '44',
            label: 'วังทองหลาง',
            zipcode: '10310'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '6',
            value: '45',
            label: 'ลาดพร้าว',
            zipcode: '10230'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '6',
            value: '46',
            label: 'คลองกุ่ม',
            zipcode: '10240'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '6',
            value: '47',
            label: 'สะพานสูง',
            zipcode: '10240'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '6',
            value: '48',
            label: 'คันนายาว',
            zipcode: '10230'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '6',
            value: '49',
            label: 'จรเข้บัว',
            zipcode: '10230'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '8',
            value: '60',
            label: 'นางเลิ้ง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '9',
            value: '61',
            label: 'คลองเตย',
            zipcode: '10110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '9',
            value: '62',
            label: 'คลองตัน',
            zipcode: '10110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '9',
            value: '63',
            label: 'พระโขนง',
            zipcode: '10110'
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '9',
            value: '64',
            label: 'บางนา',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '9',
            value: '66',
            label: 'สวนหลวง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '9',
            value: '67',
            label: 'หนองบอน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '9',
            value: '68',
            label: 'ประเวศ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '9',
            value: '69',
            label: 'ดอกไม้',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '9',
            value: '70',
            label: 'พระโขนง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '9',
            value: '71',
            label: 'คลองตัน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '9',
            value: '72',
            label: 'คลองเตย',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '10',
            value: '75',
            label: 'บางชัน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '10',
            value: '76',
            label: 'ทรายกองดิน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '10',
            value: '77',
            label: 'ทรายกองดินใต้',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '10',
            value: '78',
            label: 'สามวาตะวันออก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '10',
            value: '79',
            label: 'สามวาตะวันตก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '12',
            value: '86',
            label: 'ทุ่งวัดดอน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '12',
            value: '87',
            label: 'ยานนาวา',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '12',
            value: '90',
            label: 'วัดพระยาไกร',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '12',
            value: '91',
            label: 'บางโคล่',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '12',
            value: '92',
            label: 'บางคอแหลม',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '12',
            value: '93',
            label: 'ทุ่งมหาเมฆ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '12',
            value: '94',
            label: 'บางโคล่',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '12',
            value: '95',
            label: 'บางคอแหลม',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '14',
            value: '100',
            label: 'ถนนเพชรบุรี',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '14',
            value: '101',
            label: 'ทุ่งพญาไท',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '14',
            value: '102',
            label: 'มักกะสัน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '14',
            value: '103',
            label: 'ถนนพญาไท',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '14',
            value: '104',
            label: 'ทุ่งพญาไท',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '15',
            value: '112',
            label: 'คลองสาน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '17',
            value: '117',
            label: 'ดินแดง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '19',
            value: '128',
            label: 'ทวีวัฒนา',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '19',
            value: '130',
            label: 'ศาลาธรรมสพน์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '20',
            value: '131',
            label: 'บางพลัด',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '20',
            value: '132',
            label: 'บางบำหรุ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '20',
            value: '133',
            label: 'บางอ้อ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '20',
            value: '138',
            label: 'บางยี่ขัน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '20',
            value: '140',
            label: 'บางยี่ขัน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '20',
            value: '141',
            label: 'บางบำหรุ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '20',
            value: '142',
            label: 'บางอ้อ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '21',
            value: '143',
            label: 'บางค้อ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '21',
            value: '144',
            label: 'จอมทอง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '21',
            value: '145',
            label: 'บางขุนเทียน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '21',
            value: '146',
            label: 'บางบอน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '21',
            value: '148',
            label: 'บางมด',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '23',
            value: '160',
            label: 'หลักสอง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '36',
            value: '202',
            label: 'ตลาดบางเขน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '1',
            districtid: '36',
            value: '204',
            label: 'ทุ่งสองห้อง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '241',
            label: 'นาเกลือ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '242',
            label: 'แหลมฟ้าผ่า',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '243',
            label: 'ในคลองบางปลากด',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '245',
            label: 'ปากคลองบางปลากด',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '254',
            label: 'บางปูเก่า',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '255',
            label: 'ในคลองบางปลากด',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '256',
            label: 'ปากคลองบางปลากด',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '257',
            label: 'แหลมฟ้าผ่า',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '258',
            label: 'บ้านคลองสวน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '52',
            value: '259',
            label: 'นาเกลือ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '53',
            value: '268',
            label: 'คลองนิยม',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '54',
            value: '273',
            label: 'บางเสาธง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '54',
            value: '274',
            label: 'ศรีษะจรเข้ใหญ่',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '2',
            districtid: '54',
            value: '275',
            label: 'ศรีษะจรเข้น้อย',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '5',
            districtid: '74',
            value: '434',
            label: 'จำปา',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '97',
            value: '708',
            label: 'ทะเลชุบศร',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '740',
            label: 'ชอนสารเดช',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '741',
            label: 'หนองม่วง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '742',
            label: 'บ่อทอง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '743',
            label: 'ยางโทน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '744',
            label: 'ชอนสมบูรณ์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '745',
            label: 'โคกเจริญ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '746',
            label: 'ยางราก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '748',
            label: 'ดงดินแดง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '750',
            label: 'หนองมะค่า',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '752',
            label: 'วังทอง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '753',
            label: 'ชอนสารเดช',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '754',
            label: 'ยางโทน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '755',
            label: 'ชอนสมบูรณ์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '756',
            label: 'ดงดินแดง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '757',
            label: 'บ่อทอง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '758',
            label: 'หนองม่วง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '759',
            label: 'ยางราก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '760',
            label: 'โคกเจริญ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '761',
            label: 'ทุ่งท่าช้าง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '762',
            label: 'มหาโพธิ์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '99',
            value: '763',
            label: 'สระโบสถ์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '776',
            label: 'หนองรี',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '778',
            label: 'กุดตาเพชร',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '779',
            label: 'ลำสนธิ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '783',
            label: 'ซับสมบูรณ์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '784',
            label: 'เขารวก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '786',
            label: 'เขาฉกรรจ์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '787',
            label: 'กุดตาเพชร',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '788',
            label: 'หนองรี',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '789',
            label: 'ลำสนธิ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '790',
            label: 'หนองผักแว่น',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '791',
            label: 'ซับจำปา',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '792',
            label: 'แก่งผักกูด',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '7',
            districtid: '100',
            value: '793',
            label: 'ท่าหลวง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '117',
            value: '918',
            label: 'หนองมะโมง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '117',
            value: '921',
            label: 'กุดจอก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '117',
            value: '922',
            label: 'วังตะเคียน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '117',
            value: '923',
            label: 'สะพานหิน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '120',
            value: '942',
            label: 'เนินขาม',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '120',
            value: '943',
            label: 'สุขเดือนห้า',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '9',
            districtid: '120',
            value: '949',
            label: 'กะบกเตี้ย',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '959',
            label: 'หน้าพระลาน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '960',
            label: 'พุแค',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '961',
            label: 'ห้วยบง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '972',
            label: 'บ้านแก้ง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '973',
            label: 'ผึ้งรวง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '123',
            value: '974',
            label: 'เขาดินพัฒนา',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1031',
            label: 'ดงตะงาว',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1032',
            label: 'บ้านหลวง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1033',
            label: 'ไผ่หลิ่ว',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '128',
            value: '1034',
            label: 'ดอนพุด',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '133',
            value: '1066',
            label: 'แสลงพัน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '133',
            value: '1069',
            label: 'คำพราน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '10',
            districtid: '133',
            value: '1071',
            label: 'วังม่วง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '136',
            value: '1100',
            label: 'เทศบาลเมืองชลบุรี',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '137',
            value: '1109',
            label: 'หนองเสือช้าง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '137',
            value: '1110',
            label: 'คลองพลู',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '137',
            value: '1111',
            label: 'หนองใหญ่',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1147',
            label: 'ท่าบุญมี',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1154',
            label: 'เกาะจันทร์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1158',
            label: 'บ้านเซิด',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1159',
            label: 'พูนพัฒนาทรัพย์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1160',
            label: 'บ่อกวางทอง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1161',
            label: 'วัดสุวรรณ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '141',
            value: '1162',
            label: 'บ่อทอง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '142',
            value: '1171',
            label: 'ท่าเทววงษ์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '11',
            districtid: '148',
            value: '1186',
            label: 'หนองปรือ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1202',
            label: 'สำนักท้อน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '151',
            value: '1203',
            label: 'พลา',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1220',
            label: 'น้ำเป็น',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1221',
            label: 'ชำฆ้อ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1222',
            label: 'ห้วยทับมอญ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1225',
            label: 'เขาน้อย',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1226',
            label: 'ชุมแสง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '153',
            value: '1227',
            label: 'วังจันทร์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1239',
            label: 'มาบข่า',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1240',
            label: 'พนานิคม',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1241',
            label: 'นิคมพัฒนา',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1242',
            label: 'มะขามคู่',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1243',
            label: 'หนองไร่',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1244',
            label: 'มาบยางพร',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1245',
            label: 'แม่น้ำคู้',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1246',
            label: 'ละหาร',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1247',
            label: 'ตาสิทธิ์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '12',
            districtid: '155',
            value: '1248',
            label: 'ปลวกแดง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1295',
            label: 'วังโตนด',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1300',
            label: 'กระแจะ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1301',
            label: 'สนามไชย',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1302',
            label: 'ช้างข้าม',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1303',
            label: 'นายายอาม',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1304',
            label: 'แก่งหางแมว',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1305',
            label: 'สามพี่น้อง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1306',
            label: 'เขาวงกต',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1307',
            label: 'พวา',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1308',
            label: 'ขุนซ่อง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1310',
            label: 'กระแจะ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1311',
            label: 'สนามไช',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1312',
            label: 'ช้างข้าม',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1313',
            label: 'วังโตนด',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1314',
            label: 'นายายอาม',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '162',
            value: '1315',
            label: 'แก่งหางแมว',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '163',
            value: '1318',
            label: 'ทรายขาว',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '163',
            value: '1320',
            label: 'ปะตง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '163',
            value: '1321',
            label: 'ทุ่งขนาน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '163',
            value: '1322',
            label: 'สะตอน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '163',
            value: '1323',
            label: 'ทับช้าง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '164',
            value: '1330',
            label: 'พลวง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '164',
            value: '1332',
            label: 'ตะเคียนทอง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '164',
            value: '1334',
            label: 'คลองพลู',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '13',
            districtid: '164',
            value: '1335',
            label: 'ซากไทย',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1389',
            label: 'บ่อไร่',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1390',
            label: 'ด่านชุมพล',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1391',
            label: 'หนองบอน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1392',
            label: 'ช้างทูน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '173',
            value: '1393',
            label: 'บ่อพลอย',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '175',
            value: '1402',
            label: 'เกาะช้าง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '175',
            value: '1403',
            label: 'เกาะหมาก',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '175',
            value: '1404',
            label: 'เกาะกูด',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '14',
            districtid: '175',
            value: '1406',
            label: 'เกาะช้างใต้',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1431',
            label: 'ก้อนแก้ว',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1432',
            label: 'คลองเขื่อน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1434',
            label: 'บางเล่า',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1435',
            label: 'บางโรง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '179',
            value: '1436',
            label: 'บางตลาด',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '185',
            value: '1497',
            label: 'ท่าตะเกียบ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '15',
            districtid: '185',
            value: '1499',
            label: 'คลองตะเกรา',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1538',
            label: 'สะพานหิน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1539',
            label: 'นาดี',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1540',
            label: 'สำพันตา',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '190',
            value: '1541',
            label: 'ทุ่งโพธิ์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1548',
            label: 'สระแก้ว',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1549',
            label: 'บ้านแก้ง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1550',
            label: 'ศาลาลำดวน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1551',
            label: 'โคกปี่ฆ้อง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1552',
            label: 'ท่าแยก',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1553',
            label: 'ท่าเกษม',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1554',
            label: 'เขาฉกรรจ์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1555',
            label: 'สระขวัญ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1556',
            label: 'หนองหว้า',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1557',
            label: 'พระเพลิง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1558',
            label: 'หนองบอน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1559',
            label: 'เขาสามสิบ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1560',
            label: 'ตาหลังใน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1561',
            label: 'วังสมบูรณ์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '192',
            value: '1562',
            label: 'วังน้ำเย็น',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '193',
            value: '1563',
            label: 'วังน้ำเย็น',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '193',
            value: '1564',
            label: 'วังสมบูรณ์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '193',
            value: '1565',
            label: 'ตาหลังใน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '193',
            value: '1566',
            label: 'วังใหม่',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '193',
            value: '1567',
            label: 'คลองหินปูน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '193',
            value: '1568',
            label: 'ทุ่งมหาเจริญ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1597',
            label: 'คู้ลำพัน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1598',
            label: 'โคกปีบ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1599',
            label: 'โคกไทย',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '196',
            value: '1600',
            label: 'ไผ่ชะเลือด',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1605',
            label: 'อรัญประเทศ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1606',
            label: 'เมืองไผ่',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1607',
            label: 'หันทราย',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1608',
            label: 'คลองน้ำใส',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1609',
            label: 'ท่าข้าม',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1610',
            label: 'ป่าไร่',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1611',
            label: 'ทับพริก',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1612',
            label: 'บ้านใหม่หนองไทร',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1613',
            label: 'ผ่านศึก',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1614',
            label: 'หนองสังข์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1615',
            label: 'คลองทับจันทร์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1616',
            label: 'ฟากห้วย',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '198',
            value: '1617',
            label: 'บ้านด่าน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '199',
            value: '1618',
            label: 'ตาพระยา',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '199',
            value: '1619',
            label: 'ทัพเสด็จ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '199',
            value: '1620',
            label: 'โคกสูง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '199',
            value: '1621',
            label: 'หนองแวง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '199',
            value: '1622',
            label: 'หนองม่วง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '199',
            value: '1623',
            label: 'ทัพราช',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '199',
            value: '1624',
            label: 'ทัพไทย',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '199',
            value: '1625',
            label: 'โนนหมากมุ่น',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '199',
            value: '1626',
            label: 'โคคลาน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1627',
            label: 'วัฒนานคร',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1628',
            label: 'ท่าเกวียน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1629',
            label: 'ซับมะกรูด',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1630',
            label: 'ผักขะ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1631',
            label: 'โนนหมากเค็ง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1632',
            label: 'หนองน้ำใส',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1633',
            label: 'ช่องกุ่ม',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1634',
            label: 'หนองแวง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1635',
            label: 'ไทยอุดม',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1636',
            label: 'ไทรเดี่ยว',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1637',
            label: 'คลองหาด',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1638',
            label: 'แซร์ออ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1639',
            label: 'หนองหมากฝ้าย',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1640',
            label: 'หนองตะเคียนบอน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '200',
            value: '1641',
            label: 'ห้วยโจด',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '201',
            value: '1642',
            label: 'คลองหาด',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '201',
            value: '1643',
            label: 'ไทยอุดม',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '201',
            value: '1644',
            label: 'ซับมะกรูด',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '201',
            value: '1645',
            label: 'ไทรเดี่ยว',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '201',
            value: '1646',
            label: 'คลองไก่เถื่อน',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '201',
            value: '1647',
            label: 'เบญจขร',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '16',
            districtid: '201',
            value: '1648',
            label: 'ไทรทอง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '206',
            value: '1696',
            label: 'เขาฉกรรจ์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '206',
            value: '1698',
            label: 'หนองหว้า',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '206',
            value: '1699',
            label: 'พระเพลิง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '206',
            value: '1701',
            label: 'เขาสามสิบ',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '208',
            value: '1711',
            label: 'โคกสูง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '208',
            value: '1712',
            label: 'หนองแวง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '208',
            value: '1713',
            label: 'หนองม่วง',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '208',
            value: '1716',
            label: 'โนนหมากมุ่น',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '209',
            value: '1719',
            label: 'วังสมบูรณ์',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '209',
            value: '1721',
            label: 'วังใหม่',
            zipcode: ''
        },
        {
            geoid: '5',
            provinceid: '18',
            districtid: '209',
            value: '1724',
            label: 'วังทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1853',
            label: 'สำนักตะคร้อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1855',
            label: 'หนองแวง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '222',
            value: '1858',
            label: 'บึงปรือ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1900',
            label: 'บึงพะไล',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1908',
            label: 'แก้งสนามนาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1912',
            label: 'สีสุก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '226',
            value: '1914',
            label: 'โนนสำราญ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1921',
            label: 'โนนแดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1924',
            label: 'วังหิน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1930',
            label: 'โนนตาเถร',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1931',
            label: 'สำพะเนียง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '227',
            value: '1935',
            label: 'ดอนยาวใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1945',
            label: 'วังน้ำเขียว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1950',
            label: 'วังหมี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1951',
            label: 'ระเริง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '228',
            value: '1952',
            label: 'อุดมทรัพย์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1987',
            label: 'กระเบื้องนอก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1988',
            label: 'เมืองยาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1991',
            label: 'ละหานปลาค้าว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '19',
            districtid: '231',
            value: '1992',
            label: 'โนนอุดม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2098',
            label: 'ห้วยราช',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2100',
            label: 'บ้านด่าน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2101',
            label: 'สามแวง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2102',
            label: 'ปราสาท',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2106',
            label: 'โนนขวาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2107',
            label: 'ตาเสา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2112',
            label: 'สนวน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2114',
            label: 'วังเหนือ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2115',
            label: 'บ้านตะโก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2120',
            label: 'ปะเคียบ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '250',
            value: '2121',
            label: 'ห้วยราช',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2141',
            label: 'ตาเป๊ก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2143',
            label: 'ชำนิ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2146',
            label: 'หนองปล่อง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2148',
            label: 'ทุ่งจังหัน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2149',
            label: 'เมืองยาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2150',
            label: 'เจริญสุข',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2151',
            label: 'โนนสุวรรณ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2158',
            label: 'อีสานเขต',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2159',
            label: 'ดงอีจาน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2160',
            label: 'โกรกแก้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2161',
            label: 'ช่อผกา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2162',
            label: 'ละลวด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2167',
            label: 'หนองปล่อง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2168',
            label: 'ชำนิ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2169',
            label: 'ดอนอะราง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2170',
            label: 'เมืองไผ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2171',
            label: 'เย้ยปราสาท',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '253',
            value: '2172',
            label: 'หนองกี่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '255',
            value: '2184',
            label: 'ถาวร',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '255',
            value: '2187',
            label: 'โนนดินแดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '255',
            value: '2188',
            label: 'ยายแย้มวัฒนา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '255',
            value: '2190',
            label: 'ลำนางรอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '255',
            value: '2191',
            label: 'ส้มป่อย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '255',
            value: '2194',
            label: 'ไทยเจริญ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2198',
            label: 'จันดุม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2203',
            label: 'โคกขมิ้น',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2205',
            label: 'ป่าชัน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2206',
            label: 'สะเดา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2211',
            label: 'สำโรง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2216',
            label: 'ป่าชัน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2217',
            label: 'โคกขมิ้น',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '256',
            value: '2218',
            label: 'จันดุม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '258',
            value: '2231',
            label: 'หนองแวง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '258',
            value: '2232',
            label: 'ทองหลาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '258',
            value: '2235',
            label: 'บ้านแดงใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '258',
            value: '2238',
            label: 'กู่สวนแตง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '258',
            value: '2239',
            label: 'หนองเยือง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2256',
            label: 'ไทยสามัคคี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2257',
            label: 'ห้วยหิน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '259',
            value: '2258',
            label: 'สระแก้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2265',
            label: 'แคนดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2266',
            label: 'ดงพลอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2271',
            label: 'สระบัว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '20',
            districtid: '260',
            value: '2274',
            label: 'หัวฝาย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2343',
            label: 'ตากูก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2350',
            label: 'บึง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2352',
            label: 'เขวาสินรินทร์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2358',
            label: 'บ้านแร่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '273',
            value: '2359',
            label: 'ปราสาททอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2408',
            label: 'ด่าน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2409',
            label: 'คูตัน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2410',
            label: 'โคกกลาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2411',
            label: 'บักได',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '277',
            value: '2412',
            label: 'กาบเชิง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '278',
            value: '2414',
            label: 'บักได',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '278',
            value: '2415',
            label: 'โคกกลาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '278',
            value: '2420',
            label: 'ตาเมียง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '278',
            value: '2421',
            label: 'จีกแดก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2429',
            label: 'หนองหลวง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2430',
            label: 'หนองเทพ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2432',
            label: 'โนน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2434',
            label: 'ระเวียง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2439',
            label: 'คำผง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2440',
            label: 'สนม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2441',
            label: 'หนองระฆัง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2442',
            label: 'นานวน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2443',
            label: 'โพนโก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '279',
            value: '2444',
            label: 'แคน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2469',
            label: 'ณรงค์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2470',
            label: 'แจนแวน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2471',
            label: 'ตรวจ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2480',
            label: 'ศรีสุข',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2482',
            label: 'หนองแวง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2484',
            label: 'คูตัน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '21',
            districtid: '282',
            value: '2485',
            label: 'ด่าน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2533',
            label: 'โนนเพ็ก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2534',
            label: 'พรหมสวัสดิ์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2535',
            label: 'พยุห์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2538',
            label: 'ธาตุ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2539',
            label: 'ตำแย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2542',
            label: 'บุสูง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2545',
            label: 'ดวนใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2550',
            label: 'บ่อแก้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2551',
            label: 'ศรีสำราญ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2553',
            label: 'หนองค้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2554',
            label: 'ดวนใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2555',
            label: 'ธาตุ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2556',
            label: 'บุสูง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '290',
            value: '2557',
            label: 'คอนกาม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2580',
            label: 'ตองบิด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2581',
            label: 'ละเอาะ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2582',
            label: 'น้ำเกลี้ยง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2583',
            label: 'เขิน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2585',
            label: 'ตองปิด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2586',
            label: 'ละเอาะ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2587',
            label: 'หนองกุง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2588',
            label: 'โพธิ์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2589',
            label: 'บก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '292',
            value: '2590',
            label: 'โนนค้อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2594',
            label: 'หนองหว้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2600',
            label: 'เสียว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2607',
            label: 'หนองงูเหลือม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2608',
            label: 'ท่าคล้อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2612',
            label: 'หนองฮาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2616',
            label: 'สระเยาว์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2617',
            label: 'พิงพวย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2618',
            label: 'ศรีแก้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '293',
            value: '2619',
            label: 'ตูม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2631',
            label: 'ละลม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2633',
            label: 'โคกตาล',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2635',
            label: 'ห้วยตามอญ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2638',
            label: 'ตะเคียนราม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2639',
            label: 'ห้วยติ๊กชู',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2642',
            label: 'ดงรัก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '294',
            value: '2648',
            label: 'ละลม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2680',
            label: 'กุง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2681',
            label: 'คลีกลิ้ง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2692',
            label: 'โจดม่วง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '298',
            value: '2693',
            label: 'หนองบัวดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2702',
            label: 'ตาโกน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2706',
            label: 'เมืองจันทร์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2712',
            label: 'โดด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2713',
            label: 'เสียว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2714',
            label: 'หนองม้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2716',
            label: 'หนองใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2719',
            label: 'ผือใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2720',
            label: 'อีเซ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2721',
            label: 'ผักไหม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2722',
            label: 'กล้วยกว้าง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2723',
            label: 'ห้วยทับทัน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '22',
            districtid: '299',
            value: '2724',
            label: 'เป๊าะ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2789',
            label: 'โพนเมือง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2790',
            label: 'ท่าเมือง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2793',
            label: 'ดอนมดแดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2797',
            label: 'คำไฮใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2801',
            label: 'เหล่าแดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2802',
            label: 'เหล่าเสือโก้ก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2804',
            label: 'หนองบก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '312',
            value: '2805',
            label: 'แพงใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2844',
            label: 'กองโพน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2847',
            label: 'พังเคน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2848',
            label: 'นาตาล',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '316',
            value: '2851',
            label: 'พะลาน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '317',
            value: '2856',
            label: 'ชานุมาน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '317',
            value: '2857',
            label: 'โคกสาร',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '317',
            value: '2858',
            label: 'คำเขื่อนแก้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '317',
            value: '2859',
            label: 'หนองข่า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '317',
            value: '2860',
            label: 'คำโพน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '317',
            value: '2861',
            label: 'โคกก่ง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '317',
            value: '2862',
            label: 'ป่าก่อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2865',
            label: 'นาเยีย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2867',
            label: 'นาเรือง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2869',
            label: 'หนองอ้ม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2876',
            label: 'นาเกษม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2880',
            label: 'กุดเรือ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2881',
            label: 'นาดี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '318',
            value: '2884',
            label: 'โคกชำแระ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '320',
            value: '2893',
            label: 'ตาเกา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '320',
            value: '2896',
            label: 'ขี้เหล็ก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '320',
            value: '2899',
            label: 'ไพบูลย์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '320',
            value: '2901',
            label: 'โคกสะอาด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '324',
            value: '2939',
            label: 'พนา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '324',
            value: '2940',
            label: 'จานลาน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '324',
            value: '2941',
            label: 'ไม้กลอน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '324',
            value: '2942',
            label: 'ลือ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '324',
            value: '2943',
            label: 'ห้วย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '324',
            value: '2944',
            label: 'นาหว้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '324',
            value: '2945',
            label: 'พระเหลา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '324',
            value: '2946',
            label: 'นาป่าแซง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2963',
            label: 'ท่าช้าง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2966',
            label: 'โนนกาเล็น',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2969',
            label: 'ค้อน้อย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2972',
            label: 'หนองไฮ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2973',
            label: 'สำโรง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2974',
            label: 'สว่าง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2977',
            label: 'โคกก่อง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2979',
            label: 'โคกสว่าง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2983',
            label: 'บุ่งมะแลง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '326',
            value: '2985',
            label: 'แก่งโดม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2987',
            label: 'บุ่ง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2988',
            label: 'ไก่คำ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2989',
            label: 'นาจิก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2990',
            label: 'ดงมะยาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2991',
            label: 'อำนาจ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2992',
            label: 'เปือย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2993',
            label: 'ดงบัง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2994',
            label: 'ไร่ขี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2995',
            label: 'ปลาค้าว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2996',
            label: 'เหล่าพรวน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2997',
            label: 'สร้างนกทา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2998',
            label: 'คิ่มใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '2999',
            label: 'นาผือ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '3000',
            label: 'น้ำปลีก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '3001',
            label: 'นาวัง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '3002',
            label: 'นาหมอม้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '3003',
            label: 'โนนโพธิ์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '3004',
            label: 'โนนหนามแท่ง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '3005',
            label: 'ห้วยไร่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '3006',
            label: 'หนองมะแซว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '3007',
            label: 'แมด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '3008',
            label: 'กุดปลาดุก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '327',
            value: '3009',
            label: 'โนนงาม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '328',
            value: '3010',
            label: 'เสนางคนิคม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '328',
            value: '3011',
            label: 'โพนทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '328',
            value: '3012',
            label: 'ไร่สีสุก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '328',
            value: '3013',
            label: 'นาเวียง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '328',
            value: '3014',
            label: 'หนองไฮ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '328',
            value: '3015',
            label: 'หนองสามสี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '329',
            value: '3016',
            label: 'หัวตะพาน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '329',
            value: '3017',
            label: 'คำพระ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '329',
            value: '3018',
            label: 'เค็งใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '329',
            value: '3019',
            label: 'หนองแก้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '329',
            value: '3020',
            label: 'โพนเมืองน้อย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '329',
            value: '3021',
            label: 'สร้างถ่อน้อย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '329',
            value: '3022',
            label: 'จิกดู่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '329',
            value: '3023',
            label: 'รัตนวารี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3026',
            label: 'คันไร่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3031',
            label: 'ฝางคำ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3038',
            label: 'นิคมสร้างตนเองฯ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3039',
            label: 'ช่องเม็ก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '330',
            value: '3040',
            label: 'โนนก่อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '334',
            value: '3064',
            label: 'อำนาจ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '334',
            value: '3065',
            label: 'ดงมะยาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '334',
            value: '3066',
            label: 'เปือย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '334',
            value: '3067',
            label: 'ดงบัง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '334',
            value: '3068',
            label: 'ไร่ขี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '334',
            value: '3069',
            label: 'แมด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '337',
            value: '3080',
            label: 'ทุ่งเทิง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '338',
            value: '3086',
            label: 'หนองข่า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '338',
            value: '3087',
            label: 'คำโพน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '338',
            value: '3088',
            label: 'นาหว้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '338',
            value: '3089',
            label: 'ลือ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '338',
            value: '3090',
            label: 'ห้วย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '338',
            value: '3091',
            label: 'โนนงาม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '23',
            districtid: '338',
            value: '3092',
            label: 'นาป่าแซง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3130',
            label: 'นาเวียง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3131',
            label: 'ดงมะไฟ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3132',
            label: 'ดู่ลาย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '346',
            value: '3133',
            label: 'ทรายมูล',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3176',
            label: 'ค้อวัง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3177',
            label: 'น้ำอ้อม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3178',
            label: 'กุดน้ำใส',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '351',
            value: '3179',
            label: 'ฟ้าห่วน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3184',
            label: 'น้ำคำ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3187',
            label: 'ส้มผ่อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3191',
            label: 'คำเตย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3192',
            label: 'คำไผ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3198',
            label: 'ไทยเจริญ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3199',
            label: 'ไทยเจริญ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3200',
            label: 'คำไผ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3201',
            label: 'คำเตย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3202',
            label: 'ส้มผ่อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '24',
            districtid: '353',
            value: '3203',
            label: 'น้ำคำ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3228',
            label: 'เจาทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '355',
            value: '3229',
            label: 'บ้านเจียง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3261',
            label: 'บ้านเจียง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3262',
            label: 'เจาทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3266',
            label: 'วังทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3268',
            label: 'เจาทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '359',
            value: '3269',
            label: 'บ้านเจียง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3273',
            label: 'หนองฉิม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3277',
            label: 'ตาเนิน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3278',
            label: 'กะฮาด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3281',
            label: 'ท่ากูบ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3283',
            label: 'ซับใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3284',
            label: 'รังงาม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '360',
            value: '3285',
            label: 'ตะโกทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '361',
            value: '3293',
            label: 'บ้านไร่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '361',
            value: '3294',
            label: 'นายางกลัก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '361',
            value: '3295',
            label: 'ห้วยยายจิ๋ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '25',
            districtid: '361',
            value: '3296',
            label: 'วะตะแบก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3371',
            label: 'โพนทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3372',
            label: 'ดงมะยาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3373',
            label: 'เปือย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3374',
            label: 'หนองไฮ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3375',
            label: 'นาเวียง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3376',
            label: 'ไร่ขี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3377',
            label: 'ไร่สีสุก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3378',
            label: 'เสนางคนิคม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3379',
            label: 'อำนาจ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '380',
            value: '3380',
            label: 'ดงบัง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '381',
            value: '3386',
            label: 'หนองข่า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '383',
            value: '3398',
            label: 'นาหว้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '383',
            value: '3399',
            label: 'ลือ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '26',
            districtid: '383',
            value: '3400',
            label: 'ห้วย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3439',
            label: 'นาเหล่า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3440',
            label: 'นาแก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3444',
            label: 'วังทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3448',
            label: 'วังปลาป้อม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3450',
            label: 'เทพคีรี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3451',
            label: 'โนนภูทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3452',
            label: 'นาดี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3453',
            label: 'นาสี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3454',
            label: 'บ้านโคก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '27',
            districtid: '388',
            value: '3455',
            label: 'โคกนาเหล่า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3509',
            label: 'บ้านโต้น',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '393',
            value: '3510',
            label: 'หนองบัว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3575',
            label: 'กระนวน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3576',
            label: 'บ้านโนน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3580',
            label: 'คำแมด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3585',
            label: 'คูคำ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '401',
            value: '3586',
            label: 'ห้วยเตย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3589',
            label: 'บ้านแฮด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3590',
            label: 'โคกสำราญ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3592',
            label: 'เปือยใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3593',
            label: 'โนนศิลา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3594',
            label: 'บ้านหัน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3598',
            label: 'หนองแซง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3601',
            label: 'โนนสมบูรณ์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3604',
            label: 'บ้านแฮด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3605',
            label: 'โนนแดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3606',
            label: 'หนองปลาหมอ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3607',
            label: 'สระแก้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3608',
            label: 'ขามป้อม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3609',
            label: 'วังม่วง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '402',
            value: '3610',
            label: 'เปือยน้อย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3627',
            label: 'ทางขวาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3628',
            label: 'ท่าวัด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3629',
            label: 'ท่านางแมว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3630',
            label: 'แวงน้อย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3631',
            label: 'ก้านเหลือง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3632',
            label: 'ละหารนา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3633',
            label: 'แวงใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3634',
            label: 'โนนทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3635',
            label: 'ใหม่นาเพียง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '404',
            value: '3636',
            label: 'คอนฉิม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3662',
            label: 'บ้านโคก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3668',
            label: 'กุดธาตุ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '408',
            value: '3670',
            label: 'ขนวน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '409',
            value: '3684',
            label: 'บ้านโคก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '409',
            value: '3685',
            label: 'โพธิ์ไชย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '409',
            value: '3687',
            label: 'ซับสมบูรณ์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '409',
            value: '3688',
            label: 'นาแพง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '418',
            value: '3728',
            label: 'ในเมือง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '418',
            value: '3729',
            label: 'เมืองเก่าพัฒนา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '418',
            value: '3730',
            label: 'เขาน้อย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '28',
            districtid: '419',
            value: '3731',
            label: 'บ้านเป็ด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3753',
            label: 'ขอนยูง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3754',
            label: 'ปะโค',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3755',
            label: 'หนองหว้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3756',
            label: 'ขอนยูง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3757',
            label: 'ปะโค',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3758',
            label: 'เชียงเพ็ง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3759',
            label: 'กุดจับ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '421',
            value: '3760',
            label: 'หนองปุ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3780',
            label: 'อุ่มจาน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3783',
            label: 'ห้วยสามพาด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3787',
            label: 'นาม่วง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3792',
            label: 'โนนสะอาด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3793',
            label: 'โพธิ์ศรีสำราญ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3794',
            label: 'บุ่งแก้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3795',
            label: 'หนองแสง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '424',
            value: '3796',
            label: 'แสงสว่าง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3805',
            label: 'คอนสาย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3806',
            label: 'บ้านจีต',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3810',
            label: 'บ้านแดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3815',
            label: 'ดอนกลอย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3817',
            label: 'นาทราย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3818',
            label: 'ค้อใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3821',
            label: 'โนนทองอินทร์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3822',
            label: 'หนองหลัก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3823',
            label: 'บ้านแดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3824',
            label: 'ทุ่งใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3825',
            label: 'ทุ่งฝน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3826',
            label: 'โพนสูง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '426',
            value: '3827',
            label: 'ไชยวาน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3862',
            label: 'หนองบัว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3863',
            label: 'หนองภัยศูนย์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3864',
            label: 'โพธิ์ชัย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3865',
            label: 'หนองสวรรค์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3866',
            label: 'หัวนา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3867',
            label: 'บ้านขาม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3868',
            label: 'นามะเฟือง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3869',
            label: 'บ้านพร้าว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3870',
            label: 'โนนขมิ้น',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3871',
            label: 'ลำภู',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3872',
            label: 'กุดจิก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3873',
            label: 'โนนทัน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3874',
            label: 'นาคำไฮ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3875',
            label: 'ป่าไม้งาม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '432',
            value: '3876',
            label: 'หนองหว้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '433',
            value: '3877',
            label: 'เมืองใหม่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '433',
            value: '3878',
            label: 'ศรีบุญเรือง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '433',
            value: '3879',
            label: 'หนองบัวใต้',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '433',
            value: '3880',
            label: 'กุดสะเทียน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '433',
            value: '3881',
            label: 'นากอก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '433',
            value: '3882',
            label: 'โนนสะอาด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '433',
            value: '3883',
            label: 'ยางหล่อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '433',
            value: '3884',
            label: 'โนนม่วง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '433',
            value: '3885',
            label: 'หนองกุงแก้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '433',
            value: '3886',
            label: 'หนองแก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '433',
            value: '3887',
            label: 'ทรายทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '433',
            value: '3888',
            label: 'หันนางาม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3889',
            label: 'นากลาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3890',
            label: 'ด่านช้าง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3891',
            label: 'นาเหล่า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3892',
            label: 'นาแก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3893',
            label: 'กุดดินจี่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3894',
            label: 'ฝั่งแดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3895',
            label: 'เก่ากลอย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3896',
            label: 'วังทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3897',
            label: 'โนนเมือง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3898',
            label: 'อุทัยสวรรค์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3899',
            label: 'ดงสวรรค์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3900',
            label: 'วังปลาป้อม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3901',
            label: 'กุดแห่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3902',
            label: 'เทพคีรี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3903',
            label: 'โนนภูทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3904',
            label: 'บุญทัน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3905',
            label: 'สุวรรณคูหา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3906',
            label: 'ดงมะไฟ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3907',
            label: 'นาด่าน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3908',
            label: 'นาดี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3909',
            label: 'บ้านโคก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '434',
            value: '3910',
            label: 'นาสี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '435',
            value: '3911',
            label: 'นาสี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '435',
            value: '3912',
            label: 'บ้านโคก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '435',
            value: '3913',
            label: 'นาดี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '435',
            value: '3914',
            label: 'นาด่าน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '435',
            value: '3915',
            label: 'ดงมะไฟ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '435',
            value: '3916',
            label: 'สุวรรณคูหา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '435',
            value: '3917',
            label: 'บุญทัน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '436',
            value: '3918',
            label: 'โนนสัง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '436',
            value: '3919',
            label: 'บ้านถิ่น',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '436',
            value: '3920',
            label: 'หนองเรือ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '436',
            value: '3921',
            label: 'กุดดู่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '436',
            value: '3922',
            label: 'บ้านค้อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '436',
            value: '3923',
            label: 'โนนเมือง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '436',
            value: '3924',
            label: 'โคกใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '436',
            value: '3925',
            label: 'โคกม่วง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '436',
            value: '3926',
            label: 'นิคมพัฒนา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '436',
            value: '3927',
            label: 'ปางกู่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '437',
            value: '3941',
            label: 'บ้านเม็ก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3944',
            label: 'นายูง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3945',
            label: 'นาแค',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3949',
            label: 'โนนทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3950',
            label: 'บ้านก้อง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3952',
            label: 'ทุบกุง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3954',
            label: 'นาแค',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '438',
            value: '3955',
            label: 'นายูง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3967',
            label: 'เชียงดา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3968',
            label: 'บ้านยวด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '29',
            districtid: '439',
            value: '3969',
            label: 'สร้างคอม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '448',
            value: '4018',
            label: 'จอมศรี',
            zipcode: '42110'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '448',
            value: '4019',
            label: 'หาดทรายขาว',
            zipcode: '42110'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '449',
            value: '4020',
            label: 'ปากชม',
            zipcode: '42150'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '449',
            value: '4021',
            label: 'เชียงกลม',
            zipcode: '42150'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '449',
            value: '4022',
            label: 'หาดคัมภีร์',
            zipcode: '42150'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '449',
            value: '4023',
            label: 'ห้วยบ่อซืน',
            zipcode: '42150'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '449',
            value: '4024',
            label: 'ห้วยพิชัย',
            zipcode: '42150'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '449',
            value: '4025',
            label: 'ชมเจริญ',
            zipcode: '42150'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4026',
            label: 'ด่านซ้าย',
            zipcode: '42120'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4027',
            label: 'ปากหมัน',
            zipcode: '42120'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4028',
            label: 'นาดี',
            zipcode: '42120'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4029',
            label: 'โคกงาม',
            zipcode: '42120'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4030',
            label: 'โพนสูง',
            zipcode: '42120'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4031',
            label: 'อิปุ่ม',
            zipcode: '42120'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4032',
            label: 'กกสะทอน',
            zipcode: '42120'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4033',
            label: 'โป่ง',
            zipcode: '42120'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4034',
            label: 'วังยาว',
            zipcode: '42120'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4035',
            label: 'นาหอ',
            zipcode: '42120'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '451',
            value: '4043',
            label: 'นาแห้ว',
            zipcode: '42170'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '451',
            value: '4044',
            label: 'แสงภา',
            zipcode: '42170'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '451',
            value: '4045',
            label: 'นาพึง',
            zipcode: '42170'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '451',
            value: '4046',
            label: 'นามาลา',
            zipcode: '42170'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '451',
            value: '4047',
            label: 'เหล่ากอหก',
            zipcode: '42170'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '452',
            value: '4048',
            label: 'หนองบัว',
            zipcode: '42160'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '452',
            value: '4049',
            label: 'ท่าศาลา',
            zipcode: '42160'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '452',
            value: '4050',
            label: 'ร่องจิก',
            zipcode: '42160'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '452',
            value: '4051',
            label: 'ปลาบ่า',
            zipcode: '42160'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '452',
            value: '4052',
            label: 'ลาดค่าง',
            zipcode: '42160'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '452',
            value: '4053',
            label: 'สานตม',
            zipcode: '42160'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '453',
            value: '4054',
            label: 'ท่าลี่',
            zipcode: '42140'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '453',
            value: '4055',
            label: 'หนองผือ',
            zipcode: '42140'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '453',
            value: '4056',
            label: 'อาฮี',
            zipcode: '42140'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '453',
            value: '4057',
            label: 'น้ำแคม',
            zipcode: '42140'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '453',
            value: '4058',
            label: 'โคกใหญ่',
            zipcode: '42140'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '453',
            value: '4059',
            label: 'น้ำทูน',
            zipcode: '42140'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4060',
            label: 'วังสะพุง',
            zipcode: '42130'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4061',
            label: 'ทรายขาว',
            zipcode: '42130'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4062',
            label: 'หนองหญ้าปล้อง',
            zipcode: '42130'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4063',
            label: 'หนองงิ้ว',
            zipcode: '42130'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4064',
            label: 'ปากปวน',
            zipcode: '42130'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4065',
            label: 'ผาน้อย',
            zipcode: '42130'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4069',
            label: 'ผาบิ้ง',
            zipcode: '42130'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4070',
            label: 'เขาหลวง',
            zipcode: '42130'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4071',
            label: 'โคกขมิ้น',
            zipcode: '42130'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4072',
            label: 'ศรีสงคราม',
            zipcode: '42130'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '455',
            value: '4076',
            label: 'ศรีฐาน',
            zipcode: '42180'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '455',
            value: '4080',
            label: 'ผานกเค้า',
            zipcode: '42180'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '455',
            value: '4082',
            label: 'ภูกระดึง',
            zipcode: '42180'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '455',
            value: '4085',
            label: 'ห้วยส้ม',
            zipcode: '42180'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '456',
            value: '4087',
            label: 'ภูหอ',
            zipcode: '42230'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '456',
            value: '4088',
            label: 'หนองคัน',
            zipcode: '42230'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '456',
            value: '4090',
            label: 'ห้วยสีเสียด',
            zipcode: '42230'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '456',
            value: '4091',
            label: 'เลยวังไสย์',
            zipcode: '42230'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '456',
            value: '4092',
            label: 'แก่งศรีภูมิ',
            zipcode: '42230'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '457',
            value: '4093',
            label: 'ผาขาว',
            zipcode: '42240'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '457',
            value: '4094',
            label: 'ท่าช้างคล้อง',
            zipcode: '42240'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '457',
            value: '4095',
            label: 'โนนปอแดง',
            zipcode: '42240'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '457',
            value: '4096',
            label: 'โนนป่าซาง',
            zipcode: '42240'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '457',
            value: '4097',
            label: 'บ้านเพิ่ม',
            zipcode: '42240'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '458',
            value: '4098',
            label: 'เอราวัณ',
            zipcode: '42220'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '458',
            value: '4099',
            label: 'ผาอินทร์แปลง',
            zipcode: '42220'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '458',
            value: '4100',
            label: 'ผาสามยอด',
            zipcode: '42220'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '458',
            value: '4101',
            label: 'ทรัพย์ไพวัลย์',
            zipcode: '42220'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '459',
            value: '4102',
            label: 'หนองหิน',
            zipcode: '42190'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '459',
            value: '4103',
            label: 'ตาดข่า',
            zipcode: '42190'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '459',
            value: '4104',
            label: 'ปวนพุ',
            zipcode: '42190'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4105',
            label: 'ในเมือง',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4106',
            label: 'มีชัย',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4107',
            label: 'โพธิ์ชัย',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4108',
            label: 'กวนวัน',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4109',
            label: 'เวียงคุก',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4110',
            label: 'วัดธาตุ',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4111',
            label: 'หาดคำ',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4112',
            label: 'หินโงม',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4113',
            label: 'บ้านเดื่อ',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4114',
            label: 'ค่ายบกหวาน',
            zipcode: '43100'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4115',
            label: 'สองห้อง',
            zipcode: '43100'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4117',
            label: 'พระธาตุบังพวน',
            zipcode: '43100'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4120',
            label: 'หนองกอมเกาะ',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4121',
            label: 'ปะโค',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4122',
            label: 'เมืองหมี',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4123',
            label: 'สีกาย',
            zipcode: '43000'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '461',
            value: '4124',
            label: 'ท่าบ่อ',
            zipcode: '43110'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '461',
            value: '4125',
            label: 'น้ำโมง',
            zipcode: '43110'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '461',
            value: '4126',
            label: 'กองนาง',
            zipcode: '43110'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '461',
            value: '4127',
            label: 'โคกคอน',
            zipcode: '43110'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '461',
            value: '4128',
            label: 'บ้านเดื่อ',
            zipcode: '43110'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '461',
            value: '4129',
            label: 'บ้านถ่อน',
            zipcode: '43110'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '461',
            value: '4130',
            label: 'บ้านว่าน',
            zipcode: '43110'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '461',
            value: '4131',
            label: 'นาข่า',
            zipcode: '43110'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '461',
            value: '4132',
            label: 'โพนสา',
            zipcode: '43110'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '461',
            value: '4133',
            label: 'หนองนาง',
            zipcode: '43110'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4134',
            label: 'บึงกาฬ',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4136',
            label: 'โนนสมบูรณ์',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4137',
            label: 'หนองเข็ง',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4138',
            label: 'หอคำ',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4139',
            label: 'หนองเลิง',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4140',
            label: 'โคกก่อง',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4143',
            label: 'นาสวรรค์',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4144',
            label: 'ไคสี',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4147',
            label: 'ชัยพร',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4149',
            label: 'วิศิษฐ์',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4151',
            label: 'คำนาดี',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4152',
            label: 'โป่งเปือย',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '463',
            value: '4153',
            label: 'ศรีชมภู',
            zipcode: '43180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '463',
            value: '4154',
            label: 'ดอนหญ้านาง',
            zipcode: '43180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '463',
            value: '4155',
            label: 'พรเจริญ',
            zipcode: '43180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '463',
            value: '4156',
            label: 'หนองหัวช้าง',
            zipcode: '43180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '463',
            value: '4157',
            label: 'วังชมภู',
            zipcode: '43180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '463',
            value: '4158',
            label: 'ป่าแฝก',
            zipcode: '43180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '463',
            value: '4159',
            label: 'ศรีสำราญ',
            zipcode: '43180'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4160',
            label: 'จุมพล',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4161',
            label: 'วัดหลวง',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4162',
            label: 'กุดบง',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4163',
            label: 'ชุมช้าง',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4165',
            label: 'ทุ่งหลวง',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4166',
            label: 'เหล่าต่างคำ',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4167',
            label: 'นาหนัง',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4168',
            label: 'เซิม',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4172',
            label: 'บ้านโพธิ์',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4179',
            label: 'บ้านผือ',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4180',
            label: 'สร้างนางขาว',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '465',
            value: '4181',
            label: 'โซ่',
            zipcode: '43170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '465',
            value: '4182',
            label: 'หนองพันทา',
            zipcode: '43170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '465',
            value: '4183',
            label: 'ศรีชมภู',
            zipcode: '43170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '465',
            value: '4184',
            label: 'คำแก้ว',
            zipcode: '43170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '465',
            value: '4185',
            label: 'บัวตูม',
            zipcode: '43170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '465',
            value: '4186',
            label: 'ถ้ำเจริญ',
            zipcode: '43170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '465',
            value: '4187',
            label: 'เหล่าทอง',
            zipcode: '43170'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '466',
            value: '4188',
            label: 'พานพร้าว',
            zipcode: '43130'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '466',
            value: '4190',
            label: 'บ้านหม้อ',
            zipcode: '43130'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '466',
            value: '4191',
            label: 'พระพุทธบาท',
            zipcode: '43130'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '466',
            value: '4192',
            label: 'หนองปลาปาก',
            zipcode: '43130'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '467',
            value: '4195',
            label: 'แก้งไก่',
            zipcode: '43160'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '467',
            value: '4196',
            label: 'ผาตั้ง',
            zipcode: '43160'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '467',
            value: '4197',
            label: 'บ้านม่วง',
            zipcode: '43160'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '467',
            value: '4198',
            label: 'นางิ้ว',
            zipcode: '43160'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '467',
            value: '4199',
            label: 'สังคม',
            zipcode: '43160'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4200',
            label: 'เซกา',
            zipcode: '43150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4201',
            label: 'ซาง',
            zipcode: '43150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4202',
            label: 'ท่ากกแดง',
            zipcode: '43150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4205',
            label: 'บ้านต้อง',
            zipcode: '43150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4206',
            label: 'ป่งไฮ',
            zipcode: '43150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4207',
            label: 'น้ำจั้น',
            zipcode: '43150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4208',
            label: 'ท่าสะอาด',
            zipcode: '43150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4211',
            label: 'หนองทุ่ม',
            zipcode: '43150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4212',
            label: 'โสกก่าม',
            zipcode: '43150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '469',
            value: '4213',
            label: 'ปากคาด',
            zipcode: '43190'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '469',
            value: '4214',
            label: 'หนองยอง',
            zipcode: '43190'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '469',
            value: '4215',
            label: 'นากั้ง',
            zipcode: '43190'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '469',
            value: '4216',
            label: 'โนนศิลา',
            zipcode: '43190'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '469',
            value: '4217',
            label: 'สมสนุก',
            zipcode: '43190'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '469',
            value: '4218',
            label: 'นาดง',
            zipcode: '43190'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '470',
            value: '4219',
            label: 'บึงโขงหลง',
            zipcode: '43220'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '470',
            value: '4220',
            label: 'โพธิ์หมากแข้ง',
            zipcode: '43220'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '470',
            value: '4221',
            label: 'ดงบัง',
            zipcode: '43220'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '470',
            value: '4222',
            label: 'ท่าดอกคำ',
            zipcode: '43220'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '471',
            value: '4223',
            label: 'ศรีวิไล',
            zipcode: '43210'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '471',
            value: '4224',
            label: 'ชุมภูพร',
            zipcode: '43210'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '471',
            value: '4225',
            label: 'นาแสง',
            zipcode: '43210'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '471',
            value: '4226',
            label: 'นาสะแบง',
            zipcode: '43210'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '471',
            value: '4227',
            label: 'นาสิงห์',
            zipcode: '43210'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '472',
            value: '4228',
            label: 'บุ่งคล้า',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '472',
            value: '4229',
            label: 'หนองเดิ่น',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '472',
            value: '4230',
            label: 'โคกกว้าง',
            zipcode: '43140'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '473',
            value: '4231',
            label: 'สระใคร',
            zipcode: '43100'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '473',
            value: '4232',
            label: 'คอกช้าง',
            zipcode: '43100'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '473',
            value: '4233',
            label: 'บ้านฝาง',
            zipcode: '43100'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '474',
            value: '4234',
            label: 'เฝ้าไร่',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '474',
            value: '4235',
            label: 'นาดี',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '474',
            value: '4236',
            label: 'หนองหลวง',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '474',
            value: '4237',
            label: 'วังหลวง',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '474',
            value: '4238',
            label: 'อุดมพร',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '475',
            value: '4239',
            label: 'รัตนวาปี',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '475',
            value: '4240',
            label: 'นาทับไฮ',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '475',
            value: '4241',
            label: 'บ้านต้อน',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '475',
            value: '4242',
            label: 'พระบาทนาสิงห์',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '475',
            value: '4243',
            label: 'โพนแพง',
            zipcode: '43120'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '476',
            value: '4244',
            label: 'โพธิ์ตาก',
            zipcode: '43130'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '476',
            value: '4245',
            label: 'โพนทอง',
            zipcode: '43130'
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '476',
            value: '4246',
            label: 'ด่านศรีสุข',
            zipcode: '43130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4247',
            label: 'ตลาด',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4248',
            label: 'เขวา',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4249',
            label: 'ท่าตูม',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4250',
            label: 'แวงน่าง',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4251',
            label: 'โคกก่อ',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4252',
            label: 'ดอนหว่าน',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4253',
            label: 'เกิ้ง',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4254',
            label: 'แก่งเลิงจาน',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4255',
            label: 'ท่าสองคอน',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4256',
            label: 'ลาดพัฒนา',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4257',
            label: 'หนองปลิง',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4258',
            label: 'ห้วยแอ่ง',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4259',
            label: 'หนองโน',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '477',
            value: '4260',
            label: 'บัวค้อ',
            zipcode: '44000'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '478',
            value: '4261',
            label: 'แกดำ',
            zipcode: '44190'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '478',
            value: '4262',
            label: 'วังแสง',
            zipcode: '44190'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '478',
            value: '4263',
            label: 'มิตรภาพ',
            zipcode: '44190'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '478',
            value: '4264',
            label: 'หนองกุง',
            zipcode: '44190'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '478',
            value: '4265',
            label: 'โนนภิบาล',
            zipcode: '44190'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4266',
            label: 'หัวขวาง',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4267',
            label: 'ยางน้อย',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4268',
            label: 'วังยาว',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4269',
            label: 'เขวาไร่',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4270',
            label: 'แพง',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4271',
            label: 'แก้งแก',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4272',
            label: 'หนองเหล็ก',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4273',
            label: 'หนองบัว',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4274',
            label: 'เหล่า',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4275',
            label: 'เขื่อน',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4276',
            label: 'หนองบอน',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4277',
            label: 'โพนงาม',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4278',
            label: 'ยางท่าแจ้ง',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4279',
            label: 'แห่ใต้',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4280',
            label: 'หนองกุงสวรรค์',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4281',
            label: 'เลิงใต้',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '479',
            value: '4282',
            label: 'ดอนกลาง',
            zipcode: '44140'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '480',
            value: '4283',
            label: 'โคกพระ',
            zipcode: '44150'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '480',
            value: '4284',
            label: 'คันธารราษฎร์',
            zipcode: '44150'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '480',
            value: '4285',
            label: 'มะค่า',
            zipcode: '44150'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '480',
            value: '4286',
            label: 'ท่าขอนยาง',
            zipcode: '44150'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '480',
            value: '4287',
            label: 'นาสีนวน',
            zipcode: '44150'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '480',
            value: '4288',
            label: 'ขามเรียง',
            zipcode: '44150'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '480',
            value: '4289',
            label: 'เขวาใหญ่',
            zipcode: '44150'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '480',
            value: '4290',
            label: 'ศรีสุข',
            zipcode: '44150'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '480',
            value: '4291',
            label: 'กุดใส้จ่อ',
            zipcode: '44150'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '480',
            value: '4292',
            label: 'ขามเฒ่าพัฒนา',
            zipcode: '44150'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '481',
            value: '4293',
            label: 'เชียงยืน',
            zipcode: '44160'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '481',
            value: '4295',
            label: 'หนองซอน',
            zipcode: '44160'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '481',
            value: '4297',
            label: 'ดอนเงิน',
            zipcode: '44160'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '481',
            value: '4298',
            label: 'กู่ทอง',
            zipcode: '44160'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '481',
            value: '4299',
            label: 'นาทอง',
            zipcode: '44160'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '481',
            value: '4300',
            label: 'เสือเฒ่า',
            zipcode: '44160'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '481',
            value: '4303',
            label: 'โพนทอง',
            zipcode: '44160'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '481',
            value: '4304',
            label: 'เหล่าบัวบาน',
            zipcode: '44160'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4305',
            label: 'บรบือ',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4306',
            label: 'บ่อใหญ่',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4308',
            label: 'วังไชย',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4309',
            label: 'หนองม่วง',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4310',
            label: 'กำพี้',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4311',
            label: 'โนนราษี',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4312',
            label: 'โนนแดง',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4314',
            label: 'หนองจิก',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4315',
            label: 'บัวมาศ',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4317',
            label: 'หนองคูขาด',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4319',
            label: 'วังใหม่',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4320',
            label: 'ยาง',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4322',
            label: 'หนองสิม',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4323',
            label: 'หนองโก',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4324',
            label: 'ดอนงัว',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '483',
            value: '4325',
            label: 'นาเชือก',
            zipcode: '44170'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '483',
            value: '4326',
            label: 'สำโรง',
            zipcode: '44170'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '483',
            value: '4327',
            label: 'หนองแดง',
            zipcode: '44170'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '483',
            value: '4328',
            label: 'เขวาไร่',
            zipcode: '44170'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '483',
            value: '4329',
            label: 'หนองโพธิ์',
            zipcode: '44170'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '483',
            value: '4330',
            label: 'ปอพาน',
            zipcode: '44170'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '483',
            value: '4331',
            label: 'หนองเม็ก',
            zipcode: '44170'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '483',
            value: '4332',
            label: 'หนองเรือ',
            zipcode: '44170'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '483',
            value: '4333',
            label: 'หนองกุง',
            zipcode: '44170'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '483',
            value: '4334',
            label: 'สันป่าตอง',
            zipcode: '44170'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4335',
            label: 'ปะหลาน',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4336',
            label: 'ก้ามปู',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4337',
            label: 'เวียงสะอาด',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4338',
            label: 'เม็กดำ',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4339',
            label: 'นาสีนวล',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4343',
            label: 'ราษฎร์เจริญ',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4344',
            label: 'หนองบัวแก้ว',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4346',
            label: 'เมืองเตา',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4349',
            label: 'ลานสะแก',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4350',
            label: 'เวียงชัย',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4351',
            label: 'หนองบัว',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4352',
            label: 'ราษฎร์พัฒนา',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4353',
            label: 'เมืองเสือ',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4354',
            label: 'ภารแอ่น',
            zipcode: '44110'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4355',
            label: 'หนองแสง',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4356',
            label: 'ขามป้อม',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4357',
            label: 'เสือโก้ก',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4358',
            label: 'ดงใหญ่',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4359',
            label: 'โพธิ์ชัย',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4360',
            label: 'หัวเรือ',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4361',
            label: 'แคน',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4362',
            label: 'งัวบา',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4363',
            label: 'นาข่า',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4364',
            label: 'บ้านหวาย',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4365',
            label: 'หนองไฮ',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4366',
            label: 'ประชาพัฒนา',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4367',
            label: 'หนองทุ่ม',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4368',
            label: 'หนองแสน',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4369',
            label: 'โคกสีทองหลาง',
            zipcode: '44120'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '486',
            value: '4373',
            label: 'นาดูน',
            zipcode: '44180'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '486',
            value: '4374',
            label: 'หนองไผ่',
            zipcode: '44180'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '486',
            value: '4375',
            label: 'หนองคู',
            zipcode: '44180'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '486',
            value: '4376',
            label: 'ดงบัง',
            zipcode: '44180'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '486',
            value: '4377',
            label: 'ดงดวน',
            zipcode: '44180'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '486',
            value: '4378',
            label: 'หัวดง',
            zipcode: '44180'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '486',
            value: '4379',
            label: 'ดงยาง',
            zipcode: '44180'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '486',
            value: '4380',
            label: 'กู่สันตรัตน์',
            zipcode: '44180'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '486',
            value: '4381',
            label: 'พระธาตุ',
            zipcode: '44180'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '487',
            value: '4382',
            label: 'ยางสีสุราช',
            zipcode: '44210'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '487',
            value: '4383',
            label: 'นาภู',
            zipcode: '44210'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '487',
            value: '4384',
            label: 'แวงดง',
            zipcode: '44210'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '487',
            value: '4385',
            label: 'บ้านกู่',
            zipcode: '44210'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '487',
            value: '4386',
            label: 'ดงเมือง',
            zipcode: '44210'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '487',
            value: '4387',
            label: 'ขามเรียน',
            zipcode: '44210'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '487',
            value: '4388',
            label: 'หนองบัวสันตุ',
            zipcode: '44210'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '488',
            value: '4389',
            label: 'กุดรัง',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '488',
            value: '4390',
            label: 'นาโพธิ์',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '488',
            value: '4391',
            label: 'เลิงแฝก',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '488',
            value: '4392',
            label: 'หนองแวง',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '488',
            value: '4393',
            label: 'ห้วยเตย',
            zipcode: '44130'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '489',
            value: '4394',
            label: 'ชื่นชม',
            zipcode: '44160'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '489',
            value: '4395',
            label: 'กุดปลาดุก',
            zipcode: '44160'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '489',
            value: '4396',
            label: 'เหล่าดอกไม้',
            zipcode: '44160'
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '489',
            value: '4397',
            label: 'หนองกุง',
            zipcode: '44160'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4398',
            label: 'ในเมือง',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4399',
            label: 'รอบเมือง',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4400',
            label: 'เหนือเมือง',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4401',
            label: 'ขอนแก่น',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4402',
            label: 'นาโพธิ์',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4403',
            label: 'สะอาดสมบูรณ์',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4405',
            label: 'สีแก้ว',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4406',
            label: 'ปอภาร  (ปอพาน)',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4407',
            label: 'โนนรัง',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4414',
            label: 'หนองแก้ว',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4415',
            label: 'หนองแวง',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4417',
            label: 'ดงลาน',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4420',
            label: 'แคนใหญ่',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4421',
            label: 'โนนตาล',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4422',
            label: 'เมืองทอง',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4432',
            label: 'เกษตรวิสัย',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4433',
            label: 'เมืองบัว',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4434',
            label: 'เหล่าหลวง',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4435',
            label: 'สิงห์โคก',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4436',
            label: 'ดงครั่งใหญ่',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4437',
            label: 'บ้านฝาง',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4438',
            label: 'หนองแวง',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4439',
            label: 'กำแพง',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4440',
            label: 'กู่กาสิงห์',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4441',
            label: 'น้ำอ้อม',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4442',
            label: 'โนนสว่าง',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4443',
            label: 'ทุ่งทอง',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '492',
            value: '4444',
            label: 'ดงครั่งน้อย',
            zipcode: '45150'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '493',
            value: '4445',
            label: 'บัวแดง',
            zipcode: '45190'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '493',
            value: '4446',
            label: 'ดอกล้ำ',
            zipcode: '45190'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '493',
            value: '4447',
            label: 'หนองแคน',
            zipcode: '45190'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '493',
            value: '4448',
            label: 'โพนสูง',
            zipcode: '45190'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '493',
            value: '4449',
            label: 'โนนสวรรค์',
            zipcode: '45190'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '493',
            value: '4450',
            label: 'สระบัว',
            zipcode: '45190'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '493',
            value: '4451',
            label: 'โนนสง่า',
            zipcode: '45190'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '493',
            value: '4452',
            label: 'ขี้เหล็ก',
            zipcode: '45190'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '494',
            value: '4453',
            label: 'หัวช้าง',
            zipcode: '45180'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '494',
            value: '4454',
            label: 'หนองผือ',
            zipcode: '45180'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '494',
            value: '4455',
            label: 'เมืองหงส์',
            zipcode: '45180'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '494',
            value: '4456',
            label: 'โคกล่าม',
            zipcode: '45180'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '494',
            value: '4457',
            label: 'น้ำใส',
            zipcode: '45180'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '494',
            value: '4458',
            label: 'ดงแดง',
            zipcode: '45180'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '494',
            value: '4459',
            label: 'ดงกลาง',
            zipcode: '45180'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '494',
            value: '4460',
            label: 'ป่าสังข์',
            zipcode: '45180'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '494',
            value: '4461',
            label: 'อีง่อง',
            zipcode: '45180'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '494',
            value: '4462',
            label: 'ลิ้นฟ้า',
            zipcode: '45180'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '494',
            value: '4463',
            label: 'ดู่น้อย',
            zipcode: '45180'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '494',
            value: '4464',
            label: 'ศรีโคตร',
            zipcode: '45180'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4465',
            label: 'นิเวศน์',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4466',
            label: 'ธงธานี',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4467',
            label: 'หนองไผ่',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4468',
            label: 'ธวัชบุรี',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4470',
            label: 'อุ่มเม้า',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4471',
            label: 'มะอึ',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4474',
            label: 'เขวาทุ่ง',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4479',
            label: 'ไพศาล',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4481',
            label: 'เมืองน้อย',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4484',
            label: 'บึงนคร',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4486',
            label: 'ราชธานี',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4488',
            label: 'หนองพอก',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4489',
            label: 'พนมไพร',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4490',
            label: 'แสนสุข',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4491',
            label: 'กุดน้ำใส',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4492',
            label: 'หนองทัพไทย',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4493',
            label: 'โพธิ์ใหญ่',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4494',
            label: 'วารีสวัสดิ์',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4495',
            label: 'โคกสว่าง',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4499',
            label: 'โพธิ์ชัย',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4500',
            label: 'นานวล',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4501',
            label: 'คำไฮ',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4502',
            label: 'สระแก้ว',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4503',
            label: 'ค้อใหญ่',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4505',
            label: 'ชานุวรรณ',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4506',
            label: 'แวง',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4507',
            label: 'โคกกกม่วง',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4508',
            label: 'นาอุดม',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4509',
            label: 'สว่าง',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4510',
            label: 'หนองใหญ่',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4511',
            label: 'โพธิ์ทอง',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4512',
            label: 'โนนชัยศรี',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4513',
            label: 'โพธิ์ศรีสว่าง',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4514',
            label: 'อุ่มเม่า',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4515',
            label: 'คำนาดี',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4516',
            label: 'พรมสวรรค์',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4517',
            label: 'สระนกแก้ว',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4518',
            label: 'วังสามัคคี',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4519',
            label: 'โคกสูง',
            zipcode: '45110'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '498',
            value: '4526',
            label: 'ขามเปี้ย',
            zipcode: '45230'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '498',
            value: '4527',
            label: 'เชียงใหม่',
            zipcode: '45230'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '498',
            value: '4528',
            label: 'บัวคำ',
            zipcode: '45230'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '498',
            value: '4529',
            label: 'อัคคะคำ',
            zipcode: '45230'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '498',
            value: '4530',
            label: 'สะอาด',
            zipcode: '45230'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '498',
            value: '4531',
            label: 'คำพอุง',
            zipcode: '45230'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '498',
            value: '4532',
            label: 'หนองตาไก้',
            zipcode: '45230'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '498',
            value: '4533',
            label: 'ดอนโอง',
            zipcode: '45230'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '498',
            value: '4534',
            label: 'โพธิ์ศรี',
            zipcode: '45230'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '499',
            value: '4535',
            label: 'หนองพอก',
            zipcode: '45210'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '499',
            value: '4536',
            label: 'บึงงาม',
            zipcode: '45210'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '499',
            value: '4537',
            label: 'ภูเขาทอง',
            zipcode: '45210'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '499',
            value: '4538',
            label: 'กกโพธิ์',
            zipcode: '45210'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '499',
            value: '4539',
            label: 'โคกสว่าง',
            zipcode: '45210'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '499',
            value: '4540',
            label: 'หนองขุ่นใหญ่',
            zipcode: '45210'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '499',
            value: '4541',
            label: 'รอบเมือง',
            zipcode: '45210'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '499',
            value: '4542',
            label: 'ผาน้ำย้อย',
            zipcode: '45210'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '499',
            value: '4543',
            label: 'ท่าสีดา',
            zipcode: '45210'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4544',
            label: 'กลาง',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4545',
            label: 'นางาม',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4546',
            label: 'เมืองไพร',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4547',
            label: 'นาแซง',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4548',
            label: 'นาเมือง',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4549',
            label: 'วังหลวง',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4550',
            label: 'ท่าม่วง',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4551',
            label: 'ขวาว',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4552',
            label: 'โพธิ์ทอง',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4553',
            label: 'ภูเงิน',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4554',
            label: 'เกาะแก้ว',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4555',
            label: 'นาเลิง',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4556',
            label: 'เหล่าน้อย',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4557',
            label: 'ศรีวิลัย',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4558',
            label: 'หนองหลวง',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4559',
            label: 'พรสวรรค์',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4560',
            label: 'ขวัญเมือง',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '500',
            value: '4561',
            label: 'บึงเกลือ',
            zipcode: '45120'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4562',
            label: 'สระคู',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4563',
            label: 'ดอกไม้',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4564',
            label: 'นาใหญ่',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4565',
            label: 'หินกอง',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4566',
            label: 'เมืองทุ่ง',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4567',
            label: 'หัวโทน',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4568',
            label: 'บ่อพันขัน',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4569',
            label: 'ทุ่งหลวง',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4570',
            label: 'หัวช้าง',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4571',
            label: 'น้ำคำ',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4572',
            label: 'ห้วยหินลาด',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4573',
            label: 'ช้างเผือก',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4574',
            label: 'ทุ่งกุลา',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4575',
            label: 'ทุ่งศรีเมือง',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '501',
            value: '4576',
            label: 'จำปาขัน',
            zipcode: '45130'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '502',
            value: '4577',
            label: 'หนองผือ',
            zipcode: '45220'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '502',
            value: '4578',
            label: 'หนองหิน',
            zipcode: '45220'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '502',
            value: '4579',
            label: 'คูเมือง',
            zipcode: '45220'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '502',
            value: '4580',
            label: 'กกกุง',
            zipcode: '45220'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '502',
            value: '4581',
            label: 'เมืองสรวง',
            zipcode: '45220'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '503',
            value: '4582',
            label: 'โพนทราย',
            zipcode: '45240'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '503',
            value: '4583',
            label: 'สามขา',
            zipcode: '45240'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '503',
            value: '4584',
            label: 'ศรีสว่าง',
            zipcode: '45240'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '503',
            value: '4585',
            label: 'ยางคำ',
            zipcode: '45240'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '503',
            value: '4586',
            label: 'ท่าหาดยาว',
            zipcode: '45240'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '504',
            value: '4587',
            label: 'อาจสามารถ',
            zipcode: '45160'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '504',
            value: '4588',
            label: 'โพนเมือง',
            zipcode: '45160'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '504',
            value: '4589',
            label: 'บ้านแจ้ง',
            zipcode: '45160'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '504',
            value: '4590',
            label: 'หน่อม',
            zipcode: '45160'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '504',
            value: '4591',
            label: 'หนองหมื่นถ่าน',
            zipcode: '45160'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '504',
            value: '4592',
            label: 'หนองขาม',
            zipcode: '45160'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '504',
            value: '4593',
            label: 'โหรา',
            zipcode: '45160'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '504',
            value: '4594',
            label: 'หนองบัว',
            zipcode: '45160'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '504',
            value: '4595',
            label: 'ขี้เหล็ก',
            zipcode: '45160'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '504',
            value: '4596',
            label: 'บ้านดู่',
            zipcode: '45160'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '505',
            value: '4597',
            label: 'เมยวดี',
            zipcode: '45250'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '505',
            value: '4598',
            label: 'ชุมพร',
            zipcode: '45250'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '505',
            value: '4599',
            label: 'บุ่งเลิศ',
            zipcode: '45250'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '505',
            value: '4600',
            label: 'ชมสะอาด',
            zipcode: '45250'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '506',
            value: '4601',
            label: 'โพธิ์ทอง',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '506',
            value: '4602',
            label: 'ศรีสมเด็จ',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '506',
            value: '4603',
            label: 'เมืองเปลือย',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '506',
            value: '4604',
            label: 'หนองใหญ่',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '506',
            value: '4605',
            label: 'สวนจิก',
            zipcode: '45280'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '506',
            value: '4606',
            label: 'โพธิ์สัย',
            zipcode: '45280'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '506',
            value: '4607',
            label: 'หนองแวงควง',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '506',
            value: '4608',
            label: 'บ้านบาก',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '507',
            value: '4609',
            label: 'ดินดำ',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '507',
            value: '4610',
            label: 'ปาฝา',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '507',
            value: '4611',
            label: 'ม่วงลาด',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '507',
            value: '4612',
            label: 'จังหาร',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '507',
            value: '4613',
            label: 'ดงสิงห์',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '507',
            value: '4614',
            label: 'ยางใหญ่',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '507',
            value: '4615',
            label: 'ผักแว่น',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '507',
            value: '4616',
            label: 'แสนชาติ',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '508',
            value: '4617',
            label: 'เชียงขวัญ',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '508',
            value: '4618',
            label: 'พลับพลา',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '508',
            value: '4619',
            label: 'พระธาตุ',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '508',
            value: '4620',
            label: 'พระเจ้า',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '508',
            value: '4621',
            label: 'หมูม้น',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '508',
            value: '4622',
            label: 'บ้านเขือง',
            zipcode: '45000'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '509',
            value: '4623',
            label: 'หนองฮี',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '509',
            value: '4624',
            label: 'สาวแห',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '509',
            value: '4625',
            label: 'ดูกอึ่ง',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '509',
            value: '4626',
            label: 'เด่นราษฎร์',
            zipcode: '45140'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '510',
            value: '4627',
            label: 'ทุ่งเขาหลวง',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '510',
            value: '4628',
            label: 'เทอดไทย',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '510',
            value: '4629',
            label: 'บึงงาม',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '510',
            value: '4630',
            label: 'มะบ้า',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '510',
            value: '4631',
            label: 'เหล่า',
            zipcode: '45170'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4632',
            label: 'กาฬสินธุ์',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4633',
            label: 'เหนือ',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4634',
            label: 'หลุบ',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4635',
            label: 'ไผ่',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4636',
            label: 'ลำปาว',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4637',
            label: 'ลำพาน',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4638',
            label: 'เชียงเครือ',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4639',
            label: 'บึงวิชัย',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4640',
            label: 'ห้วยโพธิ์',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4642',
            label: 'ภูปอ',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4644',
            label: 'ภูดิน',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4646',
            label: 'หนองกุง',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4647',
            label: 'กลางหมื่น',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4648',
            label: 'ขมิ้น',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4650',
            label: 'โพนทอง',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4651',
            label: 'นาจารย์',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4652',
            label: 'ลำคลอง',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '512',
            value: '4656',
            label: 'นามน',
            zipcode: '46230'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '512',
            value: '4657',
            label: 'ยอดแกง',
            zipcode: '46230'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '512',
            value: '4658',
            label: 'สงเปลือย',
            zipcode: '46230'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '512',
            value: '4659',
            label: 'หลักเหลี่ยม',
            zipcode: '46230'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '512',
            value: '4660',
            label: 'หนองบัว',
            zipcode: '46230'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4661',
            label: 'กมลาไสย',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4662',
            label: 'หลักเมือง',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4663',
            label: 'โพนงาม',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4664',
            label: 'ดงลิง',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4665',
            label: 'ธัญญา',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4668',
            label: 'หนองแปน',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4670',
            label: 'เจ้าท่า',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4671',
            label: 'โคกสมบูรณ์',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '514',
            value: '4674',
            label: 'ร่องคำ',
            zipcode: '46210'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '514',
            value: '4675',
            label: 'สามัคคี',
            zipcode: '46210'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '514',
            value: '4676',
            label: 'เหล่าอ้อย',
            zipcode: '46210'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '515',
            value: '4677',
            label: 'บัวขาว',
            zipcode: '46110'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '515',
            value: '4678',
            label: 'แจนแลน',
            zipcode: '46110'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '515',
            value: '4679',
            label: 'เหล่าใหญ่',
            zipcode: '46110'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '515',
            value: '4680',
            label: 'จุมจัง',
            zipcode: '46110'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '515',
            value: '4681',
            label: 'เหล่าไฮงาม',
            zipcode: '46110'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '515',
            value: '4682',
            label: 'กุดหว้า',
            zipcode: '46110'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '515',
            value: '4683',
            label: 'สามขา',
            zipcode: '46110'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '515',
            value: '4684',
            label: 'นาขาม',
            zipcode: '46110'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '515',
            value: '4685',
            label: 'หนองห้าง',
            zipcode: '46110'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '515',
            value: '4686',
            label: 'นาโก',
            zipcode: '46110'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '515',
            value: '4687',
            label: 'สมสะอาด',
            zipcode: '46110'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '515',
            value: '4688',
            label: 'กุดค้าว',
            zipcode: '46110'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '516',
            value: '4689',
            label: 'คุ้มเก่า',
            zipcode: '46160'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '516',
            value: '4690',
            label: 'สงเปลือย',
            zipcode: '46160'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '516',
            value: '4691',
            label: 'หนองผือ',
            zipcode: '46160'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '516',
            value: '4694',
            label: 'กุดสิมคุ้มใหม่',
            zipcode: '46160'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '516',
            value: '4696',
            label: 'สระพังทอง',
            zipcode: '46160'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '516',
            value: '4699',
            label: 'กุดปลาค้าว',
            zipcode: '46160'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4700',
            label: 'ยางตลาด',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4701',
            label: 'หัวงัว',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4702',
            label: 'อุ่มเม่า',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4703',
            label: 'บัวบาน',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4704',
            label: 'เว่อ',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4705',
            label: 'อิตื้อ',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4706',
            label: 'หัวนาคำ',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4707',
            label: 'หนองอิเฒ่า',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4708',
            label: 'ดอนสมบูรณ์',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4709',
            label: 'นาเชือก',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4710',
            label: 'คลองขาม',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4711',
            label: 'เขาพระนอน',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4712',
            label: 'นาดี',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4713',
            label: 'โนนสูง',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '517',
            value: '4714',
            label: 'หนองตอกแป้น',
            zipcode: '46120'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '518',
            value: '4715',
            label: 'ห้วยเม็ก',
            zipcode: '46170'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '518',
            value: '4716',
            label: 'คำใหญ่',
            zipcode: '46170'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '518',
            value: '4717',
            label: 'กุดโดน',
            zipcode: '46170'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '518',
            value: '4718',
            label: 'บึงนาเรียง',
            zipcode: '46170'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '518',
            value: '4719',
            label: 'หัวหิน',
            zipcode: '46170'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '518',
            value: '4720',
            label: 'พิมูล',
            zipcode: '46170'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '518',
            value: '4721',
            label: 'คำเหมือดแก้ว',
            zipcode: '46170'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '518',
            value: '4722',
            label: 'โนนสะอาด',
            zipcode: '46170'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '518',
            value: '4723',
            label: 'ทรายทอง',
            zipcode: '46170'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '519',
            value: '4724',
            label: 'ภูสิงห์',
            zipcode: '46140'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '519',
            value: '4725',
            label: 'สหัสขันธ์',
            zipcode: '46140'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '519',
            value: '4726',
            label: 'นามะเขือ',
            zipcode: '46140'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '519',
            value: '4727',
            label: 'โนนศิลา',
            zipcode: '46140'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '519',
            value: '4728',
            label: 'นิคม',
            zipcode: '46140'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '519',
            value: '4729',
            label: 'โนนแหลมทอง',
            zipcode: '46140'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '519',
            value: '4730',
            label: 'โนนบุรี',
            zipcode: '46140'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '519',
            value: '4731',
            label: 'โนนน้ำเกลี้ยง',
            zipcode: '46140'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '520',
            value: '4736',
            label: 'ทุ่งคลอง',
            zipcode: '46180'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '520',
            value: '4737',
            label: 'โพน',
            zipcode: '46180'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '520',
            value: '4740',
            label: 'ดินจี่',
            zipcode: '46180'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '520',
            value: '4741',
            label: 'นาบอน',
            zipcode: '46180'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '520',
            value: '4742',
            label: 'นาทัน',
            zipcode: '46180'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '520',
            value: '4744',
            label: 'เนินยาง',
            zipcode: '46180'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '521',
            value: '4746',
            label: 'ท่าคันโท',
            zipcode: '46190'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '521',
            value: '4747',
            label: 'กุงเก่า',
            zipcode: '46190'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '521',
            value: '4748',
            label: 'ยางอู้ม',
            zipcode: '46190'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '521',
            value: '4749',
            label: 'กุดจิก',
            zipcode: '46190'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '521',
            value: '4750',
            label: 'นาตาล',
            zipcode: '46190'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '521',
            value: '4751',
            label: 'ดงสมบูรณ์',
            zipcode: '46190'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '522',
            value: '4754',
            label: 'หนองกุงศรี',
            zipcode: '46220'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '522',
            value: '4755',
            label: 'หนองบัว',
            zipcode: '46220'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '522',
            value: '4756',
            label: 'โคกเครือ',
            zipcode: '46220'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '522',
            value: '4757',
            label: 'หนองสรวง',
            zipcode: '46220'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '522',
            value: '4758',
            label: 'เสาเล้า',
            zipcode: '46220'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '522',
            value: '4759',
            label: 'หนองใหญ่',
            zipcode: '46220'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '522',
            value: '4760',
            label: 'ดงมูล',
            zipcode: '46220'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '522',
            value: '4761',
            label: 'ลำหนองแสน',
            zipcode: '46220'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '522',
            value: '4762',
            label: 'หนองหิน',
            zipcode: '46220'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '523',
            value: '4763',
            label: 'สมเด็จ',
            zipcode: '46150'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '523',
            value: '4764',
            label: 'หนองแวง',
            zipcode: '46150'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '523',
            value: '4765',
            label: 'แซงบาดาล',
            zipcode: '46150'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '523',
            value: '4766',
            label: 'มหาไชย',
            zipcode: '46150'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '523',
            value: '4767',
            label: 'หมูม่น',
            zipcode: '46150'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '523',
            value: '4768',
            label: 'ผาเสวย',
            zipcode: '46150'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '523',
            value: '4769',
            label: 'ศรีสมเด็จ',
            zipcode: '46150'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '523',
            value: '4770',
            label: 'ลำห้วยหลัว',
            zipcode: '46150'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '524',
            value: '4771',
            label: 'คำบง',
            zipcode: '46240'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '524',
            value: '4772',
            label: 'ไค้นุ่น',
            zipcode: '46240'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '524',
            value: '4773',
            label: 'นิคมห้วยผึ้ง',
            zipcode: '46240'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '524',
            value: '4774',
            label: 'หนองอีบุตร',
            zipcode: '46240'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '525',
            value: '4775',
            label: 'สำราญ',
            zipcode: '46180'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '525',
            value: '4776',
            label: 'สำราญใต้',
            zipcode: '46180'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '525',
            value: '4777',
            label: 'คำสร้างเที่ยง',
            zipcode: '46180'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '525',
            value: '4778',
            label: 'หนองช้าง',
            zipcode: '46180'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '526',
            value: '4779',
            label: 'นาคู',
            zipcode: '46160'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '526',
            value: '4780',
            label: 'สายนาวัง',
            zipcode: '46160'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '526',
            value: '4781',
            label: 'โนนนาจาน',
            zipcode: '46160'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '526',
            value: '4782',
            label: 'บ่อแก้ว',
            zipcode: '46160'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '526',
            value: '4783',
            label: 'ภูแล่นช้าง',
            zipcode: '46160'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '527',
            value: '4784',
            label: 'ดอนจาน',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '527',
            value: '4785',
            label: 'สะอาดไชยศรี',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '527',
            value: '4786',
            label: 'ดงพยุง',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '527',
            value: '4787',
            label: 'ม่วงนา',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '527',
            value: '4788',
            label: 'นาจำปา',
            zipcode: '46000'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '528',
            value: '4789',
            label: 'ฆ้องชัยพัฒนา',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '528',
            value: '4790',
            label: 'เหล่ากลาง',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '528',
            value: '4791',
            label: 'โคกสะอาด',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '528',
            value: '4792',
            label: 'โนนศิลาเลิง',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '528',
            value: '4793',
            label: 'ลำชี',
            zipcode: '46130'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4794',
            label: 'ธาตุเชิงชุม',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4795',
            label: 'ขมิ้น',
            zipcode: '47220'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4796',
            label: 'งิ้วด่อน',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4797',
            label: 'โนนหอม',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4799',
            label: 'เชียงเครือ',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4800',
            label: 'ท่าแร่',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4802',
            label: 'ม่วงลาย',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4804',
            label: 'ดงชน',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4805',
            label: 'ห้วยยาง',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4806',
            label: 'พังขว้าง',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4808',
            label: 'ดงมะไฟ',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4809',
            label: 'ธาตุนาเวง',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4810',
            label: 'เหล่าปอแดง',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4811',
            label: 'หนองลาด',
            zipcode: '47220'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4813',
            label: 'ฮางโฮง',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4814',
            label: 'โคกก่อง',
            zipcode: '47000'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '530',
            value: '4821',
            label: 'กุสุมาลย์',
            zipcode: '47210'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '530',
            value: '4822',
            label: 'นาโพธิ์',
            zipcode: '47210'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '530',
            value: '4823',
            label: 'นาเพียง',
            zipcode: '47230'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '530',
            value: '4824',
            label: 'โพธิไพศาล',
            zipcode: '47210'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '530',
            value: '4825',
            label: 'อุ่มจาน',
            zipcode: '47230'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '531',
            value: '4826',
            label: 'กุดบาก',
            zipcode: '47180'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '531',
            value: '4828',
            label: 'นาม่อง',
            zipcode: '47180'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '531',
            value: '4830',
            label: 'กุดไห',
            zipcode: '47180'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '532',
            value: '4832',
            label: 'พรรณา',
            zipcode: '47130'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '532',
            value: '4833',
            label: 'วังยาง',
            zipcode: '47130'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '532',
            value: '4834',
            label: 'พอกน้อย',
            zipcode: '47220'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '532',
            value: '4835',
            label: 'นาหัวบ่อ',
            zipcode: '47220'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '532',
            value: '4836',
            label: 'ไร่',
            zipcode: '47130'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '532',
            value: '4837',
            label: 'ช้างมิ่ง',
            zipcode: '47130'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '532',
            value: '4838',
            label: 'นาใน',
            zipcode: '47130'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '532',
            value: '4839',
            label: 'สว่าง',
            zipcode: '47130'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '532',
            value: '4840',
            label: 'บะฮี',
            zipcode: '47130'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '532',
            value: '4841',
            label: 'เชิงชุม',
            zipcode: '47130'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '533',
            value: '4842',
            label: 'พังโคน',
            zipcode: '47160'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '533',
            value: '4843',
            label: 'ม่วงไข่',
            zipcode: '47160'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '533',
            value: '4844',
            label: 'แร่',
            zipcode: '47160'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '533',
            value: '4845',
            label: 'ไฮหย่อง',
            zipcode: '47160'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '533',
            value: '4846',
            label: 'ต้นผึ้ง',
            zipcode: '47160'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '534',
            value: '4849',
            label: 'วาริชภูมิ',
            zipcode: '47150'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '534',
            value: '4850',
            label: 'ปลาโหล',
            zipcode: '47150'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '534',
            value: '4851',
            label: 'หนองลาด',
            zipcode: '47150'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '534',
            value: '4852',
            label: 'คำบ่อ',
            zipcode: '47150'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '534',
            value: '4853',
            label: 'ค้อเขียว',
            zipcode: '47150'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '535',
            value: '4854',
            label: 'นิคมน้ำอูน',
            zipcode: '47270'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '535',
            value: '4855',
            label: 'หนองปลิง',
            zipcode: '47270'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '535',
            value: '4856',
            label: 'หนองบัว',
            zipcode: '47270'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '535',
            value: '4857',
            label: 'สุวรรณคาม',
            zipcode: '47270'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4858',
            label: 'วานรนิวาส',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4859',
            label: 'เดื่อศรีคันไชย',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4860',
            label: 'ขัวก่าย',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4861',
            label: 'หนองสนม',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4862',
            label: 'คูสะคาม',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4863',
            label: 'ธาตุ',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4864',
            label: 'หนองแวง',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4865',
            label: 'ศรีวิชัย',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4866',
            label: 'นาซอ',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4867',
            label: 'อินทร์แปลง',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4868',
            label: 'นาคำ',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4869',
            label: 'คอนสวรรค์',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4870',
            label: 'กุดเรือคำ',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '536',
            value: '4871',
            label: 'หนองแวงใต้',
            zipcode: '47120'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '537',
            value: '4872',
            label: 'คำตากล้า',
            zipcode: '47250'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '537',
            value: '4873',
            label: 'หนองบัวสิม',
            zipcode: '47250'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '537',
            value: '4874',
            label: 'นาแต้',
            zipcode: '47250'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '537',
            value: '4875',
            label: 'แพด',
            zipcode: '47250'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '538',
            value: '4876',
            label: 'ม่วง',
            zipcode: '47140'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '538',
            value: '4877',
            label: 'มาย',
            zipcode: '47140'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '538',
            value: '4878',
            label: 'ดงหม้อทอง',
            zipcode: '47140'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '538',
            value: '4879',
            label: 'ดงเหนือ',
            zipcode: '47140'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '538',
            value: '4880',
            label: 'ดงหม้อทองใต้',
            zipcode: '47140'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '538',
            value: '4881',
            label: 'ห้วยหลัว',
            zipcode: '47140'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '538',
            value: '4882',
            label: 'โนนสะอาด',
            zipcode: '47140'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '538',
            value: '4883',
            label: 'หนองกวั่ง',
            zipcode: '47140'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '538',
            value: '4884',
            label: 'บ่อแก้ว',
            zipcode: '47140'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '539',
            value: '4885',
            label: 'อากาศ',
            zipcode: '47170'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '539',
            value: '4886',
            label: 'โพนแพง',
            zipcode: '47170'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '539',
            value: '4887',
            label: 'วาใหญ่',
            zipcode: '47170'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '539',
            value: '4888',
            label: 'โพนงาม',
            zipcode: '47170'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '539',
            value: '4889',
            label: 'ท่าก้อน',
            zipcode: '47170'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '539',
            value: '4890',
            label: 'นาฮี',
            zipcode: '47170'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '539',
            value: '4891',
            label: 'บะหว้า',
            zipcode: '47170'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '539',
            value: '4892',
            label: 'สามัคคีพัฒนา',
            zipcode: '47170'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4893',
            label: 'สว่างแดนดิน',
            zipcode: '47110'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4895',
            label: 'คำสะอาด',
            zipcode: '47110'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4896',
            label: 'บ้านต้าย',
            zipcode: '47110'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4898',
            label: 'บงเหนือ',
            zipcode: '47110'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4899',
            label: 'โพนสูง',
            zipcode: '47110'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4900',
            label: 'โคกสี',
            zipcode: '47110'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4902',
            label: 'หนองหลวง',
            zipcode: '47110'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4903',
            label: 'บงใต้',
            zipcode: '47110'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4904',
            label: 'ค้อใต้',
            zipcode: '47110'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4905',
            label: 'พันนา',
            zipcode: '47240'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4906',
            label: 'แวง',
            zipcode: '47240'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4907',
            label: 'ทรายมูล',
            zipcode: '47110'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4908',
            label: 'ตาลโกน',
            zipcode: '47240'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4909',
            label: 'ตาลเนิ้ง',
            zipcode: '47240'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4912',
            label: 'ธาตุทอง',
            zipcode: '47240'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4913',
            label: 'บ้านถ่อน',
            zipcode: '47110'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '541',
            value: '4914',
            label: 'ส่องดาว',
            zipcode: '47190'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '541',
            value: '4915',
            label: 'ท่าศิลา',
            zipcode: '47190'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '541',
            value: '4916',
            label: 'วัฒนา',
            zipcode: '47190'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '541',
            value: '4917',
            label: 'ปทุมวาปี',
            zipcode: '47190'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '542',
            value: '4918',
            label: 'เต่างอย',
            zipcode: '47260'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '542',
            value: '4919',
            label: 'บึงทวาย',
            zipcode: '47260'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '542',
            value: '4920',
            label: 'นาตาล',
            zipcode: '47260'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '542',
            value: '4921',
            label: 'จันทร์เพ็ญ',
            zipcode: '47260'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '543',
            value: '4922',
            label: 'ตองโขบ',
            zipcode: '47280'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '543',
            value: '4923',
            label: 'เหล่าโพนค้อ',
            zipcode: '47280'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '543',
            value: '4924',
            label: 'ด่านม่วงคำ',
            zipcode: '47280'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '543',
            value: '4925',
            label: 'แมดนาท่ม',
            zipcode: '47280'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '544',
            value: '4926',
            label: 'บ้านเหล่า',
            zipcode: '47290'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '544',
            value: '4927',
            label: 'เจริญศิลป์',
            zipcode: '47290'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '544',
            value: '4928',
            label: 'ทุ่งแก',
            zipcode: '47290'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '544',
            value: '4929',
            label: 'โคกศิลา',
            zipcode: '47290'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '544',
            value: '4930',
            label: 'หนองแปน',
            zipcode: '47290'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '545',
            value: '4931',
            label: 'บ้านโพน',
            zipcode: '47230'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '545',
            value: '4932',
            label: 'นาแก้ว',
            zipcode: '47230'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '545',
            value: '4933',
            label: 'นาตงวัฒนา',
            zipcode: '47230'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '545',
            value: '4934',
            label: 'บ้านแป้น',
            zipcode: '47230'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '545',
            value: '4935',
            label: 'เชียงสือ',
            zipcode: '47230'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '546',
            value: '4936',
            label: 'สร้างค้อ',
            zipcode: '47180'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '546',
            value: '4937',
            label: 'หลุบเลา',
            zipcode: '47180'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '546',
            value: '4938',
            label: 'โคกภู',
            zipcode: '47180'
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '546',
            value: '4939',
            label: 'กกปลาซิว',
            zipcode: '47180'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4940',
            label: 'ในเมือง',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4941',
            label: 'หนองแสง',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4942',
            label: 'นาทราย',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4943',
            label: 'นาราชควาย',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4944',
            label: 'กุรุคุ',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4945',
            label: 'บ้านผึ้ง',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4946',
            label: 'อาจสามารถ',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4947',
            label: 'ขามเฒ่า',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4948',
            label: 'บ้านกลาง',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4949',
            label: 'ท่าค้อ',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4950',
            label: 'คำเตย',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4951',
            label: 'หนองญาติ',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4952',
            label: 'ดงขวาง',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4953',
            label: 'วังตามัว',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '549',
            value: '4954',
            label: 'โพธิ์ตาก',
            zipcode: '48000'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '550',
            value: '4955',
            label: 'ปลาปาก',
            zipcode: '48160'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '550',
            value: '4956',
            label: 'หนองฮี',
            zipcode: '48160'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '550',
            value: '4957',
            label: 'กุตาไก้',
            zipcode: '48160'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '550',
            value: '4958',
            label: 'โคกสว่าง',
            zipcode: '48160'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '550',
            value: '4959',
            label: 'โคกสูง',
            zipcode: '48160'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '550',
            value: '4960',
            label: 'มหาชัย',
            zipcode: '48160'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '550',
            value: '4961',
            label: 'นามะเขือ',
            zipcode: '48160'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '550',
            value: '4962',
            label: 'หนองเทาใหญ่',
            zipcode: '48160'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4963',
            label: 'ท่าอุเทน',
            zipcode: '48120'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4964',
            label: 'โนนตาล',
            zipcode: '48120'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4965',
            label: 'ท่าจำปา',
            zipcode: '48120'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4966',
            label: 'ไชยบุรี',
            zipcode: '48120'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4967',
            label: 'พนอม',
            zipcode: '48120'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4968',
            label: 'พะทาย',
            zipcode: '48120'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4973',
            label: 'เวินพระบาท',
            zipcode: '48120'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4974',
            label: 'รามราช',
            zipcode: '48120'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4976',
            label: 'หนองเทา',
            zipcode: '48120'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '552',
            value: '4977',
            label: 'บ้านแพง',
            zipcode: '48140'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '552',
            value: '4978',
            label: 'ไผ่ล้อม',
            zipcode: '48140'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '552',
            value: '4979',
            label: 'โพนทอง',
            zipcode: '48140'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '552',
            value: '4980',
            label: 'หนองแวง',
            zipcode: '48140'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '552',
            value: '4984',
            label: 'นางัว',
            zipcode: '48140'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '552',
            value: '4985',
            label: 'นาเข',
            zipcode: '48140'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '553',
            value: '4986',
            label: 'ธาตุพนม',
            zipcode: '48110'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '553',
            value: '4987',
            label: 'ฝั่งแดง',
            zipcode: '48110'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '553',
            value: '4988',
            label: 'โพนแพง',
            zipcode: '48110'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '553',
            value: '4989',
            label: 'พระกลางทุ่ง',
            zipcode: '48110'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '553',
            value: '4990',
            label: 'นาถ่อน',
            zipcode: '48110'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '553',
            value: '4991',
            label: 'แสนพัน',
            zipcode: '48110'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '553',
            value: '4992',
            label: 'ดอนนางหงส์',
            zipcode: '48110'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '553',
            value: '4993',
            label: 'น้ำก่ำ',
            zipcode: '48110'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '553',
            value: '4994',
            label: 'อุ่มเหม้า',
            zipcode: '48110'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '553',
            value: '4995',
            label: 'นาหนาด',
            zipcode: '48110'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '553',
            value: '4996',
            label: 'กุดฉิม',
            zipcode: '48110'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '553',
            value: '4997',
            label: 'ธาตุพนมเหนือ',
            zipcode: '48110'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '554',
            value: '4998',
            label: 'เรณู',
            zipcode: '48170'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '554',
            value: '4999',
            label: 'โพนทอง',
            zipcode: '48170'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '554',
            value: '5000',
            label: 'ท่าลาด',
            zipcode: '48170'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '554',
            value: '5001',
            label: 'นางาม',
            zipcode: '48170'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '554',
            value: '5002',
            label: 'โคกหินแฮ่',
            zipcode: '48170'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '554',
            value: '5004',
            label: 'หนองย่างชิ้น',
            zipcode: '48170'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '554',
            value: '5005',
            label: 'เรณูใต้',
            zipcode: '48170'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '554',
            value: '5006',
            label: 'นาขาม',
            zipcode: '48170'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5007',
            label: 'นาแก',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5008',
            label: 'พระซอง',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5009',
            label: 'หนองสังข์',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5010',
            label: 'นาคู่',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5011',
            label: 'พิมาน',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5012',
            label: 'พุ่มแก',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5013',
            label: 'ก้านเหลือง',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5014',
            label: 'หนองบ่อ',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5015',
            label: 'นาเลียง',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5018',
            label: 'บ้านแก้ง',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5019',
            label: 'คำพี้',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5021',
            label: 'สีชมพู',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '556',
            value: '5023',
            label: 'ศรีสงคราม',
            zipcode: '48150'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '556',
            value: '5024',
            label: 'นาเดื่อ',
            zipcode: '48150'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '556',
            value: '5025',
            label: 'บ้านเอื้อง',
            zipcode: '48150'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '556',
            value: '5026',
            label: 'สามผง',
            zipcode: '48150'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '556',
            value: '5027',
            label: 'ท่าบ่อสงคราม',
            zipcode: '48150'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '556',
            value: '5028',
            label: 'บ้านข่า',
            zipcode: '48150'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '556',
            value: '5029',
            label: 'นาคำ',
            zipcode: '48150'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '556',
            value: '5030',
            label: 'โพนสว่าง',
            zipcode: '48150'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '556',
            value: '5031',
            label: 'หาดแพง',
            zipcode: '48150'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '557',
            value: '5032',
            label: 'นาหว้า',
            zipcode: '48180'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '557',
            value: '5033',
            label: 'นางัว',
            zipcode: '48180'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '557',
            value: '5034',
            label: 'บ้านเสียว',
            zipcode: '48180'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '557',
            value: '5035',
            label: 'นาคูณใหญ่',
            zipcode: '48180'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '557',
            value: '5036',
            label: 'เหล่าพัฒนา',
            zipcode: '48180'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '557',
            value: '5037',
            label: 'ท่าเรือ',
            zipcode: '48180'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '558',
            value: '5038',
            label: 'โพนสวรรค์',
            zipcode: '48190'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '558',
            value: '5039',
            label: 'นาหัวบ่อ',
            zipcode: '48190'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '558',
            value: '5040',
            label: 'นาขมิ้น',
            zipcode: '48190'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '558',
            value: '5041',
            label: 'โพนบก',
            zipcode: '48190'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '558',
            value: '5042',
            label: 'บ้านค้อ',
            zipcode: '48190'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '558',
            value: '5043',
            label: 'โพนจาน',
            zipcode: '48190'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '558',
            value: '5044',
            label: 'นาใน',
            zipcode: '48190'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '559',
            value: '5045',
            label: 'นาทม',
            zipcode: '48140'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '559',
            value: '5046',
            label: 'หนองซน',
            zipcode: '48140'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '559',
            value: '5047',
            label: 'ดอนเตย',
            zipcode: '48140'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '560',
            value: '5048',
            label: 'วังยาง',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '560',
            value: '5049',
            label: 'โคกสี',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '560',
            value: '5050',
            label: 'ยอดชาด',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '560',
            value: '5051',
            label: 'หนองโพธิ์',
            zipcode: '48130'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5052',
            label: 'มุกดาหาร',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5053',
            label: 'ศรีบุญเรือง',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5054',
            label: 'บ้านโคก',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5055',
            label: 'บางทรายใหญ่',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5056',
            label: 'โพนทราย',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5057',
            label: 'ผึ่งแดด',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5058',
            label: 'นาโสก',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5059',
            label: 'นาสีนวน',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5060',
            label: 'คำป่าหลาย',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5061',
            label: 'คำอาฮวน',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5062',
            label: 'ดงเย็น',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5063',
            label: 'ดงมอน',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5064',
            label: 'กุดแข้',
            zipcode: '49000'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '562',
            value: '5071',
            label: 'นิคมคำสร้อย',
            zipcode: '49130'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '562',
            value: '5072',
            label: 'นากอก',
            zipcode: '49130'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '562',
            value: '5073',
            label: 'หนองแวง',
            zipcode: '49130'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '562',
            value: '5074',
            label: 'กกแดง',
            zipcode: '49130'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '562',
            value: '5075',
            label: 'นาอุดม',
            zipcode: '49130'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '562',
            value: '5076',
            label: 'โชคชัย',
            zipcode: '49130'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '562',
            value: '5077',
            label: 'ร่มเกล้า',
            zipcode: '49130'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '563',
            value: '5078',
            label: 'ดอนตาล',
            zipcode: '49120'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '563',
            value: '5079',
            label: 'โพธิ์ไทร',
            zipcode: '49120'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '563',
            value: '5080',
            label: 'ป่าไร่',
            zipcode: '49120'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '563',
            value: '5081',
            label: 'เหล่าหมี',
            zipcode: '49120'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '563',
            value: '5082',
            label: 'บ้านบาก',
            zipcode: '49120'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '563',
            value: '5083',
            label: 'นาสะเม็ง',
            zipcode: '49120'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '563',
            value: '5084',
            label: 'บ้านแก้ง',
            zipcode: '49120'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '564',
            value: '5085',
            label: 'ดงหลวง',
            zipcode: '49140'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '564',
            value: '5086',
            label: 'หนองบัว',
            zipcode: '49140'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '564',
            value: '5087',
            label: 'กกตูม',
            zipcode: '49140'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '564',
            value: '5088',
            label: 'หนองแคน',
            zipcode: '49140'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '564',
            value: '5089',
            label: 'ชะโนดน้อย',
            zipcode: '49140'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '564',
            value: '5090',
            label: 'พังแดง',
            zipcode: '49140'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5093',
            label: 'บ้านซ่ง',
            zipcode: '49110'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5094',
            label: 'คำชะอี',
            zipcode: '49110'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5095',
            label: 'หนองเอี่ยน',
            zipcode: '49110'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5096',
            label: 'บ้านค้อ',
            zipcode: '49110'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5097',
            label: 'บ้านเหล่า',
            zipcode: '49110'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5098',
            label: 'โพนงาม',
            zipcode: '49110'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5101',
            label: 'เหล่าสร้างถ่อ',
            zipcode: '49110'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5102',
            label: 'คำบก',
            zipcode: '49110'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5104',
            label: 'น้ำเที่ยง',
            zipcode: '49110'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '566',
            value: '5108',
            label: 'หว้านใหญ่',
            zipcode: '49150'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '566',
            value: '5109',
            label: 'ป่งขาม',
            zipcode: '49150'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '566',
            value: '5110',
            label: 'บางทรายน้อย',
            zipcode: '49150'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '566',
            value: '5111',
            label: 'ชะโนด',
            zipcode: '49150'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '566',
            value: '5112',
            label: 'ดงหมู',
            zipcode: '49150'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '567',
            value: '5113',
            label: 'หนองสูง',
            zipcode: '49160'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '567',
            value: '5114',
            label: 'โนนยาง',
            zipcode: '49160'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '567',
            value: '5115',
            label: 'ภูวง',
            zipcode: '49160'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '567',
            value: '5116',
            label: 'บ้านเป้า',
            zipcode: '49160'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '567',
            value: '5117',
            label: 'หนองสูงใต้',
            zipcode: '49160'
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '567',
            value: '5118',
            label: 'หนองสูงเหนือ',
            zipcode: '49160'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5119',
            label: 'ศรีภูมิ',
            zipcode: '50200'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5120',
            label: 'พระสิงห์',
            zipcode: '50200'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5121',
            label: 'หายยา',
            zipcode: '50100'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5122',
            label: 'ช้างม่อย',
            zipcode: '50300'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5123',
            label: 'ช้างคลาน',
            zipcode: '50100'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5124',
            label: 'วัดเกต',
            zipcode: '50000'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5125',
            label: 'ช้างเผือก',
            zipcode: '50300'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5126',
            label: 'สุเทพ',
            zipcode: '50200'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5127',
            label: 'แม่เหียะ',
            zipcode: '50100'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5128',
            label: 'ป่าแดด',
            zipcode: '50100'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5129',
            label: 'หนองหอย',
            zipcode: '50000'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5130',
            label: 'ท่าศาลา',
            zipcode: '50000'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5131',
            label: 'หนองป่าครั่ง',
            zipcode: '50000'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5132',
            label: 'ฟ้าฮ่าม',
            zipcode: '50000'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5133',
            label: 'ป่าตัน',
            zipcode: '50300'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '568',
            value: '5134',
            label: 'สันผีเสื้อ',
            zipcode: '50300'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '569',
            value: '5137',
            label: 'บ้านหลวง',
            zipcode: '50160'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '569',
            value: '5138',
            label: 'ข่วงเปา',
            zipcode: '50160'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '569',
            value: '5139',
            label: 'สบเตี๊ยะ',
            zipcode: '50160'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '569',
            value: '5140',
            label: 'บ้านแปะ',
            zipcode: '50240'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '569',
            value: '5141',
            label: 'ดอยแก้ว',
            zipcode: '50160'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '569',
            value: '5143',
            label: 'แม่สอย',
            zipcode: '50240'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '570',
            value: '5145',
            label: 'ช่างเคิ่ง',
            zipcode: '50270'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '570',
            value: '5146',
            label: 'ท่าผา',
            zipcode: '50270'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '570',
            value: '5147',
            label: 'บ้านทับ',
            zipcode: '50270'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '570',
            value: '5148',
            label: 'แม่ศึก',
            zipcode: '50270'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '570',
            value: '5149',
            label: 'แม่นาจร',
            zipcode: '50270'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '570',
            value: '5150',
            label: 'บ้านจันทร์',
            zipcode: '58130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '570',
            value: '5151',
            label: 'ปางหินฝน',
            zipcode: '50270'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '570',
            value: '5152',
            label: 'กองแขก',
            zipcode: '50270'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '570',
            value: '5153',
            label: 'แม่แดด',
            zipcode: '58130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '570',
            value: '5154',
            label: 'แจ่มหลวง',
            zipcode: '58130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '571',
            value: '5155',
            label: 'เชียงดาว',
            zipcode: '50170'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '571',
            value: '5156',
            label: 'เมืองนะ',
            zipcode: '50170'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '571',
            value: '5157',
            label: 'เมืองงาย',
            zipcode: '50170'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '571',
            value: '5158',
            label: 'แม่นะ',
            zipcode: '50170'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '571',
            value: '5159',
            label: 'เมืองคอง',
            zipcode: '50170'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '571',
            value: '5160',
            label: 'ปิงโค้ง',
            zipcode: '50170'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '571',
            value: '5161',
            label: 'ทุ่งข้าวพวง',
            zipcode: '50170'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5162',
            label: 'เชิงดอย',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5163',
            label: 'สันปูเลย',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5164',
            label: 'ลวงเหนือ',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5165',
            label: 'ป่าป้อง',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5166',
            label: 'สง่าบ้าน',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5167',
            label: 'ป่าลาน',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5168',
            label: 'ตลาดขวัญ',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5169',
            label: 'สำราญราษฎร์',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5170',
            label: 'แม่คือ',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5171',
            label: 'ตลาดใหญ่',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5172',
            label: 'แม่ฮ้อยเงิน',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5173',
            label: 'แม่โป่ง',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5174',
            label: 'ป่าเมี่ยง',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '572',
            value: '5175',
            label: 'เทพเสด็จ',
            zipcode: '50220'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5176',
            label: 'สันมหาพน',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5177',
            label: 'แม่แตง',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5178',
            label: 'ขี้เหล็ก',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5179',
            label: 'ช่อแล',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5180',
            label: 'แม่หอพระ',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5181',
            label: 'สบเปิง',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5182',
            label: 'บ้านเป้า',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5183',
            label: 'สันป่ายาง',
            zipcode: '50330'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5184',
            label: 'ป่าแป๋',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5185',
            label: 'เมืองก๋าย',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5186',
            label: 'บ้านช้าง',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5187',
            label: 'กื้ดช้าง',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5188',
            label: 'อินทขิล',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '573',
            value: '5189',
            label: 'สมก๋าย',
            zipcode: '50150'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '574',
            value: '5190',
            label: 'ริมใต้',
            zipcode: '50180'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '574',
            value: '5191',
            label: 'ริมเหนือ',
            zipcode: '50180'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '574',
            value: '5192',
            label: 'สันโป่ง',
            zipcode: '50180'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '574',
            value: '5193',
            label: 'ขี้เหล็ก',
            zipcode: '50180'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '574',
            value: '5194',
            label: 'สะลวง',
            zipcode: '50330'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '574',
            value: '5195',
            label: 'ห้วยทราย',
            zipcode: '50180'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '574',
            value: '5196',
            label: 'แม่แรม',
            zipcode: '50180'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '574',
            value: '5197',
            label: 'โป่งแยง',
            zipcode: '50180'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '574',
            value: '5198',
            label: 'แม่สา',
            zipcode: '50180'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '574',
            value: '5199',
            label: 'ดอนแก้ว',
            zipcode: '50180'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '574',
            value: '5200',
            label: 'เหมืองแก้ว',
            zipcode: '50180'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '575',
            value: '5201',
            label: 'สะเมิงใต้',
            zipcode: '50250'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '575',
            value: '5202',
            label: 'สะเมิงเหนือ',
            zipcode: '50250'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '575',
            value: '5203',
            label: 'แม่สาบ',
            zipcode: '50250'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '575',
            value: '5204',
            label: 'บ่อแก้ว',
            zipcode: '50250'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '575',
            value: '5205',
            label: 'ยั้งเมิน',
            zipcode: '50250'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5206',
            label: 'เวียง',
            zipcode: '50110'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5208',
            label: 'ม่อนปิ่น',
            zipcode: '50110'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5209',
            label: 'แม่งอน',
            zipcode: '50320'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5210',
            label: 'แม่สูน',
            zipcode: '50110'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5211',
            label: 'สันทราย',
            zipcode: '50110'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5215',
            label: 'แม่คะ',
            zipcode: '50110'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5216',
            label: 'แม่ข่า',
            zipcode: '50320'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5217',
            label: 'โป่งน้ำร้อน',
            zipcode: '50110'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '577',
            value: '5223',
            label: 'แม่อาย',
            zipcode: '50280'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '577',
            value: '5224',
            label: 'แม่สาว',
            zipcode: '50280'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '577',
            value: '5225',
            label: 'สันต้นหมื้อ',
            zipcode: '50280'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '577',
            value: '5226',
            label: 'แม่นาวาง',
            zipcode: '50280'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '577',
            value: '5227',
            label: 'ท่าตอน',
            zipcode: '50280'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '577',
            value: '5228',
            label: 'บ้านหลวง',
            zipcode: '50280'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '577',
            value: '5229',
            label: 'มะลิกา',
            zipcode: '50280'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '578',
            value: '5230',
            label: 'เวียง',
            zipcode: '50190'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '578',
            value: '5231',
            label: 'ทุ่งหลวง',
            zipcode: '50190'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '578',
            value: '5232',
            label: 'ป่าตุ้ม',
            zipcode: '50190'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '578',
            value: '5233',
            label: 'ป่าไหน่',
            zipcode: '50190'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '578',
            value: '5234',
            label: 'สันทราย',
            zipcode: '50190'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '578',
            value: '5235',
            label: 'บ้านโป่ง',
            zipcode: '50190'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '578',
            value: '5236',
            label: 'น้ำแพร่',
            zipcode: '50190'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '578',
            value: '5237',
            label: 'เขื่อนผาก',
            zipcode: '50190'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '578',
            value: '5238',
            label: 'แม่แวน',
            zipcode: '50190'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '578',
            value: '5239',
            label: 'แม่ปั๋ง',
            zipcode: '50190'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '578',
            value: '5240',
            label: 'โหล่งขอด',
            zipcode: '50190'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5241',
            label: 'ยุหว่า',
            zipcode: '50120'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5242',
            label: 'สันกลาง',
            zipcode: '50120'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5243',
            label: 'ท่าวังพร้าว',
            zipcode: '50120'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5244',
            label: 'มะขามหลวง',
            zipcode: '50120'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5245',
            label: 'แม่ก๊า',
            zipcode: '50120'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5246',
            label: 'บ้านแม',
            zipcode: '50120'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5247',
            label: 'บ้านกลาง',
            zipcode: '50120'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5248',
            label: 'ทุ่งสะโตก',
            zipcode: '50120'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5250',
            label: 'ทุ่งต้อม',
            zipcode: '50120'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5254',
            label: 'น้ำบ่อหลวง',
            zipcode: '50120'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5255',
            label: 'มะขุนหวาน',
            zipcode: '50120'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5256',
            label: 'สันกำแพง',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5257',
            label: 'ทรายมูล',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5258',
            label: 'ร้องวัวแดง',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5259',
            label: 'บวกค้าง',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5260',
            label: 'แช่ช้าง',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5261',
            label: 'ออนใต้',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5265',
            label: 'แม่ปูคา',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5266',
            label: 'ห้วยทราย',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5267',
            label: 'ต้นเปา',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5268',
            label: 'สันกลาง',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '581',
            value: '5275',
            label: 'สันทรายหลวง',
            zipcode: '50210'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '581',
            value: '5276',
            label: 'สันทรายน้อย',
            zipcode: '50210'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '581',
            value: '5277',
            label: 'สันพระเนตร',
            zipcode: '50210'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '581',
            value: '5278',
            label: 'สันนาเม็ง',
            zipcode: '50210'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '581',
            value: '5279',
            label: 'สันป่าเปา',
            zipcode: '50210'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '581',
            value: '5280',
            label: 'หนองแหย่ง',
            zipcode: '50210'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '581',
            value: '5281',
            label: 'หนองจ๊อม',
            zipcode: '50210'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '581',
            value: '5282',
            label: 'หนองหาร',
            zipcode: '50290'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '581',
            value: '5283',
            label: 'แม่แฝก',
            zipcode: '50290'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '581',
            value: '5284',
            label: 'แม่แฝกใหม่',
            zipcode: '50290'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '581',
            value: '5285',
            label: 'เมืองเล็น',
            zipcode: '50210'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '581',
            value: '5286',
            label: 'ป่าไผ่',
            zipcode: '50210'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '582',
            value: '5287',
            label: 'หางดง',
            zipcode: '50230'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '582',
            value: '5288',
            label: 'หนองแก๋ว',
            zipcode: '50230'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '582',
            value: '5289',
            label: 'หารแก้ว',
            zipcode: '50230'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '582',
            value: '5290',
            label: 'หนองตอง',
            zipcode: '50340'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '582',
            value: '5291',
            label: 'ขุนคง',
            zipcode: '50230'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '582',
            value: '5292',
            label: 'สบแม่ข่า',
            zipcode: '50230'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '582',
            value: '5293',
            label: 'บ้านแหวน',
            zipcode: '50230'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '582',
            value: '5294',
            label: 'สันผักหวาน',
            zipcode: '50230'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '582',
            value: '5295',
            label: 'หนองควาย',
            zipcode: '50230'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '582',
            value: '5296',
            label: 'บ้านปง',
            zipcode: '50230'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '582',
            value: '5297',
            label: 'น้ำแพร่',
            zipcode: '50230'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '583',
            value: '5298',
            label: 'หางดง',
            zipcode: '50240'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '583',
            value: '5299',
            label: 'ฮอด',
            zipcode: '50240'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '583',
            value: '5300',
            label: 'บ้านตาล',
            zipcode: '50240'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '583',
            value: '5301',
            label: 'บ่อหลวง',
            zipcode: '50240'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '583',
            value: '5302',
            label: 'บ่อสลี',
            zipcode: '50240'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '583',
            value: '5303',
            label: 'นาคอเรือ',
            zipcode: '50240'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '584',
            value: '5304',
            label: 'ดอยเต่า',
            zipcode: '50260'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '584',
            value: '5305',
            label: 'ท่าเดื่อ',
            zipcode: '50260'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '584',
            value: '5306',
            label: 'มืดกา',
            zipcode: '50260'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '584',
            value: '5307',
            label: 'บ้านแอ่น',
            zipcode: '50260'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '584',
            value: '5308',
            label: 'บงตัน',
            zipcode: '50260'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '584',
            value: '5309',
            label: 'โปงทุ่ง',
            zipcode: '50260'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '585',
            value: '5310',
            label: 'อมก๋อย',
            zipcode: '50310'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '585',
            value: '5311',
            label: 'ยางเปียง',
            zipcode: '50310'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '585',
            value: '5312',
            label: 'แม่ตื่น',
            zipcode: '50310'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '585',
            value: '5313',
            label: 'ม่อนจอง',
            zipcode: '50310'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '585',
            value: '5314',
            label: 'สบโขง',
            zipcode: '50310'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '585',
            value: '5315',
            label: 'นาเกียน',
            zipcode: '50310'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '586',
            value: '5316',
            label: 'ยางเนิ้ง',
            zipcode: '50140'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '586',
            value: '5317',
            label: 'สารภี',
            zipcode: '50140'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '586',
            value: '5318',
            label: 'ชมภู',
            zipcode: '50140'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '586',
            value: '5319',
            label: 'ไชยสถาน',
            zipcode: '50140'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '586',
            value: '5320',
            label: 'ขัวมุง',
            zipcode: '50140'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '586',
            value: '5321',
            label: 'หนองแฝก',
            zipcode: '50140'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '586',
            value: '5322',
            label: 'หนองผึ้ง',
            zipcode: '50140'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '586',
            value: '5323',
            label: 'ท่ากว้าง',
            zipcode: '50140'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '586',
            value: '5324',
            label: 'ดอนแก้ว',
            zipcode: '50140'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '586',
            value: '5325',
            label: 'ท่าวังตาล',
            zipcode: '50140'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '586',
            value: '5326',
            label: 'สันทราย',
            zipcode: '50140'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '586',
            value: '5327',
            label: 'ป่าบง',
            zipcode: '50140'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '587',
            value: '5328',
            label: 'เมืองแหง',
            zipcode: '50350'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '587',
            value: '5329',
            label: 'เปียงหลวง',
            zipcode: '50350'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '587',
            value: '5330',
            label: 'แสนไห',
            zipcode: '50350'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '588',
            value: '5331',
            label: 'ปงตำ',
            zipcode: '50320'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '588',
            value: '5332',
            label: 'ศรีดงเย็น',
            zipcode: '50320'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '588',
            value: '5333',
            label: 'แม่ทะลบ',
            zipcode: '50320'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '588',
            value: '5334',
            label: 'หนองบัว',
            zipcode: '50320'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '589',
            value: '5335',
            label: 'บ้านกาด',
            zipcode: '50360'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '589',
            value: '5336',
            label: 'ทุ่งปี้',
            zipcode: '50360'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '589',
            value: '5337',
            label: 'ทุ่งรวงทอง',
            zipcode: '50360'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '589',
            value: '5338',
            label: 'แม่วิน',
            zipcode: '50360'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '589',
            value: '5339',
            label: 'ดอนเปา',
            zipcode: '50360'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '590',
            value: '5340',
            label: 'ออนเหนือ',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '590',
            value: '5341',
            label: 'ออนกลาง',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '590',
            value: '5342',
            label: 'บ้านสหกรณ์',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '590',
            value: '5343',
            label: 'ห้วยแก้ว',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '590',
            value: '5344',
            label: 'แม่ทา',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '590',
            value: '5345',
            label: 'ทาเหนือ',
            zipcode: '50130'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '591',
            value: '5346',
            label: 'ดอยหล่อ',
            zipcode: '50160'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '591',
            value: '5347',
            label: 'สองแคว',
            zipcode: '50160'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '591',
            value: '5348',
            label: 'ยางคราม',
            zipcode: '50160'
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '591',
            value: '5349',
            label: 'สันติสุข',
            zipcode: '50160'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5350',
            label: 'ในเมือง',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5351',
            label: 'เหมืองง่า',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5352',
            label: 'อุโมงค์',
            zipcode: '51150'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5353',
            label: 'หนองช้างคืน',
            zipcode: '51150'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5354',
            label: 'ประตูป่า',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5355',
            label: 'ริมปิง',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5356',
            label: 'ต้นธง',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5357',
            label: 'บ้านแป้น',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5358',
            label: 'เหมืองจี้',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5359',
            label: 'ป่าสัก',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5360',
            label: 'เวียงยอง',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5361',
            label: 'บ้านกลาง',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5362',
            label: 'มะเขือแจ้',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5365',
            label: 'ศรีบัวบาน',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5366',
            label: 'หนองหนาม',
            zipcode: '51000'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '596',
            value: '5369',
            label: 'ทาปลาดุก',
            zipcode: '51140'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '596',
            value: '5370',
            label: 'ทาสบเส้า',
            zipcode: '51140'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '596',
            value: '5371',
            label: 'ทากาศ',
            zipcode: '51170'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '596',
            value: '5372',
            label: 'ทาขุมเงิน',
            zipcode: '51170'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '596',
            value: '5373',
            label: 'ทาทุ่งหลวง',
            zipcode: '51170'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '596',
            value: '5374',
            label: 'ทาแม่ลอบ',
            zipcode: '51170'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '597',
            value: '5375',
            label: 'บ้านโฮ่ง',
            zipcode: '51130'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '597',
            value: '5376',
            label: 'ป่าพลู',
            zipcode: '51130'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '597',
            value: '5377',
            label: 'เหล่ายาว',
            zipcode: '51130'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '597',
            value: '5378',
            label: 'ศรีเตี้ย',
            zipcode: '51130'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '597',
            value: '5379',
            label: 'หนองปลาสะวาย',
            zipcode: '51130'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '598',
            value: '5380',
            label: 'ลี้',
            zipcode: '51110'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '598',
            value: '5381',
            label: 'แม่ตืน',
            zipcode: '51110'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '598',
            value: '5382',
            label: 'นาทราย',
            zipcode: '51110'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '598',
            value: '5383',
            label: 'ดงดำ',
            zipcode: '51110'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '598',
            value: '5384',
            label: 'ก้อ',
            zipcode: '51110'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '598',
            value: '5385',
            label: 'แม่ลาน',
            zipcode: '51110'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '598',
            value: '5387',
            label: 'ป่าไผ่',
            zipcode: '51110'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '598',
            value: '5388',
            label: 'ศรีวิชัย',
            zipcode: '51110'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '599',
            value: '5391',
            label: 'ทุ่งหัวช้าง',
            zipcode: '51160'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '599',
            value: '5392',
            label: 'บ้านปวง',
            zipcode: '51160'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '599',
            value: '5393',
            label: 'ตะเคียนปม',
            zipcode: '51160'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '600',
            value: '5394',
            label: 'ปากบ่อง',
            zipcode: '51120'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '600',
            value: '5395',
            label: 'ป่าซาง',
            zipcode: '51120'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '600',
            value: '5396',
            label: 'แม่แรง',
            zipcode: '51120'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '600',
            value: '5397',
            label: 'ม่วงน้อย',
            zipcode: '51120'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '600',
            value: '5398',
            label: 'บ้านเรือน',
            zipcode: '51120'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '600',
            value: '5399',
            label: 'มะกอก',
            zipcode: '51120'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '600',
            value: '5400',
            label: 'ท่าตุ้ม',
            zipcode: '51120'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '600',
            value: '5401',
            label: 'น้ำดิบ',
            zipcode: '51120'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '600',
            value: '5404',
            label: 'นครเจดีย์',
            zipcode: '51120'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '601',
            value: '5406',
            label: 'บ้านธิ',
            zipcode: '51180'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '601',
            value: '5407',
            label: 'ห้วยยาบ',
            zipcode: '51180'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '602',
            value: '5408',
            label: 'หนองล่อง',
            zipcode: '51120'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '602',
            value: '5409',
            label: 'หนองยวง',
            zipcode: '51120'
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '602',
            value: '5410',
            label: 'วังผาง',
            zipcode: '51120'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5411',
            label: 'เวียงเหนือ',
            zipcode: '52000'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5412',
            label: 'หัวเวียง',
            zipcode: '52000'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5413',
            label: 'สวนดอก',
            zipcode: '52100'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5414',
            label: 'สบตุ๋ย',
            zipcode: '52100'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5415',
            label: 'พระบาท',
            zipcode: '52000'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5416',
            label: 'ชมพู',
            zipcode: '52100'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5417',
            label: 'กล้วยแพะ',
            zipcode: '52000'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5418',
            label: 'ปงแสนทอง',
            zipcode: '52100'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5419',
            label: 'บ้านแลง',
            zipcode: '52000'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5420',
            label: 'บ้านเสด็จ',
            zipcode: '52000'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5421',
            label: 'พิชัย',
            zipcode: '52000'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5422',
            label: 'ทุ่งฝาย',
            zipcode: '52000'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5423',
            label: 'บ้านเอื้อม',
            zipcode: '52100'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5424',
            label: 'บ้านเป้า',
            zipcode: '52100'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5425',
            label: 'บ้านค่า',
            zipcode: '52100'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5426',
            label: 'บ่อแฮ้ว',
            zipcode: '52100'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5427',
            label: 'ต้นธงชัย',
            zipcode: '52000'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5428',
            label: 'นิคมพัฒนา',
            zipcode: '52000'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5429',
            label: 'บุญนาคพัฒนา',
            zipcode: '52000'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '604',
            value: '5432',
            label: 'บ้านดง',
            zipcode: '52220'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '604',
            value: '5433',
            label: 'นาสัก',
            zipcode: '52220'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '604',
            value: '5434',
            label: 'จางเหนือ',
            zipcode: '52220'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '604',
            value: '5435',
            label: 'แม่เมาะ',
            zipcode: '52220'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '604',
            value: '5436',
            label: 'สบป้าด',
            zipcode: '52220'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '605',
            value: '5437',
            label: 'ลำปางหลวง',
            zipcode: '52130'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '605',
            value: '5438',
            label: 'นาแก้ว',
            zipcode: '52130'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '605',
            value: '5439',
            label: 'ไหล่หิน',
            zipcode: '52130'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '605',
            value: '5440',
            label: 'วังพร้าว',
            zipcode: '52130'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '605',
            value: '5441',
            label: 'ศาลา',
            zipcode: '52130'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '605',
            value: '5442',
            label: 'เกาะคา',
            zipcode: '52130'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '605',
            value: '5443',
            label: 'นาแส่ง',
            zipcode: '52130'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '605',
            value: '5444',
            label: 'ท่าผา',
            zipcode: '52130'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '605',
            value: '5445',
            label: 'ใหม่พัฒนา',
            zipcode: '52130'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '606',
            value: '5446',
            label: 'ทุ่งงาม',
            zipcode: '52210'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '606',
            value: '5447',
            label: 'เสริมขวา',
            zipcode: '52210'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '606',
            value: '5448',
            label: 'เสริมซ้าย',
            zipcode: '52210'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '606',
            value: '5449',
            label: 'เสริมกลาง',
            zipcode: '52210'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '607',
            value: '5450',
            label: 'หลวงเหนือ',
            zipcode: '52110'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '607',
            value: '5451',
            label: 'หลวงใต้',
            zipcode: '52110'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '607',
            value: '5452',
            label: 'บ้านโป่ง',
            zipcode: '52110'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '607',
            value: '5453',
            label: 'บ้านร้อง',
            zipcode: '52110'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '607',
            value: '5454',
            label: 'ปงเตา',
            zipcode: '52110'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '607',
            value: '5455',
            label: 'นาแก',
            zipcode: '52110'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '607',
            value: '5456',
            label: 'บ้านอ้อน',
            zipcode: '52110'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '607',
            value: '5457',
            label: 'บ้านแหง',
            zipcode: '52110'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '607',
            value: '5458',
            label: 'บ้านหวด',
            zipcode: '52110'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '607',
            value: '5459',
            label: 'แม่ตีบ',
            zipcode: '52110'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '608',
            value: '5460',
            label: 'แจ้ห่ม',
            zipcode: '52120'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '608',
            value: '5461',
            label: 'บ้านสา',
            zipcode: '52120'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '608',
            value: '5462',
            label: 'ปงดอน',
            zipcode: '52120'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '608',
            value: '5463',
            label: 'แม่สุก',
            zipcode: '52120'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '608',
            value: '5464',
            label: 'เมืองมาย',
            zipcode: '52120'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '608',
            value: '5465',
            label: 'ทุ่งผึ้ง',
            zipcode: '52120'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '608',
            value: '5466',
            label: 'วิเชตนคร',
            zipcode: '52120'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '609',
            value: '5471',
            label: 'ทุ่งฮั้ว',
            zipcode: '52140'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '609',
            value: '5472',
            label: 'วังเหนือ',
            zipcode: '52140'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '609',
            value: '5473',
            label: 'วังใต้',
            zipcode: '52140'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '609',
            value: '5474',
            label: 'ร่องเคาะ',
            zipcode: '52140'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '609',
            value: '5475',
            label: 'วังทอง',
            zipcode: '52140'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '609',
            value: '5476',
            label: 'วังซ้าย',
            zipcode: '52140'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '609',
            value: '5477',
            label: 'วังแก้ว',
            zipcode: '52140'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '609',
            value: '5478',
            label: 'วังทรายคำ',
            zipcode: '52140'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '610',
            value: '5479',
            label: 'ล้อมแรด',
            zipcode: '52160'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '610',
            value: '5480',
            label: 'แม่วะ',
            zipcode: '52230'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '610',
            value: '5481',
            label: 'แม่ปะ',
            zipcode: '52160'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '610',
            value: '5482',
            label: 'แม่มอก',
            zipcode: '52160'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '610',
            value: '5483',
            label: 'เวียงมอก',
            zipcode: '52160'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '610',
            value: '5484',
            label: 'นาโป่ง',
            zipcode: '52160'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '610',
            value: '5485',
            label: 'แม่ถอด',
            zipcode: '52160'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '610',
            value: '5486',
            label: 'เถินบุรี',
            zipcode: '52160'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '611',
            value: '5487',
            label: 'แม่พริก',
            zipcode: '52180'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '611',
            value: '5488',
            label: 'ผาปัง',
            zipcode: '52180'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '611',
            value: '5489',
            label: 'แม่ปุ',
            zipcode: '52180'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '611',
            value: '5490',
            label: 'พระบาทวังตวง',
            zipcode: '52180'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '612',
            value: '5491',
            label: 'แม่ทะ',
            zipcode: '52150'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '612',
            value: '5492',
            label: 'นาครัว',
            zipcode: '52150'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '612',
            value: '5493',
            label: 'ป่าตัน',
            zipcode: '52150'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '612',
            value: '5494',
            label: 'บ้านกิ่ว',
            zipcode: '52150'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '612',
            value: '5495',
            label: 'บ้านบอม',
            zipcode: '52150'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '612',
            value: '5496',
            label: 'น้ำโจ้',
            zipcode: '52150'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '612',
            value: '5497',
            label: 'ดอนไฟ',
            zipcode: '52150'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '612',
            value: '5498',
            label: 'หัวเสือ',
            zipcode: '52150'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '612',
            value: '5500',
            label: 'วังเงิน',
            zipcode: '52150'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '612',
            value: '5501',
            label: 'สันดอนแก้ว',
            zipcode: '52150'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '613',
            value: '5502',
            label: 'สบปราบ',
            zipcode: '52170'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '613',
            value: '5503',
            label: 'สมัย',
            zipcode: '52170'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '613',
            value: '5504',
            label: 'แม่กัวะ',
            zipcode: '52170'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '613',
            value: '5505',
            label: 'นายาง',
            zipcode: '52170'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '614',
            value: '5506',
            label: 'ห้างฉัตร',
            zipcode: '52190'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '614',
            value: '5507',
            label: 'หนองหล่ม',
            zipcode: '52190'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '614',
            value: '5508',
            label: 'เมืองยาว',
            zipcode: '52190'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '614',
            value: '5509',
            label: 'ปงยางคก',
            zipcode: '52190'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '614',
            value: '5510',
            label: 'เวียงตาล',
            zipcode: '52190'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '614',
            value: '5511',
            label: 'แม่สัน',
            zipcode: '52190'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '614',
            value: '5512',
            label: 'วอแก้ว',
            zipcode: '52190'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '615',
            value: '5513',
            label: 'เมืองปาน',
            zipcode: '52240'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '615',
            value: '5514',
            label: 'บ้านขอ',
            zipcode: '52240'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '615',
            value: '5515',
            label: 'ทุ่งกว๋าว',
            zipcode: '52240'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '615',
            value: '5516',
            label: 'แจ้ซ้อน',
            zipcode: '52240'
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '615',
            value: '5517',
            label: 'หัวเมือง',
            zipcode: '52240'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5518',
            label: 'ท่าอิฐ',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5519',
            label: 'ท่าเสา',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5520',
            label: 'บ้านเกาะ',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5521',
            label: 'ป่าเซ่า',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5522',
            label: 'คุ้งตะเภา',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5523',
            label: 'วังกะพี้',
            zipcode: '53170'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5524',
            label: 'หาดกรวด',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5525',
            label: 'น้ำริด',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5526',
            label: 'งิ้วงาม',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5527',
            label: 'บ้านด่านนาขาม',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5528',
            label: 'บ้านด่าน',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5529',
            label: 'ผาจุก',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5530',
            label: 'วังดิน',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5531',
            label: 'แสนตอ',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5532',
            label: 'หาดงิ้ว',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5533',
            label: 'ขุนฝาง',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5534',
            label: 'ถ้ำฉลอง',
            zipcode: '53000'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '617',
            value: '5536',
            label: 'วังแดง',
            zipcode: '53140'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '617',
            value: '5537',
            label: 'บ้านแก่ง',
            zipcode: '53140'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '617',
            value: '5538',
            label: 'หาดสองแคว',
            zipcode: '53140'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '617',
            value: '5539',
            label: 'น้ำอ่าง',
            zipcode: '53140'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '617',
            value: '5540',
            label: 'ข่อยสูง',
            zipcode: '53140'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '618',
            value: '5545',
            label: 'ท่าปลา',
            zipcode: '53150'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '618',
            value: '5546',
            label: 'หาดล้า',
            zipcode: '53150'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '618',
            value: '5547',
            label: 'ผาเลือด',
            zipcode: '53190'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '618',
            value: '5548',
            label: 'จริม',
            zipcode: '53150'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '618',
            value: '5549',
            label: 'น้ำหมัน',
            zipcode: '53150'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '618',
            value: '5550',
            label: 'ท่าแฝก',
            zipcode: '53110'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '618',
            value: '5551',
            label: 'นางพญา',
            zipcode: '53150'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '618',
            value: '5552',
            label: 'ร่วมจิต',
            zipcode: '53190'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '619',
            value: '5553',
            label: 'แสนตอ',
            zipcode: '53110'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '619',
            value: '5554',
            label: 'บ้านฝาย',
            zipcode: '53110'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '619',
            value: '5555',
            label: 'เด่นเหล็ก',
            zipcode: '53110'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '619',
            value: '5556',
            label: 'น้ำไคร้',
            zipcode: '53110'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '619',
            value: '5557',
            label: 'น้ำไผ่',
            zipcode: '53110'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '619',
            value: '5558',
            label: 'ห้วยมุ่น',
            zipcode: '53110'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '620',
            value: '5559',
            label: 'ฟากท่า',
            zipcode: '53160'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '620',
            value: '5560',
            label: 'สองคอน',
            zipcode: '53160'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '620',
            value: '5561',
            label: 'บ้านเสี้ยว',
            zipcode: '53160'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '620',
            value: '5562',
            label: 'สองห้อง',
            zipcode: '53160'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '621',
            value: '5563',
            label: 'ม่วงเจ็ดต้น',
            zipcode: '53180'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '621',
            value: '5564',
            label: 'บ้านโคก',
            zipcode: '53180'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '621',
            value: '5565',
            label: 'นาขุม',
            zipcode: '53180'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '621',
            value: '5566',
            label: 'บ่อเบี้ย',
            zipcode: '53180'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '622',
            value: '5567',
            label: 'ในเมือง',
            zipcode: '53120'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '622',
            value: '5568',
            label: 'บ้านดารา',
            zipcode: '53220'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '622',
            value: '5569',
            label: 'ไร่อ้อย',
            zipcode: '53120'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '622',
            value: '5570',
            label: 'ท่าสัก',
            zipcode: '53220'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '622',
            value: '5571',
            label: 'คอรุม',
            zipcode: '53120'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '622',
            value: '5572',
            label: 'บ้านหม้อ',
            zipcode: '53120'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '622',
            value: '5573',
            label: 'ท่ามะเฟือง',
            zipcode: '53120'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '622',
            value: '5574',
            label: 'บ้านโคน',
            zipcode: '53120'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '622',
            value: '5575',
            label: 'พญาแมน',
            zipcode: '53120'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '622',
            value: '5576',
            label: 'นาอิน',
            zipcode: '53120'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '622',
            value: '5577',
            label: 'นายาง',
            zipcode: '53120'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '623',
            value: '5578',
            label: 'ศรีพนมมาศ',
            zipcode: '53130'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '623',
            value: '5579',
            label: 'แม่พูล',
            zipcode: '53130'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '623',
            value: '5580',
            label: 'นานกกก',
            zipcode: '53130'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '623',
            value: '5581',
            label: 'ฝายหลวง',
            zipcode: '53130'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '623',
            value: '5582',
            label: 'ชัยจุมพล',
            zipcode: '53130'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '623',
            value: '5583',
            label: 'ไผ่ล้อม',
            zipcode: '53210'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '623',
            value: '5584',
            label: 'ทุ่งยั้ง',
            zipcode: '53210'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '623',
            value: '5585',
            label: 'ด่านแม่คำมัน',
            zipcode: '53210'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '624',
            value: '5587',
            label: 'ผักขวง',
            zipcode: '53230'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '624',
            value: '5588',
            label: 'บ่อทอง',
            zipcode: '53230'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '624',
            value: '5589',
            label: 'ป่าคาย',
            zipcode: '53230'
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '624',
            value: '5590',
            label: 'น้ำพี้',
            zipcode: '53230'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5591',
            label: 'ในเวียง',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5592',
            label: 'นาจักร',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5593',
            label: 'น้ำชำ',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5594',
            label: 'ป่าแดง',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5595',
            label: 'ทุ่งโฮ้ง',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5596',
            label: 'เหมืองหม้อ',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5597',
            label: 'วังธง',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5598',
            label: 'แม่หล่าย',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5599',
            label: 'ห้วยม้า',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5600',
            label: 'ป่าแมต',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5601',
            label: 'บ้านถิ่น',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5602',
            label: 'สวนเขื่อน',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5603',
            label: 'วังหงส์',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5604',
            label: 'แม่คำมี',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5605',
            label: 'ทุ่งกวาว',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5606',
            label: 'ท่าข้าม',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5607',
            label: 'แม่ยม',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5608',
            label: 'ช่อแฮ',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5609',
            label: 'ร่องฟอง',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '625',
            value: '5610',
            label: 'กาญจนา',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5611',
            label: 'ร้องกวาง',
            zipcode: '54140'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5614',
            label: 'ร้องเข็ม',
            zipcode: '54140'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5615',
            label: 'น้ำเลา',
            zipcode: '54140'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5616',
            label: 'บ้านเวียง',
            zipcode: '54140'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5617',
            label: 'ทุ่งศรี',
            zipcode: '54140'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5618',
            label: 'แม่ยางตาล',
            zipcode: '54140'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5619',
            label: 'แม่ยางฮ่อ',
            zipcode: '54140'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5620',
            label: 'ไผ่โทน',
            zipcode: '54140'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5623',
            label: 'ห้วยโรง',
            zipcode: '54140'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5624',
            label: 'แม่ทราย',
            zipcode: '54140'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5625',
            label: 'แม่ยางร้อง',
            zipcode: '54140'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '627',
            value: '5628',
            label: 'ห้วยอ้อ',
            zipcode: '54150'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '627',
            value: '5629',
            label: 'บ้านปิน',
            zipcode: '54150'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '627',
            value: '5630',
            label: 'ต้าผามอก',
            zipcode: '54150'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '627',
            value: '5631',
            label: 'เวียงต้า',
            zipcode: '54150'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '627',
            value: '5632',
            label: 'ปากกาง',
            zipcode: '54150'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '627',
            value: '5633',
            label: 'หัวทุ่ง',
            zipcode: '54150'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '627',
            value: '5634',
            label: 'ทุ่งแล้ง',
            zipcode: '54150'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '627',
            value: '5635',
            label: 'บ่อเหล็กลอง',
            zipcode: '54150'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '627',
            value: '5636',
            label: 'แม่ปาน',
            zipcode: '54150'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '628',
            value: '5637',
            label: 'สูงเม่น',
            zipcode: '54130'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '628',
            value: '5638',
            label: 'น้ำชำ',
            zipcode: '54130'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '628',
            value: '5639',
            label: 'หัวฝาย',
            zipcode: '54130'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '628',
            value: '5640',
            label: 'ดอนมูล',
            zipcode: '54130'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '628',
            value: '5641',
            label: 'บ้านเหล่า',
            zipcode: '54130'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '628',
            value: '5642',
            label: 'บ้านกวาง',
            zipcode: '54130'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '628',
            value: '5643',
            label: 'บ้านปง',
            zipcode: '54130'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '628',
            value: '5644',
            label: 'บ้านกาศ',
            zipcode: '54130'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '628',
            value: '5645',
            label: 'ร่องกาศ',
            zipcode: '54130'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '628',
            value: '5646',
            label: 'สบสาย',
            zipcode: '54130'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '628',
            value: '5647',
            label: 'เวียงทอง',
            zipcode: '54000'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '628',
            value: '5648',
            label: 'พระหลวง',
            zipcode: '54130'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '629',
            value: '5649',
            label: 'เด่นชัย',
            zipcode: '54110'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '629',
            value: '5650',
            label: 'แม่จั๊วะ',
            zipcode: '54110'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '629',
            value: '5651',
            label: 'ไทรย้อย',
            zipcode: '54110'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '629',
            value: '5652',
            label: 'ห้วยไร่',
            zipcode: '54110'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '629',
            value: '5653',
            label: 'ปงป่าหวาย',
            zipcode: '54110'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '630',
            value: '5654',
            label: 'บ้านหนุน',
            zipcode: '54120'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '630',
            value: '5655',
            label: 'บ้านกลาง',
            zipcode: '54120'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '630',
            value: '5656',
            label: 'ห้วยหม้าย',
            zipcode: '54120'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '630',
            value: '5657',
            label: 'เตาปูน',
            zipcode: '54120'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '630',
            value: '5658',
            label: 'หัวเมือง',
            zipcode: '54120'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '630',
            value: '5659',
            label: 'สะเอียบ',
            zipcode: '54120'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '630',
            value: '5660',
            label: 'แดนชุมพล',
            zipcode: '54120'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '630',
            value: '5661',
            label: 'ทุ่งน้าว',
            zipcode: '54120'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '631',
            value: '5662',
            label: 'วังชิ้น',
            zipcode: '54160'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '631',
            value: '5663',
            label: 'สรอย',
            zipcode: '54160'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '631',
            value: '5664',
            label: 'แม่ป้าก',
            zipcode: '54160'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '631',
            value: '5665',
            label: 'นาพูน',
            zipcode: '54160'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '631',
            value: '5666',
            label: 'แม่พุง',
            zipcode: '54160'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '631',
            value: '5667',
            label: 'ป่าสัก',
            zipcode: '54160'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '631',
            value: '5668',
            label: 'แม่เกิ๋ง',
            zipcode: '54160'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '632',
            value: '5669',
            label: 'แม่คำมี',
            zipcode: '54170'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '632',
            value: '5670',
            label: 'หนองม่วงไข่',
            zipcode: '54170'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '632',
            value: '5671',
            label: 'น้ำรัด',
            zipcode: '54170'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '632',
            value: '5672',
            label: 'วังหลวง',
            zipcode: '54170'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '632',
            value: '5673',
            label: 'ตำหนักธรรม',
            zipcode: '54170'
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '632',
            value: '5674',
            label: 'ทุ่งแค้ว',
            zipcode: '54170'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5675',
            label: 'ในเวียง',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5676',
            label: 'บ่อ',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5677',
            label: 'ผาสิงห์',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5678',
            label: 'ไชยสถาน',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5679',
            label: 'ถืมตอง',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5680',
            label: 'เรือง',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5681',
            label: 'นาซาว',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5682',
            label: 'ดู่ใต้',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5683',
            label: 'กองควาย',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5690',
            label: 'สวก',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5691',
            label: 'สะเนียน',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '634',
            value: '5698',
            label: 'หนองแดง',
            zipcode: '55170'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '634',
            value: '5699',
            label: 'หมอเมือง',
            zipcode: '55170'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '634',
            value: '5700',
            label: 'น้ำพาง',
            zipcode: '55170'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '634',
            value: '5701',
            label: 'น้ำปาย',
            zipcode: '55170'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '634',
            value: '5702',
            label: 'แม่จริม',
            zipcode: '55170'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '635',
            value: '5703',
            label: 'บ้านฟ้า',
            zipcode: '55190'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '635',
            value: '5704',
            label: 'ป่าคาหลวง',
            zipcode: '55190'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '635',
            value: '5705',
            label: 'สวด',
            zipcode: '55190'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '635',
            value: '5706',
            label: 'บ้านพี้',
            zipcode: '55190'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '636',
            value: '5707',
            label: 'นาน้อย',
            zipcode: '55150'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '636',
            value: '5708',
            label: 'เชียงของ',
            zipcode: '55150'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '636',
            value: '5709',
            label: 'ศรีษะเกษ',
            zipcode: '55150'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '636',
            value: '5710',
            label: 'สถาน',
            zipcode: '55150'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '636',
            value: '5711',
            label: 'สันทะ',
            zipcode: '55150'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '636',
            value: '5712',
            label: 'บัวใหญ่',
            zipcode: '55150'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '636',
            value: '5713',
            label: 'น้ำตก',
            zipcode: '55150'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5714',
            label: 'ปัว',
            zipcode: '55120'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5715',
            label: 'แงง',
            zipcode: '55120'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5716',
            label: 'สถาน',
            zipcode: '55120'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5717',
            label: 'ศิลาแลง',
            zipcode: '55120'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5718',
            label: 'ศิลาเพชร',
            zipcode: '55120'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5719',
            label: 'อวน',
            zipcode: '55120'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5722',
            label: 'ไชยวัฒนา',
            zipcode: '55120'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5723',
            label: 'เจดีย์ชัย',
            zipcode: '55120'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5724',
            label: 'ภูคา',
            zipcode: '55120'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5725',
            label: 'สกาด',
            zipcode: '55120'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5726',
            label: 'ป่ากลาง',
            zipcode: '55120'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5727',
            label: 'วรนคร',
            zipcode: '55120'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '638',
            value: '5728',
            label: 'ริม',
            zipcode: '55140'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '638',
            value: '5729',
            label: 'ป่าคา',
            zipcode: '55140'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '638',
            value: '5730',
            label: 'ผาตอ',
            zipcode: '55140'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '638',
            value: '5731',
            label: 'ยม',
            zipcode: '55140'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '638',
            value: '5732',
            label: 'ตาลชุม',
            zipcode: '55140'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '638',
            value: '5733',
            label: 'ศรีภูมิ',
            zipcode: '55140'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '638',
            value: '5734',
            label: 'จอมพระ',
            zipcode: '55140'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '638',
            value: '5735',
            label: 'แสนทอง',
            zipcode: '55140'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '638',
            value: '5736',
            label: 'ท่าวังผา',
            zipcode: '55140'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '638',
            value: '5737',
            label: 'ผาทอง',
            zipcode: '55140'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5738',
            label: 'กลางเวียง',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5739',
            label: 'ขึ่ง',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5740',
            label: 'ไหล่น่าน',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5741',
            label: 'ตาลชุม',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5742',
            label: 'นาเหลือง',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5743',
            label: 'ส้าน',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5744',
            label: 'น้ำมวบ',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5745',
            label: 'น้ำปั้ว',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5746',
            label: 'ยาบหัวนา',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5747',
            label: 'ปงสนุก',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5748',
            label: 'อ่ายนาไลย',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5749',
            label: 'ส้านนาหนองใหม่',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5750',
            label: 'แม่ขะนิง',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5751',
            label: 'แม่สาคร',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5752',
            label: 'จอมจันทร์',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5753',
            label: 'แม่สา',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '639',
            value: '5754',
            label: 'ทุ่งศรีทอง',
            zipcode: '55110'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '640',
            value: '5755',
            label: 'ปอน',
            zipcode: '55130'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '640',
            value: '5756',
            label: 'งอบ',
            zipcode: '55130'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '640',
            value: '5757',
            label: 'และ',
            zipcode: '55130'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '640',
            value: '5758',
            label: 'ทุ่งช้าง',
            zipcode: '55130'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '641',
            value: '5762',
            label: 'เชียงกลาง',
            zipcode: '55160'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '641',
            value: '5763',
            label: 'เปือ',
            zipcode: '55160'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '641',
            value: '5764',
            label: 'เชียงคาน',
            zipcode: '55160'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '641',
            value: '5765',
            label: 'พระธาตุ',
            zipcode: '55160'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '641',
            value: '5769',
            label: 'พญาแก้ว',
            zipcode: '55160'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '641',
            value: '5770',
            label: 'พระพุทธบาท',
            zipcode: '55160'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '642',
            value: '5773',
            label: 'นาทะนุง',
            zipcode: '55180'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '642',
            value: '5774',
            label: 'บ่อแก้ว',
            zipcode: '55180'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '642',
            value: '5775',
            label: 'เมืองลี',
            zipcode: '55180'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '642',
            value: '5776',
            label: 'ปิงหลวง',
            zipcode: '55180'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '643',
            value: '5777',
            label: 'ดู่พงษ์',
            zipcode: '55210'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '643',
            value: '5778',
            label: 'ป่าแลวหลวง',
            zipcode: '55210'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '643',
            value: '5779',
            label: 'พงษ์',
            zipcode: '55210'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '644',
            value: '5780',
            label: 'บ่อเกลือเหนือ',
            zipcode: '55220'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '644',
            value: '5781',
            label: 'บ่อเกลือใต้',
            zipcode: '55220'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '644',
            value: '5783',
            label: 'ภูฟ้า',
            zipcode: '55220'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '644',
            value: '5784',
            label: 'ดงพญา',
            zipcode: '55220'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '645',
            value: '5785',
            label: 'นาไร่หลวง',
            zipcode: '55160'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '645',
            value: '5786',
            label: 'ชนแดน',
            zipcode: '55160'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '645',
            value: '5787',
            label: 'ยอด',
            zipcode: '55160'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '646',
            value: '5788',
            label: 'ม่วงตึ๊ด',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '646',
            value: '5789',
            label: 'นาปัง',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '646',
            value: '5790',
            label: 'น้ำแก่น',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '646',
            value: '5791',
            label: 'น้ำเกี๋ยน',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '646',
            value: '5792',
            label: 'เมืองจัง',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '646',
            value: '5793',
            label: 'ท่าน้าว',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '646',
            value: '5794',
            label: 'ฝายแก้ว',
            zipcode: '55000'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '647',
            value: '5795',
            label: 'ห้วยโก๋น',
            zipcode: '55130'
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '647',
            value: '5796',
            label: 'ขุนน่าน',
            zipcode: '55130'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5797',
            label: 'เวียง',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5798',
            label: 'แม่ต๋ำ',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5800',
            label: 'แม่นาเรือ',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5801',
            label: 'บ้านตุ่น',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5802',
            label: 'บ้านต๊ำ',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5803',
            label: 'บ้านต๋อม',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5804',
            label: 'แม่ปืม',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5806',
            label: 'แม่กา',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5807',
            label: 'บ้านใหม่',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5808',
            label: 'จำป่าหวาย',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5809',
            label: 'ท่าวังทอง',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5810',
            label: 'แม่ใส',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5811',
            label: 'บ้านสาง',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5812',
            label: 'ท่าจำปี',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5814',
            label: 'สันป่าม่วง',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '649',
            value: '5815',
            label: 'ห้วยข้าวก่ำ',
            zipcode: '56150'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '649',
            value: '5816',
            label: 'จุน',
            zipcode: '56150'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '649',
            value: '5817',
            label: 'ลอ',
            zipcode: '56150'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '649',
            value: '5818',
            label: 'หงส์หิน',
            zipcode: '56150'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '649',
            value: '5819',
            label: 'ทุ่งรวงทอง',
            zipcode: '56150'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '649',
            value: '5820',
            label: 'ห้วยยางขาม',
            zipcode: '56150'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '649',
            value: '5821',
            label: 'พระธาตุขิงแกง',
            zipcode: '56150'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5822',
            label: 'หย่วน',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5827',
            label: 'น้ำแวน',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5828',
            label: 'เวียง',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5829',
            label: 'ฝายกวาง',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5830',
            label: 'เจดีย์คำ',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5831',
            label: 'ร่มเย็น',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5832',
            label: 'เชียงบาน',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5833',
            label: 'แม่ลาว',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5834',
            label: 'อ่างทอง',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5835',
            label: 'ทุ่งผาสุข',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '651',
            value: '5837',
            label: 'เชียงม่วน',
            zipcode: '56160'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '651',
            value: '5838',
            label: 'บ้านมาง',
            zipcode: '56160'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '651',
            value: '5839',
            label: 'สระ',
            zipcode: '56160'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '652',
            value: '5840',
            label: 'ดอกคำใต้',
            zipcode: '56120'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '652',
            value: '5841',
            label: 'ดอนศรีชุม',
            zipcode: '56120'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '652',
            value: '5842',
            label: 'บ้านถ้ำ',
            zipcode: '56120'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '652',
            value: '5843',
            label: 'บ้านปิน',
            zipcode: '56120'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '652',
            value: '5844',
            label: 'ห้วยลาน',
            zipcode: '56120'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '652',
            value: '5845',
            label: 'สันโค้ง',
            zipcode: '56120'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '652',
            value: '5846',
            label: 'ป่าซาง',
            zipcode: '56120'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '652',
            value: '5847',
            label: 'หนองหล่ม',
            zipcode: '56120'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '652',
            value: '5848',
            label: 'ดงสุวรรณ',
            zipcode: '56120'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '652',
            value: '5849',
            label: 'บุญเกิด',
            zipcode: '56120'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '652',
            value: '5850',
            label: 'สว่างอารมณ์',
            zipcode: '56120'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '652',
            value: '5851',
            label: 'คือเวียง',
            zipcode: '56120'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '653',
            value: '5852',
            label: 'ปง',
            zipcode: '56140'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '653',
            value: '5853',
            label: 'ควร',
            zipcode: '56140'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '653',
            value: '5854',
            label: 'ออย',
            zipcode: '56140'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '653',
            value: '5855',
            label: 'งิม',
            zipcode: '56140'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '653',
            value: '5856',
            label: 'ผาช้างน้อย',
            zipcode: '56140'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '653',
            value: '5857',
            label: 'นาปรัง',
            zipcode: '56140'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '653',
            value: '5858',
            label: 'ขุนควร',
            zipcode: '56140'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '654',
            value: '5859',
            label: 'แม่ใจ',
            zipcode: '56130'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '654',
            value: '5860',
            label: 'ศรีถ้อย',
            zipcode: '56130'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '654',
            value: '5861',
            label: 'แม่สุก',
            zipcode: '56130'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '654',
            value: '5862',
            label: 'ป่าแฝก',
            zipcode: '56130'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '654',
            value: '5863',
            label: 'บ้านเหล่า',
            zipcode: '56130'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '654',
            value: '5864',
            label: 'เจริญราษฎร์',
            zipcode: '56130'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '655',
            value: '5865',
            label: 'ภูซาง',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '655',
            value: '5866',
            label: 'ป่าสัก',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '655',
            value: '5867',
            label: 'ทุ่งกล้วย',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '655',
            value: '5868',
            label: 'เชียงแรง',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '655',
            value: '5869',
            label: 'สบบง',
            zipcode: '56110'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '656',
            value: '5870',
            label: 'ห้วยแก้ว',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '656',
            value: '5871',
            label: 'ดงเจน',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '656',
            value: '5872',
            label: 'แม่อิง',
            zipcode: '56000'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5873',
            label: 'เวียง',
            zipcode: '57000'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5874',
            label: 'รอบเวียง',
            zipcode: '57000'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5875',
            label: 'บ้านดู่',
            zipcode: '57100'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5876',
            label: 'นางแล',
            zipcode: '57100'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5877',
            label: 'แม่ข้าวต้ม',
            zipcode: '57100'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5878',
            label: 'แม่ยาว',
            zipcode: '57100'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5879',
            label: 'สันทราย',
            zipcode: '57000'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5883',
            label: 'แม่กรณ์',
            zipcode: '57000'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5884',
            label: 'ห้วยชมภู',
            zipcode: '57000'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5885',
            label: 'ห้วยสัก',
            zipcode: '57000'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5886',
            label: 'ริมกก',
            zipcode: '57100'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5887',
            label: 'ดอยลาน',
            zipcode: '57000'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5888',
            label: 'ป่าอ้อดอนชัย',
            zipcode: '57000'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5890',
            label: 'ท่าสาย',
            zipcode: '57000'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5892',
            label: 'ดอยฮาง',
            zipcode: '57000'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5893',
            label: 'ท่าสุด',
            zipcode: '57100'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '658',
            value: '5903',
            label: 'เวียงชัย',
            zipcode: '57210'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '658',
            value: '5904',
            label: 'ผางาม',
            zipcode: '57210'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '658',
            value: '5905',
            label: 'เวียงเหนือ',
            zipcode: '57210'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '658',
            value: '5907',
            label: 'ดอนศิลา',
            zipcode: '57210'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '658',
            value: '5909',
            label: 'เมืองชุม',
            zipcode: '57210'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '659',
            value: '5910',
            label: 'เวียง',
            zipcode: '57140'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '659',
            value: '5911',
            label: 'สถาน',
            zipcode: '57140'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '659',
            value: '5912',
            label: 'ครึ่ง',
            zipcode: '57140'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '659',
            value: '5913',
            label: 'บุญเรือง',
            zipcode: '57140'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '659',
            value: '5914',
            label: 'ห้วยซ้อ',
            zipcode: '57140'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '659',
            value: '5917',
            label: 'ศรีดอนชัย',
            zipcode: '57230'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '659',
            value: '5919',
            label: 'ริมโขง',
            zipcode: '57140'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5922',
            label: 'เวียง',
            zipcode: '57160'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5923',
            label: 'งิ้ว',
            zipcode: '57160'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5924',
            label: 'ปล้อง',
            zipcode: '57230'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5925',
            label: 'แม่ลอย',
            zipcode: '57230'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5926',
            label: 'เชียงเคี่ยน',
            zipcode: '57230'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5930',
            label: 'ตับเต่า',
            zipcode: '57160'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5931',
            label: 'หงาว',
            zipcode: '57160'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5932',
            label: 'สันทรายงาม',
            zipcode: '57160'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5933',
            label: 'ศรีดอนไชย',
            zipcode: '57160'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5934',
            label: 'หนองแรด',
            zipcode: '57160'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5940',
            label: 'สันมะเค็ด',
            zipcode: '57120'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5941',
            label: 'แม่อ้อ',
            zipcode: '57120'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5942',
            label: 'ธารทอง',
            zipcode: '57250'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5943',
            label: 'สันติสุข',
            zipcode: '57120'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5944',
            label: 'ดอยงาม',
            zipcode: '57120'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5945',
            label: 'หัวง้ม',
            zipcode: '57120'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5946',
            label: 'เจริญเมือง',
            zipcode: '57120'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5947',
            label: 'ป่าหุ่ง',
            zipcode: '57120'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5948',
            label: 'ม่วงคำ',
            zipcode: '57120'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5949',
            label: 'ทรายขาว',
            zipcode: '57120'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5950',
            label: 'สันกลาง',
            zipcode: '57120'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5951',
            label: 'แม่เย็น',
            zipcode: '57280'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5952',
            label: 'เมืองพาน',
            zipcode: '57120'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5953',
            label: 'ทานตะวัน',
            zipcode: '57280'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5954',
            label: 'เวียงห้าว',
            zipcode: '57120'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '662',
            value: '5958',
            label: 'ป่าแดด',
            zipcode: '57190'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '662',
            value: '5959',
            label: 'ป่าแงะ',
            zipcode: '57190'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '662',
            value: '5960',
            label: 'สันมะค่า',
            zipcode: '57190'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '662',
            value: '5961',
            label: 'โรงช้าง',
            zipcode: '57190'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '662',
            value: '5962',
            label: 'ศรีโพธิ์เงิน',
            zipcode: '57190'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5963',
            label: 'แม่จัน',
            zipcode: '57110'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5964',
            label: 'จันจว้า',
            zipcode: '57270'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5965',
            label: 'แม่คำ',
            zipcode: '57240'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5966',
            label: 'ป่าซาง',
            zipcode: '57110'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5967',
            label: 'สันทราย',
            zipcode: '57110'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5968',
            label: 'ท่าข้าวเปลือก',
            zipcode: '57110'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5970',
            label: 'ป่าตึง',
            zipcode: '57110'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5972',
            label: 'แม่ไร่',
            zipcode: '57240'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5973',
            label: 'ศรีค้ำ',
            zipcode: '57110'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5974',
            label: 'จันจว้าใต้',
            zipcode: '57270'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5975',
            label: 'จอมสวรรค์',
            zipcode: '57110'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '664',
            value: '5980',
            label: 'เวียง',
            zipcode: '57150'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '664',
            value: '5981',
            label: 'ป่าสัก',
            zipcode: '57150'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '664',
            value: '5982',
            label: 'บ้านแซว',
            zipcode: '57150'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '664',
            value: '5983',
            label: 'ศรีดอนมูล',
            zipcode: '57150'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '664',
            value: '5984',
            label: 'แม่เงิน',
            zipcode: '57150'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '664',
            value: '5985',
            label: 'โยนก',
            zipcode: '57150'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '665',
            value: '5986',
            label: 'แม่สาย',
            zipcode: '57130'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '665',
            value: '5987',
            label: 'ห้วยไคร้',
            zipcode: '57220'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '665',
            value: '5988',
            label: 'เกาะช้าง',
            zipcode: '57130'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '665',
            value: '5989',
            label: 'โป่งผา',
            zipcode: '57130'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '665',
            value: '5990',
            label: 'ศรีเมืองชุม',
            zipcode: '57130'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '665',
            value: '5991',
            label: 'เวียงพางคำ',
            zipcode: '57130'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '665',
            value: '5992',
            label: 'บ้านด้าย',
            zipcode: '57220'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '665',
            value: '5993',
            label: 'โป่งงาม',
            zipcode: '57130'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '666',
            value: '5994',
            label: 'แม่สรวย',
            zipcode: '57180'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '666',
            value: '5995',
            label: 'ป่าแดด',
            zipcode: '57180'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '666',
            value: '5996',
            label: 'แม่พริก',
            zipcode: '57180'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '666',
            value: '5997',
            label: 'ศรีถ้อย',
            zipcode: '57180'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '666',
            value: '5998',
            label: 'ท่าก๊อ',
            zipcode: '57180'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '666',
            value: '5999',
            label: 'วาวี',
            zipcode: '57180'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '666',
            value: '6000',
            label: 'เจดีย์หลวง',
            zipcode: '57180'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '667',
            value: '6001',
            label: 'สันสลี',
            zipcode: '57170'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '667',
            value: '6002',
            label: 'เวียง',
            zipcode: '57170'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '667',
            value: '6003',
            label: 'บ้านโป่ง',
            zipcode: '57170'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '667',
            value: '6004',
            label: 'ป่างิ้ว',
            zipcode: '57170'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '667',
            value: '6005',
            label: 'เวียงกาหลง',
            zipcode: '57260'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '667',
            value: '6006',
            label: 'แม่เจดีย์',
            zipcode: '57260'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '667',
            value: '6007',
            label: 'แม่เจดีย์ใหม่',
            zipcode: '57260'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '668',
            value: '6009',
            label: 'แม่เปา',
            zipcode: '57290'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '668',
            value: '6010',
            label: 'แม่ต๋ำ',
            zipcode: '57290'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '668',
            value: '6011',
            label: 'ไม้ยา',
            zipcode: '57290'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '668',
            value: '6012',
            label: 'เม็งราย',
            zipcode: '57290'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '668',
            value: '6013',
            label: 'ตาดควัน',
            zipcode: '57290'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '669',
            value: '6014',
            label: 'ม่วงยาย',
            zipcode: '57310'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '669',
            value: '6015',
            label: 'ปอ',
            zipcode: '57310'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '669',
            value: '6016',
            label: 'หล่ายงาว',
            zipcode: '57310'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '669',
            value: '6017',
            label: 'ท่าข้าม',
            zipcode: '57310'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '670',
            value: '6018',
            label: 'ต้า',
            zipcode: '57340'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '670',
            value: '6019',
            label: 'ป่าตาล',
            zipcode: '57340'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '670',
            value: '6020',
            label: 'ยางฮอม',
            zipcode: '57340'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '671',
            value: '6021',
            label: 'เทอดไทย',
            zipcode: '57240'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '671',
            value: '6022',
            label: 'แม่สลองใน',
            zipcode: '57110'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '671',
            value: '6023',
            label: 'แม่สลองนอก',
            zipcode: '57110'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '671',
            value: '6024',
            label: 'แม่ฟ้าหลวง',
            zipcode: '57240'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '672',
            value: '6025',
            label: 'ดงมะดะ',
            zipcode: '57250'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '672',
            value: '6026',
            label: 'จอมหมอกแก้ว',
            zipcode: '57250'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '672',
            value: '6027',
            label: 'บัวสลี',
            zipcode: '57250'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '672',
            value: '6028',
            label: 'ป่าก่อดำ',
            zipcode: '57250'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '672',
            value: '6029',
            label: 'โป่งแพร่',
            zipcode: '57000'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '673',
            value: '6030',
            label: 'ทุ่งก่อ',
            zipcode: '57210'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '673',
            value: '6031',
            label: 'ดงมหาวัน',
            zipcode: '57210'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '673',
            value: '6032',
            label: 'ป่าซาง',
            zipcode: '57210'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '674',
            value: '6033',
            label: 'ปงน้อย',
            zipcode: '57110'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '674',
            value: '6034',
            label: 'โชคชัย',
            zipcode: '57110'
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '674',
            value: '6035',
            label: 'หนองป่าก่อ',
            zipcode: '57110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '675',
            value: '6036',
            label: 'จองคำ',
            zipcode: '58000'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '675',
            value: '6037',
            label: 'ห้วยโป่ง',
            zipcode: '58000'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '675',
            value: '6038',
            label: 'ผาบ่อง',
            zipcode: '58000'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '675',
            value: '6039',
            label: 'ปางหมู',
            zipcode: '58000'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '675',
            value: '6040',
            label: 'หมอกจำแป่',
            zipcode: '58000'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '675',
            value: '6041',
            label: 'ห้วยผา',
            zipcode: '58000'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '675',
            value: '6044',
            label: 'ห้วยปูลิง',
            zipcode: '58000'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '676',
            value: '6045',
            label: 'ขุนยวม',
            zipcode: '58140'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '676',
            value: '6046',
            label: 'แม่เงา',
            zipcode: '58140'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '676',
            value: '6047',
            label: 'เมืองปอน',
            zipcode: '58140'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '676',
            value: '6048',
            label: 'แม่ยวมน้อย',
            zipcode: '58140'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '676',
            value: '6049',
            label: 'แม่กิ๊',
            zipcode: '58140'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '676',
            value: '6050',
            label: 'แม่อูคอ',
            zipcode: '58140'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '677',
            value: '6051',
            label: 'เวียงใต้',
            zipcode: '58130'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '677',
            value: '6052',
            label: 'เวียงเหนือ',
            zipcode: '58130'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '677',
            value: '6053',
            label: 'แม่นาเติง',
            zipcode: '58130'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '677',
            value: '6054',
            label: 'แม่ฮี้',
            zipcode: '58130'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '677',
            value: '6055',
            label: 'ทุ่งยาว',
            zipcode: '58130'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '677',
            value: '6056',
            label: 'เมืองแปง',
            zipcode: '58130'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '677',
            value: '6057',
            label: 'โป่งสา',
            zipcode: '58130'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '678',
            value: '6058',
            label: 'บ้านกาศ',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '678',
            value: '6059',
            label: 'แม่สะเรียง',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '678',
            value: '6060',
            label: 'แม่คง',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '678',
            value: '6061',
            label: 'แม่เหาะ',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '678',
            value: '6062',
            label: 'แม่ยวม',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '678',
            value: '6063',
            label: 'เสาหิน',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '678',
            value: '6064',
            label: 'ป่าแป๋',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '679',
            value: '6068',
            label: 'แม่ลาน้อย',
            zipcode: '58120'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '679',
            value: '6069',
            label: 'แม่ลาหลวง',
            zipcode: '58120'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '679',
            value: '6070',
            label: 'ท่าผาปุ้ม',
            zipcode: '58120'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '679',
            value: '6071',
            label: 'แม่โถ',
            zipcode: '58120'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '679',
            value: '6072',
            label: 'ห้วยห้อม',
            zipcode: '58120'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '679',
            value: '6073',
            label: 'แม่นาจาง',
            zipcode: '58120'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '679',
            value: '6074',
            label: 'สันติคีรี',
            zipcode: '58120'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '679',
            value: '6075',
            label: 'ขุนแม่ลาน้อย',
            zipcode: '58120'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '680',
            value: '6076',
            label: 'สบเมย',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '680',
            value: '6077',
            label: 'แม่คะตวน',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '680',
            value: '6078',
            label: 'กองก๋อย',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '680',
            value: '6079',
            label: 'แม่สวด',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '680',
            value: '6080',
            label: 'ป่าโปง',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '680',
            value: '6081',
            label: 'แม่สามแลบ',
            zipcode: '58110'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '681',
            value: '6082',
            label: 'สบป่อง',
            zipcode: '58150'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '681',
            value: '6083',
            label: 'ปางมะผ้า',
            zipcode: '58150'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '681',
            value: '6084',
            label: 'ถ้ำลอด',
            zipcode: '58150'
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '681',
            value: '6085',
            label: 'นาปู่ป้อม',
            zipcode: '58150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6086',
            label: 'ปากน้ำโพ',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6087',
            label: 'กลางแดด',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6088',
            label: 'เกรียงไกร',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6089',
            label: 'แควใหญ่',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6090',
            label: 'ตะเคียนเลื่อน',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6091',
            label: 'นครสวรรค์ตก',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6092',
            label: 'นครสวรรค์ออก',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6093',
            label: 'บางพระหลวง',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6094',
            label: 'บางม่วง',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6095',
            label: 'บ้านมะเกลือ',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6096',
            label: 'บ้านแก่ง',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6097',
            label: 'พระนอน',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6098',
            label: 'วัดไทร',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6099',
            label: 'หนองกรด',
            zipcode: '60240'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6100',
            label: 'หนองกระโดน',
            zipcode: '60240'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6101',
            label: 'หนองปลิง',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '683',
            value: '6102',
            label: 'บึงเสนาท',
            zipcode: '60000'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '684',
            value: '6103',
            label: 'โกรกพระ',
            zipcode: '60170'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '684',
            value: '6104',
            label: 'ยางตาล',
            zipcode: '60170'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '684',
            value: '6105',
            label: 'บางมะฝ่อ',
            zipcode: '60170'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '684',
            value: '6106',
            label: 'บางประมุง',
            zipcode: '60170'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '684',
            value: '6107',
            label: 'นากลาง',
            zipcode: '60170'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '684',
            value: '6108',
            label: 'ศาลาแดง',
            zipcode: '60170'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '684',
            value: '6109',
            label: 'เนินกว้าว',
            zipcode: '60170'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '684',
            value: '6110',
            label: 'เนินศาลา',
            zipcode: '60170'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '684',
            value: '6111',
            label: 'หาดสูง',
            zipcode: '60170'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '685',
            value: '6112',
            label: 'ชุมแสง',
            zipcode: '60120'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '685',
            value: '6113',
            label: 'ทับกฤช',
            zipcode: '60250'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '685',
            value: '6114',
            label: 'พิกุล',
            zipcode: '60120'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '685',
            value: '6115',
            label: 'เกยไชย',
            zipcode: '60120'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '685',
            value: '6116',
            label: 'ท่าไม้',
            zipcode: '60120'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '685',
            value: '6117',
            label: 'บางเคียน',
            zipcode: '60120'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '685',
            value: '6118',
            label: 'หนองกระเจา',
            zipcode: '60120'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '685',
            value: '6119',
            label: 'พันลาน',
            zipcode: '60250'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '685',
            value: '6120',
            label: 'โคกหม้อ',
            zipcode: '60120'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '685',
            value: '6121',
            label: 'ไผ่สิงห์',
            zipcode: '60120'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '685',
            value: '6122',
            label: 'ฆะมัง',
            zipcode: '60120'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '685',
            value: '6123',
            label: 'ทับกฤชใต้',
            zipcode: '60250'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '686',
            value: '6124',
            label: 'หนองบัว',
            zipcode: '60110'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '686',
            value: '6125',
            label: 'หนองกลับ',
            zipcode: '60110'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '686',
            value: '6126',
            label: 'ธารทหาร',
            zipcode: '60110'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '686',
            value: '6127',
            label: 'ห้วยร่วม',
            zipcode: '60110'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '686',
            value: '6128',
            label: 'ห้วยถั่วใต้',
            zipcode: '60110'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '686',
            value: '6129',
            label: 'ห้วยถั่วเหนือ',
            zipcode: '60110'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '686',
            value: '6130',
            label: 'ห้วยใหญ่',
            zipcode: '60110'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '686',
            value: '6131',
            label: 'ทุ่งทอง',
            zipcode: '60110'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '686',
            value: '6132',
            label: 'วังบ่อ',
            zipcode: '60110'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6133',
            label: 'ท่างิ้ว',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6134',
            label: 'บางตาหงาย',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6135',
            label: 'หูกวาง',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6136',
            label: 'อ่างทอง',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6137',
            label: 'บ้านแดน',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6138',
            label: 'บางแก้ว',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6139',
            label: 'ตาขีด',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6140',
            label: 'ตาสัง',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6141',
            label: 'ด่านช้าง',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6142',
            label: 'หนองกรด',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6143',
            label: 'หนองตางู',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6144',
            label: 'บึงปลาทู',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '687',
            value: '6145',
            label: 'เจริญผล',
            zipcode: '60180'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '688',
            value: '6146',
            label: 'มหาโพธิ',
            zipcode: '60230'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '688',
            value: '6147',
            label: 'เก้าเลี้ยว',
            zipcode: '60230'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '688',
            value: '6148',
            label: 'หนองเต่า',
            zipcode: '60230'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '688',
            value: '6149',
            label: 'เขาดิน',
            zipcode: '60230'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '688',
            value: '6150',
            label: 'หัวดง',
            zipcode: '60230'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '689',
            value: '6151',
            label: 'ตาคลี',
            zipcode: '60140'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '689',
            value: '6152',
            label: 'ช่องแค',
            zipcode: '60210'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '689',
            value: '6153',
            label: 'จันเสน',
            zipcode: '60260'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '689',
            value: '6154',
            label: 'ห้วยหอม',
            zipcode: '60210'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '689',
            value: '6155',
            label: 'หัวหวาย',
            zipcode: '60140'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '689',
            value: '6156',
            label: 'หนองโพ',
            zipcode: '60140'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '689',
            value: '6157',
            label: 'หนองหม้อ',
            zipcode: '60140'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '689',
            value: '6158',
            label: 'สร้อยทอง',
            zipcode: '60210'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '689',
            value: '6159',
            label: 'ลาดทิพรส',
            zipcode: '60260'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '689',
            value: '6160',
            label: 'พรหมนิมิต',
            zipcode: '60210'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '690',
            value: '6161',
            label: 'ท่าตะโก',
            zipcode: '60160'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '690',
            value: '6162',
            label: 'พนมรอก',
            zipcode: '60160'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '690',
            value: '6163',
            label: 'หัวถนน',
            zipcode: '60160'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '690',
            value: '6164',
            label: 'สายลำโพง',
            zipcode: '60160'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '690',
            value: '6165',
            label: 'วังมหากร',
            zipcode: '60160'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '690',
            value: '6166',
            label: 'ดอนคา',
            zipcode: '60160'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '690',
            value: '6167',
            label: 'ทำนบ',
            zipcode: '60160'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '690',
            value: '6168',
            label: 'วังใหญ่',
            zipcode: '60160'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '690',
            value: '6169',
            label: 'พนมเศษ',
            zipcode: '60160'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '690',
            value: '6170',
            label: 'หนองหลวง',
            zipcode: '60160'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '691',
            value: '6171',
            label: 'โคกเดื่อ',
            zipcode: '60220'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '691',
            value: '6172',
            label: 'สำโรงชัย',
            zipcode: '60220'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '691',
            value: '6173',
            label: 'วังน้ำลัด',
            zipcode: '60220'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '691',
            value: '6174',
            label: 'ตะคร้อ',
            zipcode: '60220'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '691',
            value: '6175',
            label: 'โพธิ์ประสาท',
            zipcode: '60220'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '691',
            value: '6176',
            label: 'วังข่อย',
            zipcode: '60220'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '691',
            value: '6177',
            label: 'นาขอม',
            zipcode: '60220'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '691',
            value: '6178',
            label: 'ไพศาลี',
            zipcode: '60220'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '692',
            value: '6179',
            label: 'พยุหะ',
            zipcode: '60130'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '692',
            value: '6180',
            label: 'เนินมะกอก',
            zipcode: '60130'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '692',
            value: '6181',
            label: 'นิคมเขาบ่อแก้ว',
            zipcode: '60130'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '692',
            value: '6182',
            label: 'ม่วงหัก',
            zipcode: '60130'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '692',
            value: '6183',
            label: 'ยางขาว',
            zipcode: '60130'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '692',
            value: '6184',
            label: 'ย่านมัทรี',
            zipcode: '60130'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '692',
            value: '6185',
            label: 'เขาทอง',
            zipcode: '60130'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '692',
            value: '6186',
            label: 'ท่าน้ำอ้อย',
            zipcode: '60130'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '692',
            value: '6187',
            label: 'น้ำทรง',
            zipcode: '60130'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '692',
            value: '6188',
            label: 'เขากะลา',
            zipcode: '60130'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '692',
            value: '6189',
            label: 'สระทะเล',
            zipcode: '60130'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6190',
            label: 'ลาดยาว',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6191',
            label: 'ห้วยน้ำหอม',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6192',
            label: 'วังม้า',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6193',
            label: 'วังเมือง',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6194',
            label: 'สร้อยละคร',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6195',
            label: 'มาบแก',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6196',
            label: 'หนองยาว',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6197',
            label: 'หนองนมวัว',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6198',
            label: 'บ้านไร่',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6199',
            label: 'เนินขี้เหล็ก',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6205',
            label: 'ศาลเจ้าไก่ต่อ',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6206',
            label: 'สระแก้ว',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '694',
            value: '6207',
            label: 'ตากฟ้า',
            zipcode: '60190'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '694',
            value: '6208',
            label: 'ลำพยนต์',
            zipcode: '60190'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '694',
            value: '6209',
            label: 'สุขสำราญ',
            zipcode: '60190'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '694',
            value: '6210',
            label: 'หนองพิกุล',
            zipcode: '60190'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '694',
            value: '6211',
            label: 'พุนกยูง',
            zipcode: '60190'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '694',
            value: '6212',
            label: 'อุดมธัญญา',
            zipcode: '60190'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '694',
            value: '6213',
            label: 'เขาชายธง',
            zipcode: '60190'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '695',
            value: '6214',
            label: 'แม่วงก์',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '695',
            value: '6216',
            label: 'แม่เล่ย์',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '695',
            value: '6217',
            label: 'วังซ่าน',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '695',
            value: '6218',
            label: 'เขาชนกัน',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '696',
            value: '6222',
            label: 'แม่เปิน',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '697',
            value: '6223',
            label: 'ชุมตาบง',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '697',
            value: '6224',
            label: 'ปางสวรรค์',
            zipcode: '60150'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6225',
            label: 'อุทัยใหม่',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6226',
            label: 'น้ำซึม',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6227',
            label: 'สะแกกรัง',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6228',
            label: 'ดอนขวาง',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6229',
            label: 'หาดทนง',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6230',
            label: 'เกาะเทโพ',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6231',
            label: 'ท่าซุง',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6232',
            label: 'หนองแก',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6233',
            label: 'โนนเหล็ก',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6234',
            label: 'หนองเต่า',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6235',
            label: 'หนองไผ่แบน',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6236',
            label: 'หนองพังค่า',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6237',
            label: 'ทุ่งใหญ่',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6238',
            label: 'เนินแจง',
            zipcode: '61000'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '702',
            value: '6240',
            label: 'ทัพทัน',
            zipcode: '61120'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '702',
            value: '6241',
            label: 'ทุ่งนาไทย',
            zipcode: '61120'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '702',
            value: '6242',
            label: 'เขาขี้ฝอย',
            zipcode: '61120'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '702',
            value: '6243',
            label: 'หนองหญ้าปล้อง',
            zipcode: '61120'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '702',
            value: '6244',
            label: 'โคกหม้อ',
            zipcode: '61120'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '702',
            value: '6245',
            label: 'หนองยายดา',
            zipcode: '61120'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '702',
            value: '6246',
            label: 'หนองกลางดง',
            zipcode: '61120'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '702',
            value: '6247',
            label: 'หนองกระทุ่ม',
            zipcode: '61120'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '702',
            value: '6248',
            label: 'หนองสระ',
            zipcode: '61120'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '702',
            value: '6249',
            label: 'ตลุกดู่',
            zipcode: '61120'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '703',
            value: '6250',
            label: 'สว่างอารมณ์',
            zipcode: '61150'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '703',
            value: '6251',
            label: 'หนองหลวง',
            zipcode: '61150'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '703',
            value: '6252',
            label: 'พลวงสองนาง',
            zipcode: '61150'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '703',
            value: '6253',
            label: 'ไผ่เขียว',
            zipcode: '61150'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '703',
            value: '6254',
            label: 'บ่อยาง',
            zipcode: '61150'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '704',
            value: '6255',
            label: 'หนองฉาง',
            zipcode: '61110'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '704',
            value: '6256',
            label: 'หนองยาง',
            zipcode: '61110'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '704',
            value: '6257',
            label: 'หนองนางนวล',
            zipcode: '61110'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '704',
            value: '6258',
            label: 'หนองสรวง',
            zipcode: '61110'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '704',
            value: '6259',
            label: 'บ้านเก่า',
            zipcode: '61110'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '704',
            value: '6260',
            label: 'อุทัยเก่า',
            zipcode: '61110'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '704',
            value: '6261',
            label: 'ทุ่งโพ',
            zipcode: '61110'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '704',
            value: '6262',
            label: 'ทุ่งพง',
            zipcode: '61110'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '704',
            value: '6263',
            label: 'เขาบางแกรก',
            zipcode: '61170'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '704',
            value: '6264',
            label: 'เขากวางทอง',
            zipcode: '61110'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '705',
            value: '6265',
            label: 'หนองขาหย่าง',
            zipcode: '61130'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '705',
            value: '6266',
            label: 'หนองไผ่',
            zipcode: '61130'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '705',
            value: '6267',
            label: 'ดอนกลอย',
            zipcode: '61130'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '705',
            value: '6268',
            label: 'ห้วยรอบ',
            zipcode: '61130'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '705',
            value: '6269',
            label: 'ทุ่งพึ่ง',
            zipcode: '61130'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '705',
            value: '6270',
            label: 'ท่าโพ',
            zipcode: '61130'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '705',
            value: '6271',
            label: 'หมกแถว',
            zipcode: '61130'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '705',
            value: '6272',
            label: 'หลุมเข้า',
            zipcode: '61130'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '705',
            value: '6273',
            label: 'ดงขวาง',
            zipcode: '61130'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6274',
            label: 'บ้านไร่',
            zipcode: '61140'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6275',
            label: 'ทัพหลวง',
            zipcode: '61140'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6276',
            label: 'ห้วยแห้ง',
            zipcode: '61140'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6277',
            label: 'คอกควาย',
            zipcode: '61140'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6278',
            label: 'วังหิน',
            zipcode: '61180'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6279',
            label: 'เมืองการุ้ง',
            zipcode: '61180'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6280',
            label: 'แก่นมะกรูด',
            zipcode: '61140'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6281',
            label: 'หนองจอก',
            zipcode: '61180'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6282',
            label: 'หูช้าง',
            zipcode: '61180'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6283',
            label: 'บ้านบึง',
            zipcode: '61140'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6284',
            label: 'บ้านใหม่คลองเคียน',
            zipcode: '61180'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6285',
            label: 'หนองบ่มกล้วย',
            zipcode: '61180'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6286',
            label: 'เจ้าวัด',
            zipcode: '61140'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '707',
            value: '6292',
            label: 'ลานสัก',
            zipcode: '61160'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '707',
            value: '6293',
            label: 'ประดู่ยืน',
            zipcode: '61160'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '707',
            value: '6294',
            label: 'ป่าอ้อ',
            zipcode: '61160'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '707',
            value: '6295',
            label: 'ระบำ',
            zipcode: '61160'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '707',
            value: '6296',
            label: 'น้ำรอบ',
            zipcode: '61160'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '707',
            value: '6297',
            label: 'ทุ่งนางาม',
            zipcode: '61160'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '708',
            value: '6298',
            label: 'สุขฤทัย',
            zipcode: '61170'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '708',
            value: '6299',
            label: 'ทองหลาง',
            zipcode: '61170'
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '708',
            value: '6300',
            label: 'ห้วยคต',
            zipcode: '61170'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6301',
            label: 'ในเมือง',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6302',
            label: 'ไตรตรึงษ์',
            zipcode: '62160'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6303',
            label: 'อ่างทอง',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6304',
            label: 'นาบ่อคำ',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6305',
            label: 'นครชุม',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6306',
            label: 'ทรงธรรม',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6307',
            label: 'ลานดอกไม้',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6310',
            label: 'หนองปลิง',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6311',
            label: 'คณฑี',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6312',
            label: 'นิคมทุ่งโพธิ์ทะเล',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6313',
            label: 'เทพนคร',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6314',
            label: 'วังทอง',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6315',
            label: 'ท่าขุนราม',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6317',
            label: 'คลองแม่ลาย',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6318',
            label: 'ธำมรงค์',
            zipcode: '62160'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6319',
            label: 'สระแก้ว',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '710',
            value: '6323',
            label: 'ไทรงาม',
            zipcode: '62150'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '710',
            value: '6324',
            label: 'หนองคล้า',
            zipcode: '62150'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '710',
            value: '6325',
            label: 'หนองทอง',
            zipcode: '62150'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '710',
            value: '6326',
            label: 'หนองไม้กอง',
            zipcode: '62150'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '710',
            value: '6327',
            label: 'มหาชัย',
            zipcode: '62150'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '710',
            value: '6328',
            label: 'พานทอง',
            zipcode: '62150'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '710',
            value: '6329',
            label: 'หนองแม่แตง',
            zipcode: '62150'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '711',
            value: '6330',
            label: 'คลองน้ำไหล',
            zipcode: '62180'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '711',
            value: '6331',
            label: 'โป่งน้ำร้อน',
            zipcode: '62180'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '711',
            value: '6332',
            label: 'คลองลานพัฒนา',
            zipcode: '62180'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '711',
            value: '6333',
            label: 'สักงาม',
            zipcode: '62180'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6336',
            label: 'ยางสูง',
            zipcode: '62130'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6337',
            label: 'ป่าพุทรา',
            zipcode: '62130'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6338',
            label: 'แสนตอ',
            zipcode: '62130'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6339',
            label: 'สลกบาตร',
            zipcode: '62140'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6340',
            label: 'บ่อถ้ำ',
            zipcode: '62140'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6341',
            label: 'ดอนแตง',
            zipcode: '62140'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6342',
            label: 'วังชะพลู',
            zipcode: '62140'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6343',
            label: 'โค้งไผ่',
            zipcode: '62140'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6344',
            label: 'ปางมะค่า',
            zipcode: '62140'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6345',
            label: 'วังหามแห',
            zipcode: '62140'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6346',
            label: 'เกาะตาล',
            zipcode: '62130'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6348',
            label: 'คลองขลุง',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6349',
            label: 'ท่ามะเขือ',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6351',
            label: 'ท่าพุทรา',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6352',
            label: 'แม่ลาด',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6353',
            label: 'วังยาง',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6354',
            label: 'วังแขม',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6355',
            label: 'หัวถนน',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6356',
            label: 'วังไทร',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6360',
            label: 'วังบัว',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6363',
            label: 'คลองสมบูรณ์',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '714',
            value: '6365',
            label: 'พรานกระต่าย',
            zipcode: '62110'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '714',
            value: '6366',
            label: 'หนองหัววัว',
            zipcode: '62110'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '714',
            value: '6367',
            label: 'ท่าไม้',
            zipcode: '62110'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '714',
            value: '6368',
            label: 'วังควง',
            zipcode: '62110'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '714',
            value: '6369',
            label: 'วังตะแบก',
            zipcode: '62110'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '714',
            value: '6370',
            label: 'เขาคีริส',
            zipcode: '62110'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '714',
            value: '6371',
            label: 'คุยบ้านโอง',
            zipcode: '62110'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '714',
            value: '6372',
            label: 'คลองพิไกร',
            zipcode: '62110'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '714',
            value: '6373',
            label: 'ถ้ำกระต่ายทอง',
            zipcode: '62110'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '714',
            value: '6374',
            label: 'ห้วยยั้ง',
            zipcode: '62110'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '715',
            value: '6375',
            label: 'ลานกระบือ',
            zipcode: '62170'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '715',
            value: '6376',
            label: 'ช่องลม',
            zipcode: '62170'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '715',
            value: '6377',
            label: 'หนองหลวง',
            zipcode: '62170'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '715',
            value: '6378',
            label: 'โนนพลวง',
            zipcode: '62170'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '715',
            value: '6379',
            label: 'ประชาสุขสันต์',
            zipcode: '62170'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '715',
            value: '6380',
            label: 'บึงทับแรต',
            zipcode: '62170'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '715',
            value: '6381',
            label: 'จันทิมา',
            zipcode: '62170'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '716',
            value: '6382',
            label: 'ทุ่งทราย',
            zipcode: '62190'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '716',
            value: '6383',
            label: 'ทุ่งทอง',
            zipcode: '62190'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '716',
            value: '6384',
            label: 'ถาวรวัฒนา',
            zipcode: '62190'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '717',
            value: '6385',
            label: 'โพธิ์ทอง',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '717',
            value: '6386',
            label: 'หินดาต',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '717',
            value: '6387',
            label: 'ปางตาไว',
            zipcode: '62120'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '718',
            value: '6388',
            label: 'บึงสามัคคี',
            zipcode: '62210'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '718',
            value: '6389',
            label: 'วังชะโอน',
            zipcode: '62210'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '718',
            value: '6390',
            label: 'ระหาน',
            zipcode: '62210'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '718',
            value: '6391',
            label: 'เทพนิมิต',
            zipcode: '62210'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '719',
            value: '6392',
            label: 'โกสัมพี',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '719',
            value: '6393',
            label: 'เพชรชมภู',
            zipcode: '62000'
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '719',
            value: '6394',
            label: 'ลานดอกไม้ตก',
            zipcode: '62000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6395',
            label: 'ระแหง',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6396',
            label: 'หนองหลวง',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6397',
            label: 'เชียงเงิน',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6398',
            label: 'หัวเดียด',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6399',
            label: 'หนองบัวเหนือ',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6400',
            label: 'ไม้งาม',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6401',
            label: 'โป่งแดง',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6402',
            label: 'น้ำรึม',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6403',
            label: 'วังหิน',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6405',
            label: 'แม่ท้อ',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6406',
            label: 'ป่ามะม่วง',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6407',
            label: 'หนองบัวใต้',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6408',
            label: 'วังประจบ',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6409',
            label: 'ตลุกกลางทุ่ง',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '721',
            value: '6412',
            label: 'ตากออก',
            zipcode: '63120'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '721',
            value: '6413',
            label: 'สมอโคน',
            zipcode: '63120'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '721',
            value: '6414',
            label: 'แม่สลิด',
            zipcode: '63120'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '721',
            value: '6415',
            label: 'ตากตก',
            zipcode: '63120'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '721',
            value: '6416',
            label: 'เกาะตะเภา',
            zipcode: '63120'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '721',
            value: '6417',
            label: 'ทุ่งกระเชาะ',
            zipcode: '63120'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '721',
            value: '6418',
            label: 'ท้องฟ้า',
            zipcode: '63120'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '722',
            value: '6419',
            label: 'สามเงา',
            zipcode: '63130'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '722',
            value: '6420',
            label: 'วังหมัน',
            zipcode: '63130'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '722',
            value: '6421',
            label: 'ยกกระบัตร',
            zipcode: '63130'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '722',
            value: '6422',
            label: 'ย่านรี',
            zipcode: '63130'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '722',
            value: '6423',
            label: 'บ้านนา',
            zipcode: '63130'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '722',
            value: '6424',
            label: 'วังจันทร์',
            zipcode: '63130'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '723',
            value: '6425',
            label: 'แม่ระมาด',
            zipcode: '63140'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '723',
            value: '6426',
            label: 'แม่จะเรา',
            zipcode: '63140'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '723',
            value: '6427',
            label: 'ขะเนจื้อ',
            zipcode: '63140'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '723',
            value: '6428',
            label: 'แม่ตื่น',
            zipcode: '63140'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '723',
            value: '6429',
            label: 'สามหมื่น',
            zipcode: '63140'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '723',
            value: '6430',
            label: 'พระธาตุ',
            zipcode: '63140'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '724',
            value: '6431',
            label: 'ท่าสองยาง',
            zipcode: '63150'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '724',
            value: '6432',
            label: 'แม่ต้าน',
            zipcode: '63150'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '724',
            value: '6433',
            label: 'แม่สอง',
            zipcode: '63150'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '724',
            value: '6434',
            label: 'แม่หละ',
            zipcode: '63150'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '724',
            value: '6435',
            label: 'แม่วะหลวง',
            zipcode: '63150'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '724',
            value: '6436',
            label: 'แม่อุสุ',
            zipcode: '63150'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '725',
            value: '6437',
            label: 'แม่สอด',
            zipcode: '63110'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '725',
            value: '6438',
            label: 'แม่กุ',
            zipcode: '63110'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '725',
            value: '6439',
            label: 'พะวอ',
            zipcode: '63110'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '725',
            value: '6440',
            label: 'แม่ตาว',
            zipcode: '63110'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '725',
            value: '6441',
            label: 'แม่กาษา',
            zipcode: '63110'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '725',
            value: '6442',
            label: 'ท่าสายลวด',
            zipcode: '63110'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '725',
            value: '6443',
            label: 'แม่ปะ',
            zipcode: '63110'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '725',
            value: '6444',
            label: 'มหาวัน',
            zipcode: '63110'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '725',
            value: '6445',
            label: 'ด่านแม่ละเมา',
            zipcode: '63110'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '725',
            value: '6446',
            label: 'พระธาตุผาแดง',
            zipcode: '63110'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '726',
            value: '6447',
            label: 'พบพระ',
            zipcode: '63160'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '726',
            value: '6448',
            label: 'ช่องแคบ',
            zipcode: '63160'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '726',
            value: '6449',
            label: 'คีรีราษฎร์',
            zipcode: '63160'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '726',
            value: '6450',
            label: 'วาเล่ย์',
            zipcode: '63160'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '726',
            value: '6451',
            label: 'รวมไทยพัฒนา',
            zipcode: '63160'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '727',
            value: '6452',
            label: 'อุ้มผาง',
            zipcode: '63170'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '727',
            value: '6453',
            label: 'หนองหลวง',
            zipcode: '63170'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '727',
            value: '6454',
            label: 'โมโกร',
            zipcode: '63170'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '727',
            value: '6455',
            label: 'แม่จัน',
            zipcode: '63170'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '727',
            value: '6456',
            label: 'แม่ละมุ้ง',
            zipcode: '63170'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '727',
            value: '6457',
            label: 'แม่กลอง',
            zipcode: '63170'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '728',
            value: '6458',
            label: 'เชียงทอง',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '728',
            value: '6459',
            label: 'นาโบสถ์',
            zipcode: '63000'
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '728',
            value: '6460',
            label: 'ประดาง',
            zipcode: '63000'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '730',
            value: '6461',
            label: 'ธานี',
            zipcode: '64000'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '730',
            value: '6462',
            label: 'บ้านสวน',
            zipcode: '64220'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '730',
            value: '6463',
            label: 'เมืองเก่า',
            zipcode: '64210'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '730',
            value: '6464',
            label: 'ปากแคว',
            zipcode: '64000'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '730',
            value: '6465',
            label: 'ยางซ้าย',
            zipcode: '64000'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '730',
            value: '6466',
            label: 'บ้านกล้วย',
            zipcode: '64000'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '730',
            value: '6467',
            label: 'บ้านหลุม',
            zipcode: '64000'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '730',
            value: '6468',
            label: 'ตาลเตี้ย',
            zipcode: '64220'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '730',
            value: '6469',
            label: 'ปากพระ',
            zipcode: '64000'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '730',
            value: '6470',
            label: 'วังทองแดง',
            zipcode: '64210'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '731',
            value: '6471',
            label: 'ลานหอย',
            zipcode: '64140'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '731',
            value: '6472',
            label: 'บ้านด่าน',
            zipcode: '64140'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '731',
            value: '6473',
            label: 'วังตะคร้อ',
            zipcode: '64140'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '731',
            value: '6474',
            label: 'วังน้ำขาว',
            zipcode: '64140'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '731',
            value: '6475',
            label: 'ตลิ่งชัน',
            zipcode: '64140'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '731',
            value: '6476',
            label: 'หนองหญ้าปล้อง',
            zipcode: '64140'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '731',
            value: '6477',
            label: 'วังลึก',
            zipcode: '64140'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '732',
            value: '6478',
            label: 'โตนด',
            zipcode: '64160'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '732',
            value: '6479',
            label: 'ทุ่งหลวง',
            zipcode: '64160'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '732',
            value: '6480',
            label: 'บ้านป้อม',
            zipcode: '64160'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '732',
            value: '6481',
            label: 'สามพวง',
            zipcode: '64160'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '732',
            value: '6482',
            label: 'ศรีคีรีมาศ',
            zipcode: '64160'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '732',
            value: '6483',
            label: 'หนองจิก',
            zipcode: '64160'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '732',
            value: '6484',
            label: 'นาเชิงคีรี',
            zipcode: '64160'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '732',
            value: '6485',
            label: 'หนองกระดิ่ง',
            zipcode: '64160'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '732',
            value: '6486',
            label: 'บ้านน้ำพุ',
            zipcode: '64160'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '732',
            value: '6487',
            label: 'ทุ่งยางเมือง',
            zipcode: '64160'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '733',
            value: '6488',
            label: 'กง',
            zipcode: '64170'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '733',
            value: '6489',
            label: 'บ้านกร่าง',
            zipcode: '64170'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '733',
            value: '6490',
            label: 'ไกรนอก',
            zipcode: '64170'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '733',
            value: '6491',
            label: 'ไกรกลาง',
            zipcode: '64170'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '733',
            value: '6492',
            label: 'ไกรใน',
            zipcode: '64170'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '733',
            value: '6493',
            label: 'ดงเดือย',
            zipcode: '64170'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '733',
            value: '6494',
            label: 'ป่าแฝก',
            zipcode: '64170'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '733',
            value: '6495',
            label: 'กกแรต',
            zipcode: '64170'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '733',
            value: '6496',
            label: 'ท่าฉนวน',
            zipcode: '64170'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '733',
            value: '6497',
            label: 'หนองตูม',
            zipcode: '64170'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '733',
            value: '6498',
            label: 'บ้านใหม่สุขเกษม',
            zipcode: '64170'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '734',
            value: '6499',
            label: 'หาดเสี้ยว',
            zipcode: '64130'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '734',
            value: '6500',
            label: 'ป่างิ้ว',
            zipcode: '64130'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '734',
            value: '6501',
            label: 'แม่สำ',
            zipcode: '64130'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '734',
            value: '6502',
            label: 'แม่สิน',
            zipcode: '64130'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '734',
            value: '6503',
            label: 'บ้านตึก',
            zipcode: '64130'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '734',
            value: '6504',
            label: 'หนองอ้อ',
            zipcode: '64130'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '734',
            value: '6505',
            label: 'ท่าชัย',
            zipcode: '64190'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '734',
            value: '6506',
            label: 'ศรีสัชนาลัย',
            zipcode: '64190'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '734',
            value: '6507',
            label: 'ดงคู่',
            zipcode: '64130'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '734',
            value: '6508',
            label: 'บ้านแก่ง',
            zipcode: '64130'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '734',
            value: '6509',
            label: 'สารจิตร',
            zipcode: '64130'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6510',
            label: 'คลองตาล',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6511',
            label: 'วังลึก',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6512',
            label: 'สามเรือน',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6513',
            label: 'บ้านนา',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6514',
            label: 'วังทอง',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6515',
            label: 'นาขุนไกร',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6516',
            label: 'เกาะตาเลี้ยง',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6517',
            label: 'วัดเกาะ',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6518',
            label: 'บ้านไร่',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6519',
            label: 'ทับผึ้ง',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6520',
            label: 'บ้านซ่าน',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6521',
            label: 'วังใหญ่',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '735',
            value: '6522',
            label: 'ราวต้นจันทร์',
            zipcode: '64120'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6523',
            label: 'เมืองสวรรคโลก',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6524',
            label: 'ในเมือง',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6525',
            label: 'คลองกระจง',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6526',
            label: 'วังพิณพาทย์',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6527',
            label: 'วังไม้ขอน',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6528',
            label: 'ย่านยาว',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6529',
            label: 'นาทุ่ง',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6530',
            label: 'คลองยาง',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6531',
            label: 'เมืองบางยม',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6532',
            label: 'ท่าทอง',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6533',
            label: 'ปากน้ำ',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6534',
            label: 'ป่ากุมเกาะ',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6535',
            label: 'เมืองบางขลัง',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6536',
            label: 'หนองกลับ',
            zipcode: '64110'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '737',
            value: '6542',
            label: 'ศรีนคร',
            zipcode: '64180'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '737',
            value: '6543',
            label: 'นครเดิฐ',
            zipcode: '64180'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '737',
            value: '6544',
            label: 'น้ำขุม',
            zipcode: '64180'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '737',
            value: '6545',
            label: 'คลองมะพลับ',
            zipcode: '64180'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '737',
            value: '6546',
            label: 'หนองบัว',
            zipcode: '64180'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '738',
            value: '6547',
            label: 'บ้านใหม่ไชยมงคล',
            zipcode: '64230'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '738',
            value: '6548',
            label: 'ไทยชนะศึก',
            zipcode: '64150'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '738',
            value: '6549',
            label: 'ทุ่งเสลี่ยม',
            zipcode: '64150'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '738',
            value: '6550',
            label: 'กลางดง',
            zipcode: '64150'
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '738',
            value: '6551',
            label: 'เขาแก้วศรีสมบูรณ์',
            zipcode: '64230'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6552',
            label: 'ในเมือง',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6553',
            label: 'วังน้ำคู้',
            zipcode: '65230'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6554',
            label: 'วัดจันทร์',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6555',
            label: 'วัดพริก',
            zipcode: '65230'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6556',
            label: 'ท่าทอง',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6557',
            label: 'ท่าโพธิ์',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6558',
            label: 'สมอแข',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6559',
            label: 'ดอนทอง',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6560',
            label: 'บ้านป่า',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6561',
            label: 'ปากโทก',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6562',
            label: 'หัวรอ',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6563',
            label: 'จอมทอง',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6564',
            label: 'บ้านกร่าง',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6565',
            label: 'บ้านคลอง',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6566',
            label: 'พลายชุมพล',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6567',
            label: 'มะขามสูง',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6568',
            label: 'อรัญญิก',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6569',
            label: 'บึงพระ',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6570',
            label: 'ไผ่ขอดอน',
            zipcode: '65000'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '739',
            value: '6571',
            label: 'งิ้วงาม',
            zipcode: '65230'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '740',
            value: '6572',
            label: 'นครไทย',
            zipcode: '65120'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '740',
            value: '6573',
            label: 'หนองกะท้าว',
            zipcode: '65120'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '740',
            value: '6574',
            label: 'บ้านแยง',
            zipcode: '65120'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '740',
            value: '6575',
            label: 'เนินเพิ่ม',
            zipcode: '65120'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '740',
            value: '6576',
            label: 'นาบัว',
            zipcode: '65120'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '740',
            value: '6577',
            label: 'นครชุม',
            zipcode: '65120'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '740',
            value: '6578',
            label: 'น้ำกุ่ม',
            zipcode: '65120'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '740',
            value: '6579',
            label: 'ยางโกลน',
            zipcode: '65120'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '740',
            value: '6580',
            label: 'บ่อโพธิ์',
            zipcode: '65120'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '740',
            value: '6581',
            label: 'บ้านพร้าว',
            zipcode: '65120'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '740',
            value: '6582',
            label: 'ห้วยเฮี้ย',
            zipcode: '65120'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '741',
            value: '6583',
            label: 'ป่าแดง',
            zipcode: '65170'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '741',
            value: '6584',
            label: 'ชาติตระการ',
            zipcode: '65170'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '741',
            value: '6585',
            label: 'สวนเมี่ยง',
            zipcode: '65170'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '741',
            value: '6586',
            label: 'บ้านดง',
            zipcode: '65170'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '741',
            value: '6587',
            label: 'บ่อภาค',
            zipcode: '65170'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '741',
            value: '6588',
            label: 'ท่าสะแก',
            zipcode: '65170'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '742',
            value: '6589',
            label: 'บางระกำ',
            zipcode: '65140'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '742',
            value: '6590',
            label: 'ปลักแรด',
            zipcode: '65140'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '742',
            value: '6591',
            label: 'พันเสา',
            zipcode: '65140'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '742',
            value: '6592',
            label: 'วังอิทก',
            zipcode: '65140'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '742',
            value: '6593',
            label: 'บึงกอก',
            zipcode: '65140'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '742',
            value: '6594',
            label: 'หนองกุลา',
            zipcode: '65140'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '742',
            value: '6595',
            label: 'ชุมแสงสงคราม',
            zipcode: '65240'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '742',
            value: '6596',
            label: 'นิคมพัฒนา',
            zipcode: '65140'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '742',
            value: '6597',
            label: 'บ่อทอง',
            zipcode: '65140'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '742',
            value: '6598',
            label: 'ท่านางงาม',
            zipcode: '65140'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '742',
            value: '6599',
            label: 'คุยม่วง',
            zipcode: '65240'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '743',
            value: '6600',
            label: 'บางกระทุ่ม',
            zipcode: '65110'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '743',
            value: '6601',
            label: 'บ้านไร่',
            zipcode: '65110'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '743',
            value: '6602',
            label: 'โคกสลุด',
            zipcode: '65110'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '743',
            value: '6603',
            label: 'สนามคลี',
            zipcode: '65110'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '743',
            value: '6604',
            label: 'ท่าตาล',
            zipcode: '65110'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '743',
            value: '6605',
            label: 'ไผ่ล้อม',
            zipcode: '65110'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '743',
            value: '6606',
            label: 'นครป่าหมาก',
            zipcode: '65110'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '743',
            value: '6607',
            label: 'เนินกุ่ม',
            zipcode: '65210'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '743',
            value: '6608',
            label: 'วัดตายม',
            zipcode: '65210'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '744',
            value: '6609',
            label: 'พรหมพิราม',
            zipcode: '65150'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '744',
            value: '6610',
            label: 'ท่าช้าง',
            zipcode: '65150'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '744',
            value: '6611',
            label: 'วงฆ้อง',
            zipcode: '65180'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '744',
            value: '6612',
            label: 'มะตูม',
            zipcode: '65150'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '744',
            value: '6613',
            label: 'หอกลอง',
            zipcode: '65150'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '744',
            value: '6614',
            label: 'ศรีภิรมย์',
            zipcode: '65180'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '744',
            value: '6615',
            label: 'ตลุกเทียม',
            zipcode: '65180'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '744',
            value: '6616',
            label: 'วังวน',
            zipcode: '65150'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '744',
            value: '6617',
            label: 'หนองแขม',
            zipcode: '65150'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '744',
            value: '6618',
            label: 'มะต้อง',
            zipcode: '65180'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '744',
            value: '6619',
            label: 'ทับยายเชียง',
            zipcode: '65150'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '744',
            value: '6620',
            label: 'ดงประคำ',
            zipcode: '65180'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '745',
            value: '6621',
            label: 'วัดโบสถ์',
            zipcode: '65160'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '745',
            value: '6622',
            label: 'ท่างาม',
            zipcode: '65160'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '745',
            value: '6623',
            label: 'ท้อแท้',
            zipcode: '65160'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '745',
            value: '6624',
            label: 'บ้านยาง',
            zipcode: '65160'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '745',
            value: '6625',
            label: 'หินลาด',
            zipcode: '65160'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '745',
            value: '6626',
            label: 'คันโช้ง',
            zipcode: '65160'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6627',
            label: 'วังทอง',
            zipcode: '65130'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6628',
            label: 'พันชาลี',
            zipcode: '65130'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6629',
            label: 'แม่ระกา',
            zipcode: '65130'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6630',
            label: 'บ้านกลาง',
            zipcode: '65220'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6631',
            label: 'วังพิกุล',
            zipcode: '65130'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6632',
            label: 'แก่งโสภา',
            zipcode: '65220'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6633',
            label: 'ท่าหมื่นราม',
            zipcode: '65130'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6634',
            label: 'วังนกแอ่น',
            zipcode: '65130'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6635',
            label: 'หนองพระ',
            zipcode: '65130'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6636',
            label: 'ชัยนาม',
            zipcode: '65130'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6637',
            label: 'ดินทอง',
            zipcode: '65130'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '747',
            value: '6643',
            label: 'ชมพู',
            zipcode: '65190'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '747',
            value: '6644',
            label: 'บ้านมุง',
            zipcode: '65190'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '747',
            value: '6645',
            label: 'ไทรย้อย',
            zipcode: '65190'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '747',
            value: '6646',
            label: 'วังโพรง',
            zipcode: '65190'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '747',
            value: '6647',
            label: 'บ้านน้อยซุ้มขี้เหล็ก',
            zipcode: '65190'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '747',
            value: '6648',
            label: 'เนินมะปราง',
            zipcode: '65190'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '747',
            value: '6649',
            label: 'วังยาง',
            zipcode: '65190'
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '747',
            value: '6650',
            label: 'โคกแหลม',
            zipcode: '65190'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6651',
            label: 'ในเมือง',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6652',
            label: 'ไผ่ขวาง',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6653',
            label: 'ย่านยาว',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6654',
            label: 'ท่าฬ่อ',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6655',
            label: 'ปากทาง',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6656',
            label: 'คลองคะเชนทร์',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6657',
            label: 'โรงช้าง',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6658',
            label: 'เมืองเก่า',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6659',
            label: 'ท่าหลวง',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6660',
            label: 'บ้านบุ่ง',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6661',
            label: 'ฆะมัง',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6662',
            label: 'ดงป่าคำ',
            zipcode: '66170'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6663',
            label: 'หัวดง',
            zipcode: '66170'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6665',
            label: 'ป่ามะคาบ',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6669',
            label: 'สายคำโห้',
            zipcode: '66000'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6670',
            label: 'ดงกลาง',
            zipcode: '66170'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '749',
            value: '6679',
            label: 'วังทรายพูน',
            zipcode: '66180'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '749',
            value: '6680',
            label: 'หนองปลาไหล',
            zipcode: '66180'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '749',
            value: '6681',
            label: 'หนองพระ',
            zipcode: '66180'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '749',
            value: '6682',
            label: 'หนองปล้อง',
            zipcode: '66180'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '750',
            value: '6683',
            label: 'โพธิ์ประทับช้าง',
            zipcode: '66190'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '750',
            value: '6684',
            label: 'ไผ่ท่าโพ',
            zipcode: '66190'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '750',
            value: '6685',
            label: 'วังจิก',
            zipcode: '66190'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '750',
            value: '6686',
            label: 'ไผ่รอบ',
            zipcode: '66190'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '750',
            value: '6687',
            label: 'ดงเสือเหลือง',
            zipcode: '66190'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '750',
            value: '6688',
            label: 'เนินสว่าง',
            zipcode: '66190'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '750',
            value: '6689',
            label: 'ทุ่งใหญ่',
            zipcode: '66190'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6690',
            label: 'ตะพานหิน',
            zipcode: '66110'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6691',
            label: 'งิ้วราย',
            zipcode: '66110'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6692',
            label: 'ห้วยเกตุ',
            zipcode: '66110'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6693',
            label: 'ไทรโรงโขน',
            zipcode: '66110'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6694',
            label: 'หนองพยอม',
            zipcode: '66110'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6695',
            label: 'ทุ่งโพธิ์',
            zipcode: '66150'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6696',
            label: 'ดงตะขบ',
            zipcode: '66110'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6697',
            label: 'คลองคูณ',
            zipcode: '66110'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6698',
            label: 'วังสำโรง',
            zipcode: '66110'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6699',
            label: 'วังหว้า',
            zipcode: '66110'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6700',
            label: 'วังหลุม',
            zipcode: '66150'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6701',
            label: 'ทับหมัน',
            zipcode: '66110'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6702',
            label: 'ไผ่หลวง',
            zipcode: '66110'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6707',
            label: 'บางมูลนาก',
            zipcode: '66120'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6708',
            label: 'บางไผ่',
            zipcode: '66120'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6709',
            label: 'หอไกร',
            zipcode: '66120'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6710',
            label: 'เนินมะกอก',
            zipcode: '66120'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6711',
            label: 'วังสำโรง',
            zipcode: '66120'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6712',
            label: 'ภูมิ',
            zipcode: '66120'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6713',
            label: 'วังกรด',
            zipcode: '66120'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6714',
            label: 'ห้วยเขน',
            zipcode: '66120'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6715',
            label: 'วังตะกู',
            zipcode: '66210'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6720',
            label: 'ลำประดา',
            zipcode: '66120'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6722',
            label: 'โพทะเล',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6723',
            label: 'ท้ายน้ำ',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6724',
            label: 'ทะนง',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6725',
            label: 'ท่าบัว',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6726',
            label: 'ทุ่งน้อย',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6727',
            label: 'ท่าขมิ้น',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6728',
            label: 'ท่าเสา',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6729',
            label: 'บางคลาน',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6732',
            label: 'ท่านั่ง',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6733',
            label: 'บ้านน้อย',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6734',
            label: 'วัดขวาง',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '754',
            value: '6738',
            label: 'สามง่าม',
            zipcode: '66140'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '754',
            value: '6739',
            label: 'กำแพงดิน',
            zipcode: '66140'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '754',
            value: '6740',
            label: 'รังนก',
            zipcode: '66140'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '754',
            value: '6743',
            label: 'เนินปอ',
            zipcode: '66140'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '754',
            value: '6744',
            label: 'หนองโสน',
            zipcode: '66140'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '755',
            value: '6747',
            label: 'ทับคล้อ',
            zipcode: '66150'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '755',
            value: '6748',
            label: 'เขาทราย',
            zipcode: '66230'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '755',
            value: '6749',
            label: 'เขาเจ็ดลูก',
            zipcode: '66230'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '755',
            value: '6750',
            label: 'ท้ายทุ่ง',
            zipcode: '66150'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '756',
            value: '6751',
            label: 'สากเหล็ก',
            zipcode: '66160'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '756',
            value: '6752',
            label: 'ท่าเยี่ยม',
            zipcode: '66160'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '756',
            value: '6753',
            label: 'คลองทราย',
            zipcode: '66160'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '756',
            value: '6754',
            label: 'หนองหญ้าไทร',
            zipcode: '66160'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '756',
            value: '6755',
            label: 'วังทับไทร',
            zipcode: '66160'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '757',
            value: '6756',
            label: 'ห้วยแก้ว',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '757',
            value: '6757',
            label: 'โพธิ์ไทรงาม',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '757',
            value: '6758',
            label: 'แหลมรัง',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '757',
            value: '6759',
            label: 'บางลาย',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '757',
            value: '6760',
            label: 'บึงนาราง',
            zipcode: '66130'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '758',
            value: '6761',
            label: 'วังงิ้วใต้',
            zipcode: '66210'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '758',
            value: '6762',
            label: 'วังงิ้ว',
            zipcode: '66210'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '758',
            value: '6763',
            label: 'ห้วยร่วม',
            zipcode: '66210'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '758',
            value: '6764',
            label: 'ห้วยพุก',
            zipcode: '66210'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '758',
            value: '6765',
            label: 'สำนักขุนเณร',
            zipcode: '66210'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '759',
            value: '6766',
            label: 'บ้านนา',
            zipcode: '66140'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '759',
            value: '6767',
            label: 'บึงบัว',
            zipcode: '66140'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '759',
            value: '6768',
            label: 'วังโมกข์',
            zipcode: '66140'
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '759',
            value: '6769',
            label: 'หนองหลุม',
            zipcode: '66220'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6770',
            label: 'ในเมือง',
            zipcode: '67000'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6771',
            label: 'ตะเบาะ',
            zipcode: '67000'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6772',
            label: 'บ้านโตก',
            zipcode: '67000'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6773',
            label: 'สะเดียง',
            zipcode: '67000'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6774',
            label: 'ป่าเลา',
            zipcode: '67000'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6775',
            label: 'นางั่ว',
            zipcode: '67000'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6776',
            label: 'ท่าพล',
            zipcode: '67250'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6777',
            label: 'ดงมูลเหล็ก',
            zipcode: '67000'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6778',
            label: 'บ้านโคก',
            zipcode: '67000'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6779',
            label: 'ชอนไพร',
            zipcode: '67000'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6780',
            label: 'นาป่า',
            zipcode: '67000'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6781',
            label: 'นายม',
            zipcode: '67210'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6782',
            label: 'วังชมภู',
            zipcode: '67210'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6783',
            label: 'น้ำร้อน',
            zipcode: '67000'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6784',
            label: 'ห้วยสะแก',
            zipcode: '67210'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6785',
            label: 'ห้วยใหญ่',
            zipcode: '67000'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '760',
            value: '6786',
            label: 'ระวิง',
            zipcode: '67210'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '761',
            value: '6787',
            label: 'ชนแดน',
            zipcode: '67150'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '761',
            value: '6788',
            label: 'ดงขุย',
            zipcode: '67190'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '761',
            value: '6789',
            label: 'ท่าข้าม',
            zipcode: '67150'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '761',
            value: '6790',
            label: 'พุทธบาท',
            zipcode: '67150'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '761',
            value: '6791',
            label: 'ลาดแค',
            zipcode: '67150'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '761',
            value: '6792',
            label: 'บ้านกล้วย',
            zipcode: '67190'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '761',
            value: '6794',
            label: 'ซับพุทรา',
            zipcode: '67150'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '761',
            value: '6795',
            label: 'ตะกุดไร',
            zipcode: '67190'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '761',
            value: '6796',
            label: 'ศาลาลาย',
            zipcode: '67150'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6799',
            label: 'หล่มสัก',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6800',
            label: 'วัดป่า',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6801',
            label: 'ตาลเดี่ยว',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6802',
            label: 'ฝายนาแซง',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6803',
            label: 'หนองสว่าง',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6804',
            label: 'น้ำเฮี้ย',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6805',
            label: 'สักหลง',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6806',
            label: 'ท่าอิบุญ',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6807',
            label: 'บ้านโสก',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6808',
            label: 'บ้านติ้ว',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6809',
            label: 'ห้วยไร่',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6810',
            label: 'น้ำก้อ',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6811',
            label: 'ปากช่อง',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6812',
            label: 'น้ำชุน',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6813',
            label: 'หนองไขว่',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6814',
            label: 'ลานบ่า',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6815',
            label: 'บุ่งคล้า',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6816',
            label: 'บุ่งน้ำเต้า',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6817',
            label: 'บ้านกลาง',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6818',
            label: 'ช้างตะลูด',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6819',
            label: 'บ้านไร่',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6820',
            label: 'ปากดุก',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6821',
            label: 'บ้านหวาย',
            zipcode: '67110'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '763',
            value: '6823',
            label: 'หล่มเก่า',
            zipcode: '67120'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '763',
            value: '6824',
            label: 'นาซำ',
            zipcode: '67120'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '763',
            value: '6825',
            label: 'หินฮาว',
            zipcode: '67120'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '763',
            value: '6826',
            label: 'บ้านเนิน',
            zipcode: '67120'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '763',
            value: '6827',
            label: 'ศิลา',
            zipcode: '67120'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '763',
            value: '6828',
            label: 'นาแซง',
            zipcode: '67120'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '763',
            value: '6829',
            label: 'วังบาล',
            zipcode: '67120'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '763',
            value: '6830',
            label: 'นาเกาะ',
            zipcode: '67120'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '763',
            value: '6831',
            label: 'ตาดกลอย',
            zipcode: '67120'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6833',
            label: 'ท่าโรง',
            zipcode: '67130'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6834',
            label: 'สระประดู่',
            zipcode: '67130'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6835',
            label: 'สามแยก',
            zipcode: '67130'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6836',
            label: 'โคกปรง',
            zipcode: '67130'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6837',
            label: 'น้ำร้อน',
            zipcode: '67130'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6838',
            label: 'บ่อรัง',
            zipcode: '67130'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6839',
            label: 'พุเตย',
            zipcode: '67180'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6840',
            label: 'พุขาม',
            zipcode: '67180'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6841',
            label: 'ภูน้ำหยด',
            zipcode: '67180'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6842',
            label: 'ซับสมบูรณ์',
            zipcode: '67180'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6843',
            label: 'บึงกระจับ',
            zipcode: '67130'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6844',
            label: 'วังใหญ่',
            zipcode: '67180'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6845',
            label: 'ยางสาว',
            zipcode: '67130'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6846',
            label: 'ซับน้อย',
            zipcode: '67180'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '765',
            value: '6851',
            label: 'ศรีเทพ',
            zipcode: '67170'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '765',
            value: '6852',
            label: 'สระกรวด',
            zipcode: '67170'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '765',
            value: '6853',
            label: 'คลองกระจัง',
            zipcode: '67170'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '765',
            value: '6854',
            label: 'นาสนุ่น',
            zipcode: '67170'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '765',
            value: '6855',
            label: 'โคกสะอาด',
            zipcode: '67170'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '765',
            value: '6856',
            label: 'หนองย่างทอย',
            zipcode: '67170'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '765',
            value: '6857',
            label: 'ประดู่งาม',
            zipcode: '67170'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6858',
            label: 'กองทูล',
            zipcode: '67140'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6859',
            label: 'นาเฉลียง',
            zipcode: '67220'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6860',
            label: 'บ้านโภชน์',
            zipcode: '67140'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6861',
            label: 'ท่าแดง',
            zipcode: '67140'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6862',
            label: 'เพชรละคร',
            zipcode: '67140'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6863',
            label: 'บ่อไทย',
            zipcode: '67140'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6864',
            label: 'ห้วยโป่ง',
            zipcode: '67220'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6865',
            label: 'วังท่าดี',
            zipcode: '67140'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6866',
            label: 'บัววัฒนา',
            zipcode: '67140'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6867',
            label: 'หนองไผ่',
            zipcode: '67140'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6868',
            label: 'วังโบสถ์',
            zipcode: '67140'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6869',
            label: 'ยางงาม',
            zipcode: '67220'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '766',
            value: '6870',
            label: 'ท่าด้วง',
            zipcode: '67140'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '767',
            value: '6871',
            label: 'ซับสมอทอด',
            zipcode: '67160'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '767',
            value: '6872',
            label: 'ซับไม้แดง',
            zipcode: '67160'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '767',
            value: '6873',
            label: 'หนองแจง',
            zipcode: '67160'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '767',
            value: '6874',
            label: 'กันจุ',
            zipcode: '67160'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '767',
            value: '6875',
            label: 'วังพิกุล',
            zipcode: '67230'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '767',
            value: '6876',
            label: 'พญาวัง',
            zipcode: '67160'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '767',
            value: '6877',
            label: 'ศรีมงคล',
            zipcode: '67160'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '767',
            value: '6878',
            label: 'สระแก้ว',
            zipcode: '67160'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '767',
            value: '6879',
            label: 'บึงสามพัน',
            zipcode: '67160'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '768',
            value: '6880',
            label: 'น้ำหนาว',
            zipcode: '67260'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '768',
            value: '6881',
            label: 'หลักด่าน',
            zipcode: '67260'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '768',
            value: '6882',
            label: 'วังกวาง',
            zipcode: '67260'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '768',
            value: '6883',
            label: 'โคกมน',
            zipcode: '67260'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '769',
            value: '6884',
            label: 'วังโป่ง',
            zipcode: '67240'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '769',
            value: '6885',
            label: 'ท้ายดง',
            zipcode: '67240'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '769',
            value: '6886',
            label: 'ซับเปิบ',
            zipcode: '67240'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '769',
            value: '6887',
            label: 'วังหิน',
            zipcode: '67240'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '769',
            value: '6888',
            label: 'วังศาล',
            zipcode: '67240'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '770',
            value: '6889',
            label: 'ทุ่งสมอ',
            zipcode: '67270'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '770',
            value: '6890',
            label: 'แคมป์สน',
            zipcode: '67280'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '770',
            value: '6891',
            label: 'เขาค้อ',
            zipcode: '67270'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '770',
            value: '6892',
            label: 'ริมสีม่วง',
            zipcode: '67270'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '770',
            value: '6893',
            label: 'สะเดาะพง',
            zipcode: '67270'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '770',
            value: '6894',
            label: 'หนองแม่นา',
            zipcode: '67270'
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '770',
            value: '6895',
            label: 'เข็กน้อย',
            zipcode: '67280'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6896',
            label: 'หน้าเมือง',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6897',
            label: 'เจดีย์หัก',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6898',
            label: 'ดอนตะโก',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6899',
            label: 'หนองกลางนา',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6900',
            label: 'ห้วยไผ่',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6901',
            label: 'คุ้งน้ำวน',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6902',
            label: 'คุ้งกระถิน',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6903',
            label: 'อ่างทอง',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6904',
            label: 'โคกหม้อ',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6905',
            label: 'สามเรือน',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6906',
            label: 'พิกุลทอง',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6907',
            label: 'น้ำพุ',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6908',
            label: 'ดอนแร่',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6909',
            label: 'หินกอง',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6910',
            label: 'เขาแร้ง',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6911',
            label: 'เกาะพลับพลา',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6912',
            label: 'หลุมดิน',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6913',
            label: 'บางป่า',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6914',
            label: 'พงสวาย',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6915',
            label: 'คูบัว',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6916',
            label: 'ท่าราบ',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '771',
            value: '6917',
            label: 'บ้านไร่',
            zipcode: '70000'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '772',
            value: '6918',
            label: 'จอมบึง',
            zipcode: '70150'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '772',
            value: '6919',
            label: 'ปากช่อง',
            zipcode: '70150'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '772',
            value: '6920',
            label: 'เบิกไพร',
            zipcode: '70150'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '772',
            value: '6921',
            label: 'ด่านทับตะโก',
            zipcode: '70150'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '772',
            value: '6922',
            label: 'แก้มอ้น',
            zipcode: '70150'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '772',
            value: '6923',
            label: 'รางบัว',
            zipcode: '70150'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '773',
            value: '6927',
            label: 'สวนผึ้ง',
            zipcode: '70180'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '773',
            value: '6928',
            label: 'ป่าหวาย',
            zipcode: '70180'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '773',
            value: '6930',
            label: 'ท่าเคย',
            zipcode: '70180'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '773',
            value: '6933',
            label: 'ตะนาวศรี',
            zipcode: '70180'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6934',
            label: 'ดำเนินสะดวก',
            zipcode: '70130'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6935',
            label: 'ประสาทสิทธิ์',
            zipcode: '70210'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6936',
            label: 'ศรีสุราษฎร์',
            zipcode: '70130'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6937',
            label: 'ตาหลวง',
            zipcode: '70130'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6938',
            label: 'ดอนกรวย',
            zipcode: '70130'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6939',
            label: 'ดอนคลัง',
            zipcode: '70130'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6940',
            label: 'บัวงาม',
            zipcode: '70210'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6941',
            label: 'บ้านไร่',
            zipcode: '70130'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6942',
            label: 'แพงพวย',
            zipcode: '70130'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6943',
            label: 'สี่หมื่น',
            zipcode: '70130'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6944',
            label: 'ท่านัด',
            zipcode: '70130'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6945',
            label: 'ขุนพิทักษ์',
            zipcode: '70130'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '774',
            value: '6946',
            label: 'ดอนไผ่',
            zipcode: '70130'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6947',
            label: 'บ้านโป่ง',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6948',
            label: 'ท่าผา',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6949',
            label: 'กรับใหญ่',
            zipcode: '70190'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6950',
            label: 'ปากแรต',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6951',
            label: 'หนองกบ',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6952',
            label: 'หนองอ้อ',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6953',
            label: 'ดอนกระเบื้อง',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6954',
            label: 'สวนกล้วย',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6955',
            label: 'นครชุมน์',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6956',
            label: 'บ้านม่วง',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6957',
            label: 'คุ้งพยอม',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6958',
            label: 'หนองปลาหมอ',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6959',
            label: 'เขาขลุง',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6960',
            label: 'เบิกไพร',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '775',
            value: '6961',
            label: 'ลาดบัวขาว',
            zipcode: '70110'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '776',
            value: '6962',
            label: 'บางแพ',
            zipcode: '70160'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '776',
            value: '6963',
            label: 'วังเย็น',
            zipcode: '70160'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '776',
            value: '6964',
            label: 'หัวโพ',
            zipcode: '70160'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '776',
            value: '6965',
            label: 'วัดแก้ว',
            zipcode: '70160'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '776',
            value: '6966',
            label: 'ดอนใหญ่',
            zipcode: '70160'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '776',
            value: '6967',
            label: 'ดอนคา',
            zipcode: '70160'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '776',
            value: '6968',
            label: 'โพหัก',
            zipcode: '70160'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6969',
            label: 'โพธาราม',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6970',
            label: 'ดอนกระเบื้อง',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6971',
            label: 'หนองโพ',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6972',
            label: 'บ้านเลือก',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6973',
            label: 'คลองตาคต',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6974',
            label: 'บ้านฆ้อง',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6975',
            label: 'บ้านสิงห์',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6976',
            label: 'ดอนทราย',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6977',
            label: 'เจ็ดเสมียน',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6978',
            label: 'คลองข่อย',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6979',
            label: 'ชำแระ',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6980',
            label: 'สร้อยฟ้า',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6981',
            label: 'ท่าชุมพล',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6982',
            label: 'บางโตนด',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6983',
            label: 'เตาปูน',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6984',
            label: 'นางแก้ว',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6985',
            label: 'ธรรมเสน',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6986',
            label: 'เขาชะงุ้ม',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '777',
            value: '6987',
            label: 'หนองกวาง',
            zipcode: '70120'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '778',
            value: '6988',
            label: 'ทุ่งหลวง',
            zipcode: '70140'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '778',
            value: '6989',
            label: 'วังมะนาว',
            zipcode: '70140'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '778',
            value: '6990',
            label: 'ดอนทราย',
            zipcode: '70140'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '778',
            value: '6991',
            label: 'หนองกระทุ่ม',
            zipcode: '70140'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '778',
            value: '6992',
            label: 'ปากท่อ',
            zipcode: '70140'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '778',
            value: '6993',
            label: 'ป่าไก่',
            zipcode: '70140'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '778',
            value: '6994',
            label: 'วัดยางงาม',
            zipcode: '70140'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '778',
            value: '6995',
            label: 'อ่างหิน',
            zipcode: '70140'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '778',
            value: '6996',
            label: 'บ่อกระดาน',
            zipcode: '70140'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '778',
            value: '6997',
            label: 'ยางหัก',
            zipcode: '70140'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '778',
            value: '6998',
            label: 'วันดาว',
            zipcode: '70140'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '778',
            value: '6999',
            label: 'ห้วยยางโทน',
            zipcode: '70140'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '779',
            value: '7000',
            label: 'เกาะศาลพระ',
            zipcode: '70170'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '779',
            value: '7001',
            label: 'จอมประทัด',
            zipcode: '70170'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '779',
            value: '7002',
            label: 'วัดเพลง',
            zipcode: '70170'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '780',
            value: '7003',
            label: 'บ้านคา',
            zipcode: '70180'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '780',
            value: '7004',
            label: 'บ้านบึง',
            zipcode: '70180'
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '780',
            value: '7005',
            label: 'หนองพันจันทร์',
            zipcode: '70180'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7006',
            label: 'บ้านเหนือ',
            zipcode: '71000'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7007',
            label: 'บ้านใต้',
            zipcode: '71000'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7008',
            label: 'ปากแพรก',
            zipcode: '71000'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7009',
            label: 'ท่ามะขาม',
            zipcode: '71000'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7010',
            label: 'แก่งเสี้ยน',
            zipcode: '71000'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7011',
            label: 'หนองบัว',
            zipcode: '71190'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7012',
            label: 'ลาดหญ้า',
            zipcode: '71190'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7013',
            label: 'วังด้ง',
            zipcode: '71190'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7014',
            label: 'ช่องสะเดา',
            zipcode: '71190'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7015',
            label: 'หนองหญ้า',
            zipcode: '71000'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7016',
            label: 'เกาะสำโรง',
            zipcode: '71000'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7018',
            label: 'บ้านเก่า',
            zipcode: '71000'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7021',
            label: 'วังเย็น',
            zipcode: '71000'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '783',
            value: '7022',
            label: 'ลุ่มสุ่ม',
            zipcode: '71150'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '783',
            value: '7023',
            label: 'ท่าเสา',
            zipcode: '71150'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '783',
            value: '7024',
            label: 'สิงห์',
            zipcode: '71150'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '783',
            value: '7025',
            label: 'ไทรโยค',
            zipcode: '71150'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '783',
            value: '7026',
            label: 'วังกระแจะ',
            zipcode: '71150'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '783',
            value: '7027',
            label: 'ศรีมงคล',
            zipcode: '71150'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '783',
            value: '7028',
            label: 'บ้องตี้',
            zipcode: '71150'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '784',
            value: '7029',
            label: 'บ่อพลอย',
            zipcode: '71160'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '784',
            value: '7030',
            label: 'หนองกุ่ม',
            zipcode: '71160'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '784',
            value: '7031',
            label: 'หนองรี',
            zipcode: '71220'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '784',
            value: '7033',
            label: 'หลุมรัง',
            zipcode: '71160'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '784',
            value: '7036',
            label: 'ช่องด่าน',
            zipcode: '71160'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '784',
            value: '7037',
            label: 'หนองกร่าง',
            zipcode: '71220'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '785',
            value: '7038',
            label: 'นาสวน',
            zipcode: '71250'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '785',
            value: '7039',
            label: 'ด่านแม่แฉลบ',
            zipcode: '71250'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '785',
            value: '7040',
            label: 'หนองเป็ด',
            zipcode: '71250'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '785',
            value: '7041',
            label: 'ท่ากระดาน',
            zipcode: '71250'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '785',
            value: '7042',
            label: 'เขาโจด',
            zipcode: '71220'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '785',
            value: '7043',
            label: 'แม่กระบุง',
            zipcode: '71250'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7044',
            label: 'พงตึก',
            zipcode: '71120'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7045',
            label: 'ยางม่วง',
            zipcode: '71120'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7046',
            label: 'ดอนชะเอม',
            zipcode: '71130'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7047',
            label: 'ท่าไม้',
            zipcode: '71120'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7048',
            label: 'ตะคร้ำเอน',
            zipcode: '71130'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7049',
            label: 'ท่ามะกา',
            zipcode: '71120'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7050',
            label: 'ท่าเรือ',
            zipcode: '71130'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7051',
            label: 'โคกตะบอง',
            zipcode: '71120'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7052',
            label: 'ดอนขมิ้น',
            zipcode: '71120'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7053',
            label: 'อุโลกสี่หมื่น',
            zipcode: '71130'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7054',
            label: 'เขาสามสิบหาบ',
            zipcode: '71120'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7055',
            label: 'พระแท่น',
            zipcode: '71130'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7056',
            label: 'หวายเหนียว',
            zipcode: '71120'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7057',
            label: 'แสนตอ',
            zipcode: '71130'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7058',
            label: 'สนามแย้',
            zipcode: '70190'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7059',
            label: 'ท่าเสา',
            zipcode: '71120'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '786',
            value: '7060',
            label: 'หนองลาน',
            zipcode: '71130'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7061',
            label: 'ท่าม่วง',
            zipcode: '71110'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7062',
            label: 'วังขนาย',
            zipcode: '71110'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7063',
            label: 'วังศาลา',
            zipcode: '71110'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7064',
            label: 'ท่าล้อ',
            zipcode: '71110'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7065',
            label: 'หนองขาว',
            zipcode: '71110'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7066',
            label: 'ทุ่งทอง',
            zipcode: '71110'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7067',
            label: 'เขาน้อย',
            zipcode: '71110'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7068',
            label: 'ม่วงชุม',
            zipcode: '71110'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7069',
            label: 'บ้านใหม่',
            zipcode: '71110'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7070',
            label: 'พังตรุ',
            zipcode: '71110'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7071',
            label: 'ท่าตะคร้อ',
            zipcode: '71130'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7072',
            label: 'รางสาลี่',
            zipcode: '71110'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '787',
            value: '7073',
            label: 'หนองตากยา',
            zipcode: '71110'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '788',
            value: '7074',
            label: 'ท่าขนุน',
            zipcode: '71180'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '788',
            value: '7075',
            label: 'ปิล๊อก',
            zipcode: '71180'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '788',
            value: '7076',
            label: 'หินดาด',
            zipcode: '71180'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '788',
            value: '7077',
            label: 'ลิ่นถิ่น',
            zipcode: '71180'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '788',
            value: '7078',
            label: 'ชะแล',
            zipcode: '71180'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '788',
            value: '7079',
            label: 'ห้วยเขย่ง',
            zipcode: '71180'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '788',
            value: '7080',
            label: 'สหกรณ์นิคม',
            zipcode: '71180'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '789',
            value: '7081',
            label: 'หนองลู',
            zipcode: '71240'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '789',
            value: '7082',
            label: 'ปรังเผล',
            zipcode: '71240'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '789',
            value: '7083',
            label: 'ไล่โว่',
            zipcode: '71240'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '790',
            value: '7084',
            label: 'พนมทวน',
            zipcode: '71140'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '790',
            value: '7085',
            label: 'หนองโรง',
            zipcode: '71140'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '790',
            value: '7086',
            label: 'ทุ่งสมอ',
            zipcode: '71140'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '790',
            value: '7087',
            label: 'ดอนเจดีย์',
            zipcode: '71140'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '790',
            value: '7088',
            label: 'พังตรุ',
            zipcode: '71140'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '790',
            value: '7089',
            label: 'รางหวาย',
            zipcode: '71170'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '790',
            value: '7094',
            label: 'หนองสาหร่าย',
            zipcode: '71140'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '790',
            value: '7095',
            label: 'ดอนตาเพชร',
            zipcode: '71140'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '791',
            value: '7096',
            label: 'เลาขวัญ',
            zipcode: '71210'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '791',
            value: '7097',
            label: 'หนองโสน',
            zipcode: '71210'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '791',
            value: '7098',
            label: 'หนองประดู่',
            zipcode: '71210'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '791',
            value: '7099',
            label: 'หนองปลิง',
            zipcode: '71210'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '791',
            value: '7100',
            label: 'หนองนกแก้ว',
            zipcode: '71210'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '791',
            value: '7101',
            label: 'ทุ่งกระบ่ำ',
            zipcode: '71210'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '791',
            value: '7102',
            label: 'หนองฝ้าย',
            zipcode: '71210'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '792',
            value: '7103',
            label: 'ด่านมะขามเตี้ย',
            zipcode: '71260'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '792',
            value: '7104',
            label: 'กลอนโด',
            zipcode: '71260'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '792',
            value: '7105',
            label: 'จรเข้เผือก',
            zipcode: '71260'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '792',
            value: '7106',
            label: 'หนองไผ่',
            zipcode: '71260'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '793',
            value: '7107',
            label: 'หนองปรือ',
            zipcode: '71220'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '793',
            value: '7108',
            label: 'หนองปลาไหล',
            zipcode: '71220'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '793',
            value: '7109',
            label: 'สมเด็จเจริญ',
            zipcode: '71220'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '794',
            value: '7110',
            label: 'ห้วยกระเจา',
            zipcode: '71170'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '794',
            value: '7111',
            label: 'วังไผ่',
            zipcode: '71170'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '794',
            value: '7112',
            label: 'ดอนแสลบ',
            zipcode: '71170'
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '794',
            value: '7113',
            label: 'สระลงเรือ',
            zipcode: '71170'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7114',
            label: 'ท่าพี่เลี้ยง',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7115',
            label: 'รั้วใหญ่',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7116',
            label: 'ทับตีเหล็ก',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7117',
            label: 'ท่าระหัด',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7118',
            label: 'ไผ่ขวาง',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7119',
            label: 'โคกโคเฒ่า',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7120',
            label: 'ดอนตาล',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7121',
            label: 'ดอนมะสังข์',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7122',
            label: 'พิหารแดง',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7123',
            label: 'ดอนกำยาน',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7124',
            label: 'ดอนโพธิ์ทอง',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7125',
            label: 'บ้านโพธิ์',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7126',
            label: 'สระแก้ว',
            zipcode: '72230'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7127',
            label: 'ตลิ่งชัน',
            zipcode: '72230'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7128',
            label: 'บางกุ้ง',
            zipcode: '72210'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7129',
            label: 'ศาลาขาว',
            zipcode: '72210'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7130',
            label: 'สวนแตง',
            zipcode: '72210'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7131',
            label: 'สนามชัย',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7132',
            label: 'โพธิ์พระยา',
            zipcode: '72000'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '797',
            value: '7133',
            label: 'สนามคลี',
            zipcode: '72230'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7134',
            label: 'เขาพระ',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7135',
            label: 'เดิมบาง',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7136',
            label: 'นางบวช',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7137',
            label: 'เขาดิน',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7138',
            label: 'ปากน้ำ',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7139',
            label: 'ทุ่งคลี',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7140',
            label: 'โคกช้าง',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7141',
            label: 'หัวเขา',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7142',
            label: 'หัวนา',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7143',
            label: 'บ่อกรุ',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7144',
            label: 'วังศรีราช',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7145',
            label: 'ป่าสะแก',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7146',
            label: 'ยางนอน',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7147',
            label: 'หนองกระทุ่ม',
            zipcode: '72120'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '799',
            value: '7152',
            label: 'หนองมะค่าโมง',
            zipcode: '72180'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '799',
            value: '7153',
            label: 'ด่านช้าง',
            zipcode: '72180'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '799',
            value: '7154',
            label: 'ห้วยขมิ้น',
            zipcode: '72180'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '799',
            value: '7155',
            label: 'องค์พระ',
            zipcode: '72180'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '799',
            value: '7156',
            label: 'วังคัน',
            zipcode: '72180'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '799',
            value: '7157',
            label: 'นิคมกระเสียว',
            zipcode: '72180'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '799',
            value: '7158',
            label: 'วังยาว',
            zipcode: '72180'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7159',
            label: 'โคกคราม',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7160',
            label: 'บางปลาม้า',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7161',
            label: 'ตะค่า',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7162',
            label: 'บางใหญ่',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7163',
            label: 'กฤษณา',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7164',
            label: 'สาลี',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7165',
            label: 'ไผ่กองดิน',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7166',
            label: 'องครักษ์',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7167',
            label: 'จรเข้ใหญ่',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7168',
            label: 'บ้านแหลม',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7169',
            label: 'มะขามล้ม',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7170',
            label: 'วังน้ำเย็น',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7171',
            label: 'วัดโบสถ์',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '800',
            value: '7172',
            label: 'วัดดาว',
            zipcode: '72150'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '801',
            value: '7173',
            label: 'ศรีประจันต์',
            zipcode: '72140'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '801',
            value: '7174',
            label: 'บ้านกร่าง',
            zipcode: '72140'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '801',
            value: '7175',
            label: 'มดแดง',
            zipcode: '72140'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '801',
            value: '7176',
            label: 'บางงาม',
            zipcode: '72140'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '801',
            value: '7177',
            label: 'ดอนปรู',
            zipcode: '72140'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '801',
            value: '7178',
            label: 'ปลายนา',
            zipcode: '72140'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '801',
            value: '7179',
            label: 'วังหว้า',
            zipcode: '72140'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '801',
            value: '7180',
            label: 'วังน้ำซับ',
            zipcode: '72140'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '801',
            value: '7181',
            label: 'วังยาง',
            zipcode: '72140'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '802',
            value: '7182',
            label: 'ดอนเจดีย์',
            zipcode: '72170'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '802',
            value: '7183',
            label: 'หนองสาหร่าย',
            zipcode: '72170'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '802',
            value: '7184',
            label: 'ไร่รถ',
            zipcode: '72170'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '802',
            value: '7185',
            label: 'สระกระโจม',
            zipcode: '72250'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '802',
            value: '7186',
            label: 'ทะเลบก',
            zipcode: '72250'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7187',
            label: 'สองพี่น้อง',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7188',
            label: 'บางเลน',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7189',
            label: 'บางตาเถร',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7190',
            label: 'บางตะเคียน',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7191',
            label: 'บ้านกุ่ม',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7192',
            label: 'หัวโพธิ์',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7193',
            label: 'บางพลับ',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7194',
            label: 'เนินพระปรางค์',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7195',
            label: 'บ้านช้าง',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7196',
            label: 'ต้นตาล',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7197',
            label: 'ศรีสำราญ',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7198',
            label: 'ทุ่งคอก',
            zipcode: '72190'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7199',
            label: 'หนองบ่อ',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7200',
            label: 'บ่อสุพรรณ',
            zipcode: '72190'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '803',
            value: '7201',
            label: 'ดอนมะนาว',
            zipcode: '72110'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '804',
            value: '7202',
            label: 'ย่านยาว',
            zipcode: '72130'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '804',
            value: '7203',
            label: 'วังลึก',
            zipcode: '72130'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '804',
            value: '7204',
            label: 'สามชุก',
            zipcode: '72130'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '804',
            value: '7205',
            label: 'หนองผักนาก',
            zipcode: '72130'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '804',
            value: '7206',
            label: 'บ้านสระ',
            zipcode: '72130'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '804',
            value: '7207',
            label: 'หนองสะเดา',
            zipcode: '72130'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '804',
            value: '7208',
            label: 'กระเสียว',
            zipcode: '72130'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7213',
            label: 'อู่ทอง',
            zipcode: '72160'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7214',
            label: 'สระยายโสม',
            zipcode: '72220'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7215',
            label: 'จรเข้สามพัน',
            zipcode: '72160'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7216',
            label: 'บ้านดอน',
            zipcode: '72160'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7217',
            label: 'ยุ้งทะลาย',
            zipcode: '72160'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7218',
            label: 'ดอนมะเกลือ',
            zipcode: '72220'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7219',
            label: 'หนองโอ่ง',
            zipcode: '72160'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7220',
            label: 'ดอนคา',
            zipcode: '72160'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7221',
            label: 'พลับพลาไชย',
            zipcode: '72160'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7222',
            label: 'บ้านโข้ง',
            zipcode: '72160'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7223',
            label: 'เจดีย์',
            zipcode: '72160'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7224',
            label: 'สระพังลาน',
            zipcode: '72220'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '805',
            value: '7225',
            label: 'กระจัน',
            zipcode: '72160'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '806',
            value: '7226',
            label: 'หนองหญ้าไซ',
            zipcode: '72240'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '806',
            value: '7227',
            label: 'หนองราชวัตร',
            zipcode: '72240'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '806',
            value: '7228',
            label: 'หนองโพธิ์',
            zipcode: '72240'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '806',
            value: '7229',
            label: 'แจงงาม',
            zipcode: '72240'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '806',
            value: '7230',
            label: 'หนองขาม',
            zipcode: '72240'
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '806',
            value: '7231',
            label: 'ทัพหลวง',
            zipcode: '72240'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7232',
            label: 'พระปฐมเจดีย์',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7233',
            label: 'บางแขม',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7234',
            label: 'พระประโทน',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7235',
            label: 'ธรรมศาลา',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7236',
            label: 'ตาก้อง',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7237',
            label: 'มาบแค',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7238',
            label: 'สนามจันทร์',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7239',
            label: 'ดอนยายหอม',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7240',
            label: 'ถนนขาด',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7241',
            label: 'บ่อพลับ',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7242',
            label: 'นครปฐม',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7243',
            label: 'วังตะกู',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7244',
            label: 'หนองปากโลง',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7245',
            label: 'สามควายเผือก',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7246',
            label: 'ทุ่งน้อย',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7247',
            label: 'หนองดินแดง',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7248',
            label: 'วังเย็น',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7249',
            label: 'โพรงมะเดื่อ',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7250',
            label: 'ลำพยา',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7251',
            label: 'สระกะเทียม',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7252',
            label: 'สวนป่าน',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7253',
            label: 'ห้วยจรเข้',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7254',
            label: 'ทัพหลวง',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7255',
            label: 'หนองงูเหลือม',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '807',
            value: '7256',
            label: 'บ้านยาง',
            zipcode: '73000'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7257',
            label: 'ทุ่งกระพังโหม',
            zipcode: '73140'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7258',
            label: 'กระตีบ',
            zipcode: '73180'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7259',
            label: 'ทุ่งลูกนก',
            zipcode: '73140'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7260',
            label: 'ห้วยขวาง',
            zipcode: '73140'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7261',
            label: 'ทุ่งขวาง',
            zipcode: '73140'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7262',
            label: 'สระสี่มุม',
            zipcode: '73140'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7263',
            label: 'ทุ่งบัว',
            zipcode: '73140'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7264',
            label: 'ดอนข่อย',
            zipcode: '73140'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7265',
            label: 'สระพัฒนา',
            zipcode: '73180'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7266',
            label: 'ห้วยหมอนทอง',
            zipcode: '73140'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7267',
            label: 'ห้วยม่วง',
            zipcode: '73180'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7268',
            label: 'กำแพงแสน',
            zipcode: '73140'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7269',
            label: 'รางพิกุล',
            zipcode: '73140'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7270',
            label: 'หนองกระทุ่ม',
            zipcode: '73140'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '808',
            value: '7271',
            label: 'วังน้ำเขียว',
            zipcode: '73140'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7272',
            label: 'นครชัยศรี',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7273',
            label: 'บางกระเบา',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7274',
            label: 'วัดแค',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7275',
            label: 'ท่าตำหนัก',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7276',
            label: 'บางแก้ว',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7277',
            label: 'ท่ากระชับ',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7278',
            label: 'ขุนแก้ว',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7279',
            label: 'ท่าพระยา',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7280',
            label: 'พะเนียด',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7281',
            label: 'บางระกำ',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7282',
            label: 'โคกพระเจดีย์',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7283',
            label: 'ศรีษะทอง',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7284',
            label: 'แหลมบัว',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7285',
            label: 'ศรีมหาโพธิ์',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7286',
            label: 'สัมปทวน',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7287',
            label: 'วัดสำโรง',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7288',
            label: 'ดอนแฝก',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7289',
            label: 'ห้วยพลู',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7290',
            label: 'วัดละมุด',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7291',
            label: 'บางพระ',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7292',
            label: 'บางแก้วฟ้า',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7293',
            label: 'ลานตากฟ้า',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7294',
            label: 'งิ้วราย',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7295',
            label: 'ไทยาวาส',
            zipcode: '73120'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '810',
            value: '7302',
            label: 'สามง่าม',
            zipcode: '73150'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '810',
            value: '7303',
            label: 'ห้วยพระ',
            zipcode: '73150'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '810',
            value: '7304',
            label: 'ลำเหย',
            zipcode: '73150'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '810',
            value: '7305',
            label: 'ดอนพุทรา',
            zipcode: '73150'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '810',
            value: '7306',
            label: 'บ้านหลวง',
            zipcode: '73150'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '810',
            value: '7307',
            label: 'ดอนรวก',
            zipcode: '73150'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '810',
            value: '7308',
            label: 'ห้วยด้วน',
            zipcode: '73150'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '810',
            value: '7309',
            label: 'ลำลูกบัว',
            zipcode: '73150'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7310',
            label: 'บางเลน',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7311',
            label: 'บางปลา',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7312',
            label: 'บางหลวง',
            zipcode: '73190'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7313',
            label: 'บางภาษี',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7314',
            label: 'บางระกำ',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7315',
            label: 'บางไทรป่า',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7316',
            label: 'หินมูล',
            zipcode: '73190'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7317',
            label: 'ไทรงาม',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7318',
            label: 'ดอนตูม',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7319',
            label: 'นิลเพชร',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7320',
            label: 'บัวปากท่า',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7321',
            label: 'คลองนกกระทุง',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7322',
            label: 'นราภิรมย์',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7323',
            label: 'ลำพญา',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '811',
            value: '7324',
            label: 'ไผ่หูช้าง',
            zipcode: '73130'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7325',
            label: 'ท่าข้าม',
            zipcode: '73110'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7326',
            label: 'ทรงคนอง',
            zipcode: '73210'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7327',
            label: 'หอมเกร็ด',
            zipcode: '73110'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7328',
            label: 'บางกระทึก',
            zipcode: '73210'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7329',
            label: 'บางเตย',
            zipcode: '73210'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7330',
            label: 'สามพราน',
            zipcode: '73110'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7331',
            label: 'บางช้าง',
            zipcode: '73110'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7332',
            label: 'ไร่ขิง',
            zipcode: '73210'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7333',
            label: 'ท่าตลาด',
            zipcode: '73110'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7334',
            label: 'กระทุ่มล้ม',
            zipcode: '73220'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7335',
            label: 'คลองใหม่',
            zipcode: '73110'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7336',
            label: 'ตลาดจินดา',
            zipcode: '73110'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7337',
            label: 'คลองจินดา',
            zipcode: '73110'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7338',
            label: 'ยายชา',
            zipcode: '73110'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7339',
            label: 'บ้านใหม่',
            zipcode: '73110'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '812',
            value: '7340',
            label: 'อ้อมใหญ่',
            zipcode: '73160'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '813',
            value: '7341',
            label: 'ศาลายา',
            zipcode: '73170'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '813',
            value: '7342',
            label: 'คลองโยง',
            zipcode: '73170'
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '813',
            value: '7343',
            label: 'มหาสวัสดิ์',
            zipcode: '73170'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7344',
            label: 'มหาชัย',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7345',
            label: 'ท่าฉลอม',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7346',
            label: 'โกรกกราก',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7347',
            label: 'บ้านบ่อ',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7348',
            label: 'บางโทรัด',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7349',
            label: 'กาหลง',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7350',
            label: 'นาโคก',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7351',
            label: 'ท่าจีน',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7352',
            label: 'นาดี',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7353',
            label: 'ท่าทราย',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7354',
            label: 'คอกกระบือ',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7355',
            label: 'บางน้ำจืด',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7356',
            label: 'พันท้ายนรสิงห์',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7357',
            label: 'โคกขาม',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7358',
            label: 'บ้านเกาะ',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7359',
            label: 'บางกระเจ้า',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7360',
            label: 'บางหญ้าแพรก',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '814',
            value: '7361',
            label: 'ชัยมงคล',
            zipcode: '74000'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '815',
            value: '7362',
            label: 'ตลาดกระทุ่มแบน',
            zipcode: '74110'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '815',
            value: '7363',
            label: 'อ้อมน้อย',
            zipcode: '74130'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '815',
            value: '7364',
            label: 'ท่าไม้',
            zipcode: '74110'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '815',
            value: '7365',
            label: 'สวนหลวง',
            zipcode: '74110'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '815',
            value: '7366',
            label: 'บางยาง',
            zipcode: '74110'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '815',
            value: '7367',
            label: 'คลองมะเดื่อ',
            zipcode: '74110'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '815',
            value: '7368',
            label: 'หนองนกไข่',
            zipcode: '74110'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '815',
            value: '7369',
            label: 'ดอนไก่ดี',
            zipcode: '74110'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '815',
            value: '7370',
            label: 'แคราย',
            zipcode: '74110'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '815',
            value: '7371',
            label: 'ท่าเสา',
            zipcode: '74110'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '816',
            value: '7372',
            label: 'บ้านแพ้ว',
            zipcode: '74120'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '816',
            value: '7373',
            label: 'หลักสาม',
            zipcode: '74120'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '816',
            value: '7374',
            label: 'ยกกระบัตร',
            zipcode: '74120'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '816',
            value: '7375',
            label: 'โรงเข้',
            zipcode: '74120'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '816',
            value: '7376',
            label: 'หนองสองห้อง',
            zipcode: '74120'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '816',
            value: '7377',
            label: 'หนองบัว',
            zipcode: '74120'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '816',
            value: '7378',
            label: 'หลักสอง',
            zipcode: '74120'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '816',
            value: '7379',
            label: 'เจ็ดริ้ว',
            zipcode: '74120'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '816',
            value: '7380',
            label: 'คลองตัน',
            zipcode: '74120'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '816',
            value: '7381',
            label: 'อำแพง',
            zipcode: '74120'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '816',
            value: '7382',
            label: 'สวนส้ม',
            zipcode: '74120'
        },
        {
            geoid: '2',
            provinceid: '59',
            districtid: '816',
            value: '7383',
            label: 'เกษตรพัฒนา',
            zipcode: '74120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '817',
            value: '7384',
            label: 'แม่กลอง',
            zipcode: '75000'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '817',
            value: '7385',
            label: 'บางขันแตก',
            zipcode: '75000'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '817',
            value: '7386',
            label: 'ลาดใหญ่',
            zipcode: '75000'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '817',
            value: '7387',
            label: 'บ้านปรก',
            zipcode: '75000'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '817',
            value: '7388',
            label: 'บางแก้ว',
            zipcode: '75000'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '817',
            value: '7389',
            label: 'ท้ายหาด',
            zipcode: '75000'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '817',
            value: '7390',
            label: 'แหลมใหญ่',
            zipcode: '75000'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '817',
            value: '7391',
            label: 'คลองเขิน',
            zipcode: '75000'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '817',
            value: '7392',
            label: 'คลองโคน',
            zipcode: '75000'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '817',
            value: '7393',
            label: 'นางตะเคียน',
            zipcode: '75000'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '817',
            value: '7394',
            label: 'บางจะเกร็ง',
            zipcode: '75000'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7395',
            label: 'กระดังงา',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7396',
            label: 'บางสะแก',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7397',
            label: 'บางยี่รงค์',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7398',
            label: 'โรงหีบ',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7399',
            label: 'บางคนที',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7400',
            label: 'ดอนมะโนรา',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7401',
            label: 'บางพรม',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7402',
            label: 'บางกุ้ง',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7403',
            label: 'จอมปลวก',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7404',
            label: 'บางนกแขวก',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7405',
            label: 'ยายแพง',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7406',
            label: 'บางกระบือ',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '818',
            value: '7407',
            label: 'บ้านปราโมทย์',
            zipcode: '75120'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '819',
            value: '7408',
            label: 'อัมพวา',
            zipcode: '75110'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '819',
            value: '7409',
            label: 'สวนหลวง',
            zipcode: '75110'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '819',
            value: '7410',
            label: 'ท่าคา',
            zipcode: '75110'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '819',
            value: '7411',
            label: 'วัดประดู่',
            zipcode: '75110'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '819',
            value: '7412',
            label: 'เหมืองใหม่',
            zipcode: '75110'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '819',
            value: '7413',
            label: 'บางช้าง',
            zipcode: '75110'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '819',
            value: '7414',
            label: 'แควอ้อม',
            zipcode: '75110'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '819',
            value: '7415',
            label: 'ปลายโพงพาง',
            zipcode: '75110'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '819',
            value: '7416',
            label: 'บางแค',
            zipcode: '75110'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '819',
            value: '7417',
            label: 'แพรกหนามแดง',
            zipcode: '75110'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '819',
            value: '7418',
            label: 'ยี่สาร',
            zipcode: '75110'
        },
        {
            geoid: '2',
            provinceid: '60',
            districtid: '819',
            value: '7419',
            label: 'บางนางลี่',
            zipcode: '75110'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7420',
            label: 'ท่าราบ',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7421',
            label: 'คลองกระแชง',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7422',
            label: 'บางจาน',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7423',
            label: 'นาพันสาม',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7424',
            label: 'ธงชัย',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7425',
            label: 'บ้านกุ่ม',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7426',
            label: 'หนองโสน',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7427',
            label: 'ไร่ส้ม',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7428',
            label: 'เวียงคอย',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7429',
            label: 'บางจาก',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7430',
            label: 'บ้านหม้อ',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7431',
            label: 'ต้นมะม่วง',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7432',
            label: 'ช่องสะแก',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7433',
            label: 'นาวุ้ง',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7434',
            label: 'สำมะโรง',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7435',
            label: 'โพพระ',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7436',
            label: 'หาดเจ้าสำราญ',
            zipcode: '76100'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7437',
            label: 'หัวสะพาน',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7438',
            label: 'ต้นมะพร้าว',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7439',
            label: 'วังตะโก',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7440',
            label: 'โพไร่หวาน',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7441',
            label: 'ดอนยาง',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7442',
            label: 'หนองขนาน',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7443',
            label: 'หนองพลับ',
            zipcode: '76000'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7445',
            label: 'เขาย้อย',
            zipcode: '76140'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7446',
            label: 'สระพัง',
            zipcode: '76140'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7447',
            label: 'บางเค็ม',
            zipcode: '76140'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7448',
            label: 'ทับคาง',
            zipcode: '76140'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7449',
            label: 'หนองปลาไหล',
            zipcode: '76140'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7450',
            label: 'หนองปรง',
            zipcode: '76140'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7451',
            label: 'หนองชุมพล',
            zipcode: '76140'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7452',
            label: 'ห้วยโรง',
            zipcode: '76140'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7453',
            label: 'ห้วยท่าช้าง',
            zipcode: '76140'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7454',
            label: 'หนองชุมพลเหนือ',
            zipcode: '76140'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '822',
            value: '7458',
            label: 'หนองหญ้าปล้อง',
            zipcode: '76160'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '822',
            value: '7459',
            label: 'ยางน้ำกลัดเหนือ',
            zipcode: '76160'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '822',
            value: '7460',
            label: 'ยางน้ำกลัดใต้',
            zipcode: '76160'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '822',
            value: '7461',
            label: 'ท่าตะคร้อ',
            zipcode: '76160'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '823',
            value: '7462',
            label: 'ชะอำ',
            zipcode: '76120'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '823',
            value: '7463',
            label: 'บางเก่า',
            zipcode: '76120'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '823',
            value: '7464',
            label: 'นายาง',
            zipcode: '76120'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '823',
            value: '7465',
            label: 'เขาใหญ่',
            zipcode: '76120'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '823',
            value: '7466',
            label: 'หนองศาลา',
            zipcode: '76120'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '823',
            value: '7467',
            label: 'ห้วยทรายเหนือ',
            zipcode: '76120'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '823',
            value: '7468',
            label: 'ไร่ใหม่พัฒนา',
            zipcode: '76120'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '823',
            value: '7469',
            label: 'สามพระยา',
            zipcode: '76120'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '823',
            value: '7470',
            label: 'ดอนขุนห้วย',
            zipcode: '76120'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7471',
            label: 'ท่ายาง',
            zipcode: '76130'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7472',
            label: 'ท่าคอย',
            zipcode: '76130'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7473',
            label: 'ยางหย่อง',
            zipcode: '76130'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7474',
            label: 'หนองจอก',
            zipcode: '76130'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7475',
            label: 'มาบปลาเค้า',
            zipcode: '76130'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7476',
            label: 'ท่าไม้รวก',
            zipcode: '76130'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7477',
            label: 'วังไคร้',
            zipcode: '76130'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7481',
            label: 'กลัดหลวง',
            zipcode: '76130'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7482',
            label: 'ปึกเตียน',
            zipcode: '76130'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7483',
            label: 'เขากระปุก',
            zipcode: '76130'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7484',
            label: 'ท่าแลง',
            zipcode: '76130'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7485',
            label: 'บ้านในดง',
            zipcode: '76130'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7492',
            label: 'บ้านลาด',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7493',
            label: 'บ้านหาด',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7494',
            label: 'บ้านทาน',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7495',
            label: 'ตำหรุ',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7496',
            label: 'สมอพลือ',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7497',
            label: 'ไร่มะขาม',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7498',
            label: 'ท่าเสน',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7499',
            label: 'หนองกระเจ็ด',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7500',
            label: 'หนองกะปุ',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7501',
            label: 'ลาดโพธิ์',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7502',
            label: 'สะพานไกร',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7503',
            label: 'ไร่โคก',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7504',
            label: 'โรงเข้',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7505',
            label: 'ไร่สะท้อน',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7506',
            label: 'ห้วยข้อง',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7507',
            label: 'ท่าช้าง',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7508',
            label: 'ถ้ำรงค์',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '825',
            value: '7509',
            label: 'ห้วยลึก',
            zipcode: '76150'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '826',
            value: '7510',
            label: 'บ้านแหลม',
            zipcode: '76110'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '826',
            value: '7511',
            label: 'บางขุนไทร',
            zipcode: '76110'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '826',
            value: '7512',
            label: 'ปากทะเล',
            zipcode: '76110'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '826',
            value: '7513',
            label: 'บางแก้ว',
            zipcode: '76110'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '826',
            value: '7514',
            label: 'แหลมผักเบี้ย',
            zipcode: '76100'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '826',
            value: '7515',
            label: 'บางตะบูน',
            zipcode: '76110'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '826',
            value: '7516',
            label: 'บางตะบูนออก',
            zipcode: '76110'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '826',
            value: '7517',
            label: 'บางครก',
            zipcode: '76110'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '826',
            value: '7518',
            label: 'ท่าแร้ง',
            zipcode: '76110'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '826',
            value: '7519',
            label: 'ท่าแร้งออก',
            zipcode: '76110'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '827',
            value: '7520',
            label: 'แก่งกระจาน',
            zipcode: '76170'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '827',
            value: '7521',
            label: 'สองพี่น้อง',
            zipcode: '76170'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '827',
            value: '7522',
            label: 'วังจันทร์',
            zipcode: '76170'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '827',
            value: '7523',
            label: 'ป่าเด็ง',
            zipcode: '76170'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '827',
            value: '7524',
            label: 'พุสวรรค์',
            zipcode: '76170'
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '827',
            value: '7525',
            label: 'ห้วยแม่เพรียง',
            zipcode: '76170'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '828',
            value: '7526',
            label: 'ประจวบคีรีขันธ์',
            zipcode: '77000'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '828',
            value: '7527',
            label: 'เกาะหลัก',
            zipcode: '77000'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '828',
            value: '7528',
            label: 'คลองวาฬ',
            zipcode: '77000'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '828',
            value: '7529',
            label: 'ห้วยทราย',
            zipcode: '77000'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '828',
            value: '7530',
            label: 'อ่าวน้อย',
            zipcode: '77000'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '828',
            value: '7531',
            label: 'บ่อนอก',
            zipcode: '77210'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '829',
            value: '7532',
            label: 'กุยบุรี',
            zipcode: '77150'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '829',
            value: '7533',
            label: 'กุยเหนือ',
            zipcode: '77150'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '829',
            value: '7534',
            label: 'เขาแดง',
            zipcode: '77150'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '829',
            value: '7535',
            label: 'ดอนยายหนู',
            zipcode: '77150'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '829',
            value: '7537',
            label: 'สามกระทาย',
            zipcode: '77150'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '829',
            value: '7538',
            label: 'หาดขาม',
            zipcode: '77150'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '830',
            value: '7539',
            label: 'ทับสะแก',
            zipcode: '77130'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '830',
            value: '7540',
            label: 'อ่างทอง',
            zipcode: '77130'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '830',
            value: '7541',
            label: 'นาหูกวาง',
            zipcode: '77130'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '830',
            value: '7542',
            label: 'เขาล้าน',
            zipcode: '77130'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '830',
            value: '7543',
            label: 'ห้วยยาง',
            zipcode: '77130'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '830',
            value: '7544',
            label: 'แสงอรุณ',
            zipcode: '77130'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '831',
            value: '7545',
            label: 'กำเนิดนพคุณ',
            zipcode: '77140'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '831',
            value: '7546',
            label: 'พงศ์ประศาสน์',
            zipcode: '77140'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '831',
            value: '7547',
            label: 'ร่อนทอง',
            zipcode: '77230'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '831',
            value: '7548',
            label: 'ธงชัย',
            zipcode: '77190'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '831',
            value: '7549',
            label: 'ชัยเกษม',
            zipcode: '77190'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '831',
            value: '7550',
            label: 'ทองมงคล',
            zipcode: '77230'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '831',
            value: '7551',
            label: 'แม่รำพึง',
            zipcode: '77140'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '832',
            value: '7552',
            label: 'ปากแพรก',
            zipcode: '77170'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '832',
            value: '7553',
            label: 'บางสะพาน',
            zipcode: '77170'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '832',
            value: '7554',
            label: 'ทรายทอง',
            zipcode: '77170'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '832',
            value: '7555',
            label: 'ช้างแรก',
            zipcode: '77170'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '832',
            value: '7556',
            label: 'ไชยราช',
            zipcode: '77170'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '833',
            value: '7557',
            label: 'ปราณบุรี',
            zipcode: '77120'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '833',
            value: '7558',
            label: 'เขาน้อย',
            zipcode: '77120'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '833',
            value: '7560',
            label: 'ปากน้ำปราณ',
            zipcode: '77220'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '833',
            value: '7563',
            label: 'หนองตาแต้ม',
            zipcode: '77120'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '833',
            value: '7564',
            label: 'วังก์พง',
            zipcode: '77120'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '833',
            value: '7565',
            label: 'เขาจ้าว',
            zipcode: '77120'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '834',
            value: '7566',
            label: 'หัวหิน',
            zipcode: '77110'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '834',
            value: '7567',
            label: 'หนองแก',
            zipcode: '77110'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '834',
            value: '7568',
            label: 'หินเหล็กไฟ',
            zipcode: '77110'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '834',
            value: '7569',
            label: 'หนองพลับ',
            zipcode: '77110'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '834',
            value: '7570',
            label: 'ทับใต้',
            zipcode: '77110'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '834',
            value: '7571',
            label: 'ห้วยสัตว์ใหญ่',
            zipcode: '77110'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '834',
            value: '7572',
            label: 'บึงนคร',
            zipcode: '77110'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '835',
            value: '7573',
            label: 'สามร้อยยอด',
            zipcode: '77120'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '835',
            value: '7574',
            label: 'ศิลาลอย',
            zipcode: '77180'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '835',
            value: '7575',
            label: 'ไร่เก่า',
            zipcode: '77180'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '835',
            value: '7576',
            label: 'ศาลาลัย',
            zipcode: '77180'
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '835',
            value: '7577',
            label: 'ไร่ใหม่',
            zipcode: '77180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7578',
            label: 'ในเมือง',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7579',
            label: 'ท่าวัง',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7580',
            label: 'คลัง',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7583',
            label: 'ท่าไร่',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7584',
            label: 'ปากนคร',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7585',
            label: 'นาทราย',
            zipcode: '80280'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7589',
            label: 'กำแพงเซา',
            zipcode: '80280'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7590',
            label: 'ไชยมนตรี',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7591',
            label: 'มะม่วงสองต้น',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7592',
            label: 'นาเคียน',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7593',
            label: 'ท่างิ้ว',
            zipcode: '80280'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7595',
            label: 'โพธิ์เสด็จ',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7596',
            label: 'บางจาก',
            zipcode: '80330'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7597',
            label: 'ปากพูน',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7598',
            label: 'ท่าซัก',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7599',
            label: 'ท่าเรือ',
            zipcode: '80290'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '837',
            value: '7605',
            label: 'พรหมโลก',
            zipcode: '80320'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '837',
            value: '7606',
            label: 'บ้านเกาะ',
            zipcode: '80320'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '837',
            value: '7607',
            label: 'อินคีรี',
            zipcode: '80320'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '837',
            value: '7608',
            label: 'ทอนหงส์',
            zipcode: '80320'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '837',
            value: '7609',
            label: 'นาเรียง',
            zipcode: '80320'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '838',
            value: '7610',
            label: 'เขาแก้ว',
            zipcode: '80230'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '838',
            value: '7611',
            label: 'ลานสกา',
            zipcode: '80230'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '838',
            value: '7612',
            label: 'ท่าดี',
            zipcode: '80230'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '838',
            value: '7613',
            label: 'กำโลน',
            zipcode: '80230'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '838',
            value: '7614',
            label: 'ขุนทะเล',
            zipcode: '80230'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7615',
            label: 'ฉวาง',
            zipcode: '80150'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7617',
            label: 'ละอาย',
            zipcode: '80250'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7618',
            label: 'นาแว',
            zipcode: '80260'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7619',
            label: 'ไม้เรียง',
            zipcode: '80150'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7620',
            label: 'กะเปียด',
            zipcode: '80260'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7621',
            label: 'นากะชะ',
            zipcode: '80150'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7623',
            label: 'ห้วยปริก',
            zipcode: '80260'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7624',
            label: 'ไสหร้า',
            zipcode: '80150'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7629',
            label: 'นาเขลียง',
            zipcode: '80260'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7630',
            label: 'จันดี',
            zipcode: '80250'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '840',
            value: '7631',
            label: 'พิปูน',
            zipcode: '80270'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '840',
            value: '7632',
            label: 'กะทูน',
            zipcode: '80270'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '840',
            value: '7633',
            label: 'เขาพระ',
            zipcode: '80270'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '840',
            value: '7634',
            label: 'ยางค้อม',
            zipcode: '80270'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '840',
            value: '7635',
            label: 'ควนกลาง',
            zipcode: '80270'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7636',
            label: 'เชียรใหญ่',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7638',
            label: 'ท่าขนาน',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7639',
            label: 'บ้านกลาง',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7640',
            label: 'บ้านเนิน',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7641',
            label: 'ไสหมาก',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7642',
            label: 'ท้องลำเจียก',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7645',
            label: 'เสือหึง',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7646',
            label: 'การะเกด',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7647',
            label: 'เขาพระบาท',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7648',
            label: 'แม่เจ้าอยู่หัว',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7649',
            label: 'ชะอวด',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7650',
            label: 'ท่าเสม็ด',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7651',
            label: 'ท่าประจะ',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7652',
            label: 'เคร็ง',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7653',
            label: 'วังอ่าง',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7654',
            label: 'บ้านตูล',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7655',
            label: 'ขอนหาด',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7656',
            label: 'เกาะขันธ์',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7657',
            label: 'ควนหนองหงษ์',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7658',
            label: 'เขาพระทอง',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7659',
            label: 'นางหลง',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7662',
            label: 'ท่าศาลา',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7663',
            label: 'กลาย',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7664',
            label: 'ท่าขึ้น',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7665',
            label: 'หัวตะพาน',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7667',
            label: 'สระแก้ว',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7668',
            label: 'โมคลาน',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7670',
            label: 'ไทยบุรี',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7671',
            label: 'ดอนตะโก',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7672',
            label: 'ตลิ่งชัน',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7674',
            label: 'โพธิ์ทอง',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7676',
            label: 'ปากแพรก',
            zipcode: '80110'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7677',
            label: 'ชะมาย',
            zipcode: '80110'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7678',
            label: 'หนองหงส์',
            zipcode: '80110'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7679',
            label: 'ควนกรด',
            zipcode: '80110'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7680',
            label: 'นาไม้ไผ่',
            zipcode: '80110'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7681',
            label: 'นาหลวงเสน',
            zipcode: '80110'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7682',
            label: 'เขาโร',
            zipcode: '80110'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7683',
            label: 'กะปาง',
            zipcode: '80310'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7684',
            label: 'ที่วัง',
            zipcode: '80110'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7685',
            label: 'น้ำตก',
            zipcode: '80110'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7686',
            label: 'ถ้ำใหญ่',
            zipcode: '80110'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7687',
            label: 'นาโพธิ์',
            zipcode: '80110'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7688',
            label: 'เขาขาว',
            zipcode: '80110'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '845',
            value: '7695',
            label: 'นาบอน',
            zipcode: '80220'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '845',
            value: '7696',
            label: 'ทุ่งสง',
            zipcode: '80220'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '845',
            value: '7697',
            label: 'แก้วแสน',
            zipcode: '80220'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '846',
            value: '7698',
            label: 'ท่ายาง',
            zipcode: '80240'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '846',
            value: '7699',
            label: 'ทุ่งสัง',
            zipcode: '80240'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '846',
            value: '7700',
            label: 'ทุ่งใหญ่',
            zipcode: '80240'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '846',
            value: '7701',
            label: 'กุแหระ',
            zipcode: '80240'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '846',
            value: '7702',
            label: 'ปริก',
            zipcode: '80240'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '846',
            value: '7703',
            label: 'บางรูป',
            zipcode: '80240'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '846',
            value: '7704',
            label: 'กรุงหยัน',
            zipcode: '80240'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7705',
            label: 'ปากพนัง',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7706',
            label: 'คลองน้อย',
            zipcode: '80330'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7707',
            label: 'ป่าระกำ',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7708',
            label: 'ชะเมา',
            zipcode: '80330'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7709',
            label: 'คลองกระบือ',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7710',
            label: 'เกาะทวด',
            zipcode: '80330'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7711',
            label: 'บ้านใหม่',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7712',
            label: 'หูล่อง',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7713',
            label: 'แหลมตะลุมพุก',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7714',
            label: 'ปากพนังฝั่งตะวันตก',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7715',
            label: 'บางศาลา',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7716',
            label: 'บางพระ',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7717',
            label: 'บางตะพง',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7718',
            label: 'ปากพนังฝั่งตะวันออก',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7719',
            label: 'บ้านเพิง',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7720',
            label: 'ท่าพยา',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7721',
            label: 'ปากแพรก',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '847',
            value: '7722',
            label: 'ขนาบนาก',
            zipcode: '80140'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '848',
            value: '7723',
            label: 'ร่อนพิบูลย์',
            zipcode: '80130'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '848',
            value: '7724',
            label: 'หินตก',
            zipcode: '80350'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '848',
            value: '7725',
            label: 'เสาธง',
            zipcode: '80350'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '848',
            value: '7726',
            label: 'ควนเกย',
            zipcode: '80130'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '848',
            value: '7727',
            label: 'ควนพัง',
            zipcode: '80130'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '848',
            value: '7728',
            label: 'ควนชุม',
            zipcode: '80130'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '849',
            value: '7734',
            label: 'สิชล',
            zipcode: '80120'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '849',
            value: '7735',
            label: 'ทุ่งปรัง',
            zipcode: '80120'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '849',
            value: '7736',
            label: 'ฉลอง',
            zipcode: '80120'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '849',
            value: '7737',
            label: 'เสาเภา',
            zipcode: '80340'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '849',
            value: '7738',
            label: 'เปลี่ยน',
            zipcode: '80120'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '849',
            value: '7739',
            label: 'สี่ขีด',
            zipcode: '80120'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '849',
            value: '7740',
            label: 'เทพราช',
            zipcode: '80340'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '849',
            value: '7741',
            label: 'เขาน้อย',
            zipcode: '80120'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '849',
            value: '7742',
            label: 'ทุ่งใส',
            zipcode: '80120'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '850',
            value: '7743',
            label: 'ขนอม',
            zipcode: '80210'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '850',
            value: '7744',
            label: 'ควนทอง',
            zipcode: '80210'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '850',
            value: '7745',
            label: 'ท้องเนียน',
            zipcode: '80210'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '851',
            value: '7746',
            label: 'หัวไทร',
            zipcode: '80170'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '851',
            value: '7747',
            label: 'หน้าสตน',
            zipcode: '80170'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '851',
            value: '7748',
            label: 'ทรายขาว',
            zipcode: '80170'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '851',
            value: '7749',
            label: 'แหลม',
            zipcode: '80170'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '851',
            value: '7750',
            label: 'เขาพังไกร',
            zipcode: '80170'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '851',
            value: '7751',
            label: 'บ้านราม',
            zipcode: '80170'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '851',
            value: '7752',
            label: 'บางนบ',
            zipcode: '80170'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '851',
            value: '7753',
            label: 'ท่าซอม',
            zipcode: '80170'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '851',
            value: '7754',
            label: 'ควนชะลิก',
            zipcode: '80170'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '851',
            value: '7755',
            label: 'รามแก้ว',
            zipcode: '80170'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '851',
            value: '7756',
            label: 'เกาะเพชร',
            zipcode: '80170'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '852',
            value: '7757',
            label: 'บางขัน',
            zipcode: '80360'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '852',
            value: '7758',
            label: 'บ้านลำนาว',
            zipcode: '80360'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '852',
            value: '7759',
            label: 'วังหิน',
            zipcode: '80360'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '852',
            value: '7760',
            label: 'บ้านนิคม',
            zipcode: '80360'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '853',
            value: '7761',
            label: 'ถ้ำพรรณรา',
            zipcode: '80260'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '853',
            value: '7762',
            label: 'คลองเส',
            zipcode: '80260'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '853',
            value: '7763',
            label: 'ดุสิต',
            zipcode: '80260'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '854',
            value: '7764',
            label: 'บ้านควนมุด',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '854',
            value: '7765',
            label: 'บ้านชะอวด',
            zipcode: '80180'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '854',
            value: '7766',
            label: 'ควนหนองคว้า',
            zipcode: '80130'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '854',
            value: '7767',
            label: 'ทุ่งโพธิ์',
            zipcode: '80130'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '854',
            value: '7768',
            label: 'นาหมอบุญ',
            zipcode: '80130'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '854',
            value: '7769',
            label: 'สามตำบล',
            zipcode: '80130'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '855',
            value: '7770',
            label: 'นาพรุ',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '855',
            value: '7771',
            label: 'นาสาร',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '855',
            value: '7772',
            label: 'ท้ายสำเภา',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '855',
            value: '7773',
            label: 'ช้างซ้าย',
            zipcode: '80000'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '856',
            value: '7774',
            label: 'นบพิตำ',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '856',
            value: '7775',
            label: 'กรุงชิง',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '856',
            value: '7776',
            label: 'กะหรอ',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '856',
            value: '7777',
            label: 'นาเหรง',
            zipcode: '80160'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '857',
            value: '7778',
            label: 'ช้างกลาง',
            zipcode: '80250'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '857',
            value: '7779',
            label: 'หลักช้าง',
            zipcode: '80250'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '857',
            value: '7780',
            label: 'สวนขัน',
            zipcode: '80250'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '858',
            value: '7781',
            label: 'เชียรเขา',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '858',
            value: '7782',
            label: 'ดอนตรอ',
            zipcode: '80290'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '858',
            value: '7783',
            label: 'สวนหลวง',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '858',
            value: '7784',
            label: 'ทางพูน',
            zipcode: '80190'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7785',
            label: 'ปากน้ำ',
            zipcode: '81000'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7786',
            label: 'กระบี่ใหญ่',
            zipcode: '81000'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7787',
            label: 'กระบี่น้อย',
            zipcode: '81000'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7789',
            label: 'เขาคราม',
            zipcode: '81000'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7790',
            label: 'เขาทอง',
            zipcode: '81000'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7795',
            label: 'ทับปริก',
            zipcode: '81000'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7799',
            label: 'ไสไทย',
            zipcode: '81000'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7800',
            label: 'อ่าวนาง',
            zipcode: '81000'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7801',
            label: 'หนองทะเล',
            zipcode: '81000'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7802',
            label: 'คลองประสงค์',
            zipcode: '81000'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '865',
            value: '7811',
            label: 'เขาพนม',
            zipcode: '81140'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '865',
            value: '7812',
            label: 'เขาดิน',
            zipcode: '81140'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '865',
            value: '7813',
            label: 'สินปุน',
            zipcode: '80240'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '865',
            value: '7814',
            label: 'พรุเตียว',
            zipcode: '81140'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '865',
            value: '7815',
            label: 'หน้าเขา',
            zipcode: '81140'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '865',
            value: '7816',
            label: 'โคกหาร',
            zipcode: '80240'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '866',
            value: '7817',
            label: 'เกาะลันตาใหญ่',
            zipcode: '81150'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '866',
            value: '7818',
            label: 'เกาะลันตาน้อย',
            zipcode: '81150'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '866',
            value: '7819',
            label: 'เกาะกลาง',
            zipcode: '81120'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '866',
            value: '7820',
            label: 'คลองยาง',
            zipcode: '81120'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '866',
            value: '7821',
            label: 'ศาลาด่าน',
            zipcode: '81150'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '867',
            value: '7822',
            label: 'คลองท่อมใต้',
            zipcode: '81120'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '867',
            value: '7823',
            label: 'คลองท่อมเหนือ',
            zipcode: '81120'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '867',
            value: '7824',
            label: 'คลองพน',
            zipcode: '81170'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '867',
            value: '7825',
            label: 'ทรายขาว',
            zipcode: '81170'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '867',
            value: '7826',
            label: 'ห้วยน้ำขาว',
            zipcode: '81120'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '867',
            value: '7827',
            label: 'พรุดินนา',
            zipcode: '81120'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '867',
            value: '7828',
            label: 'เพหลา',
            zipcode: '81120'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '868',
            value: '7830',
            label: 'อ่าวลึกใต้',
            zipcode: '81110'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '868',
            value: '7831',
            label: 'แหลมสัก',
            zipcode: '81110'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '868',
            value: '7832',
            label: 'นาเหนือ',
            zipcode: '81110'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '868',
            value: '7833',
            label: 'คลองหิน',
            zipcode: '81110'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '868',
            value: '7834',
            label: 'อ่าวลึกน้อย',
            zipcode: '81110'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '868',
            value: '7835',
            label: 'อ่าวลึกเหนือ',
            zipcode: '81110'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '868',
            value: '7836',
            label: 'เขาใหญ่',
            zipcode: '81110'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '868',
            value: '7837',
            label: 'คลองยา',
            zipcode: '81110'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '868',
            value: '7838',
            label: 'บ้านกลาง',
            zipcode: '81110'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '869',
            value: '7842',
            label: 'ปลายพระยา',
            zipcode: '81160'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '869',
            value: '7843',
            label: 'เขาเขน',
            zipcode: '81160'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '869',
            value: '7844',
            label: 'เขาต่อ',
            zipcode: '81160'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '869',
            value: '7845',
            label: 'คีรีวง',
            zipcode: '81160'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '870',
            value: '7846',
            label: 'ลำทับ',
            zipcode: '81120'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '870',
            value: '7847',
            label: 'ดินอุดม',
            zipcode: '81120'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '870',
            value: '7848',
            label: 'ทุ่งไทรทอง',
            zipcode: '81120'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '870',
            value: '7849',
            label: 'ดินแดง',
            zipcode: '81120'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '871',
            value: '7850',
            label: 'เหนือคลอง',
            zipcode: '81130'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '871',
            value: '7851',
            label: 'เกาะศรีบอยา',
            zipcode: '81130'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '871',
            value: '7852',
            label: 'คลองขนาน',
            zipcode: '81130'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '871',
            value: '7853',
            label: 'คลองเขม้า',
            zipcode: '81130'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '871',
            value: '7854',
            label: 'โคกยาง',
            zipcode: '81130'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '871',
            value: '7855',
            label: 'ตลิ่งชัน',
            zipcode: '81130'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '871',
            value: '7856',
            label: 'ปกาสัย',
            zipcode: '81130'
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '871',
            value: '7857',
            label: 'ห้วยยูง',
            zipcode: '81130'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '872',
            value: '7858',
            label: 'ท้ายช้าง',
            zipcode: '82000'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '872',
            value: '7859',
            label: 'นบปริง',
            zipcode: '82000'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '872',
            value: '7860',
            label: 'ถ้ำน้ำผุด',
            zipcode: '82000'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '872',
            value: '7861',
            label: 'บางเตย',
            zipcode: '82000'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '872',
            value: '7862',
            label: 'ตากแดด',
            zipcode: '82000'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '872',
            value: '7863',
            label: 'สองแพรก',
            zipcode: '82000'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '872',
            value: '7864',
            label: 'ทุ่งคาโงก',
            zipcode: '82000'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '872',
            value: '7865',
            label: 'เกาะปันหยี',
            zipcode: '82000'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '872',
            value: '7866',
            label: 'ป่ากอ',
            zipcode: '82000'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '873',
            value: '7869',
            label: 'เกาะยาวน้อย',
            zipcode: '82160'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '873',
            value: '7870',
            label: 'เกาะยาวใหญ่',
            zipcode: '82160'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '873',
            value: '7871',
            label: 'พรุใน',
            zipcode: '83000'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '874',
            value: '7872',
            label: 'กะปง',
            zipcode: '82170'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '874',
            value: '7873',
            label: 'ท่านา',
            zipcode: '82170'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '874',
            value: '7874',
            label: 'เหมาะ',
            zipcode: '82170'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '874',
            value: '7875',
            label: 'เหล',
            zipcode: '82170'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '874',
            value: '7876',
            label: 'รมณีย์',
            zipcode: '82170'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '875',
            value: '7877',
            label: 'ถ้ำ',
            zipcode: '82130'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '875',
            value: '7878',
            label: 'กระโสม',
            zipcode: '82130'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '875',
            value: '7879',
            label: 'กะไหล',
            zipcode: '82130'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '875',
            value: '7880',
            label: 'ท่าอยู่',
            zipcode: '82130'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '875',
            value: '7881',
            label: 'หล่อยูง',
            zipcode: '82140'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '875',
            value: '7882',
            label: 'โคกกลอย',
            zipcode: '82140'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '875',
            value: '7883',
            label: 'คลองเคียน',
            zipcode: '82130'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '876',
            value: '7884',
            label: 'ตะกั่วป่า',
            zipcode: '82110'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '876',
            value: '7885',
            label: 'บางนายสี',
            zipcode: '82110'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '876',
            value: '7886',
            label: 'บางไทร',
            zipcode: '82110'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '876',
            value: '7887',
            label: 'บางม่วง',
            zipcode: '82110'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '876',
            value: '7888',
            label: 'ตำตัว',
            zipcode: '82110'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '876',
            value: '7889',
            label: 'โคกเคียน',
            zipcode: '82110'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '876',
            value: '7890',
            label: 'คึกคัก',
            zipcode: '82190'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '876',
            value: '7891',
            label: 'เกาะคอเขา',
            zipcode: '82190'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '877',
            value: '7892',
            label: 'คุระ',
            zipcode: '82150'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '877',
            value: '7893',
            label: 'บางวัน',
            zipcode: '82150'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '877',
            value: '7894',
            label: 'เกาะพระทอง',
            zipcode: '82150'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '877',
            value: '7896',
            label: 'แม่นางขาว',
            zipcode: '82150'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '878',
            value: '7897',
            label: 'ทับปุด',
            zipcode: '82180'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '878',
            value: '7898',
            label: 'มะรุ่ย',
            zipcode: '82180'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '878',
            value: '7899',
            label: 'บ่อแสน',
            zipcode: '82180'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '878',
            value: '7900',
            label: 'ถ้ำทองหลาง',
            zipcode: '82180'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '878',
            value: '7901',
            label: 'โคกเจริญ',
            zipcode: '82180'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '878',
            value: '7902',
            label: 'บางเหรียง',
            zipcode: '82180'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '879',
            value: '7903',
            label: 'ท้ายเหมือง',
            zipcode: '82120'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '879',
            value: '7904',
            label: 'นาเตย',
            zipcode: '82120'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '879',
            value: '7905',
            label: 'บางทอง',
            zipcode: '82120'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '879',
            value: '7906',
            label: 'ทุ่งมะพร้าว',
            zipcode: '82120'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '879',
            value: '7907',
            label: 'ลำภี',
            zipcode: '82120'
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '879',
            value: '7908',
            label: 'ลำแก่น',
            zipcode: '82120'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '880',
            value: '7909',
            label: 'ตลาดใหญ่',
            zipcode: '83000'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '880',
            value: '7910',
            label: 'ตลาดเหนือ',
            zipcode: '83000'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '880',
            value: '7911',
            label: 'เกาะแก้ว',
            zipcode: '83000'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '880',
            value: '7912',
            label: 'รัษฎา',
            zipcode: '83000'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '880',
            value: '7913',
            label: 'วิชิต',
            zipcode: '83000'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '880',
            value: '7914',
            label: 'ฉลอง',
            zipcode: '83130'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '880',
            value: '7915',
            label: 'ราไวย์',
            zipcode: '83130'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '880',
            value: '7916',
            label: 'กะรน',
            zipcode: '83100'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '881',
            value: '7917',
            label: 'กะทู้',
            zipcode: '83120'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '881',
            value: '7918',
            label: 'ป่าตอง',
            zipcode: '83150'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '881',
            value: '7919',
            label: 'กมลา',
            zipcode: '83150'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '882',
            value: '7920',
            label: 'เทพกระษัตรี',
            zipcode: '83110'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '882',
            value: '7921',
            label: 'ศรีสุนทร',
            zipcode: '83110'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '882',
            value: '7922',
            label: 'เชิงทะเล',
            zipcode: '83110'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '882',
            value: '7923',
            label: 'ป่าคลอก',
            zipcode: '83110'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '882',
            value: '7924',
            label: 'ไม้ขาว',
            zipcode: '83110'
        },
        {
            geoid: '6',
            provinceid: '66',
            districtid: '882',
            value: '7925',
            label: 'สาคู',
            zipcode: '83110'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '884',
            value: '7926',
            label: 'ตลาด',
            zipcode: '84000'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '884',
            value: '7927',
            label: 'มะขามเตี้ย',
            zipcode: '84000'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '884',
            value: '7928',
            label: 'วัดประดู่',
            zipcode: '84000'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '884',
            value: '7929',
            label: 'ขุนทะเล',
            zipcode: '84100'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '884',
            value: '7930',
            label: 'บางใบไม้',
            zipcode: '84000'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '884',
            value: '7931',
            label: 'บางชนะ',
            zipcode: '84000'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '884',
            value: '7932',
            label: 'คลองน้อย',
            zipcode: '84000'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '884',
            value: '7933',
            label: 'บางไทร',
            zipcode: '84000'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '884',
            value: '7934',
            label: 'บางโพธิ์',
            zipcode: '84000'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '884',
            value: '7935',
            label: 'บางกุ้ง',
            zipcode: '84000'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '884',
            value: '7936',
            label: 'คลองฉนาก',
            zipcode: '84000'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7937',
            label: 'ท่าทองใหม่',
            zipcode: '84290'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7938',
            label: 'ท่าทอง',
            zipcode: '84160'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7939',
            label: 'กะแดะ',
            zipcode: '84160'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7940',
            label: 'ทุ่งกง',
            zipcode: '84290'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7941',
            label: 'กรูด',
            zipcode: '84160'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7942',
            label: 'ช้างซ้าย',
            zipcode: '84160'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7943',
            label: 'พลายวาส',
            zipcode: '84160'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7944',
            label: 'ป่าร่อน',
            zipcode: '84160'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7945',
            label: 'ตะเคียนทอง',
            zipcode: '84160'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7946',
            label: 'ช้างขวา',
            zipcode: '84160'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7947',
            label: 'ท่าอุแท',
            zipcode: '84160'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7948',
            label: 'ทุ่งรัง',
            zipcode: '84290'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '885',
            value: '7949',
            label: 'คลองสระ',
            zipcode: '84160'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '886',
            value: '7950',
            label: 'ดอนสัก',
            zipcode: '84220'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '886',
            value: '7951',
            label: 'ชลคราม',
            zipcode: '84160'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '886',
            value: '7952',
            label: 'ไชยคราม',
            zipcode: '84220'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '886',
            value: '7953',
            label: 'ปากแพรก',
            zipcode: '84340'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '887',
            value: '7954',
            label: 'อ่างทอง',
            zipcode: '84140'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '887',
            value: '7955',
            label: 'ลิปะน้อย',
            zipcode: '84140'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '887',
            value: '7956',
            label: 'ตลิ่งงาม',
            zipcode: '84140'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '887',
            value: '7957',
            label: 'หน้าเมือง',
            zipcode: '84140'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '887',
            value: '7958',
            label: 'มะเร็ต',
            zipcode: '84310'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '887',
            value: '7959',
            label: 'บ่อผุด',
            zipcode: '84320'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '887',
            value: '7960',
            label: 'แม่น้ำ',
            zipcode: '84330'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '888',
            value: '7961',
            label: 'เกาะพะงัน',
            zipcode: '84280'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '888',
            value: '7962',
            label: 'บ้านใต้',
            zipcode: '84280'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '888',
            value: '7963',
            label: 'เกาะเต่า',
            zipcode: '84280'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '889',
            value: '7964',
            label: 'ตลาดไชยา',
            zipcode: '84110'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '889',
            value: '7965',
            label: 'พุมเรียง',
            zipcode: '84110'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '889',
            value: '7966',
            label: 'เลม็ด',
            zipcode: '84110'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '889',
            value: '7967',
            label: 'เวียง',
            zipcode: '84110'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '889',
            value: '7968',
            label: 'ทุ่ง',
            zipcode: '84110'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '889',
            value: '7969',
            label: 'ป่าเว',
            zipcode: '84110'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '889',
            value: '7970',
            label: 'ตะกรบ',
            zipcode: '84110'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '889',
            value: '7971',
            label: 'โมถ่าย',
            zipcode: '84110'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '889',
            value: '7972',
            label: 'ปากหมาก',
            zipcode: '84110'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '890',
            value: '7973',
            label: 'ท่าชนะ',
            zipcode: '84170'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '890',
            value: '7974',
            label: 'สมอทอง',
            zipcode: '84170'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '890',
            value: '7975',
            label: 'ประสงค์',
            zipcode: '84170'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '890',
            value: '7976',
            label: 'คันธุลี',
            zipcode: '84170'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '890',
            value: '7977',
            label: 'วัง',
            zipcode: '84170'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '890',
            value: '7978',
            label: 'คลองพา',
            zipcode: '84170'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '891',
            value: '7979',
            label: 'ท่าขนอน',
            zipcode: '84180'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '891',
            value: '7980',
            label: 'บ้านยาง',
            zipcode: '84180'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '891',
            value: '7981',
            label: 'น้ำหัก',
            zipcode: '84180'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '891',
            value: '7984',
            label: 'กะเปา',
            zipcode: '84180'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '891',
            value: '7985',
            label: 'ท่ากระดาน',
            zipcode: '84180'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '891',
            value: '7986',
            label: 'ย่านยาว',
            zipcode: '84180'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '891',
            value: '7987',
            label: 'ถ้ำสิงขร',
            zipcode: '84180'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '891',
            value: '7988',
            label: 'บ้านทำเนียบ',
            zipcode: '84180'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '892',
            value: '7990',
            label: 'เขาวง',
            zipcode: '84230'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '892',
            value: '7991',
            label: 'พระแสง',
            zipcode: '84230'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '892',
            value: '7992',
            label: 'พรุไทย',
            zipcode: '84230'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '892',
            value: '7993',
            label: 'เขาพัง',
            zipcode: '84230'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '893',
            value: '7995',
            label: 'พนม',
            zipcode: '84250'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '893',
            value: '7996',
            label: 'ต้นยวน',
            zipcode: '84250'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '893',
            value: '7997',
            label: 'คลองศก',
            zipcode: '84250'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '893',
            value: '7998',
            label: 'พลูเถื่อน',
            zipcode: '84250'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '893',
            value: '7999',
            label: 'พังกาญจน์',
            zipcode: '84250'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '893',
            value: '8000',
            label: 'คลองชะอุ่น',
            zipcode: '84250'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '894',
            value: '8001',
            label: 'ท่าฉาง',
            zipcode: '84150'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '894',
            value: '8002',
            label: 'ท่าเคย',
            zipcode: '84150'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '894',
            value: '8003',
            label: 'คลองไทร',
            zipcode: '84150'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '894',
            value: '8004',
            label: 'เขาถ่าน',
            zipcode: '84150'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '894',
            value: '8005',
            label: 'เสวียด',
            zipcode: '84150'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '894',
            value: '8006',
            label: 'ปากฉลุย',
            zipcode: '84150'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8007',
            label: 'นาสาร',
            zipcode: '84120'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8008',
            label: 'พรุพี',
            zipcode: '84270'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8009',
            label: 'ทุ่งเตา',
            zipcode: '84120'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8010',
            label: 'ลำพูน',
            zipcode: '84120'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8011',
            label: 'ท่าชี',
            zipcode: '84120'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8012',
            label: 'ควนศรี',
            zipcode: '84270'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8013',
            label: 'ควนสุบรรณ',
            zipcode: '84120'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8014',
            label: 'คลองปราบ',
            zipcode: '84120'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8015',
            label: 'น้ำพุ',
            zipcode: '84120'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8016',
            label: 'ทุ่งเตาใหม่',
            zipcode: '84120'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8017',
            label: 'เพิ่มพูนทรัพย์',
            zipcode: '84120'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '896',
            value: '8020',
            label: 'บ้านนา',
            zipcode: '84240'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '896',
            value: '8021',
            label: 'ท่าเรือ',
            zipcode: '84240'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '896',
            value: '8022',
            label: 'ทรัพย์ทวี',
            zipcode: '84240'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '896',
            value: '8023',
            label: 'นาใต้',
            zipcode: '84240'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '897',
            value: '8024',
            label: 'เคียนซา',
            zipcode: '84260'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '897',
            value: '8025',
            label: 'พ่วงพรมคร',
            zipcode: '84210'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '897',
            value: '8026',
            label: 'เขาตอก',
            zipcode: '84260'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '897',
            value: '8027',
            label: 'อรัญคามวารี',
            zipcode: '84260'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '897',
            value: '8028',
            label: 'บ้านเสด็จ',
            zipcode: '84260'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '898',
            value: '8029',
            label: 'เวียงสระ',
            zipcode: '84190'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '898',
            value: '8030',
            label: 'บ้านส้อง',
            zipcode: '84190'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '898',
            value: '8031',
            label: 'คลองฉนวน',
            zipcode: '84190'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '898',
            value: '8032',
            label: 'ทุ่งหลวง',
            zipcode: '84190'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '898',
            value: '8033',
            label: 'เขานิพันธ์',
            zipcode: '84190'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '899',
            value: '8034',
            label: 'อิปัน',
            zipcode: '84210'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '899',
            value: '8035',
            label: 'สินปุน',
            zipcode: '84210'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '899',
            value: '8036',
            label: 'บางสวรรค์',
            zipcode: '84210'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '899',
            value: '8037',
            label: 'ไทรขึง',
            zipcode: '84210'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '899',
            value: '8038',
            label: 'สินเจริญ',
            zipcode: '84210'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '899',
            value: '8039',
            label: 'ไทรโสภา',
            zipcode: '84210'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '899',
            value: '8040',
            label: 'สาคู',
            zipcode: '84210'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8043',
            label: 'ท่าข้าม',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8044',
            label: 'ท่าสะท้อน',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8045',
            label: 'ลีเล็ด',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8046',
            label: 'บางมะเดื่อ',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8047',
            label: 'บางเดือน',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8048',
            label: 'ท่าโรงช้าง',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8049',
            label: 'กรูด',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8050',
            label: 'พุนพิน',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8051',
            label: 'บางงอน',
            zipcode: '84130'
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4036',
            label: 'ร่องจิก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4037',
            label: 'แสงภา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4038',
            label: 'ปลาบ่า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4039',
            label: 'นาพึ่ง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4040',
            label: 'ท่าศาลา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4041',
            label: 'หนองบัว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '450',
            value: '4042',
            label: 'นาแห้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4066',
            label: 'เอราวัณ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4067',
            label: 'ผาอินทร์แปลง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4068',
            label: 'ผาสามยอด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4073',
            label: 'ทรัพย์ไพวัลย์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4074',
            label: 'หนองคัน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '454',
            value: '4075',
            label: 'ภูหอ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '455',
            value: '4077',
            label: 'ปวนพุ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '455',
            value: '4078',
            label: 'ท่าช้างคล้อง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '455',
            value: '4079',
            label: 'ผาขาว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '455',
            value: '4081',
            label: 'โนนป่าซาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '455',
            value: '4083',
            label: 'หนองหิน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '455',
            value: '4084',
            label: 'โนนปอแดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '455',
            value: '4086',
            label: 'ตาดข่า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '30',
            districtid: '456',
            value: '4089',
            label: 'วังน้ำใส',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4116',
            label: 'สระใคร',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4118',
            label: 'บ้านฝาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '460',
            value: '4119',
            label: 'คอกช้าง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4135',
            label: 'ชุมภูพร',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4141',
            label: 'หนองเดิ่น',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4142',
            label: 'นาสะแบง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4145',
            label: 'โคกกว้าง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4146',
            label: 'ศรีวิไล',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4148',
            label: 'นาแสง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '462',
            value: '4150',
            label: 'บุ่งคล้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4164',
            label: 'รัตนวาปี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4169',
            label: 'หนองหลวง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4170',
            label: 'โพนแพง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4171',
            label: 'เฝ้าไร่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4173',
            label: 'นาทับไฮ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4174',
            label: 'วังหลวง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4175',
            label: 'พระบาทนาสิงห์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4176',
            label: 'อุดมพร',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4177',
            label: 'นาดี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '464',
            value: '4178',
            label: 'บ้านต้อน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '466',
            value: '4189',
            label: 'โพธิ์ตาก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '466',
            value: '4193',
            label: 'โพนทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '31',
            districtid: '466',
            value: '4194',
            label: 'ด่านศรีสุข',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4203',
            label: 'โพธิ์หมากแข้ง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4204',
            label: 'ดงบัง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4209',
            label: 'บึงโขงหลง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '468',
            value: '4210',
            label: 'ท่าดอกคำ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '481',
            value: '4294',
            label: 'ชื่นชม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '481',
            value: '4296',
            label: 'เหล่าดอกไม้',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '481',
            value: '4301',
            label: 'กุดปลาดุก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '481',
            value: '4302',
            label: 'หนองกุง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4307',
            label: 'กุดรัง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4313',
            label: 'เลิงแฝก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4316',
            label: 'นาโพธิ์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4318',
            label: 'หนองแวง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '482',
            value: '4321',
            label: 'ห้วยเตย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4340',
            label: 'ดงเมือง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4341',
            label: 'แวงดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4342',
            label: 'ขามเรียน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4345',
            label: 'นาภู',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4347',
            label: 'บ้านกู่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '484',
            value: '4348',
            label: 'ยางสีสุราช',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4370',
            label: 'หนองไผ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4371',
            label: 'นาดูน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '32',
            districtid: '485',
            value: '4372',
            label: 'หนองคู',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4404',
            label: 'ปาฝา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4408',
            label: 'ดงสิงห์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4409',
            label: 'สวนจิก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4410',
            label: 'ม่วงลาด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4411',
            label: 'โพธิ์ทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4412',
            label: 'จังหาร',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4413',
            label: 'ดินดำ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4416',
            label: 'ศรีสมเด็จ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4418',
            label: 'หนองใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4419',
            label: 'เมืองเปลือย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4423',
            label: 'ดงสิงห์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4424',
            label: 'จังหาร',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4425',
            label: 'ม่วงลาด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4426',
            label: 'ปาฝา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4427',
            label: 'ดินดำ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4428',
            label: 'สวนจิก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4429',
            label: 'เมืองเปลือย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4430',
            label: 'ศรีสมเด็จ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '491',
            value: '4431',
            label: 'โพธิ์ทอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4469',
            label: 'หมูม้น',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4472',
            label: 'เหล่า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4473',
            label: 'มะบ้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4475',
            label: 'พระธาตุ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4476',
            label: 'บึงงาม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4477',
            label: 'บ้านเขือง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4478',
            label: 'พระเจ้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4480',
            label: 'เทอดไทย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4482',
            label: 'โนนศิลา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4483',
            label: 'เชียงขวัญ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4485',
            label: 'พลับพลา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '495',
            value: '4487',
            label: 'ทุ่งเขาหลวง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4496',
            label: 'หนองฮี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4497',
            label: 'เด่นราษฎร์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4498',
            label: 'ดูกอึ่ง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '496',
            value: '4504',
            label: 'สาวแห',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4520',
            label: 'ชุมพร',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4521',
            label: 'เมยวดี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4522',
            label: 'คำพอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4523',
            label: 'อัคคะคำ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4524',
            label: 'เชียงใหม่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '33',
            districtid: '497',
            value: '4525',
            label: 'ขามเบี้ย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4641',
            label: 'ม่วงนา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4643',
            label: 'ดงพยุง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4645',
            label: 'ดอนจาน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4649',
            label: 'นาจำปา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4653',
            label: 'สะอาดไชยศรี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4654',
            label: 'นามน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '511',
            value: '4655',
            label: 'ยอดแกง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4666',
            label: 'กุดฆ้องชัย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4667',
            label: 'ลำชี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4669',
            label: 'โคกสะอาด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4672',
            label: 'โนนศิลา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '513',
            value: '4673',
            label: 'ฆ้องชัยพัฒนา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '516',
            value: '4692',
            label: 'ภูแล่นช้าง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '516',
            value: '4693',
            label: 'นาคู',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '516',
            value: '4695',
            label: 'บ่อแก้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '516',
            value: '4697',
            label: 'สายนาวัง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '516',
            value: '4698',
            label: 'โนนนาจาน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '519',
            value: '4732',
            label: 'หนองบัว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '519',
            value: '4733',
            label: 'ทุ่งคลอง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '519',
            value: '4734',
            label: 'สำราญ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '519',
            value: '4735',
            label: 'โพน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '520',
            value: '4738',
            label: 'สำราญ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '520',
            value: '4739',
            label: 'สำราญใต้',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '520',
            value: '4743',
            label: 'คำสร้างเที่ยง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '520',
            value: '4745',
            label: 'หนองช้าง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '521',
            value: '4752',
            label: 'โคกเครือ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '34',
            districtid: '521',
            value: '4753',
            label: 'สหัสขันธ์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4798',
            label: 'นาตงวัฒนา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4801',
            label: 'บ้านโพน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4803',
            label: 'กกปลาซิว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4807',
            label: 'นาแก้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4812',
            label: 'บ้านแป้น',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4815',
            label: 'นาตงวัฒนา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4816',
            label: 'นาแก้ว',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4817',
            label: 'บ้านโพน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4818',
            label: 'เหล่าโพนค้อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4819',
            label: 'ตองโขบ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '529',
            value: '4820',
            label: 'เต่างอย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '531',
            value: '4827',
            label: 'โคกภู',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '531',
            value: '4829',
            label: 'สร้างค้อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '531',
            value: '4831',
            label: 'หลุบเลา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '533',
            value: '4847',
            label: 'คลองกระจัง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '533',
            value: '4848',
            label: 'สระกรวด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4894',
            label: 'บ้านเหล่า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4897',
            label: 'เจริญศิลป์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4901',
            label: 'ทุ่งแก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4910',
            label: 'โคกศิลา',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '35',
            districtid: '540',
            value: '4911',
            label: 'หนองแปน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4969',
            label: 'นาขมิ้น',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4970',
            label: 'โพนบก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4971',
            label: 'โพนสวรรค์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4972',
            label: 'บ้านค้อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '551',
            value: '4975',
            label: 'นาหัวบ่อ',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '552',
            value: '4981',
            label: 'นาทม',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '552',
            value: '4982',
            label: 'หนองซน',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '552',
            value: '4983',
            label: 'ดอนเตย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '554',
            value: '5003',
            label: 'เรณูนคร',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5016',
            label: 'โคกสี',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5017',
            label: 'วังยาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5020',
            label: 'ยอดชาด',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '36',
            districtid: '555',
            value: '5022',
            label: 'หนองโพธิ์',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5065',
            label: 'หนองแวง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5066',
            label: 'กกแดง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5067',
            label: 'นากอก',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5068',
            label: 'นิคมคำสร้อย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5069',
            label: 'บางทรายน้อย',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '561',
            value: '5070',
            label: 'หว้านใหญ่',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5091',
            label: 'หนองสูงใต้',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5092',
            label: 'หนองสูง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5099',
            label: 'โนนยาง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5100',
            label: 'บ้านเป้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5103',
            label: 'ภูวง',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5105',
            label: 'หนองสูงใต้',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5106',
            label: 'บ้านเป้า',
            zipcode: ''
        },
        {
            geoid: '3',
            provinceid: '37',
            districtid: '565',
            value: '5107',
            label: 'หนองสูง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '569',
            value: '5135',
            label: 'ยางคราม',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '569',
            value: '5136',
            label: 'สองแคว',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '569',
            value: '5142',
            label: 'ดอยหล่อ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '569',
            value: '5144',
            label: 'สันติสุข',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5207',
            label: 'ปงตำ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5212',
            label: 'ศรีดงเย็น',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5213',
            label: 'แม่ทะลบ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5214',
            label: 'หนองบัว',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5218',
            label: 'แม่นาวาง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5219',
            label: 'แม่สาว',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5220',
            label: 'แม่อาย',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5221',
            label: 'ศรีดงเย็น ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '576',
            value: '5222',
            label: 'ปงตำ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5249',
            label: 'ทุ่งปี้',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5251',
            label: 'บ้านกาด ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5252',
            label: 'แม่วิน',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '579',
            value: '5253',
            label: 'ทุ่งรวงทอง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5262',
            label: 'ออนเหนือ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5263',
            label: 'บ้านสหกรณ์',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5264',
            label: 'ห้วยแก้ว',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5269',
            label: 'แม่ทา',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5270',
            label: 'ทาเหนือ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5271',
            label: 'ออนกลาง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5272',
            label: 'แม่วิน',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5273',
            label: 'ทุ่งปี้',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '38',
            districtid: '580',
            value: '5274',
            label: 'บ้านกาด',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5363',
            label: 'บ้านธิ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5364',
            label: 'ห้วยยาบ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5367',
            label: 'ห้วยยาบ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '595',
            value: '5368',
            label: 'บ้านธิ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '598',
            value: '5386',
            label: 'บ้านไผ่',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '598',
            value: '5389',
            label: 'บ้านปวง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '598',
            value: '5390',
            label: 'ทุ่งหัวช้าง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '600',
            value: '5402',
            label: 'วังผาง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '600',
            value: '5403',
            label: 'หนองล่อง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '39',
            districtid: '600',
            value: '5405',
            label: 'หนองยวง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5430',
            label: 'นาสัก',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '603',
            value: '5431',
            label: 'บ้านดง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '608',
            value: '5467',
            label: 'แจ้ซ้อน',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '608',
            value: '5468',
            label: 'ทุ่งกว๋าว',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '608',
            value: '5469',
            label: 'บ้านขอ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '608',
            value: '5470',
            label: 'เมืองปาน',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '40',
            districtid: '612',
            value: '5499',
            label: 'สบป้าด',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '616',
            value: '5535',
            label: 'ร่วมจิตร',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '617',
            value: '5541',
            label: 'น้ำพี้',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '617',
            value: '5542',
            label: 'บ่อทอง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '617',
            value: '5543',
            label: 'ผักขวง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '617',
            value: '5544',
            label: 'ป่าคาย',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '41',
            districtid: '623',
            value: '5586',
            label: 'ศรีพนมมาศ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5612',
            label: 'หนองม่วงไข่',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5613',
            label: 'แม่คำมี',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5621',
            label: 'น้ำรัด',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5622',
            label: 'วังหลวง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5626',
            label: 'หนองม่วงไข่',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '42',
            districtid: '626',
            value: '5627',
            label: 'แม่คำมี',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5684',
            label: 'ฝายแก้ว',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5685',
            label: 'ม่วงตึ๊ด',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5686',
            label: 'ท่าน้าว',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5687',
            label: 'นาปัง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5688',
            label: 'เมืองจัง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5689',
            label: 'น้ำแก่น',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5692',
            label: 'น้ำเกี๋ยน',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5693',
            label: 'ป่าคาหลวง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5694',
            label: 'หมอเมือง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5695',
            label: 'บ้านฟ้า',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '633',
            value: '5696',
            label: 'ดู่พงษ์',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '634',
            value: '5697',
            label: 'พงษ์',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5720',
            label: 'บ่อเกลือเหนือ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '637',
            value: '5721',
            label: 'บ่อเกลือใต้',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '640',
            value: '5759',
            label: 'ห้วยโก๋น',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '640',
            value: '5760',
            label: 'เปือ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '640',
            value: '5761',
            label: 'เชียงกลาง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '641',
            value: '5766',
            label: 'นาไร่หลวง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '641',
            value: '5767',
            label: 'ชนแดน',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '641',
            value: '5768',
            label: 'ยอด',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '641',
            value: '5771',
            label: 'นาไร่หลวง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '641',
            value: '5772',
            label: 'ยอด',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '43',
            districtid: '644',
            value: '5782',
            label: 'ขุนน่าน',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5799',
            label: 'ดงเจน',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5805',
            label: 'ห้วยแก้ว',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '648',
            value: '5813',
            label: 'แม่อิง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5823',
            label: 'ทุ่งกล้วย',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5824',
            label: 'สบบง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5825',
            label: 'เชียงแรง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5826',
            label: 'ภูซาง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '44',
            districtid: '650',
            value: '5836',
            label: 'ป่าสัก',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5880',
            label: 'บัวสลี',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5881',
            label: 'ดงมะดะ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5882',
            label: 'ป่าก่อดำ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5889',
            label: 'จอมหมอกแก้ว',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5891',
            label: 'โป่งแพร่',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5894',
            label: 'ทุ่งก่อ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5895',
            label: 'ป่าก่อดำ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5896',
            label: 'ดงมะดะ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5897',
            label: 'บัวสลี',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5898',
            label: 'เวียงเหนือ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5899',
            label: 'ผางาม',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5900',
            label: 'เวียงชัย',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '657',
            value: '5901',
            label: 'ทุ่งก่อ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '658',
            value: '5902',
            label: 'ทุ่งก่อ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '658',
            value: '5906',
            label: 'ป่าซาง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '658',
            value: '5908',
            label: 'ดงมหาวัน',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '659',
            value: '5915',
            label: 'ม่วงยาย',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '659',
            value: '5916',
            label: 'ปอ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '659',
            value: '5918',
            label: 'หล่ายงาว',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '659',
            value: '5920',
            label: 'ปอ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '659',
            value: '5921',
            label: 'ม่วงยาย',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5927',
            label: 'ต้า',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5928',
            label: 'ป่าตาล',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5929',
            label: 'ยางฮอม',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5935',
            label: 'แม่ลอย',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5936',
            label: 'ต้า',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5937',
            label: 'ยางฮอม',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5938',
            label: 'แม่เปา',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '660',
            value: '5939',
            label: 'ป่าตาล',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5955',
            label: 'ป่าแงะ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5956',
            label: 'สันมะค่า',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '661',
            value: '5957',
            label: 'ป่าแดด',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5969',
            label: 'ปงน้อย',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5971',
            label: 'หนองป่าก่อ',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5976',
            label: 'เทอดไทย',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5977',
            label: 'แม่สลองใน',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5978',
            label: 'แม่สลองนอก',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '663',
            value: '5979',
            label: 'โชคชัย',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '45',
            districtid: '667',
            value: '6008',
            label: 'เวียงกาหลง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '675',
            value: '6042',
            label: 'ปางมะผ้า',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '675',
            value: '6043',
            label: 'สบป่อง',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '678',
            value: '6065',
            label: 'กองกอย',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '678',
            value: '6066',
            label: 'แม่คะตวน',
            zipcode: ''
        },
        {
            geoid: '1',
            provinceid: '46',
            districtid: '678',
            value: '6067',
            label: 'สบเมย',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6200',
            label: 'แม่เล่ย์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6201',
            label: 'แม่วงก์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6202',
            label: 'วังซ่าน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6203',
            label: 'เขาชนกัน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '693',
            value: '6204',
            label: 'ปางสวรรค์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '695',
            value: '6215',
            label: 'ห้วยน้ำหอม',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '695',
            value: '6219',
            label: 'ปางสวรรค์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '695',
            value: '6220',
            label: 'แม่เปิน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '47',
            districtid: '695',
            value: '6221',
            label: 'ชุมตาบง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '701',
            value: '6239',
            label: 'ข้าวเม่า',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6287',
            label: 'ห้วยคต',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6288',
            label: 'สุขฤทัย',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6289',
            label: 'ป่าอ้อ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6290',
            label: 'ประดู่ยืน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '48',
            districtid: '706',
            value: '6291',
            label: 'ลานสัก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6308',
            label: 'ลานดอกไม้ตก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6309',
            label: 'โกสัมพี',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6316',
            label: 'เพชรชมภู',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6320',
            label: 'หนองคล้า',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6321',
            label: 'โป่งน้ำร้อน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '709',
            value: '6322',
            label: 'ไทรงาม',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6334',
            label: 'วังชะโอน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6335',
            label: 'ระหาน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '712',
            value: '6347',
            label: 'บึงสามัคคี',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6350',
            label: 'ทุ่งทราย',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6357',
            label: 'โพธิ์ทอง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6358',
            label: 'ปางตาไว',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6359',
            label: 'ถาวรวัฒนา',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6361',
            label: 'ทุ่งทอง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6362',
            label: 'หินดาต',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '49',
            districtid: '713',
            value: '6364',
            label: 'ทุ่งทราย',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6404',
            label: 'เชียงทอง',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6410',
            label: 'นาโบสถ์',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '50',
            districtid: '720',
            value: '6411',
            label: 'ประดาง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6537',
            label: 'ประชาราษฎร์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6538',
            label: 'คลองมะพลับ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6539',
            label: 'น้ำขุม',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6540',
            label: 'นครเดิฐ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '51',
            districtid: '736',
            value: '6541',
            label: 'ศรีนคร',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6638',
            label: 'บ้านน้อยซุ้มขี้เหล็ก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6639',
            label: 'วังโพรง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6640',
            label: 'ไทรย้อย',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6641',
            label: 'บ้านมุง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '52',
            districtid: '746',
            value: '6642',
            label: 'ชมพู',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6664',
            label: 'หนองปล้อง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6666',
            label: 'สากเหล็ก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6667',
            label: 'ท่าเยี่ยม',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6668',
            label: 'คลองทราย',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6671',
            label: 'ไผ่รอบ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6672',
            label: 'วังจิก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6673',
            label: 'โพธิ์ประทับช้าง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6674',
            label: 'ไผ่ท่าโพ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6675',
            label: 'วังจิก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6676',
            label: 'หนองพระ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6677',
            label: 'หนองปลาไหล',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '748',
            value: '6678',
            label: 'วังทรายพูน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6703',
            label: 'ท้ายทุ่ง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6704',
            label: 'เขาเจ็ดลูก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6705',
            label: 'เขาทราย',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '751',
            value: '6706',
            label: 'ทับคล้อ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6716',
            label: 'สำนักขุนเณร',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6717',
            label: 'ห้วยพุก',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6718',
            label: 'ห้วยร่วม',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6719',
            label: 'วังงิ้ว',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '752',
            value: '6721',
            label: 'วังงิ้วใต้',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6730',
            label: 'บางลาย',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6731',
            label: 'บึงนาราง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6735',
            label: 'โพธิ์ไทรงาม',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6736',
            label: 'แหลมรัง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '753',
            value: '6737',
            label: 'ห้วยแก้ว',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '754',
            value: '6741',
            label: 'หนองหลุม',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '754',
            value: '6742',
            label: 'บ้านนา',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '754',
            value: '6745',
            label: 'วังโมกข์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '53',
            districtid: '754',
            value: '6746',
            label: 'บึงบัว',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '761',
            value: '6793',
            label: 'ซับเปิป',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '761',
            value: '6797',
            label: 'ท้ายดง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '761',
            value: '6798',
            label: 'วังโป่ง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '762',
            value: '6822',
            label: 'แคมป์สน',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '763',
            value: '6832',
            label: 'น้ำหนาว',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6847',
            label: 'นาสนุ่น',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6848',
            label: 'คลองกระจัง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6849',
            label: 'สระกรวด',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '54',
            districtid: '764',
            value: '6850',
            label: 'ศรีเทพ',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '772',
            value: '6924',
            label: 'ป่าหวาย',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '772',
            value: '6925',
            label: 'บ้านผึ้ง',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '772',
            value: '6926',
            label: 'สวนผึ้ง',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '773',
            value: '6929',
            label: 'บ้านบึง',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '773',
            value: '6931',
            label: 'บ้านคา',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '55',
            districtid: '773',
            value: '6932',
            label: 'หนองพันจันทร์',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7017',
            label: 'ด่านมะขามเตี้ย',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7019',
            label: 'จรเข้เผือก',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '782',
            value: '7020',
            label: 'กลอนโด',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '784',
            value: '7032',
            label: 'หนองปรือ',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '784',
            value: '7034',
            label: 'หนองปลาไหล',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '784',
            value: '7035',
            label: 'สมเด็จเจริญ',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '790',
            value: '7090',
            label: 'ดอนแสลบ',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '790',
            value: '7091',
            label: 'ห้วยกระเจา',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '790',
            value: '7092',
            label: 'สระลงเรือ',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '56',
            districtid: '790',
            value: '7093',
            label: 'วังไผ่',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7148',
            label: 'องค์พระ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7149',
            label: 'ห้วยขมิ้น',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7150',
            label: 'ด่านช้าง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '798',
            value: '7151',
            label: 'หนองมะค่าโมง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '804',
            value: '7209',
            label: 'แจงงาม',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '804',
            value: '7210',
            label: 'หนองโพธิ์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '804',
            value: '7211',
            label: 'หนองราชวัตร',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '57',
            districtid: '804',
            value: '7212',
            label: 'หนองหญ้าไซ',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7296',
            label: 'ศาลายา',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7297',
            label: 'มหาสวัสดิ์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7298',
            label: 'คลองโยง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7299',
            label: 'มหาสวัสดิ์',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7300',
            label: 'คลองโยง',
            zipcode: ''
        },
        {
            geoid: '2',
            provinceid: '58',
            districtid: '809',
            value: '7301',
            label: 'ศาลายา',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '820',
            value: '7444',
            label: 'มาตยาวงศ์',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7455',
            label: 'ยางน้ำกลักใต้',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7456',
            label: 'ยางน้ำกลักเหนือ',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '821',
            value: '7457',
            label: 'หนองหญ้าปล้อง',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7478',
            label: 'วังจันทร์',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7479',
            label: 'สองพี่น้อง',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7480',
            label: 'แก่งกระจาน',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7486',
            label: 'สระปลาดู่',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7487',
            label: 'บางเมือง',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7488',
            label: 'นาไพร',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7489',
            label: 'วังจันทร์',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7490',
            label: 'สองพี่น้อง',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '61',
            districtid: '824',
            value: '7491',
            label: 'แก่งกระจาน',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '829',
            value: '7536',
            label: 'ไร่ใหม่',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '833',
            value: '7559',
            label: 'ศิลาลอย',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '833',
            value: '7561',
            label: 'สามร้อยยอด',
            zipcode: ''
        },
        {
            geoid: '4',
            provinceid: '62',
            districtid: '833',
            value: '7562',
            label: 'ไร่เก่า',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7581',
            label: 'นา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7582',
            label: 'ศาลามีชัย',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7586',
            label: 'นาพรุ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7587',
            label: 'ช้างซ้าย',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7588',
            label: 'นาสาร',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7594',
            label: 'ท้ายสำเภา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7600',
            label: 'อินคีรี',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7601',
            label: 'พรหมโลก',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7602',
            label: 'ศาลามีชัย',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7603',
            label: 'นา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '836',
            value: '7604',
            label: 'บ้านเกาะ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7616',
            label: 'ช้างกลาง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7622',
            label: 'ถ้ำพรรณรา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7625',
            label: 'หลักช้าง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7626',
            label: 'สวนขัน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7627',
            label: 'คลองเส',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '839',
            value: '7628',
            label: 'ดุสิต',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7637',
            label: 'เชียรเขา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7643',
            label: 'ดอนตรอ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '841',
            value: '7644',
            label: 'สวนหลวง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7660',
            label: 'บ้านควนมุด',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '842',
            value: '7661',
            label: 'บ้านชะอวด',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7666',
            label: 'กะหรอ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7669',
            label: 'นบพิตำ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7673',
            label: 'กรุงชิง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '843',
            value: '7675',
            label: 'นาเหรง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7689',
            label: 'วังหิน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7690',
            label: 'บ้านลำนาว',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7691',
            label: 'บางขัน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7692',
            label: 'แก้วแสน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7693',
            label: 'ทุ่งสง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '844',
            value: '7694',
            label: 'นาบอน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '848',
            value: '7729',
            label: 'สามตำบล',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '848',
            value: '7730',
            label: 'ทางพูน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '848',
            value: '7731',
            label: 'นาหมอบุญ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '848',
            value: '7732',
            label: 'ทุ่งโพธิ์',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '63',
            districtid: '848',
            value: '7733',
            label: 'ควนหนองคว้า',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7788',
            label: 'เกาะศรีบอยา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7791',
            label: 'คลองขนาน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7792',
            label: 'คลองเขม้า',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7793',
            label: 'โคกยาง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7794',
            label: 'ตลิ่งชัน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7796',
            label: 'ปกาสัย',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7797',
            label: 'ห้วยยูง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7798',
            label: 'เหนือคลอง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7803',
            label: 'เกาะศรีบายอ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7804',
            label: 'คลองเขม้า',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7805',
            label: 'โคกยาง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7806',
            label: 'ห้วยยูง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7807',
            label: 'คลองขนาน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7808',
            label: 'ตลิ่งชัน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7809',
            label: 'ปกาสัย',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '864',
            value: '7810',
            label: 'เหนือคลอง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '867',
            value: '7829',
            label: 'ลำทับ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '868',
            value: '7839',
            label: 'เขาเขน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '868',
            value: '7840',
            label: 'เขาต่อ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '64',
            districtid: '868',
            value: '7841',
            label: 'ปลายพระยา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '872',
            value: '7867',
            label: 'เกาะยาวใหญ่',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '872',
            value: '7868',
            label: 'เกาะยาวน้อย',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '65',
            districtid: '877',
            value: '7895',
            label: 'เกาะคอเขา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '891',
            value: '7982',
            label: 'ตะกุกใต้',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '891',
            value: '7983',
            label: 'ตะกุกเหนือ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '891',
            value: '7989',
            label: 'ตะกุดใต้',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '892',
            value: '7994',
            label: 'ไกรสร',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8018',
            label: 'ท่าเรือ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '895',
            value: '8019',
            label: 'บ้านนา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '899',
            value: '8041',
            label: 'ชัยบุรี',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '899',
            value: '8042',
            label: 'สองแพรก',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8052',
            label: 'ศรีวิชัย',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8053',
            label: 'น้ำรอบ',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8054',
            label: 'มะลวน',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8055',
            label: 'หัวเตย',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8056',
            label: 'หนองไทร',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8057',
            label: 'เขาหัวควาย',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8058',
            label: 'ตะปาน',
            zipcode: '84130'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '901',
            value: '8060',
            label: 'สองแพรก',
            zipcode: '84350'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '901',
            value: '8061',
            label: 'ชัยบุรี',
            zipcode: '84350'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '901',
            value: '8062',
            label: 'คลองน้อย',
            zipcode: '84350'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '901',
            value: '8063',
            label: 'ไทรทอง',
            zipcode: '84350'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '902',
            value: '8064',
            label: 'ตะกุกใต้',
            zipcode: '84180'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '902',
            value: '8065',
            label: 'ตะกุกเหนือ',
            zipcode: '84180'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '905',
            value: '8066',
            label: 'เขานิเวศน์',
            zipcode: '85000'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '905',
            value: '8067',
            label: 'ราชกรูด',
            zipcode: '85000'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '905',
            value: '8068',
            label: 'หงาว',
            zipcode: '85000'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '905',
            value: '8069',
            label: 'บางริ้น',
            zipcode: '85000'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '905',
            value: '8070',
            label: 'ปากน้ำ',
            zipcode: '85000'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '905',
            value: '8071',
            label: 'บางนอน',
            zipcode: '85000'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '905',
            value: '8072',
            label: 'หาดส้มแป้น',
            zipcode: '85000'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '905',
            value: '8073',
            label: 'ทรายแดง',
            zipcode: '85130'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '905',
            value: '8074',
            label: 'เกาะพยาม',
            zipcode: '85000'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '906',
            value: '8075',
            label: 'ละอุ่นใต้',
            zipcode: '85130'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '906',
            value: '8076',
            label: 'ละอุ่นเหนือ',
            zipcode: '85130'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '906',
            value: '8077',
            label: 'บางพระใต้',
            zipcode: '85130'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '906',
            value: '8078',
            label: 'บางพระเหนือ',
            zipcode: '85130'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '906',
            value: '8079',
            label: 'บางแก้ว',
            zipcode: '85130'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '906',
            value: '8080',
            label: 'ในวงเหนือ',
            zipcode: '85130'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '906',
            value: '8081',
            label: 'ในวงใต้',
            zipcode: '85130'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '907',
            value: '8082',
            label: 'ม่วงกลวง',
            zipcode: '85120'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '907',
            value: '8083',
            label: 'กะเปอร์',
            zipcode: '85120'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '907',
            value: '8084',
            label: 'เชี่ยวเหลียง',
            zipcode: '85120'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '907',
            value: '8085',
            label: 'บ้านนา',
            zipcode: '85120'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '907',
            value: '8086',
            label: 'บางหิน',
            zipcode: '85120'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '908',
            value: '8089',
            label: 'น้ำจืด',
            zipcode: '85110'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '908',
            value: '8090',
            label: 'น้ำจืดน้อย',
            zipcode: '85110'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '908',
            value: '8091',
            label: 'มะมุ',
            zipcode: '85110'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '908',
            value: '8092',
            label: 'ปากจั่น',
            zipcode: '85110'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '908',
            value: '8093',
            label: 'ลำเลียง',
            zipcode: '85110'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '908',
            value: '8094',
            label: 'จ.ป.ร.',
            zipcode: '85110'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '908',
            value: '8095',
            label: 'บางใหญ่',
            zipcode: '85110'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '909',
            value: '8096',
            label: 'นาคา',
            zipcode: '85120'
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '909',
            value: '8097',
            label: 'กำพวน',
            zipcode: '85120'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8098',
            label: 'ท่าตะเภา',
            zipcode: '86000'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8099',
            label: 'ปากน้ำ',
            zipcode: '86120'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8100',
            label: 'ท่ายาง',
            zipcode: '86000'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8101',
            label: 'บางหมาก',
            zipcode: '86000'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8102',
            label: 'นาทุ่ง',
            zipcode: '86000'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8103',
            label: 'นาชะอัง',
            zipcode: '86000'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8104',
            label: 'ตากแดด',
            zipcode: '86000'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8105',
            label: 'บางลึก',
            zipcode: '86000'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8106',
            label: 'หาดพันไกร',
            zipcode: '86000'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8107',
            label: 'วังไผ่',
            zipcode: '86000'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8108',
            label: 'วังใหม่',
            zipcode: '86190'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8109',
            label: 'บ้านนา',
            zipcode: '86190'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8110',
            label: 'ขุนกระทิง',
            zipcode: '86000'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8111',
            label: 'ทุ่งคา',
            zipcode: '86100'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8112',
            label: 'วิสัยเหนือ',
            zipcode: '86100'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8113',
            label: 'หาดทรายรี',
            zipcode: '86120'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '910',
            value: '8114',
            label: 'ถ้ำสิงห์',
            zipcode: '86100'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '911',
            value: '8115',
            label: 'ท่าแซะ',
            zipcode: '86140'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '911',
            value: '8116',
            label: 'คุริง',
            zipcode: '86140'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '911',
            value: '8117',
            label: 'สลุย',
            zipcode: '86140'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '911',
            value: '8118',
            label: 'นากระตาม',
            zipcode: '86140'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '911',
            value: '8119',
            label: 'รับร่อ',
            zipcode: '86190'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '911',
            value: '8120',
            label: 'ท่าข้าม',
            zipcode: '86140'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '911',
            value: '8121',
            label: 'หงษ์เจริญ',
            zipcode: '86140'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '911',
            value: '8122',
            label: 'หินแก้ว',
            zipcode: '86190'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '911',
            value: '8123',
            label: 'ทรัพย์อนันต์',
            zipcode: '86140'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '911',
            value: '8124',
            label: 'สองพี่น้อง',
            zipcode: '86140'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '912',
            value: '8125',
            label: 'บางสน',
            zipcode: '86160'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '912',
            value: '8126',
            label: 'ทะเลทรัพย์',
            zipcode: '86160'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '912',
            value: '8127',
            label: 'สะพลี',
            zipcode: '86230'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '912',
            value: '8128',
            label: 'ชุมโค',
            zipcode: '86160'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '912',
            value: '8129',
            label: 'ดอนยาง',
            zipcode: '86210'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '912',
            value: '8130',
            label: 'ปากคลอง',
            zipcode: '86210'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '912',
            value: '8131',
            label: 'เขาไชยราช',
            zipcode: '86210'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8132',
            label: 'หลังสวน',
            zipcode: '86110'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8133',
            label: 'ขันเงิน',
            zipcode: '86110'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8134',
            label: 'ท่ามะพลา',
            zipcode: '86110'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8135',
            label: 'นาขา',
            zipcode: '86110'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8136',
            label: 'นาพญา',
            zipcode: '86110'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8137',
            label: 'บ้านควน',
            zipcode: '86110'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8138',
            label: 'บางมะพร้าว',
            zipcode: '86110'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8139',
            label: 'บางน้ำจืด',
            zipcode: '86150'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8140',
            label: 'ปากน้ำ',
            zipcode: '86150'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8141',
            label: 'พ้อแดง',
            zipcode: '86110'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8142',
            label: 'แหลมทราย',
            zipcode: '86110'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8143',
            label: 'วังตะกอ',
            zipcode: '86110'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '913',
            value: '8144',
            label: 'หาดยาย',
            zipcode: '86110'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '914',
            value: '8145',
            label: 'ละแม',
            zipcode: '86170'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '914',
            value: '8146',
            label: 'ทุ่งหลวง',
            zipcode: '86170'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '914',
            value: '8147',
            label: 'สวนแตง',
            zipcode: '86170'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '914',
            value: '8148',
            label: 'ทุ่งคาวัด',
            zipcode: '86170'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '915',
            value: '8149',
            label: 'พะโต๊ะ',
            zipcode: '86180'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '915',
            value: '8150',
            label: 'ปากทรง',
            zipcode: '86180'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '915',
            value: '8151',
            label: 'ปังหวาน',
            zipcode: '86180'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '915',
            value: '8152',
            label: 'พระรักษ์',
            zipcode: '86180'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '916',
            value: '8153',
            label: 'นาโพธิ์',
            zipcode: '86130'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '916',
            value: '8154',
            label: 'สวี',
            zipcode: '86130'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '916',
            value: '8155',
            label: 'ทุ่งระยะ',
            zipcode: '86130'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '916',
            value: '8156',
            label: 'ท่าหิน',
            zipcode: '86130'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '916',
            value: '8157',
            label: 'ปากแพรก',
            zipcode: '86130'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '916',
            value: '8158',
            label: 'ด่านสวี',
            zipcode: '86130'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '916',
            value: '8159',
            label: 'ครน',
            zipcode: '86130'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '916',
            value: '8160',
            label: 'วิสัยใต้',
            zipcode: '86130'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '916',
            value: '8161',
            label: 'นาสัก',
            zipcode: '86130'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '916',
            value: '8162',
            label: 'เขาทะลุ',
            zipcode: '86130'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '916',
            value: '8163',
            label: 'เขาค่าย',
            zipcode: '86130'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '917',
            value: '8164',
            label: 'ปากตะโก',
            zipcode: '86220'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '917',
            value: '8165',
            label: 'ทุ่งตะไคร',
            zipcode: '86220'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '917',
            value: '8166',
            label: 'ตะโก',
            zipcode: '86220'
        },
        {
            geoid: '6',
            provinceid: '69',
            districtid: '917',
            value: '8167',
            label: 'ช่องไม้แก้ว',
            zipcode: '86220'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8168',
            label: 'บ่อยาง',
            zipcode: '90000'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8169',
            label: 'เขารูปช้าง',
            zipcode: '90000'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8170',
            label: 'เกาะแต้ว',
            zipcode: '90000'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8171',
            label: 'พะวง',
            zipcode: '90100'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8172',
            label: 'ทุ่งหวัง',
            zipcode: '90000'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8173',
            label: 'เกาะยอ',
            zipcode: '90100'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '919',
            value: '8197',
            label: 'จะทิ้งพระ',
            zipcode: '90190'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '919',
            value: '8198',
            label: 'กระดังงา',
            zipcode: '90190'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '919',
            value: '8199',
            label: 'สนามชัย',
            zipcode: '90190'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '919',
            value: '8200',
            label: 'ดีหลวง',
            zipcode: '90190'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '919',
            value: '8201',
            label: 'ชุมพล',
            zipcode: '90190'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '919',
            value: '8202',
            label: 'คลองรี',
            zipcode: '90190'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '919',
            value: '8203',
            label: 'คูขุด',
            zipcode: '90190'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '919',
            value: '8204',
            label: 'ท่าหิน',
            zipcode: '90190'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '919',
            value: '8205',
            label: 'วัดจันทร์',
            zipcode: '90190'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '919',
            value: '8206',
            label: 'บ่อแดง',
            zipcode: '90190'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '919',
            value: '8207',
            label: 'บ่อดาน',
            zipcode: '90190'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8208',
            label: 'บ้านนา',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8209',
            label: 'ป่าชิง',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8210',
            label: 'สะพานไม้แก่น',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8211',
            label: 'สะกอม',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8212',
            label: 'นาหว้า',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8213',
            label: 'นาทับ',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8214',
            label: 'น้ำขาว',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8215',
            label: 'ขุนตัดหวาย',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8216',
            label: 'ท่าหมอไทร',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8217',
            label: 'จะโหนง',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8218',
            label: 'คู',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8219',
            label: 'แค',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8220',
            label: 'คลองเปียะ',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '920',
            value: '8221',
            label: 'ตลิ่งชัน',
            zipcode: '90130'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '921',
            value: '8222',
            label: 'นาทวี',
            zipcode: '90160'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '921',
            value: '8223',
            label: 'ฉาง',
            zipcode: '90160'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '921',
            value: '8224',
            label: 'นาหมอศรี',
            zipcode: '90160'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '921',
            value: '8225',
            label: 'คลองทราย',
            zipcode: '90160'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '921',
            value: '8226',
            label: 'ปลักหนู',
            zipcode: '90160'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '921',
            value: '8227',
            label: 'ท่าประดู่',
            zipcode: '90160'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '921',
            value: '8228',
            label: 'สะท้อน',
            zipcode: '90160'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '921',
            value: '8229',
            label: 'ทับช้าง',
            zipcode: '90160'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '921',
            value: '8230',
            label: 'ประกอบ',
            zipcode: '90160'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '921',
            value: '8231',
            label: 'คลองกวาง',
            zipcode: '90160'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '922',
            value: '8232',
            label: 'เทพา',
            zipcode: '90150'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '922',
            value: '8233',
            label: 'ปากบาง',
            zipcode: '90150'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '922',
            value: '8234',
            label: 'เกาะสะบ้า',
            zipcode: '90150'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '922',
            value: '8235',
            label: 'ลำไพล',
            zipcode: '90260'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '922',
            value: '8236',
            label: 'ท่าม่วง',
            zipcode: '90260'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '922',
            value: '8237',
            label: 'วังใหญ่',
            zipcode: '90260'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '922',
            value: '8238',
            label: 'สะกอม',
            zipcode: '90150'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '923',
            value: '8239',
            label: 'สะบ้าย้อย',
            zipcode: '90210'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '923',
            value: '8240',
            label: 'ทุ่งพอ',
            zipcode: '90210'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '923',
            value: '8241',
            label: 'เปียน',
            zipcode: '90210'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '923',
            value: '8242',
            label: 'บ้านโหนด',
            zipcode: '90210'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '923',
            value: '8243',
            label: 'จะแหน',
            zipcode: '90210'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '923',
            value: '8244',
            label: 'คูหา',
            zipcode: '90210'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '923',
            value: '8245',
            label: 'เขาแดง',
            zipcode: '90210'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '923',
            value: '8246',
            label: 'บาโหย',
            zipcode: '90210'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '923',
            value: '8247',
            label: 'ธารคีรี',
            zipcode: '90210'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8248',
            label: 'ระโนด',
            zipcode: '90140'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8249',
            label: 'คลองแดน',
            zipcode: '90140'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8250',
            label: 'ตะเครียะ',
            zipcode: '90140'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8251',
            label: 'ท่าบอน',
            zipcode: '90140'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8252',
            label: 'บ้านใหม่',
            zipcode: '90140'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8253',
            label: 'บ่อตรุ',
            zipcode: '90140'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8254',
            label: 'ปากแตระ',
            zipcode: '90140'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8255',
            label: 'พังยาง',
            zipcode: '90140'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8256',
            label: 'ระวะ',
            zipcode: '90140'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8257',
            label: 'วัดสน',
            zipcode: '90140'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8258',
            label: 'บ้านขาว',
            zipcode: '90140'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8259',
            label: 'แดนสงวน',
            zipcode: '90140'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '925',
            value: '8263',
            label: 'เกาะใหญ่',
            zipcode: '90270'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '925',
            value: '8264',
            label: 'โรง',
            zipcode: '90270'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '925',
            value: '8265',
            label: 'เชิงแส',
            zipcode: '90270'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '925',
            value: '8266',
            label: 'กระแสสินธุ์',
            zipcode: '90270'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8267',
            label: 'กำแพงเพชร',
            zipcode: '90180'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8268',
            label: 'ท่าชะมวง',
            zipcode: '90180'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8269',
            label: 'คูหาใต้',
            zipcode: '90180'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8270',
            label: 'ควนรู',
            zipcode: '90180'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8275',
            label: 'เขาพระ',
            zipcode: '90180'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '927',
            value: '8280',
            label: 'สะเดา',
            zipcode: '90120'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '927',
            value: '8281',
            label: 'ปริก',
            zipcode: '90120'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '927',
            value: '8282',
            label: 'พังลา',
            zipcode: '90170'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '927',
            value: '8283',
            label: 'สำนักแต้ว',
            zipcode: '90120'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '927',
            value: '8284',
            label: 'ทุ่งหมอ',
            zipcode: '90240'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '927',
            value: '8285',
            label: 'ท่าโพธิ์',
            zipcode: '90170'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '927',
            value: '8286',
            label: 'ปาดังเบซาร์',
            zipcode: '90240'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '927',
            value: '8287',
            label: 'สำนักขาม',
            zipcode: '90320'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '927',
            value: '8288',
            label: 'เขามีเกียรติ',
            zipcode: '90170'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8289',
            label: 'หาดใหญ่',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8290',
            label: 'ควนลัง',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8291',
            label: 'คูเต่า',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8292',
            label: 'คอหงส์',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8293',
            label: 'คลองแห',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8295',
            label: 'คลองอู่ตะเภา',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8296',
            label: 'ฉลุง',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8299',
            label: 'ทุ่งใหญ่',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8300',
            label: 'ทุ่งตำเสา',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8301',
            label: 'ท่าข้าม',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8302',
            label: 'น้ำน้อย',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8304',
            label: 'บ้านพรุ',
            zipcode: '90250'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8306',
            label: 'พะตง',
            zipcode: '90230'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '929',
            value: '8319',
            label: 'นาหม่อม',
            zipcode: '90310'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '929',
            value: '8320',
            label: 'พิจิตร',
            zipcode: '90310'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '929',
            value: '8321',
            label: 'ทุ่งขมิ้น',
            zipcode: '90310'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '929',
            value: '8322',
            label: 'คลองหรัง',
            zipcode: '90310'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '930',
            value: '8323',
            label: 'รัตภูมิ',
            zipcode: '90220'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '930',
            value: '8324',
            label: 'ควนโส',
            zipcode: '90220'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '930',
            value: '8325',
            label: 'ห้วยลึก',
            zipcode: '90220'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '930',
            value: '8326',
            label: 'บางเหรียง',
            zipcode: '90220'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '931',
            value: '8327',
            label: 'บางกล่ำ',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '931',
            value: '8328',
            label: 'ท่าช้าง',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '931',
            value: '8329',
            label: 'แม่ทอม',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '931',
            value: '8330',
            label: 'บ้านหาร',
            zipcode: '90110'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '932',
            value: '8331',
            label: 'ชิงโค',
            zipcode: '90280'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '932',
            value: '8332',
            label: 'สทิงหม้อ',
            zipcode: '90280'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '932',
            value: '8333',
            label: 'ทำนบ',
            zipcode: '90280'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '932',
            value: '8334',
            label: 'รำแดง',
            zipcode: '90330'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '932',
            value: '8335',
            label: 'วัดขนุน',
            zipcode: '90330'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '932',
            value: '8336',
            label: 'ชะแล้',
            zipcode: '90330'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '932',
            value: '8337',
            label: 'ปากรอ',
            zipcode: '90330'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '932',
            value: '8338',
            label: 'ป่าขาด',
            zipcode: '90330'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '932',
            value: '8339',
            label: 'หัวเขา',
            zipcode: '90280'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '932',
            value: '8340',
            label: 'บางเขียด',
            zipcode: '90330'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '932',
            value: '8341',
            label: 'ม่วงงาม',
            zipcode: '90330'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '933',
            value: '8342',
            label: 'คลองหอยโข่ง',
            zipcode: '90230'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '933',
            value: '8343',
            label: 'ทุ่งลาน',
            zipcode: '90230'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '933',
            value: '8344',
            label: 'โคกม่วง',
            zipcode: '90230'
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '933',
            value: '8345',
            label: 'คลองหลา',
            zipcode: '90115'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8347',
            label: 'พิมาน',
            zipcode: '91000'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8348',
            label: 'คลองขุด',
            zipcode: '91000'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8349',
            label: 'ควนขัน',
            zipcode: '91000'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8350',
            label: 'บ้านควน',
            zipcode: '91140'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8351',
            label: 'ฉลุง',
            zipcode: '91140'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8352',
            label: 'เกาะสาหร่าย',
            zipcode: '91000'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8353',
            label: 'ตันหยงโป',
            zipcode: '91000'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8354',
            label: 'เจ๊ะบิลัง',
            zipcode: '91000'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8355',
            label: 'ตำมะลัง',
            zipcode: '91000'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8356',
            label: 'ปูยู',
            zipcode: '91000'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8357',
            label: 'ควนโพธิ์',
            zipcode: '91140'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8358',
            label: 'เกตรี',
            zipcode: '91140'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '937',
            value: '8360',
            label: 'ควนโดน',
            zipcode: '91160'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '937',
            value: '8361',
            label: 'ควนสตอ',
            zipcode: '91160'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '937',
            value: '8362',
            label: 'ย่านซื่อ',
            zipcode: '91160'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '937',
            value: '8363',
            label: 'วังประจัน',
            zipcode: '91160'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '938',
            value: '8364',
            label: 'ทุ่งนุ้ย',
            zipcode: '91130'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '938',
            value: '8365',
            label: 'ควนกาหลง',
            zipcode: '91130'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '938',
            value: '8366',
            label: 'อุใดเจริญ',
            zipcode: '91130'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '939',
            value: '8369',
            label: 'ท่าแพ',
            zipcode: '91150'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '939',
            value: '8370',
            label: 'แป-ระ',
            zipcode: '91150'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '939',
            value: '8371',
            label: 'สาคร',
            zipcode: '91150'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '939',
            value: '8372',
            label: 'ท่าเรือ',
            zipcode: '91150'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '940',
            value: '8373',
            label: 'กำแพง',
            zipcode: '91110'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '940',
            value: '8374',
            label: 'ละงู',
            zipcode: '91110'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '940',
            value: '8375',
            label: 'เขาขาว',
            zipcode: '91110'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '940',
            value: '8376',
            label: 'ปากน้ำ',
            zipcode: '91110'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '940',
            value: '8377',
            label: 'น้ำผุด',
            zipcode: '91110'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '940',
            value: '8378',
            label: 'แหลมสน',
            zipcode: '91110'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '941',
            value: '8379',
            label: 'ทุ่งหว้า',
            zipcode: '91120'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '941',
            value: '8380',
            label: 'นาทอน',
            zipcode: '91120'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '941',
            value: '8381',
            label: 'ขอนคลาน',
            zipcode: '91120'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '941',
            value: '8382',
            label: 'ทุ่งบุหลัง',
            zipcode: '91120'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '941',
            value: '8383',
            label: 'ป่าแก่บ่อหิน',
            zipcode: '91120'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '942',
            value: '8384',
            label: 'ปาล์มพัฒนา',
            zipcode: '91130'
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '942',
            value: '8385',
            label: 'นิคมพัฒนา',
            zipcode: '91130'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8386',
            label: 'ทับเที่ยง',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8389',
            label: 'นาพละ',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8390',
            label: 'บ้านควน',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8391',
            label: 'นาบินหลา',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8392',
            label: 'ควนปริง',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8393',
            label: 'นาโยงใต้',
            zipcode: '92170'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8394',
            label: 'บางรัก',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8395',
            label: 'โคกหล่อ',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8398',
            label: 'นาโต๊ะหมิง',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8399',
            label: 'หนองตรุด',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8400',
            label: 'น้ำผุด',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8402',
            label: 'นาตาล่วง',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8403',
            label: 'บ้านโพธิ์',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8404',
            label: 'นาท่ามเหนือ',
            zipcode: '92190'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8405',
            label: 'นาท่ามใต้',
            zipcode: '92190'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8413',
            label: 'กันตัง',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8414',
            label: 'ควนธานี',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8415',
            label: 'บางหมาก',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8416',
            label: 'บางเป้า',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8417',
            label: 'วังวน',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8418',
            label: 'กันตังใต้',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8419',
            label: 'โคกยาง',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8420',
            label: 'คลองลุ',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8421',
            label: 'ย่านซื่อ',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8422',
            label: 'บ่อน้ำร้อน',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8423',
            label: 'บางสัก',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8424',
            label: 'นาเกลือ',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8425',
            label: 'เกาะลิบง',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '944',
            value: '8426',
            label: 'คลองชีล้อม',
            zipcode: '92110'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '945',
            value: '8427',
            label: 'ย่านตาขาว',
            zipcode: '92140'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '945',
            value: '8428',
            label: 'หนองบ่อ',
            zipcode: '92140'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '945',
            value: '8429',
            label: 'นาชุมเห็ด',
            zipcode: '92140'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '945',
            value: '8430',
            label: 'ในควน',
            zipcode: '92140'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '945',
            value: '8431',
            label: 'โพรงจระเข้',
            zipcode: '92140'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '945',
            value: '8432',
            label: 'ทุ่งกระบือ',
            zipcode: '92140'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '945',
            value: '8433',
            label: 'ทุ่งค่าย',
            zipcode: '92140'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '945',
            value: '8434',
            label: 'เกาะเปียะ',
            zipcode: '92140'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8435',
            label: 'ท่าข้าม',
            zipcode: '92120'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8436',
            label: 'ทุ่งยาว',
            zipcode: '92180'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8437',
            label: 'ปะเหลียน',
            zipcode: '92180'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8438',
            label: 'บางด้วน',
            zipcode: '92140'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8441',
            label: 'บ้านนา',
            zipcode: '92140'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8443',
            label: 'สุโสะ',
            zipcode: '92120'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8444',
            label: 'ลิพัง',
            zipcode: '92180'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8445',
            label: 'เกาะสุกร',
            zipcode: '92120'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8446',
            label: 'ท่าพญา',
            zipcode: '92140'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8447',
            label: 'แหลมสอม',
            zipcode: '92180'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '947',
            value: '8448',
            label: 'บ่อหิน',
            zipcode: '92150'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '947',
            value: '8449',
            label: 'เขาไม้แก้ว',
            zipcode: '92150'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '947',
            value: '8450',
            label: 'กะลาเส',
            zipcode: '92150'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '947',
            value: '8451',
            label: 'ไม้ฝาด',
            zipcode: '92150'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '947',
            value: '8452',
            label: 'นาเมืองเพชร',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8458',
            label: 'ห้วยยอด',
            zipcode: '92130'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8459',
            label: 'หนองช้างแล่น',
            zipcode: '92130'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8462',
            label: 'บางดี',
            zipcode: '92210'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8463',
            label: 'บางกุ้ง',
            zipcode: '92210'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8464',
            label: 'เขากอบ',
            zipcode: '92130'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8465',
            label: 'เขาขาว',
            zipcode: '92130'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8466',
            label: 'เขาปูน',
            zipcode: '92130'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8467',
            label: 'ปากแจ่ม',
            zipcode: '92190'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8468',
            label: 'ปากคม',
            zipcode: '92130'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8471',
            label: 'ท่างิ้ว',
            zipcode: '92130'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8472',
            label: 'ลำภูรา',
            zipcode: '92190'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8473',
            label: 'นาวง',
            zipcode: '92210'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8474',
            label: 'ห้วยนาง',
            zipcode: '92130'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8476',
            label: 'ในเตา',
            zipcode: '92130'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8477',
            label: 'ทุ่งต่อ',
            zipcode: '92130'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8478',
            label: 'วังคีรี',
            zipcode: '92210'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '949',
            value: '8483',
            label: 'เขาวิเศษ',
            zipcode: '92220'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '949',
            value: '8484',
            label: 'วังมะปราง',
            zipcode: '92220'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '949',
            value: '8485',
            label: 'อ่าวตง',
            zipcode: '92220'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '949',
            value: '8486',
            label: 'ท่าสะบ้า',
            zipcode: '92000'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '949',
            value: '8487',
            label: 'วังมะปรางเหนือ',
            zipcode: '92220'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '950',
            value: '8488',
            label: 'นาโยงเหนือ',
            zipcode: '92170'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '950',
            value: '8489',
            label: 'ช่อง',
            zipcode: '92170'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '950',
            value: '8490',
            label: 'ละมอ',
            zipcode: '92170'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '950',
            value: '8491',
            label: 'โคกสะบ้า',
            zipcode: '92170'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '950',
            value: '8492',
            label: 'นาหมื่นศรี',
            zipcode: '92170'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '950',
            value: '8493',
            label: 'นาข้าวเสีย',
            zipcode: '92170'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '951',
            value: '8494',
            label: 'ควนเมา',
            zipcode: '92160'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '951',
            value: '8495',
            label: 'คลองปาง',
            zipcode: '92160'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '951',
            value: '8496',
            label: 'หนองบัว',
            zipcode: '92160'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '951',
            value: '8497',
            label: 'หนองปรือ',
            zipcode: '92130'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '951',
            value: '8498',
            label: 'เขาไพร',
            zipcode: '92160'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '952',
            value: '8499',
            label: 'หาดสำราญ',
            zipcode: '92120'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '952',
            value: '8500',
            label: 'บ้าหวี',
            zipcode: '92120'
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '952',
            value: '8501',
            label: 'ตะเสะ',
            zipcode: '92120'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8502',
            label: 'คูหาสวรรค์',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8504',
            label: 'เขาเจียก',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8505',
            label: 'ท่ามิหรำ',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8506',
            label: 'โคกชะงาย',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8507',
            label: 'นาท่อม',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8508',
            label: 'ปรางหมู่',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8509',
            label: 'ท่าแค',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8510',
            label: 'ลำปำ',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8511',
            label: 'ตำนาน',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8512',
            label: 'ควนมะพร้าว',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8513',
            label: 'ร่มเมือง',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8514',
            label: 'ชัยบุรี',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8515',
            label: 'นาโหนด',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8516',
            label: 'พญาขัน',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '955',
            value: '8520',
            label: 'กงหรา',
            zipcode: '93180'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '955',
            value: '8521',
            label: 'ชะรัด',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '955',
            value: '8522',
            label: 'คลองเฉลิม',
            zipcode: '93180'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '955',
            value: '8523',
            label: 'คลองทรายขาว',
            zipcode: '93180'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '955',
            value: '8524',
            label: 'สมหวัง',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8525',
            label: 'เขาชัยสน',
            zipcode: '93130'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8526',
            label: 'ควนขนุน',
            zipcode: '93130'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8529',
            label: 'จองถนน',
            zipcode: '93130'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8530',
            label: 'หานโพธิ์',
            zipcode: '93130'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8531',
            label: 'โคกม่วง',
            zipcode: '93130'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '957',
            value: '8538',
            label: 'แม่ขรี',
            zipcode: '93160'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '957',
            value: '8539',
            label: 'ตะโหมด',
            zipcode: '93160'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '957',
            value: '8540',
            label: 'คลองใหญ่',
            zipcode: '93160'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8541',
            label: 'ควนขนุน',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8542',
            label: 'ทะเลน้อย',
            zipcode: '93150'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8544',
            label: 'นาขยาด',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8545',
            label: 'พนมวังก์',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8546',
            label: 'แหลมโตนด',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8548',
            label: 'ปันแต',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8549',
            label: 'โตนดด้วน',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8550',
            label: 'ดอนทราย',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8551',
            label: 'มะกอกเหนือ',
            zipcode: '93150'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8552',
            label: 'พนางตุง',
            zipcode: '93150'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8553',
            label: 'ชะมวง',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8556',
            label: 'แพรกหา',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '959',
            value: '8561',
            label: 'ปากพะยูน',
            zipcode: '93120'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '959',
            value: '8562',
            label: 'ดอนประดู่',
            zipcode: '93120'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '959',
            value: '8563',
            label: 'เกาะนางคำ',
            zipcode: '93120'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '959',
            value: '8564',
            label: 'เกาะหมาก',
            zipcode: '93120'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '959',
            value: '8565',
            label: 'ฝาละมี',
            zipcode: '93120'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '959',
            value: '8566',
            label: 'หารเทา',
            zipcode: '93120'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '959',
            value: '8567',
            label: 'ดอนทราย',
            zipcode: '93120'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '960',
            value: '8571',
            label: 'เขาย่า',
            zipcode: '93190'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '960',
            value: '8572',
            label: 'เขาปู่',
            zipcode: '93190'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '960',
            value: '8573',
            label: 'ตะแพน',
            zipcode: '93190'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '961',
            value: '8574',
            label: 'ป่าบอน',
            zipcode: '93170'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '961',
            value: '8575',
            label: 'โคกทราย',
            zipcode: '93170'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '961',
            value: '8576',
            label: 'หนองธง',
            zipcode: '93170'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '961',
            value: '8577',
            label: 'ทุ่งนารี',
            zipcode: '93170'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '961',
            value: '8578',
            label: 'วังใหม่',
            zipcode: '93170'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '962',
            value: '8579',
            label: 'ท่ามะเดื่อ',
            zipcode: '93140'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '962',
            value: '8580',
            label: 'นาปะขอ',
            zipcode: '93140'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '962',
            value: '8581',
            label: 'โคกสัก',
            zipcode: '93140'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '963',
            value: '8582',
            label: 'ป่าพะยอม',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '963',
            value: '8583',
            label: 'ลานข่อย',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '963',
            value: '8584',
            label: 'เกาะเต่า',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '963',
            value: '8585',
            label: 'บ้านพร้าว',
            zipcode: '93110'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '964',
            value: '8586',
            label: 'ชุมพล',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '964',
            value: '8587',
            label: 'บ้านนา',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '964',
            value: '8588',
            label: 'อ่างทอง',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '964',
            value: '8589',
            label: 'ลำสินธุ์',
            zipcode: '93000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8590',
            label: 'สะบารัง',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8591',
            label: 'อาเนาะรู',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8592',
            label: 'จะบังติกอ',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8593',
            label: 'บานา',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8594',
            label: 'ตันหยงลุโละ',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8595',
            label: 'คลองมานิง',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8596',
            label: 'กะมิยอ',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8597',
            label: 'บาราโหม',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8598',
            label: 'ปะกาฮะรัง',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8599',
            label: 'รูสะมิแล',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8600',
            label: 'ตะลุโบะ',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8601',
            label: 'บาราเฮาะ',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '965',
            value: '8602',
            label: 'ปุยุด',
            zipcode: '94000'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8603',
            label: 'โคกโพธิ์',
            zipcode: '94120'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8604',
            label: 'มะกรูด',
            zipcode: '94120'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8605',
            label: 'บางโกระ',
            zipcode: '94120'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8606',
            label: 'ป่าบอน',
            zipcode: '94120'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8607',
            label: 'ทรายขาว',
            zipcode: '94120'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8608',
            label: 'นาประดู่',
            zipcode: '94180'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8609',
            label: 'ปากล่อ',
            zipcode: '94180'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8610',
            label: 'ทุ่งพลา',
            zipcode: '94180'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8613',
            label: 'ท่าเรือ',
            zipcode: '94120'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8615',
            label: 'นาเกตุ',
            zipcode: '94120'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8616',
            label: 'ควนโนรี',
            zipcode: '94180'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8617',
            label: 'ช้างให้ตก',
            zipcode: '94120'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '967',
            value: '8618',
            label: 'เกาะเปาะ',
            zipcode: '94170'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '967',
            value: '8619',
            label: 'คอลอตันหยง',
            zipcode: '94170'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '967',
            value: '8620',
            label: 'ดอนรัก',
            zipcode: '94170'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '967',
            value: '8621',
            label: 'ดาโต๊ะ',
            zipcode: '94170'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '967',
            value: '8622',
            label: 'ตุยง',
            zipcode: '94170'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '967',
            value: '8623',
            label: 'ท่ากำชำ',
            zipcode: '94170'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '967',
            value: '8624',
            label: 'บ่อทอง',
            zipcode: '94170'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '967',
            value: '8625',
            label: 'บางเขา',
            zipcode: '94170'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '967',
            value: '8626',
            label: 'บางตาวา',
            zipcode: '94170'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '967',
            value: '8627',
            label: 'ปุโละปุโย',
            zipcode: '94170'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '967',
            value: '8628',
            label: 'ยาบี',
            zipcode: '94170'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '967',
            value: '8629',
            label: 'ลิปะสะโง',
            zipcode: '94170'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '968',
            value: '8630',
            label: 'ปะนาเระ',
            zipcode: '94130'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '968',
            value: '8631',
            label: 'ท่าข้าม',
            zipcode: '94130'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '968',
            value: '8632',
            label: 'บ้านนอก',
            zipcode: '94130'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '968',
            value: '8633',
            label: 'ดอน',
            zipcode: '94130'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '968',
            value: '8634',
            label: 'ควน',
            zipcode: '94190'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '968',
            value: '8635',
            label: 'ท่าน้ำ',
            zipcode: '94130'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '968',
            value: '8636',
            label: 'คอกกระบือ',
            zipcode: '94130'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '968',
            value: '8637',
            label: 'พ่อมิ่ง',
            zipcode: '94130'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '968',
            value: '8638',
            label: 'บ้านกลาง',
            zipcode: '94130'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '968',
            value: '8639',
            label: 'บ้านน้ำบ่อ',
            zipcode: '94130'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8640',
            label: 'มายอ',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8641',
            label: 'ถนน',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8642',
            label: 'ตรัง',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8643',
            label: 'กระหวะ',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8644',
            label: 'ลุโบะยิไร',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8645',
            label: 'ลางา',
            zipcode: '94190'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8646',
            label: 'กระเสาะ',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8647',
            label: 'เกาะจัน',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8648',
            label: 'ปะโด',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8649',
            label: 'สาคอบน',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8650',
            label: 'สาคอใต้',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8651',
            label: 'สะกำ',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '969',
            value: '8652',
            label: 'ปานัน',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '970',
            value: '8653',
            label: 'ตะโละแมะนา',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '970',
            value: '8654',
            label: 'พิเทน',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '970',
            value: '8655',
            label: 'น้ำดำ',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '970',
            value: '8656',
            label: 'ปากู',
            zipcode: '94140'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '971',
            value: '8657',
            label: 'ตะลุบัน',
            zipcode: '94110'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '971',
            value: '8658',
            label: 'ตะบิ้ง',
            zipcode: '94110'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '971',
            value: '8659',
            label: 'ปะเสยะวอ',
            zipcode: '94110'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '971',
            value: '8660',
            label: 'บางเก่า',
            zipcode: '94110'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '971',
            value: '8661',
            label: 'บือเระ',
            zipcode: '94110'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '971',
            value: '8662',
            label: 'เตราะบอน',
            zipcode: '94110'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '971',
            value: '8663',
            label: 'กะดุนง',
            zipcode: '94110'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '971',
            value: '8664',
            label: 'ละหาร',
            zipcode: '94110'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '971',
            value: '8665',
            label: 'มะนังดาลำ',
            zipcode: '94110'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '971',
            value: '8666',
            label: 'แป้น',
            zipcode: '94110'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '971',
            value: '8667',
            label: 'ทุ่งคล้า',
            zipcode: '94190'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '972',
            value: '8668',
            label: 'ไทรทอง',
            zipcode: '94220'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '972',
            value: '8669',
            label: 'ไม้แก่น',
            zipcode: '94220'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '972',
            value: '8670',
            label: 'ตะโละไกรทอง',
            zipcode: '94220'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '972',
            value: '8671',
            label: 'ดอนทราย',
            zipcode: '94220'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8672',
            label: 'ตะโละ',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8673',
            label: 'ตะโละกาโปร์',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8674',
            label: 'ตันหยงดาลอ',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8675',
            label: 'ตันหยงจึงงา',
            zipcode: '94190'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8676',
            label: 'ตอหลัง',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8677',
            label: 'ตาแกะ',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8678',
            label: 'ตาลีอายร์',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8679',
            label: 'ยามู',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8680',
            label: 'บางปู',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8681',
            label: 'หนองแรต',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8682',
            label: 'ปิยามุมัง',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8683',
            label: 'ปุลากง',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8684',
            label: 'บาโลย',
            zipcode: '94190'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8685',
            label: 'สาบัน',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8686',
            label: 'มะนังยง',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8687',
            label: 'ราตาปันยัง',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8688',
            label: 'จะรัง',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '973',
            value: '8689',
            label: 'แหลมโพธิ์',
            zipcode: '94150'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '974',
            value: '8690',
            label: 'ยะรัง',
            zipcode: '94160'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '974',
            value: '8691',
            label: 'สะดาวา',
            zipcode: '94160'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '974',
            value: '8692',
            label: 'ประจัน',
            zipcode: '94160'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '974',
            value: '8693',
            label: 'สะนอ',
            zipcode: '94160'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '974',
            value: '8694',
            label: 'ระแว้ง',
            zipcode: '94160'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '974',
            value: '8695',
            label: 'ปิตูมุดี',
            zipcode: '94160'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '974',
            value: '8696',
            label: 'วัด',
            zipcode: '94160'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '974',
            value: '8697',
            label: 'กระโด',
            zipcode: '94160'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '974',
            value: '8698',
            label: 'คลองใหม่',
            zipcode: '94160'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '974',
            value: '8699',
            label: 'เมาะมาวี',
            zipcode: '94160'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '974',
            value: '8700',
            label: 'กอลำ',
            zipcode: '94160'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '974',
            value: '8701',
            label: 'เขาตูม',
            zipcode: '94160'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '975',
            value: '8702',
            label: 'กะรุบี',
            zipcode: '94230'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '975',
            value: '8703',
            label: 'ตะโละดือรามัน',
            zipcode: '94230'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '975',
            value: '8704',
            label: 'ปล่องหอย',
            zipcode: '94230'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '976',
            value: '8705',
            label: 'แม่ลาน',
            zipcode: '94180'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '976',
            value: '8706',
            label: 'ม่วงเตี้ย',
            zipcode: '94180'
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '976',
            value: '8707',
            label: 'ป่าไร่',
            zipcode: '94180'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8708',
            label: 'สะเตง',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8709',
            label: 'บุดี',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8710',
            label: 'ยุโป',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8711',
            label: 'ลิดล',
            zipcode: '95160'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8713',
            label: 'ยะลา',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8715',
            label: 'ท่าสาป',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8716',
            label: 'ลำใหม่',
            zipcode: '95160'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8717',
            label: 'หน้าถ้ำ',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8718',
            label: 'ลำพะยา',
            zipcode: '95160'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8719',
            label: 'เปาะเส้ง',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8721',
            label: 'พร่อน',
            zipcode: '95160'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8722',
            label: 'บันนังสาเรง',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8723',
            label: 'สะเตงนอก',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8725',
            label: 'ตาเซะ',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '978',
            value: '8726',
            label: 'เบตง',
            zipcode: '95110'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '978',
            value: '8727',
            label: 'ยะรม',
            zipcode: '95110'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '978',
            value: '8728',
            label: 'ตาเนาะแมเราะ',
            zipcode: '95110'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '978',
            value: '8729',
            label: 'อัยเยอร์เวง',
            zipcode: '95110'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '978',
            value: '8730',
            label: 'ธารน้ำทิพย์',
            zipcode: '95110'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '979',
            value: '8731',
            label: 'บันนังสตา',
            zipcode: '95130'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '979',
            value: '8732',
            label: 'บาเจาะ',
            zipcode: '95130'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '979',
            value: '8733',
            label: 'ตาเนาะปูเต๊ะ',
            zipcode: '95130'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '979',
            value: '8734',
            label: 'ถ้ำทะลุ',
            zipcode: '95130'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '979',
            value: '8735',
            label: 'ตลิ่งชัน',
            zipcode: '95130'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '979',
            value: '8736',
            label: 'เขื่อนบางลาง',
            zipcode: '95130'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '980',
            value: '8740',
            label: 'ธารโต',
            zipcode: '95150'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '980',
            value: '8741',
            label: 'บ้านแหร',
            zipcode: '95150'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '980',
            value: '8742',
            label: 'แม่หวาด',
            zipcode: '95170'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '980',
            value: '8743',
            label: 'คีรีเขต',
            zipcode: '95150'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '981',
            value: '8744',
            label: 'ยะหา',
            zipcode: '95120'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '981',
            value: '8745',
            label: 'ละแอ',
            zipcode: '95120'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '981',
            value: '8746',
            label: 'ปะแต',
            zipcode: '95120'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '981',
            value: '8747',
            label: 'บาโร๊ะ',
            zipcode: '95120'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '981',
            value: '8749',
            label: 'ตาชี',
            zipcode: '95120'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '981',
            value: '8750',
            label: 'บาโงยซิแน',
            zipcode: '95120'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '981',
            value: '8751',
            label: 'กาตอง',
            zipcode: '95120'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8754',
            label: 'กายูบอเกาะ',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8755',
            label: 'กาลูปัง',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8756',
            label: 'กาลอ',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8757',
            label: 'กอตอตือร๊ะ',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8758',
            label: 'โกตาบารู',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8759',
            label: 'เกะรอ',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8760',
            label: 'จะกว๊ะ',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8761',
            label: 'ท่าธง',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8762',
            label: 'เนินงาม',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8763',
            label: 'บาลอ',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8764',
            label: 'บาโงย',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8765',
            label: 'บือมัง',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8766',
            label: 'ยะต๊ะ',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8767',
            label: 'วังพญา',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8768',
            label: 'อาซ่อง',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '982',
            value: '8769',
            label: 'ตะโล๊ะหะลอ',
            zipcode: '95140'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '983',
            value: '8770',
            label: 'กาบัง',
            zipcode: '95120'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '983',
            value: '8771',
            label: 'บาละ',
            zipcode: '95120'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '984',
            value: '8772',
            label: 'กรงปินัง',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '984',
            value: '8773',
            label: 'สะเอะ',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '984',
            value: '8774',
            label: 'ห้วยกระทิง',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '984',
            value: '8775',
            label: 'ปุโรง',
            zipcode: '95000'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '985',
            value: '8776',
            label: 'บางนาค',
            zipcode: '96000'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '985',
            value: '8777',
            label: 'ลำภู',
            zipcode: '96000'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '985',
            value: '8778',
            label: 'มะนังตายอ',
            zipcode: '96000'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '985',
            value: '8779',
            label: 'บางปอ',
            zipcode: '96000'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '985',
            value: '8780',
            label: 'กะลุวอ',
            zipcode: '96000'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '985',
            value: '8781',
            label: 'กะลุวอเหนือ',
            zipcode: '96000'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '985',
            value: '8782',
            label: 'โคกเคียน',
            zipcode: '96000'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '986',
            value: '8783',
            label: 'เจ๊ะเห',
            zipcode: '96110'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '986',
            value: '8784',
            label: 'ไพรวัน',
            zipcode: '96110'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '986',
            value: '8785',
            label: 'พร่อน',
            zipcode: '96110'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '986',
            value: '8786',
            label: 'ศาลาใหม่',
            zipcode: '96110'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '986',
            value: '8787',
            label: 'บางขุนทอง',
            zipcode: '96110'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '986',
            value: '8788',
            label: 'เกาะสะท้อน',
            zipcode: '96110'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '986',
            value: '8789',
            label: 'นานาค',
            zipcode: '96110'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '986',
            value: '8790',
            label: 'โฆษิต',
            zipcode: '96110'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '987',
            value: '8791',
            label: 'บาเจาะ',
            zipcode: '96170'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '987',
            value: '8792',
            label: 'ลุโบะสาวอ',
            zipcode: '96170'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '987',
            value: '8793',
            label: 'กาเยาะมาตี',
            zipcode: '96170'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '987',
            value: '8794',
            label: 'ปะลุกาสาเมาะ',
            zipcode: '96170'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '987',
            value: '8795',
            label: 'บาเระเหนือ',
            zipcode: '96170'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '987',
            value: '8796',
            label: 'บาเระใต้',
            zipcode: '96170'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '988',
            value: '8797',
            label: 'ยี่งอ',
            zipcode: '96180'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '988',
            value: '8798',
            label: 'ละหาร',
            zipcode: '96180'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '988',
            value: '8799',
            label: 'จอเบาะ',
            zipcode: '96180'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '988',
            value: '8800',
            label: 'ลุโบะบายะ',
            zipcode: '96180'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '988',
            value: '8801',
            label: 'ลุโบะบือซา',
            zipcode: '96180'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '988',
            value: '8802',
            label: 'ตะปอเยาะ',
            zipcode: '96180'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '989',
            value: '8803',
            label: 'ตันหยงมัส',
            zipcode: '96130'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '989',
            value: '8804',
            label: 'ตันหยงลิมอ',
            zipcode: '96130'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '989',
            value: '8808',
            label: 'บองอ',
            zipcode: '96220'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '989',
            value: '8809',
            label: 'กาลิซา',
            zipcode: '96130'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '989',
            value: '8810',
            label: 'บาโงสะโต',
            zipcode: '96130'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '989',
            value: '8811',
            label: 'เฉลิม',
            zipcode: '96130'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '989',
            value: '8812',
            label: 'มะรือโบตก',
            zipcode: '96130'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '990',
            value: '8815',
            label: 'รือเสาะ',
            zipcode: '96150'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '990',
            value: '8816',
            label: 'สาวอ',
            zipcode: '96150'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '990',
            value: '8817',
            label: 'เรียง',
            zipcode: '96150'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '990',
            value: '8818',
            label: 'สามัคคี',
            zipcode: '96150'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '990',
            value: '8819',
            label: 'บาตง',
            zipcode: '96150'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '990',
            value: '8820',
            label: 'ลาโละ',
            zipcode: '96150'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '990',
            value: '8821',
            label: 'รือเสาะออก',
            zipcode: '96150'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '990',
            value: '8822',
            label: 'โคกสะตอ',
            zipcode: '96150'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '990',
            value: '8823',
            label: 'สุวารี',
            zipcode: '96150'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '991',
            value: '8826',
            label: 'ซากอ',
            zipcode: '96210'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '991',
            value: '8827',
            label: 'ตะมะยูง',
            zipcode: '96210'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '991',
            value: '8828',
            label: 'ศรีสาคร',
            zipcode: '96210'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '991',
            value: '8829',
            label: 'เชิงคีรี',
            zipcode: '96210'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '991',
            value: '8830',
            label: 'กาหลง',
            zipcode: '96210'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '991',
            value: '8831',
            label: 'ศรีบรรพต',
            zipcode: '96210'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '992',
            value: '8832',
            label: 'แว้ง',
            zipcode: '96160'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '992',
            value: '8833',
            label: 'กายูคละ',
            zipcode: '96160'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '992',
            value: '8834',
            label: 'ฆอเลาะ',
            zipcode: '96160'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '992',
            value: '8835',
            label: 'โละจูด',
            zipcode: '96160'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '992',
            value: '8836',
            label: 'แม่ดง',
            zipcode: '96160'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '992',
            value: '8837',
            label: 'เอราวัณ',
            zipcode: '96160'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '993',
            value: '8839',
            label: 'มาโมง',
            zipcode: '96190'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '993',
            value: '8840',
            label: 'สุคิริน',
            zipcode: '96190'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '993',
            value: '8841',
            label: 'เกียร์',
            zipcode: '96190'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '993',
            value: '8842',
            label: 'ภูเขาทอง',
            zipcode: '96190'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '993',
            value: '8843',
            label: 'ร่มไทร',
            zipcode: '96190'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '994',
            value: '8844',
            label: 'สุไหงโก-ลก',
            zipcode: '96120'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '994',
            value: '8845',
            label: 'ปาเสมัส',
            zipcode: '96120'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '994',
            value: '8846',
            label: 'มูโนะ',
            zipcode: '96120'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '994',
            value: '8847',
            label: 'ปูโยะ',
            zipcode: '96120'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '995',
            value: '8848',
            label: 'ปะลุรู',
            zipcode: '96140'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '995',
            value: '8849',
            label: 'สุไหงปาดี',
            zipcode: '96140'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '995',
            value: '8850',
            label: 'โต๊ะเด็ง',
            zipcode: '96140'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '995',
            value: '8851',
            label: 'สากอ',
            zipcode: '96140'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '995',
            value: '8852',
            label: 'ริโก๋',
            zipcode: '96140'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '995',
            value: '8853',
            label: 'กาวะ',
            zipcode: '96140'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '996',
            value: '8854',
            label: 'จะแนะ',
            zipcode: '96220'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '996',
            value: '8855',
            label: 'ดุซงญอ',
            zipcode: '96220'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '996',
            value: '8856',
            label: 'ผดุงมาตร',
            zipcode: '96220'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '996',
            value: '8857',
            label: 'ช้างเผือก',
            zipcode: '96220'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '997',
            value: '8858',
            label: 'จวบ',
            zipcode: '96130'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '997',
            value: '8859',
            label: 'บูกิต',
            zipcode: '96130'
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '997',
            value: '8860',
            label: 'มะรือโบออก',
            zipcode: '96130'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8861',
            label: 'คำนาดี',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8862',
            label: 'บึงโขงหลง',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8863',
            label: 'ไคสี',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8864',
            label: 'ชัยพร',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8865',
            label: 'นาสวรรค์',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8865',
            label: 'นาสวรรค์',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8866',
            label: 'โนนสมบูรณ์',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8867',
            label: 'บึงกาฬ',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8868',
            label: 'โป่งเปื่อย',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8869',
            label: 'วิศิษฐ์',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8870',
            label: 'หนองเข็ง',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8871',
            label: 'หนองเลิง',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '999',
            value: '8872',
            label: 'หอคำ',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1000',
            value: '8873',
            label: 'ซาง',
            zipcode: '38150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1000',
            value: '8874',
            label: 'เซกา',
            zipcode: '38150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1000',
            value: '8875',
            label: 'ท่ากกแดง',
            zipcode: '38150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1000',
            value: '8876',
            label: 'ท่าสะอาด',
            zipcode: '38150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1000',
            value: '8877',
            label: 'น้ำจั้น',
            zipcode: '38150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1000',
            value: '8878',
            label: 'บ้านต้อง',
            zipcode: '38150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1000',
            value: '8879',
            label: 'ป่งไฮ',
            zipcode: '38150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1000',
            value: '8880',
            label: 'โสกก่าม',
            zipcode: '38150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1000',
            value: '8881',
            label: 'หนองทุ่ม',
            zipcode: '38150'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1001',
            value: '8882',
            label: 'คำแก้ว',
            zipcode: '38170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1001',
            value: '8883',
            label: 'โซ่',
            zipcode: '38170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1001',
            value: '8884',
            label: 'ถ้ำเจริญ',
            zipcode: '38170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1001',
            value: '8885',
            label: 'บัวตูม',
            zipcode: '38170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1001',
            value: '8886',
            label: 'ศรีชมภู',
            zipcode: '38170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1001',
            value: '8887',
            label: 'หนองพันทา',
            zipcode: '38170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1001',
            value: '8888',
            label: 'เหล่าทอง',
            zipcode: '38170'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1002',
            value: '8889',
            label: 'ดอนหญ้านาง',
            zipcode: '38180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1002',
            value: '8890',
            label: 'ป่าแฝก',
            zipcode: '38180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1002',
            value: '8891',
            label: 'พรเจริญ',
            zipcode: '38180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1002',
            value: '8892',
            label: 'วังชมภู',
            zipcode: '38180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1002',
            value: '8893',
            label: 'ศรีชมภู',
            zipcode: '38180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1002',
            value: '8894',
            label: 'ศรีสำราญ',
            zipcode: '38180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1002',
            value: '8895',
            label: 'หนองหัวช้าง',
            zipcode: '38180'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1003',
            value: '8896',
            label: 'ชุมภูพร',
            zipcode: '38210'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1003',
            value: '8897',
            label: 'นาสะแบง',
            zipcode: '38210'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1003',
            value: '8898',
            label: 'นาสิงห์',
            zipcode: '38210'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1003',
            value: '8899',
            label: 'นาแสง',
            zipcode: '38210'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1003',
            value: '8900',
            label: 'ศรีวิไล',
            zipcode: '38210'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1004',
            value: '8901',
            label: 'ดงบัง',
            zipcode: '38220'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1004',
            value: '8902',
            label: 'ท่าดอกคำ',
            zipcode: '38220'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1004',
            value: '8903',
            label: 'บึงโขงหลง',
            zipcode: '38220'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1004',
            value: '8904',
            label: 'โพธิ์หมากแข้ง',
            zipcode: '38220'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1005',
            value: '8905',
            label: 'นากั้ง',
            zipcode: '38190'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1005',
            value: '8906',
            label: 'นาดง',
            zipcode: '38190'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1005',
            value: '8907',
            label: 'โนนศิลา',
            zipcode: '38190'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1005',
            value: '8908',
            label: 'ปากคาด',
            zipcode: '38190'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1005',
            value: '8909',
            label: 'สมสนุก',
            zipcode: '38190'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1005',
            value: '8910',
            label: 'หนองยอง',
            zipcode: '38190'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1006',
            value: '8911',
            label: 'โคกกว้าง',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1006',
            value: '8912',
            label: 'บุ่งคล้า',
            zipcode: '38000'
        },
        {
            geoid: '3',
            provinceid: '77',
            districtid: '1006',
            value: '8913',
            label: 'หนองเดิน',
            zipcode: '38000'
        },
        {
            geoid: '6',
            provinceid: '67',
            districtid: '900',
            value: '8059',
            label: 'คลองไทร',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '907',
            value: '8087',
            label: 'นาคา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '68',
            districtid: '907',
            value: '8088',
            label: 'กำพวน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8174',
            label: 'ชิงโค',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8175',
            label: 'สทิงหม้อ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8176',
            label: 'ทำนบ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8177',
            label: 'รำแดง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8178',
            label: 'วัดขนุน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8179',
            label: 'ชะแล้',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8180',
            label: 'ปากรอ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8181',
            label: 'ป่าขาด',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8182',
            label: 'หัวเขา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8183',
            label: 'บางเขียด',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8184',
            label: 'ม่วงงาม',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8185',
            label: 'ปากรอ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8186',
            label: 'ทำนบ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8187',
            label: 'ชลเจริญ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8188',
            label: 'ม่วงงาม',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8189',
            label: 'หัวเขา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8190',
            label: 'ชะแล้',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8191',
            label: 'วัดขนุน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8192',
            label: 'สทิงหม้อ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8193',
            label: 'บางเขียด',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8194',
            label: 'ป่าขาด',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8195',
            label: 'รำแดง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '918',
            value: '8196',
            label: 'ชิงโค',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8260',
            label: 'เชิงแส',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8261',
            label: 'โรง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '924',
            value: '8262',
            label: 'เกาะใหญ่',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8271',
            label: 'ควนโส',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8272',
            label: 'รัตภูมิ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8273',
            label: 'บางเหรียง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8274',
            label: 'ห้วยลึก',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8276',
            label: 'บางเหรี่ยง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8277',
            label: 'ห้วยลึก',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8278',
            label: 'ควนโส',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '926',
            value: '8279',
            label: 'รัตภูมิ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8294',
            label: 'คลองหอยโข่ง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8297',
            label: 'ทุ่งลาน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8298',
            label: 'ท่าช้าง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8303',
            label: 'บางกล่ำ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8305',
            label: 'บ้านหาร',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8307',
            label: 'แม่ทอม',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8308',
            label: 'โคกม่วง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8309',
            label: 'ทุ่งลาน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8310',
            label: 'คลองหอยโข่ง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8311',
            label: 'บ้านหาร',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8312',
            label: 'แม่ทอม',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8313',
            label: 'ท่าช้าง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8314',
            label: 'บางกล่ำ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8315',
            label: 'คลองหรัง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8316',
            label: 'ทุ่งขมิ้น',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8317',
            label: 'พิจิตร',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '928',
            value: '8318',
            label: 'นาหม่อม',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '70',
            districtid: '934',
            value: '8346',
            label: 'สำนักขาม',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '936',
            value: '8359',
            label: 'ท่าแพ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '938',
            value: '8367',
            label: 'นิคมพัฒนา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '71',
            districtid: '938',
            value: '8368',
            label: 'ปาล์มพัฒนา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8387',
            label: 'โคกสะบ้า',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8388',
            label: 'ละมอ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8396',
            label: 'นาข้าวเสีย',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8397',
            label: 'นาหมื่นศรี',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8401',
            label: 'นาโยงเหนือ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8406',
            label: 'ช่อง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8407',
            label: 'นาข้าวเสีย',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8408',
            label: 'โคกสะบ้า',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8409',
            label: 'ละมอ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8410',
            label: 'นาหมื่นศรี',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8411',
            label: 'ช่อง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '943',
            value: '8412',
            label: 'นาโยงเหนือ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8439',
            label: 'หาดสำราญ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8440',
            label: 'ตะเสะ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '946',
            value: '8442',
            label: 'บ้าหวี',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '947',
            value: '8453',
            label: 'ท่าสะบ้า',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '947',
            value: '8454',
            label: 'สิเกา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '947',
            value: '8455',
            label: 'อ่าวตง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '947',
            value: '8456',
            label: 'วังมะปราง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '947',
            value: '8457',
            label: 'เขาวิเศษ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8460',
            label: 'หนองปรือ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8461',
            label: 'หนองบัว',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8469',
            label: 'คลองปาง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8470',
            label: 'ควนเมา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8475',
            label: 'เขาไพร',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8479',
            label: 'หนองปรือ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8480',
            label: 'หนองบัว',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8481',
            label: 'คลองปาง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '72',
            districtid: '948',
            value: '8482',
            label: 'ควนเมา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8503',
            label: 'บ้านนา',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8517',
            label: 'ลำสินธุ์',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8518',
            label: 'อ่างทอง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '954',
            value: '8519',
            label: 'ชุมพล',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8527',
            label: 'ท่ามะเดื่อ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8528',
            label: 'นาปะขอ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8532',
            label: 'โคกสัก',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8533',
            label: 'นาปะขอ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8534',
            label: 'คลองใหญ่',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8535',
            label: 'ตะโหมด',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8536',
            label: 'ท่ามะเดื่อ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '956',
            value: '8537',
            label: 'แม่ขรี',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8543',
            label: 'เกาะเต่า',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8547',
            label: 'ป่าพะยอม',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8554',
            label: 'บ้านพร้าว',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8555',
            label: 'ลานข่อย',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8557',
            label: 'คำไผ่',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8558',
            label: 'คำเตย',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8559',
            label: 'ส้มผ่อ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '958',
            value: '8560',
            label: 'ป่าพะยอม',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '959',
            value: '8568',
            label: 'หนองแซง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '959',
            value: '8569',
            label: 'โคกทราย',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '73',
            districtid: '959',
            value: '8570',
            label: 'ป่าบอน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8611',
            label: 'แม่ลาน',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8612',
            label: 'ป่าไร่',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '74',
            districtid: '966',
            value: '8614',
            label: 'ม่วงเตี้ย',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8712',
            label: 'ปุโรง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8714',
            label: 'สะเอะ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8720',
            label: 'กรงปินัง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '977',
            value: '8724',
            label: 'ห้วยกระทิง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '979',
            value: '8737',
            label: 'แม่หวาด',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '979',
            value: '8738',
            label: 'บ้านแหร',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '979',
            value: '8739',
            label: 'ธารโต',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '981',
            value: '8748',
            label: 'กาบัง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '981',
            value: '8752',
            label: 'บาละ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '75',
            districtid: '981',
            value: '8753',
            label: 'กาบัง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '989',
            value: '8805',
            label: 'จวบ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '989',
            value: '8806',
            label: 'มะรือโบตะวันออก',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '989',
            value: '8807',
            label: 'บูกิต',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '989',
            value: '8813',
            label: 'ดุซงญอ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '989',
            value: '8814',
            label: 'จะแนะ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '990',
            value: '8824',
            label: 'ตะมะยูง',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '990',
            value: '8825',
            label: 'ซากอ',
            zipcode: ''
        },
        {
            geoid: '6',
            provinceid: '76',
            districtid: '992',
            value: '8838',
            label: 'มาโม',
            zipcode: ''
        }
    ]
};
export default initaddress;
