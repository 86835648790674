/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';

export function AsideMenuList({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url) ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open ` : '';
    };

    return (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            <li className={`menu-item ${getMenuItemActive('/dashboard', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/dashboard'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ภาพรวม' src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
                    </span>
                    <span className='menu-text'>ภาพรวม</span>
                </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            <li className={`menu-item ${getMenuItemActive('/member', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/member'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ผู้ใช้งานระบบ' src={toAbsoluteUrl('/media/svg/icons/General/User.svg')} />
                    </span>
                    <span className='menu-text'>ผู้ใช้งานระบบ</span>
                </NavLink>
            </li>
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/funeral', true)}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
            >
                <NavLink className='menu-link menu-toggle' to='/funeral'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ฌาปนกิจ' src={toAbsoluteUrl('/media/svg/icons/Communication/Shield-user.svg')} />
                    </span>
                    <span className='menu-text'>ฌาปนกิจ</span>
                    <i className='menu-arrow' />
                </NavLink>
                <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                        <li className='menu-item menu-item-parent' aria-haspopup='true'>
                            <span className='menu-link'>
                                <span className='menu-text'>ฌาปนกิจ</span>
                            </span>
                        </li>

                        <li className={`menu-item ${getMenuItemActive('/funeral/allowance')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/funeral/allowance'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='หักเงินสงเคราะห์'
                                        src={toAbsoluteUrl('/media/svg/icons/Communication/Share.svg')}
                                    />
                                </span>
                                <span className='menu-text'>หักเงินสงเคราะห์</span>
                            </NavLink>
                        </li>

                        <li className={`menu-item ${getMenuItemActive('/funeral/approve')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/funeral/approve'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='รายชื่อผู้ต้องการเป็นสมาชิก'
                                        src={toAbsoluteUrl('/media/svg/icons/Communication/Adress-book2.svg')}
                                    />
                                </span>
                                <span className='menu-text'>รายชื่อผู้ต้องการเป็นสมาชิก</span>
                            </NavLink>
                        </li>

                        <li className={`menu-item ${getMenuItemActive('/cremation/qrcode')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/cremation/qrcode'>
                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='สมัครสมาชิก'
                                        src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
                                    />
                                </span>
                                <span className='menu-text'>สมัครสมาชิก</span>
                                <span className='menu-label'>
                                    <span className='label label-warning label-inline'>รอการชำระเงิน</span>
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </li> */}

            {/*begin::1 Level*/}
            {/* <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/report', true)}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
            >
                <NavLink className='menu-link menu-toggle' to='/report'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ฌาปนกิจ' src={toAbsoluteUrl('/media/svg/icons/Communication/Clipboard-list.svg')} />
                    </span>
                    <span className='menu-text'>รายงาน</span>
                    <i className='menu-arrow' />
                </NavLink>
                <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                        <li className='menu-item menu-item-parent' aria-haspopup='true'>
                            <span className='menu-link'>
                                <span className='menu-text'>รายงาน</span>
                            </span>
                        </li>

                        <li className={`menu-item ${getMenuItemActive('/report/management')}`} aria-haspopup='true'>
                            <NavLink className='menu-link' to='/report/management'>
                                <i className='menu-bullet menu-bullet-dot'>
                                    <span />
                                </i>

                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='สมาชิกสหกรณ์'
                                        src={toAbsoluteUrl('/media/svg/icons/Communication/Share.svg')}
                                    />
                                </span>
                                <span className='menu-text'>จัดการรายการ</span>
                            </NavLink>
                        </li>

                        <li
                            className={`menu-item ${getMenuItemActive('/report/cooperativemember')}`}
                            aria-haspopup='true'
                        >
                            <NavLink className='menu-link' to='/report/cooperativemember'>
                                <i className='menu-bullet menu-bullet-dot'>
                                    <span />
                                </i>

                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='สมาชิกสหกรณ์'
                                        src={toAbsoluteUrl('/media/svg/icons/Communication/Share.svg')}
                                    />
                                </span>
                                <span className='menu-text'>สมาชิกสหกรณ์</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </li> */}

            <li
                className={`menu-item menu-item-submenu ${getMenuItemActive('/setting', true)}`}
                aria-haspopup='true'
                data-menu-toggle='hover'
            >
                <NavLink className='menu-link menu-toggle' to='/setting'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ตั้งค่า' src={toAbsoluteUrl('/media/svg/icons/General/Settings-2.svg')} />
                    </span>
                    <span className='menu-text'>ตั้งค่า</span>
                    <i className='menu-arrow' />
                </NavLink>
                <div className='menu-submenu '>
                    <i className='menu-arrow' />
                    <ul className='menu-subnav'>
                        <li className='menu-item menu-item-parent' aria-haspopup='true'>
                            <span className='menu-link'>
                                <span className='menu-text'>ตั้งค่า</span>
                            </span>
                        </li>

                        <li
                            className={`menu-item ${getMenuItemActive('/setting/changepassword')}`}
                            aria-haspopup='true'
                        >
                            <NavLink className='menu-link' to='/setting/changepassword'>
                                {/* <i className='menu-bullet menu-bullet-dot'>
                                    <span />
                                </i> */}

                                <span className='svg-icon menu-icon'>
                                    <SVG
                                        title='เปลี่ยนรหัสผ่าน'
                                        src={toAbsoluteUrl('/media/svg/icons/General/Shield-protected.svg')}
                                    />
                                </span>
                                <span className='menu-text'>เปลี่ยนรหัสผ่าน</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </li>

            {/* <div className='separator separator-solid separator-secondary separator-border-3 my-5' /> */}

            <br />

            {/*begin::1 Level*/}
            <li className={`menu-item ${getMenuItemActive('/logout', false)}`} aria-haspopup='true'>
                <NavLink className='menu-link' to='/logout'>
                    <span className='svg-icon menu-icon'>
                        <SVG title='ออกจากระบบ' src={toAbsoluteUrl('/media/svg/icons/Electric/Shutdown.svg')} />
                    </span>
                    <span className='menu-text'>ออกจากระบบ</span>
                </NavLink>
            </li>
            {/*end::1 Level*/}
        </ul>
    );
}
