import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { MemberSearchResult, MemberDetail } from './components';

import config from '../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function MemberMain() {
    const { info } = useSelector((state) => state.auth);

    const [ page, setPage ] = useState('main');
    const [ tab, setTab ] = useState('information');
    const [ loading, setLoading ] = useState(false);
    const [ keyword, setKeyword ] = useState('');
    const [ response, setResponse ] = useState({});
    const [ userinfo, setUserinfo ] = useState({});
    const [ order, setOrder ] = useState({});

    const btnSearch = () => {
        if (keyword === '') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณากรอกรายละเอียดที่ต้องการค้นหาให้เรียบร้อย',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        setLoading(true);
        fetch(config.url + 'admin/member/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.token,
                search: keyword,
                group_id: '2'
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResponse(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const btnView = (userid) => {
        setLoading(true);
        fetch(config.url + 'admin/member/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.token,
                id: userid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setUserinfo(result);
                    setPage('detail');
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const btnUpdate = (userid, param) => {
        setLoading(true);
        fetch(config.url + 'admin/member/update', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.token,
                id: userid.toString(),
                update: param
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getOrder = (userid, type) => {
        setLoading(true);
        fetch(config.url + 'admin/order/list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.token,
                user_id: userid,
                account_type: type
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (type === '1') {
                    setOrder(result);
                    // if (result.responsecode === 0) {
                    //     setOrderlist(result.orders);
                    // }
                    setTab('stock');
                } else if (type === '2') {
                    setOrder(result);
                    // if (result.responsecode === 0) {
                    //     setOrderlist(result.orders);
                    // }
                    setTab('funeral');
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    return (
        <div className='member-page'>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {page === 'main' ? (
                        <div className='row main-page'>
                            <div className='col-12'>
                                <div className='card rounded-lg'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-12 p-0'>
                                                <div className='form-group mb-0'>
                                                    <label>กรุณากรอกรายละเอียดที่ต้องการค้นหา</label>
                                                    <div className='input-group'>
                                                        <input
                                                            type='text'
                                                            className='form-control rounded-lg rounded-right-0'
                                                            value={keyword}
                                                            onChange={(e) => setKeyword(e.target.value)}
                                                            onKeyPress={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    btnSearch();
                                                                }
                                                            }}
                                                        />
                                                        <div
                                                            className='input-group-append cursor-pointer'
                                                            onClick={btnSearch}
                                                        >
                                                            <span className='input-group-text rounded-lg rounded-left-0'>
                                                                <span className='fa fa-search' />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* {Object.keys(response).length > 0 && ( */}
                            <MemberSearchResult response={response} btnView={btnView} />
                            {/* )} */}
                        </div>
                    ) : (
                        <div className='row detail-page'>
                            <MemberDetail
                                setPage={setPage}
                                userinfo={userinfo}
                                setUserinfo={setUserinfo}
                                order={order}
                                setOrder={setOrder}
                                btnUpdate={btnUpdate}
                                getOrder={getOrder}
                                tab={tab}
                                setTab={setTab}
                                // orderlist={orderlist}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
