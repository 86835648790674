import React from 'react';

import { ReportManagementSearchbar } from './components';

export function ReportManagementMain() {
    return (
        <div className='row m-0'>
            <ReportManagementSearchbar />
        </div>
    );
}
