import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import NumberFormat from 'react-number-format';

import Moment from 'moment';

import 'moment/locale/th';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import initaddress from '../../../../../address';

import config from '../../../../../config';

export function MemberInformation(props) {
    const { info } = useSelector((state) => state.auth);

    const [ loading, setLoading ] = useState(true);
    const [ address, setAddress ] = useState('');
    const [ account, setAccount ] = useState({});

    useEffect(() => {
        let addresslist = [];
        if (props.info.address.address1 !== '') {
            addresslist.push(props.info.address.address1);
        }
        if (props.info.address.address2 !== '') {
            addresslist.push(props.info.address.address2);
        }
        if (props.info.address.subdistrict !== '') {
            for (const thissubdistrict in initaddress.subdistrict) {
                if (initaddress.subdistrict[thissubdistrict]['value'] === props.info.address.subdistrict.toString()) {
                    addresslist.push(initaddress.subdistrict[thissubdistrict]['label']);
                    break;
                }
            }
        }
        if (props.info.address.district !== '') {
            for (const thisdistrict in initaddress.district) {
                if (initaddress.district[thisdistrict]['value'] === props.info.address.district.toString()) {
                    addresslist.push(initaddress.district[thisdistrict]['label']);
                    break;
                }
            }
        }
        if (props.info.address.province !== '') {
            for (const thisprovince in initaddress.province) {
                if (initaddress.province[thisprovince]['value'] === props.info.address.province.toString()) {
                    addresslist.push(initaddress.province[thisprovince]['label']);
                    break;
                }
            }
        }
        if (props.info.address.postalcode !== '') {
            addresslist.push(props.info.address.postalcode);
        }
        setAddress(addresslist.join(' '));
        getAccount();
    }, []);

    const getAccount = () => {
        fetch(config.url + 'admin/account/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.token,
                user_id: props.info.id.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setAccount(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <div className='row m-0'>
            <div className='col-12 col-md-6 p-0 mb-7 mb-md-0'>
                <h2 className='d-inline-block mb-5'>รายละเอียดสมาชิก</h2>
                <div className='row justify-content-between m-0'>
                    <div className='col-12 col-md-6 form-group'>
                        <h6 className='d-block m-0'> ชื่อ : {props.info.first_name} </h6>
                    </div>
                    <div className='col-12 col-md-6 form-group'>
                        <h6 className='d-block m-0'> นามสกุล : {props.info.last_name} </h6>
                    </div>
                    <div className='col-12 col-md-6 form-group'>
                        <h6 className='d-block m-0'>
                            {' '}
                            ประเภทข้อมูลประจำตัว : {props.info.id_type === '1' ? 'ไทย' : 'ต่างชาติ'}{' '}
                        </h6>
                    </div>
                    <div className='col-12 col-md-6 form-group'>
                        <h6 className='d-block m-0'>
                            {' '}
                            หมายเลข{props.info.id_type === '1' ? 'บัตรประชาชน' : 'พาสปอร์ต'} : {props.info.personal_id}{' '}
                        </h6>
                    </div>
                    <div className='col-12 col-md-6 form-group'>
                        <h6 className='d-block m-0'> เบอร์ติดต่อ : {props.info.contact_number} </h6>
                    </div>
                    <div className='col-12 col-md-6 form-group'>
                        <h6 className='d-block m-0'>
                            {' '}
                            วัน/เดือน/ปีเกิด :{' '}
                            {Moment(new Date(props.info.birthdate))
                                .add(543, 'year')
                                .locale('th')
                                .format('DD/MMMM/YYYY')}{' '}
                        </h6>
                    </div>
                    <div className='col-12 form-group'>
                        <h6 className='d-block m-0'> ที่อยู่ : {address} </h6>
                    </div>
                </div>
                <h2 className='d-inline-block mb-5'>รายละเอียดบัญชี</h2>
                {loading ? (
                    <div className='row align-items-center col-12 p-0 m-0 max-h-200px'>
                        <div className='text-center col-12 p-0'>
                            <i className='text-dark-75 fas fa-spinner fa-pulse fa-5x' />
                            <h1 className='col mt-5'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                ) : (
                    <OverlayScrollbarsComponent className='max-h-200px'>
                        <div className='card-body py-0 px-5'>
                            {Object.keys(account).length > 0 ? (
                                <>
                                    {account.responsecode === 0 ? (
                                        <>
                                            {account.account.map((object, index, arr) => {
                                                return (
                                                    <div
                                                        className={`inline-card border-solid rounded-lg${arr.length -
                                                            1 !==
                                                            index && ' mb-5'}`}
                                                        key={index}
                                                    >
                                                        <div className='card border-0 p-5'>
                                                            <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                <div className='symbol-label'>
                                                                    <i
                                                                        className={`fas fa-${object.account_type === '1'
                                                                            ? 'university'
                                                                            : object.account_type === '2' ? 'users' : 'coins'} fa-2x`}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p className='card-number font-size-h6 m-0'>
                                                                {object.account_type === '1' ? 'หุ้นสหกรณ์' : object.account_type === '2' ? 'ฌาปนกิจ' : 'หน่วยลงทุน'}
                                                            </p>
                                                            <p className='card-number font-size-h6 m-0 ml-auto'>
                                                                <NumberFormat
                                                                    value={parseFloat(object.balance)}
                                                                    displayType={'text'}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    thousandSeparator={true}
                                                                    suffix={
                                                                        object.account_type === '1' ? ' หุ้น' : ' บาท'
                                                                    }
                                                                />
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <div className='row align-items-center col-12 p-0 m-0 min-h-100px'>
                                            <div className='text-center col-12 p-0'>
                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                <h1 className='col mt-5'>ไม่พบข้อมูล</h1>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className='row align-items-center col-12 p-0 m-0 min-h-100px'>
                                    <div className='text-center col-12 p-0'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                    </div>
                                </div>
                            )}
                        </div>
                    </OverlayScrollbarsComponent>
                )}
            </div>
            <div className='col-12 col-md-6 p-0'>
                <h2 className='d-inline-block mb-5'>รายละเอียดผู้รับผลประโยชน์</h2>
                {props.info.beneficiary.length > 0 ? (
                    <OverlayScrollbarsComponent className='max-h-400px'>
                        <div className='row justify-content-between m-0 p-5'>
                            {props.info.beneficiary.map((object, index, arr) => {
                                let thisaddress = [];
                                if (object.address1 !== '') {
                                    thisaddress.push(object.address1);
                                }
                                if (object.address2 !== '') {
                                    thisaddress.push(object.address2);
                                }
                                if (object.subdistrict !== '') {
                                    thisaddress.push(object.subdistrict);
                                }
                                if (object.district !== '') {
                                    thisaddress.push(object.district);
                                }
                                if (object.province !== '') {
                                    thisaddress.push(object.province);
                                }
                                if (object.postalcode !== '') {
                                    thisaddress.push(object.postalcode);
                                }
                                let thisnewaddress = thisaddress.join(' ');
                                return (
                                    <div
                                        className={`card card-custom rounded-lg bg-white col-12 p-0${arr.length - 1 !==
                                            index && ' gutter-b'}`}
                                        key={index}
                                    >
                                        <div className='card-body'>
                                            <div className='row m-0'>
                                                <div className='col-12 form-group'>
                                                    <h6 className='d-block m-0'> ชื่อ : {object.contact_firstname} </h6>
                                                </div>
                                                <div className='col-12 form-group'>
                                                    <h6 className='d-block m-0'>
                                                        {' '}
                                                        นามสกุล : {object.contact_lastname}{' '}
                                                    </h6>
                                                </div>
                                                {/* <div className='col-12 form-group'>
                                                    <h6 className='d-block m-0'> ประเภทข้อมูลประจำตัว : {object.contact_lastname} </h6>
                                                </div>
                                                <div className='col-12 form-group'>
                                                    <h6 className='d-block m-0'> หมายเลขประจำตัว : test </h6>
                                                </div> */}
                                                <div className='col-12 form-group'>
                                                    <h6 className='d-block m-0'>
                                                        {' '}
                                                        เบอร์ติดต่อ : {object.contact_phonenumber}{' '}
                                                    </h6>
                                                </div>
                                                {/* <div className='col-12 form-group'>
                                                    <h6 className='d-block m-0'> วัน/เดือน/ปีเกิด : test </h6>
                                                </div> */}
                                                <div className='col-12 form-group'>
                                                    <h6 className='d-block m-0'> ที่อยู่ : {thisnewaddress} </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </OverlayScrollbarsComponent>
                ) : (
                    <div className='row align-items-center col-12 p-0 m-0 min-h-100px'>
                        <div className='text-center col-12 p-0'>
                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                            <h1 className='col mt-5'>ไม่พบข้อมูล</h1>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
