import React from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export function MemberSearchResult(props) {
    return (
        <div className='col-12 mt-5 search-result'>
            {Object.keys(props.response).length > 0 ? (
                <>
                    {props.response.responsecode === 0 ? (
                        <OverlayScrollbarsComponent className='max-h-400px'>
                            <div className='card rounded-lg'>
                                <div className='card-body'>
                                    {props.response.info.map((object, index) => {
                                        return (
                                            <div
                                                className='search-result new-job bg-hover-success cursor-pointer my-3'
                                                onClick={() => props.btnView(object.id.toString())}
                                                key={index}
                                            >
                                                {/* <div className='badge'>ปัจจุบัน</div> */}
                                                <div className='content p-0'>
                                                    <h2>
                                                        <div className='text-dark'>{object.personal_id}</div>
                                                        <div className='text-dark font-size-h4'>
                                                            {object.first_name === '' ? '{ชื่อ}' : object.first_name}{' '}
                                                            {object.last_name === '' ? '{นามสกุล}' : object.last_name}
                                                        </div>
                                                    </h2>
                                                    <div className='actions'>
                                                        <span className='date'>
                                                            ลงทะเบียนเมื่อวันที่ {object.created_date}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </OverlayScrollbarsComponent>
                    ) : (
                        <div className='row bg-white align-items-center col-12 p-0 m-0 min-h-400px rounded-lg'>
                            <div className='text-center col-12 p-0'>
                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                <h1 className='col mt-5'>ไม่พบข้อมูล</h1>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div className='row bg-white align-items-center col-12 p-0 m-0 min-h-400px rounded-lg'>
                    <div className='text-center col-12 p-0'>
                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                        <h1 className='col mt-5'>ไม่พบข้อมูล</h1>
                    </div>
                </div>
            )}
        </div>
    );
}
