import React, { Suspense } from 'react';
import { Redirect, Switch, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
// import {BuilderPage} from "./pages/BuilderPage";
// import {MyPage} from "./pages/MyPage";
// import {DashboardPage} from "./pages/DashboardPage";
import { DashboardMain } from './pages/customs/dashboard/main';
import { MemberMain } from './pages/customs/member/information/main';
import { ReportManagementMain } from './pages/customs/report/management/main';
import SettingChangePasswordMain from './pages/customs/setting/changepassword/main';

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );

function usePageViews() {
    const { info } = useSelector((state) => state.auth);
    let location = useLocation();
    let history = useHistory();
    React.useEffect(
        () => {
            // console.log([ 'pageview', location.pathname ]);
            if (new Date() >= new Date(info.token_expire)) {
                history.push('/logout');
            }
        },
        [ location ]
    );
}

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect
    usePageViews();

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from='/' to='/dashboard' />
                }
                <ContentRoute path='/dashboard' component={DashboardMain} />
                <ContentRoute path='/member' component={MemberMain} />
                <ContentRoute path='/report/management' component={ReportManagementMain} />
                <ContentRoute path='/setting/changepassword' component={SettingChangePasswordMain} />
                {/* <ContentRoute path="/builder" component={BuilderPage}/> */}
                {/* <ContentRoute path="/my-page" component={MyPage}/> */}
                {/* <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/> */}
                <Redirect to='error/error-v1' />
            </Switch>
        </Suspense>
    );
}
