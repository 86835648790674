import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import $ from 'jquery';

import { Dropdown, Modal } from 'react-bootstrap';

import { QRCode } from 'react-qrcode-logo';

import Moment from 'moment';

import html2canvas from 'html2canvas';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import NumberFormat from 'react-number-format';

import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';

import config from '../../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function MemberStock(props) {
    const { info } = useSelector((state) => state.auth);

    const [ modalloading, setModalLoading ] = useState(false);
    const [ modalresponse, setModalResponse ] = useState({});
    const [ showStockModal, setStockShow ] = useState(false);
    const [ qrcode, setQrcode ] = useState('');

    const handleStockClose = () => setStockShow(false);
    const handleStockShow = () => setStockShow(true);

    useEffect(() => {
        $(document).ready(function() {
            $('#stock_table').DataTable({
                data: props.data.orders,
                lengthChange: false,
                pageLength: -1,
                responsive: true,
                dom: 'Bfrtip',
                buttons: [ 'excel' ],
                order: [ [ 6, 'asc' ] ],
                language: {
                    paginate: {
                        previous: 'ก่อนหน้า',
                        next: 'ถัดไป'
                    },
                    info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                    infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                    emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                    infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                    zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                },
                oLanguage: {
                    sSearch: 'คำสำคัญ'
                },
                columns: [
                    {
                        title: 'ดูรายละเอียด',
                        className: 'dt-body-center',
                        data: null,
                        render: function(data, type, row) {
                            return `<button
                                            type="button"
                                            class="btn btn-icon btn-primary rounded-lg stock-order-management"
                                            data-orderid="${data.id}"
                                        >
                                            <i class="fa fa-search"></i>
                                        </button>`;
                        }
                    },
                    {
                        title: 'ประเภทรายการ',
                        // data: "name",
                        data: function(value) {
                            if (value.type === 'REG') {
                                return 'รายการสมัคร';
                            } else {
                                return 'รายการสั่งซื้อ';
                            }
                        }
                    },
                    {
                        title: 'ช่องทางการชำระเงิน',
                        // data: "name",
                        data: function(value) {
                            if (value.payment === 1) {
                                return 'กระเป๋าเครดิตปันสุข';
                            } else {
                                return 'คิวอาร์โค้ด';
                            }
                        }
                    },
                    {
                        title: 'สถานะรายการ',
                        // data: "name",
                        data: function(value) {
                            if (value.status === 'paid') {
                                return 'ชำระแล้ว';
                            } else {
                                if (new Date() > new Date(value.created_date).setDate(new Date(value.created_date).getDate()+1)) {
                                    if (new Date() > new Date(value.updated_date).setDate(new Date(value.updated_date).getDate()+1)) {
                                        return 'หมดอายุ';
                                    } else {
                                        return 'รอดำเนินการ';
                                    }
                                } else {
                                    return 'รอดำเนินการ';
                                }
                            }
                        }
                    },
                    {
                        title: 'จำนวน',
                        // data: "name",
                        data: function(value) {
                            return parseInt(value.amount).toString() + ' หุ้น';
                        }
                    },
                    {
                        title: 'ราคารวม',
                        // data: "name",
                        data: function(value) {
                            return parseFloat(value.total_gross_price).toFixed(2).toString() + ' บาท';
                        }
                    },
                    {
                        title: 'วันที่ทำรายการ',
                        // data: "name",
                        data: function(value) {
                            return value.created_date;
                        }
                    }
                    // {
                    //     title: 'วันที่',
                    //     // data: "name",
                    //     data: function(value) {
                    //         if (value.date !== null && value.date !== '') {
                    //             return value.date;
                    //         } else {
                    //             return '-';
                    //         }
                    //     }
                    // }
                ]
            });

            $('#stock_table_wrapper').addClass('p-5 bg-white rounded-lg');
            // $('#react-qrcode-logo').addClass('bg-red');
        });
    }, []);

    useEffect(() => {
        $(document).ready(function() {
            $('.stock-order-management').click(function() {
                btnMoreDetail($(this).attr('data-orderid'));
            });
        });
    }, []);

    const btnMoreDetail = (orderid) => {
        // setModalLoading(true);
        // console.log(orderid);
        // setModalLoading(false);
        setModalLoading(true);
        fetch(config.url + 'admin/order/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.token,
                id: orderid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setModalLoading(false);
                if (result.responsecode === 0) {
                    setModalResponse(result);
                    setQrcode(result.orders.qr);
                    handleStockShow();
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถดึงรายละเอียดเพิ่มเติมได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return false;
                }
            })
            .catch((error) => {
                setModalLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'ไม่สามารถดึงรายละเอียดเพิ่มเติมได้ กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return false;
            });
    };

    const btnDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `payment-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    const btnRegenQRcode = (orderid) => {
        setModalLoading(true);
        fetch(config.url + 'admin/order/regenerate-qr', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.token,
                order_id: orderid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setModalLoading(false);
                if (result.responsecode === 0) {
                    setQrcode(result.qr);
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `ดำเนินการสร้างคิวอาร์โค๊ดใหม่เรียบร้อยแล้ว`,
                        icon: 'success',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return false;
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถดึงรายละเอียดเพิ่มเติมได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return false;
                }
            })
            .catch((error) => {
                setModalLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'ไม่สามารถดึงรายละเอียดเพิ่มเติมได้ กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return false;
            });
    };

    return (
        <>
            {props.data.responsecode === 0 ? (
                <table id='stock_table' className='table table-striped table-bordered w-100' />
            ) : props.data.responsecode === 101 ? (
                <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                    <div className='text-center col-12 p-0'>
                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                        <h1 className='col mt-5'>ไม่พบรายละเอียดหุ้น กรุณาลองใหม่อีกครั้งภายหลัง</h1>
                    </div>
                </div>
            ) : (
                <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                    <div className='text-center col-12 p-0'>
                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                        <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                    </div>
                </div>
            )}

            {modalloading && (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}

            {Object.keys(modalresponse).length > 0 && (
                <Modal show={showStockModal} onHide={handleStockClose} size='xl' centered aria-labelledby='stock-order-detail-modal'>
                    <Modal.Header closeButton>
                        <Modal.Title id='stock-order-detail-modal' className='col-11 p-0'>
                            รายละเอียด
                        </Modal.Title>
                        <button type='button' className='close col-1 text-right pr-3' onClick={handleStockClose}>
                            <i className='ki ki-close' />
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row d-flex justify-content-center'>
                            {modalresponse.orders.payment === 'QR-Code' && (
                                <div className='col-lg-6 col-12 p-0'>
                                    <div className='card gutter-b rounded-lg'>
                                        <div className='card-header p-3 bg-secondary rounded-lg rounded-bottom-0'>
                                            <div className='card-toolbar text-right'>
                                                <Dropdown className='rounded-lg'>
                                                    <Dropdown.Toggle
                                                        className='rounded-lg'
                                                        id='dropdown-autoclose-true'
                                                    >
                                                        คำสั่ง
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={btnDownload}>
                                                            บันทึกคิวอาร์โค๊ด
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() =>
                                                                btnRegenQRcode(modalresponse.orders.id.toString())}
                                                            disabled={modalresponse.orders.status === 'paid'}
                                                        >
                                                            ขอคิวอาร์โค๊ดใหม่
                                                        </Dropdown.Item>
                                                        {/* <Dropdown.Item href="#">ยกเลิกรายการ</Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <div className='row m-0'>
                                                <div className='col-12 p-0'>
                                                    <div className='text-center'>
                                                        <QRCode
                                                            className='border'
                                                            value={qrcode}
                                                            size={256}
                                                            eyeRadius={10}
                                                            logoImage={toAbsoluteUrl(
                                                                '/media/logos/cooperative-logo-bg.png'
                                                            )}
                                                            logoWidth={100}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='col-12 p-0'>
                                <div className='bg-light rounded-lg d-flex flex-column p-3'>
                                    <div className='p-2 d-flex'>
                                        <div className='col-6'>ชำระเงินผ่านช่องทาง</div>
                                        <div className='ml-auto text-right'>
                                            {modalresponse.orders.payment === 'QR-Code' ? (
                                                'คิวอาร์โค้ด'
                                            ) : (
                                                'กระเป๋าปันสุข'
                                            )}
                                        </div>
                                    </div>
                                    <div className='p-2 d-flex'>
                                        <div className='col-6'>สถานะการทำรายการ</div>
                                        <div className='ml-auto text-right'>
                                            {modalresponse.orders.status === 'paid' ? (
                                                'ดำเนินการเรียบร้อย'
                                            ) : (
                                                'รอการชำระเงิน'
                                            )}
                                        </div>
                                    </div>
                                    <div className='p-2 d-flex'>
                                        <div className='col-6'>ยอดรวม</div>
                                        <div className='ml-auto text-right'>
                                            <NumberFormat
                                                value={parseFloat(modalresponse.orders.total_gross_price)}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                                suffix={' บาท'}
                                            />
                                        </div>
                                    </div>
                                    <div className='p-2 d-flex'>
                                        <div className='col-12'>รายละเอียดค่าบริการ</div>
                                    </div>
                                    <div className='p-2 d-flex'>
                                        <div className='col-12'>
                                            <div className='table-responsive'>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th className='pl-0 font-weight-bold text-muted text-uppercase'>
                                                                ชื่อบริการ
                                                            </th>
                                                            <th className='text-right font-weight-bold text-muted text-uppercase'>
                                                                จำนวน
                                                            </th>
                                                            <th className='text-right font-weight-bold text-muted text-uppercase'>
                                                                ราคาต่ออัน
                                                            </th>
                                                            <th className='text-right pr-0 font-weight-bold text-muted text-uppercase'>
                                                                ราคารวม
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {modalresponse.orders.order_item.map((object, index) => {
                                                            return (
                                                                <tr
                                                                    className='font-weight-boldest font-size-lg'
                                                                    key={index}
                                                                >
                                                                    <td className='pl-0 pt-7'>{object.item_name}</td>
                                                                    <td className='text-right pt-7'>
                                                                        {object.quantity}
                                                                    </td>
                                                                    <td className='text-right pt-7'>
                                                                        <NumberFormat
                                                                            value={parseFloat(object.price)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />
                                                                    </td>
                                                                    <td className='text-primary pr-0 pt-7 text-right'>
                                                                        <NumberFormat
                                                                            value={parseFloat(object.gross_price)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}
