import React, { useState } from 'react';

import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import { UserWidget, StockWidget } from './components';

const mockupNewuser = {
    datelist: [ '08/10', '09/10', '10/10', '11/10', '12/10' ],
    useramountlist: [ 10, 25, 100, 7, 40 ]
};

export function DashboardMain() {
    const [ newuser, setNewuser ] = useState(mockupNewuser);
    const [ stocks, setStock ] = useState(mockupNewuser);
    return (
        <div className='row'>
            <div className='col-12 p-0 text-center'>
                <h1 className='mb-5'>mockup page (ตัวอย่างข้อมูล)</h1>
            </div>
            <div className='col-lg-6 col-12'>
                <div className='card card-custom card-stretch gutter-b rounded-lg'>
                    <div className='card-body d-flex flex-column p-0'>
                        <div className='d-flex align-items-center justify-content-between card-spacer flex-grow-1'>
                            <span className='symbol symbol-circle symbol-50 symbol-light-primary mr-2'>
                                <span className='symbol-label'>
                                    <span className='svg-icon svg-icon-xl svg-icon-primary'>
                                        <SVG
                                            title='ผู้ใช้งานทั้งหมดในระบบ'
                                            src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')}
                                        />
                                    </span>
                                </span>
                            </span>
                            <div className='d-flex flex-column text-right'>
                                <span className='text-dark-75 font-weight-bolder font-size-h3'>89</span>
                                <span className='text-muted font-weight-bold mt-2'>ผู้ใช้งานทั้งหมดในระบบ</span>
                            </div>
                        </div>
                        <div className='separator separator-solid separator-border-4 mt-3 mb-3' />
                        <div className='row card-spacer'>
                            <div className='col-6 d-flex flex-column text-center border-right'>
                                <span className='text-success font-weight-bolder font-size-h3'>75</span>
                                <span className='text-muted font-weight-bold mt-2'>ชำระเงินแล้ว</span>
                            </div>
                            <div className='col-6 d-flex flex-column text-center'>
                                <span className='text-danger font-weight-bolder font-size-h3'>15</span>
                                <span className='text-muted font-weight-bold mt-2'>ยังไม่ได้ชำระเงิน</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-lg-6 col-12'>
                <UserWidget newuser={newuser} />
            </div>

            <div className='col-lg-6 col-12'>
                <div className='card card-custom card-stretch gutter-b rounded-lg'>
                    <div className='card-body d-flex flex-column p-0'>
                        <div className='d-flex align-items-center justify-content-between card-spacer flex-grow-1'>
                            <span className='symbol symbol-circle symbol-50 symbol-light-primary mr-2'>
                                <span className='symbol-label'>
                                    <span className='svg-icon svg-icon-xl svg-icon-primary'>
                                        <SVG
                                            title='จำนวนหุ้นทั้งหมดในระบบ'
                                            src={toAbsoluteUrl('/media/svg/icons/Shopping/Chart-pie.svg')}
                                        />
                                    </span>
                                </span>
                            </span>
                            <div className='d-flex flex-column text-right'>
                                <span className='text-dark-75 font-weight-bolder font-size-h3'>89</span>
                                <span className='text-muted font-weight-bold mt-2'>จำนวนหุ้นทั้งหมดในระบบ</span>
                            </div>
                        </div>
                        <div className='separator separator-solid separator-border-4 mt-3 mb-3' />
                        <div className='row card-spacer'>
                            <div className='col-12 d-flex flex-column text-center border-right'>
                                <span className='text-success font-weight-bolder font-size-h3'>75</span>
                                <span className='text-muted font-weight-bold mt-2'>
                                    จำนวนหุ้นที่ซื้อได้สูงสุดต่อครั้ง
                                </span>
                            </div>
                            {/* <div className='col-6 d-flex flex-column text-center'>
                                <span className='text-danger font-weight-bolder font-size-h3'>15</span>
                                <span className='text-muted font-weight-bold mt-2'>ยังไม่ได้ชำระเงิน</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-lg-6 col-12'>
                <StockWidget stocks={stocks} />
            </div>
        </div>
    );
}
