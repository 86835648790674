import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { connect } from 'react-redux';

import { injectIntl } from 'react-intl';

import * as auth from '../../../../modules/Auth/_redux/authRedux';

import config from '../../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

function SettingChangePasswordMain(props) {
    const { info } = useSelector((state) => state.auth);

    const [ loading, setLoading ] = useState(false);
    const [ password, setPassword ] = useState('');
    const [ confirmpassword, setConfirmPassword ] = useState('');

    const btnSubmit = () => {
        if (password === '') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณากรอกรหัสผ่านใหม่ให้เรียบร้อย',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        }
        if (confirmpassword === '') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณายืนยันรหัสผ่านใหม่ให้เรียบร้อย',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false
            });
            return;
        } else {
            if (password !== confirmpassword) {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณายืนยันรหัสผ่านใหม่ให้ถูกต้อง',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            }
        }
        setLoading(true);
        fetch(config.url + 'admin/member/change-password', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: info.token,
                username: info.username,
                old_password: info.password,
                new_password: password,
                confirm_password: confirmpassword
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: 'ดำเนินการเปลี่ยนแปลงรหัสผ่านเรียบร้อย',
                        icon: 'success',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            let newinfo = info;
                            newinfo['password'] = password;
                            props.login(newinfo);
                            setPassword('');
                            setConfirmPassword('');
                        }
                    });
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถทำรายการได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return false;
                }
                // setResponse(result);
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'ไม่สามารถทำรายการได้ กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return false;
            });
    };
    return (
        <div className='setting-change-password-page'>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='row justify-content-center align-content-center min-h-500px'>
                    <div className='col-lg-8 col-12 p-md-20 pt-10 bg-white'>
                        <div className='row justify-content-center'>
                            <div className='col-12 text-center p-0'>
                                <h6 className='text-dark font-weight-bold mb-10'>เปลี่ยนรหัสผ่าน</h6>
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-form-label col-3 text-lg-right text-left'>รหัสผ่านใหม่</label>
                            <div className='col-9'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg rounded-lg'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='form-group row'>
                            <label className='col-form-label col-3 text-lg-right text-left'>ยืนยันรหัสผ่านใหม่</label>
                            <div className='col-9'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg rounded-lg'
                                    value={confirmpassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='row justify-content-center'>
                            <button type='button' className='btn btn-primary rounded-lg' onClick={btnSubmit}>
                                บันทึกการเปลี่ยนแปลง
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(SettingChangePasswordMain));
