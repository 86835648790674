import React from 'react';

export function ReportManagementSearchbar(props) {
    return (
        <div className='col-12 p-0'>
            <div className='card rounded-lg'>
                <div className='card-header bg-primary text-white rounded-lg rounded-bottom-0'>
                    <i className='fa fa-search text-white mr-3' />
                    <strong>ค้นหา</strong>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='form-group col-sm-6'>
                            <label>ประเภทการค้นหา</label>
                            {/* <Select
                                defaultValue={this.filter[0]}
                                options={this.filter}
                                placeholder="เลือก"
                                onChange={this.selectFilter}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            /> */}
                        </div>
                        <div className='form-group col-sm-6'>
                            <label>ข้อมูลที่ต้องการค้นหา</label>
                            {/* {this.state.selected === 'ordernumber' || this.state.selected === '' ?
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    id="ordernumber"
                                /> :
                                <NumberFormat
                                    type="text"
                                    className="form-control"
                                    id="phonenumber"
                                    onChange={this.handleChange}
                                    customInput={Input}
                                    allowEmptyFormatting
                                    format="0#########"
                                    mask="_"
                                />
                            } */}
                        </div>
                    </div>
                </div>
                <div className='card-footer text-right'>
                    <button
                        type='button'
                        // onClick={this.search}
                        className='btn btn-sm btn-primary'
                    >
                        <i className='far fa-dot-circle' /> ค้นหา
                    </button>
                </div>
            </div>
        </div>
    );
}
