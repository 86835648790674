import React, { useState } from 'react';

import { Nav, Tab } from 'react-bootstrap';

import { MemberInformation, MemberEdit, MemberStock, MemberFuneral } from './index';

export function MemberDetail(props) {
    const btnBack = () => {
        props.setPage('main');
        props.setTab('information');
        props.setUserinfo({});
        props.setOrder({});
    };

    const handleSelect = (tab) => {
        if (tab === 'stock') {
            props.getOrder(props.userinfo.info.id.toString(), '1');
        } else if (tab === 'funeral') {
            props.getOrder(props.userinfo.info.id.toString(), '2');
        } else {
            props.setTab(tab);
        }
    };
    return (
        <div className='col-12'>
            <div className='card rounded-lg'>
                <div className='card-header bg-secondary p-4 rounded-lg rounded-bottom-0'>
                    <div className='row justify-content-between m-0'>
                        <button type='button' className='btn btn-link-primary' onClick={btnBack}>
                            <i className='fas fa-angle-double-left icon-2x py-3' />
                            ย้อนกลับ
                        </button>
                    </div>
                </div>
                <div className='card-body rounded-lg rounded-top-0'>
                    <div className='d-inline-block mb-5'>
                        <span>
                            <div>
                                <span className='d-inline-block'>ข้อมูลสมาชิก : Information</span>
                            </div>
                            <div>
                                <h1>
                                    {props.userinfo.info.first_name === '' ? (
                                        '{ชื่อ}'
                                    ) : (
                                        props.userinfo.info.first_name
                                    )}{' '}
                                    {props.userinfo.info.last_name === '' ? '{นามสกุล}' : props.userinfo.info.last_name}
                                </h1>
                            </div>
                        </span>
                    </div>
                    <Tab.Container defaultActiveKey={props.tab} onSelect={handleSelect}>
                        <div className='row'>
                            <div className='col-12 p-0'>
                                <Nav fill variant='tabs'>
                                    <Nav.Item>
                                        <Nav.Link eventKey='information'>รายละเอียดทั่วไป</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='edit' disabled>
                                            แก้ไขรายละเอียดทั่วไป
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='stock'>รายละเอียดหุ้น</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey='funeral'>รายละเอียดกองทุนฌาปนกิจ</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className='col-12 p-0'>
                                <Tab.Content>
                                    <Tab.Pane eventKey='information' className='p-5 bg-gray-300'>
                                        <MemberInformation info={props.userinfo.info} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='edit' className='p-5 bg-gray-300'>
                                        <MemberEdit info={props.userinfo.info} btnUpdate={props.btnUpdate} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='stock' className='p-5 bg-gray-300'>
                                        {Object.keys(props.order).length > 0 && <MemberStock data={props.order} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey='funeral' className='p-5 bg-gray-300'>
                                        {Object.keys(props.order).length > 0 && <MemberFuneral data={props.order} />}
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
            </div>
        </div>
    );
}
