import React, { useEffect, useMemo } from 'react';

import objectPath from 'object-path';

import ApexCharts from 'apexcharts';

import SVG from 'react-inlinesvg';

import { useHtmlClassService } from '../../../../../_metronic/layout';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export function UserWidget(props) {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(
        () => {
            return {
                colorsGrayGray500: objectPath.get(uiService.config, 'js.colors.gray.gray500'),
                colorsGrayGray200: objectPath.get(uiService.config, 'js.colors.gray.gray200'),
                colorsGrayGray300: objectPath.get(uiService.config, 'js.colors.gray.gray300'),
                colorsThemeBasePrimary: objectPath.get(uiService.config, 'js.colors.theme.base.primary'),
                colorsThemeLightPrimary: objectPath.get(uiService.config, 'js.colors.theme.light.primary'),
                fontFamily: objectPath.get(uiService.config, 'js.fontFamily')
            };
        },
        [ uiService ]
    );
    useEffect(
        () => {
            const element = document.getElementById('user_widget_chart');

            if (!element) {
                return;
            }

            const options = getChartOption(layoutProps, props.newuser);
            const chartnewUsers = new ApexCharts(element, options);
            chartnewUsers.render();
            return function cleanUp() {
                chartnewUsers.destroy();
            };
        },
        [ layoutProps ]
    );
    return (
        <div className='card card-custom card-stretch gutter-b rounded-lg'>
            <div className='card-body d-flex flex-column p-0'>
                <div className='d-flex align-items-center justify-content-between card-spacer flex-grow-1'>
                    <span className='symbol symbol-circle symbol-50 symbol-light-primary mr-2'>
                        <span className='symbol-label'>
                            <span className='svg-icon svg-icon-xl svg-icon-primary'>
                                <SVG
                                    title='ผู้ใช้งานใหม่ประจำวัน'
                                    src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')}
                                />
                            </span>
                        </span>
                    </span>
                    <div className='d-flex flex-column text-right'>
                        <span className='text-dark-75 font-weight-bolder font-size-h3'>40</span>
                        <span className='text-muted font-weight-bold mt-2'>ผู้ใช้งานใหม่ประจำวัน</span>
                    </div>
                </div>
                <div id='user_widget_chart' className='card-rounded-bottom-lg rounded-bottom-lg bg-white' />
            </div>
        </div>
    );
}

function getChartOption(layoutProps, data) {
    var options = {
        series: [
            {
                name: 'ผู้ใช้งานใหม่',
                data: data.useramountlist
            }
        ],
        chart: {
            type: 'area',
            height: 150,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {},
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            type: 'solid',
            opacity: 1
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [ layoutProps.colorsThemeBasePrimary ]
        },
        xaxis: {
            categories: data.datelist,
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: false,
                style: {
                    colors: layoutProps.colorsGrayGray500,
                    fontSize: '12px',
                    fontFamily: layoutProps.fontFamily
                }
            },
            crosshairs: {
                show: false,
                position: 'front',
                stroke: {
                    color: layoutProps.colorsGrayGray300,
                    width: 1,
                    dashArray: 3
                }
            },
            tooltip: {
                enabled: false,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                    fontFamily: 'Kanit'
                }
            }
        },
        yaxis: {
            min: 0,
            max: Math.max(...data.useramountlist) + 10,
            labels: {
                show: false,
                style: {
                    colors: layoutProps.colorsGrayGray500,
                    fontSize: '12px',
                    fontFamily: 'Kanit'
                }
            }
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0
                }
            }
        },
        tooltip: {
            style: {
                fontSize: '12px',
                fontFamily: 'Kanit'
            },
            y: {
                formatter: function(val) {
                    return val + ' คน';
                }
            }
        },
        colors: [ layoutProps.colorsThemeLightPrimary ],
        markers: {
            colors: [ layoutProps.colorsThemeLightPrimary ],
            strokeColor: [ layoutProps.colorsThemeBasePrimary ],
            strokeWidth: 3
        }
    };
    return options;
}
